import {filter, flatMap, map} from 'rxjs/operators';
import {GenericEpic, actionOf, pipe} from '@/store/epic-helper';
import {UIActions} from '@/store/ui/actions';
import {findPageRouteStack, matchRoute} from '@/router/routes';
import {currentRouteSelector, pagesSelector} from '@/store/ui/selectors';
import {goBack, push, replace} from 'connected-react-router';

const locationChangedEpic: GenericEpic = (action$, states$) =>
    action$.pipe(
        filter(action => {
            if (action.type !== '@@router/LOCATION_CHANGE') {
                return false;
            }

            const currentRouteStack = matchRoute(action.payload.location.pathname);
            if (currentRouteStack.length === 0) {
                return false;
            }

            if (action.payload.isFirstRendering === true) {
                return true;
            }

            const currentRoute = currentRouteSelector(states$.value);
            if (currentRoute == null) {
                return false;
            }

            return currentRoute.navigated !== action.payload.location.pathname;
        }),
        map(action => {
            const currentRouteStack = matchRoute(action.payload.location.pathname);

            const urlRoutePage = currentRouteStack[currentRouteStack.length - 1];

            if (action.payload.isFirstRendering === true) {
                return UIActions.replacePage(urlRoutePage);
            }

            if (action.payload.action === 'POP') {
                return UIActions.popPage();
            } else if (action.payload.action === 'REPLACE') {
                return UIActions.replacePage(urlRoutePage);
            } else if (action.payload.action === 'PUSH') {
                return UIActions.pushPage(urlRoutePage);
            }
        })
    );

const pushPageEpic: GenericEpic = (action$, states$) =>
    pipe(
        action$,
        actionOf(UIActions.pushPage),
        flatMap(() => {
            const currentRoute = currentRouteSelector(states$.value);
            const currentRouteStack = findPageRouteStack(currentRoute.page);
            const breadcrumbPath = currentRouteStack.reduce((acc, cur) => [...acc, cur.page], new Array<string>());

            return [
                push(currentRoute.navigated),
                UIActions.breadcrumbs(breadcrumbPath)
            ];
        })
    );

const replacePageEpic: GenericEpic = (action$, states$) =>
    pipe(
        action$,
        actionOf(UIActions.replacePage),
        flatMap(() => {
            const currentRoute = currentRouteSelector(states$.value);
            const currentRouteStack = findPageRouteStack(currentRoute.page);
            const breadcrumbPath = currentRouteStack.reduce((acc, cur) => [...acc, cur.page], new Array<string>());

            return [
                replace(currentRoute.navigated),
                UIActions.breadcrumbs(breadcrumbPath)
            ];
        })
    );

const popPageEpic: GenericEpic = (action$, state$) =>
    pipe(
        action$,
        actionOf(UIActions.popPage),
        flatMap(() => {
            const currentStack = pagesSelector(state$.value);
            if (currentStack == null || currentStack.length === 0) {
                return [goBack()];
            }

            const pageRouteStack = findPageRouteStack(currentStack[currentStack.length - 1].page);
            const breadcrumbPath = pageRouteStack.reduce((acc, cur) => [...acc, cur.page], new Array<string>());

            return [
                goBack(),
                UIActions.breadcrumbs(breadcrumbPath)
            ];
        })
    );

export default [
    pushPageEpic,
    replacePageEpic,
    popPageEpic,
    locationChangedEpic
];