/* eslint-disable @typescript-eslint/no-explicit-any */
import {combineEpics} from 'redux-observable';
import authenticationEpics from '@/store/authentication/epics';
import planboardEpics from '@/store/planboard/epics';
import generalEpics from '@/store/general/epics';
import uiEpics from '@/store/ui/epics';

export const epics: any = combineEpics(
    ...authenticationEpics,
    ...planboardEpics,
    ...generalEpics,
    ...uiEpics
);