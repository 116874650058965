import { RequestedTimeslot, onDeserializeRequestedTimeslot, onSerializeRequestedTimeslot} from './requested-timeslot';
import moment from 'moment';

export interface RequestIncidentalCommand {
    presentDate: moment.Moment;
    presentTimeslots: RequestedTimeslot[];
    comments?: string;
}

export function onDeserializeRequestIncidentalCommand(data: RequestIncidentalCommand): RequestIncidentalCommand {
    if (data == null) {
        return data;
    }

    if (data.presentDate != null) {
        data.presentDate = moment(data.presentDate);
    }
    if (data.presentTimeslots != null) {
        for (let i = 0; i < data.presentTimeslots.length; i++) {
            if (data.presentTimeslots[i] != null) {
                data.presentTimeslots[i] = onDeserializeRequestedTimeslot(data.presentTimeslots[i]);
            }
        }
    }

    return data;
}

export function onSerializeRequestIncidentalCommand(data: RequestIncidentalCommand): RequestIncidentalCommand {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    serialized.presentDate = data.presentDate?.format('YYYY-MM-DD') as unknown as moment.Moment;
    if (data.presentTimeslots != null) {
        for (let i = 0; i < data.presentTimeslots.length; i++) {
            serialized.presentTimeslots[i] = onSerializeRequestedTimeslot(data.presentTimeslots[i]);
        }
    }      

    return serialized;
}
