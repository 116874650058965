import { CareType, ApplicationType } from '@/client/models';
import moment from 'moment';
import { ScoredApplication } from './score-calculator';

export interface Summary {
    [location: string]: SummaryDetails;
}

export type CareTypeSummary = { [kind in CareType]: string[] };
export type ApplicationTypeSummary = { [kind in ApplicationType]: string[] };

export interface SummaryDetails {
    locations: string[];
    starting: MonthSummary;
    applicationTypes: ApplicationTypeSummary;
    newPerMonth: MonthSummary;
    changedPerMonth: MonthSummary;
    totalNew: string[];
    totalChanges: string[];
    brotherSister: string[];
    careTypes: CareTypeSummary;
    scorings: ScoringSummary;
}

export interface ScoringSummary {
    high: string[];
    medium: string[];
    low: string[];
}

export interface MonthSummary {
    [month: number]: string[];
}

export function monthToArray(month: MonthSummary): number[] {
    const values: number[] = [];

    for (const monthNumber of Object.keys(month)) {
        values.push(month[monthNumber].length);
    }

    return values;
}

function createNewMonthSummary(): MonthSummary {
    const summary: MonthSummary = { };

    for (let i = 0; i < 12; i++) {
        summary[i] = [];
    }

    return summary;
}

function createNewApplicationTypeSummary(): ApplicationTypeSummary {
    return {
        change: [],        
        new: [],        
        end: []
    };
}

function createNewCareTypeSummary(): CareTypeSummary {
    return {
        betweenSchoolCare: [],
        daycare: [],
        holidayCare: [],
        outOfSchoolCare: [],
        playschool: []
    };
}

function createNewScoringSummary(): ScoringSummary {
    return {
        high: [],
        medium: [],
        low: []
    };
}

export function getOrCreateSummaryDetails(summary: Summary, location: string): SummaryDetails {
    if (summary[location] == null) {
        const locationSummary: SummaryDetails = {
            locations: [ location ],
            starting: createNewMonthSummary(),
            applicationTypes: createNewApplicationTypeSummary(),
            newPerMonth: createNewMonthSummary(),
            changedPerMonth: createNewMonthSummary(),
            totalChanges: [],
            totalNew: [],
            brotherSister: [],
            careTypes: createNewCareTypeSummary(),
            scorings: createNewScoringSummary()
        };
        
        summary[location] = locationSummary;
    }

    return summary[location];
    
}

export function createSummary(applications: ScoredApplication[], forYear: number): Summary {
    const summary: Summary = { };

    for (const application of applications) { 
        
        const startingOn = moment(application.startsOn);
        const createdOn = moment(application.requested.on);

        if (startingOn.year() !== forYear && createdOn.year() !== forYear) {
            continue;
        }
        
        for (const location of application.preferredLocationIds) {
            const summaryDetails = getOrCreateSummaryDetails(summary, location);          

            summaryDetails.starting[startingOn.month()].push(application.applicationId);
            summaryDetails.applicationTypes[application.applicationType].push(application.applicationId);

            if (application.applicationType === ApplicationType.Change) {
                if (!summaryDetails.totalChanges.includes(application.applicationId)) {
                    summaryDetails.totalChanges.push(application.applicationId);
                }                
                summaryDetails.changedPerMonth[startingOn.month()].push(application.applicationId);            
            } else if (application.applicationType === ApplicationType.New) {
                if (!summaryDetails.totalNew.includes(application.applicationId)) {
                    summaryDetails.totalNew.push(application.applicationId);
                }
                summaryDetails.newPerMonth[startingOn.month()].push(application.applicationId);
            }

            if (application.hasSibling) {
                if (!summaryDetails.brotherSister.includes(application.applicationId)) {
                    summaryDetails.brotherSister.push(application.applicationId);
                }
            }

            for (const careType of application.careTypes) {
                if (!summaryDetails.careTypes[careType].includes(application.applicationId)) {
                    summaryDetails.careTypes[careType].push(application.applicationId);
                }
            }

            if (application.score <= 3.33) {
                summaryDetails.scorings.low.push(application.applicationId);
            } else if (application.score > 3.33 && application.score <= 6.66) {
                summaryDetails.scorings.medium.push(application.applicationId);
            } else if (application.score > 6.66) {
                summaryDetails.scorings.high.push(application.applicationId);
            }
        }
    }
    
    return summary;
}

function createMonthSummary(summaries: MonthSummary[]): MonthSummary {
    const result = createNewMonthSummary();

    for (const summary of summaries) {
        for (const month of Object.keys(summary)) {
            for (const applicationId of summary[month].filter(x => !result[month].includes(x))) {                
                result[month].push(applicationId);
            }
        }
    }

    return result;
}

function createApplicationTypeSummary(summaries: ApplicationTypeSummary[]): ApplicationTypeSummary {
    const result = createNewApplicationTypeSummary();

    for (const summary of summaries) {
        for (const applicationType of Object.keys(summary)) {
            for (const applicationId of summary[applicationType].filter(x => !result[applicationType].includes(x))) {                
                result[applicationType].push(applicationId);
            }
        }
    }

    return result;
}

function createCareTypeSummary(summaries: CareTypeSummary[]): CareTypeSummary {
    const result = createNewCareTypeSummary();

    for (const summary of summaries) {
        for (const careType of Object.keys(summary)) {
            for (const applicationId of summary[careType].filter(x => !result[careType].includes(x))) {                
                result[careType].push(applicationId);
            }
        }
    }

    return result;
}

function createScoringSummary(summaries: ScoringSummary[]): ScoringSummary {
    const result = createNewScoringSummary();

    for (const summary of summaries) {
        for (const scoringCategory of Object.keys(summary)) {
            for (const applicationId of summary[scoringCategory].filter(x => !result[scoringCategory].includes(x))) {                
                result[scoringCategory].push(applicationId);
            }
        }
    }

    return result;
}

function combine(collection1: string[], collection2: string[]): string[] {
    const result = collection1.slice(0);

    for (const item of collection2) {
        if (!result.includes(item)) {
            result.push(item);
        }
    }

    return result;
}

export function combineSummaries(summaries: SummaryDetails[]): SummaryDetails {
    return {
        locations: summaries.flatMap(x => x.locations),
        starting: createMonthSummary(summaries.map(x => x.starting)),
        applicationTypes: createApplicationTypeSummary(summaries.map(x => x.applicationTypes)),
        newPerMonth: createMonthSummary(summaries.map(x => x.newPerMonth)),
        changedPerMonth: createMonthSummary(summaries.map(x => x.changedPerMonth)),        
        totalChanges: summaries.map(x => x.totalChanges).reduce((acc, cur) => combine(acc, cur), []),
        totalNew: summaries.map(x => x.totalNew).reduce((acc, cur) => combine(acc, cur), []),
        brotherSister: summaries.map(x => x.brotherSister).reduce((acc, cur) => combine(acc, cur), []),
        careTypes: createCareTypeSummary(summaries.map(x => x.careTypes)),
        scorings: createScoringSummary(summaries.map(x => x.scorings))
    };
}