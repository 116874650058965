/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styled from 'styled-components';
import { DatePicker } from 'antd';
import { SmallButton, SmallPrimaryButton } from '@/components/buttons';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import { FilterDropdownProps } from 'antd/lib/table/interface';

export interface DateFilterProps extends FilterDropdownProps {
    onSearch?: (key: React.Key) => void;
    column: ColumnProps<any>;
}

export const DateFilter: React.FC<DateFilterProps> = props => {
    const { setSelectedKeys, confirm, clearFilters, onSearch } = props;
    let selectedKeys = props.selectedKeys;

    if (selectedKeys == null || selectedKeys.length === 0) {
        selectedKeys = [moment().toISOString()];
    }

    function handleSearch(): void {
        confirm();
        if (onSearch) {
            onSearch(selectedKeys[0]);
        }
    }

    function handleReset(): void {
        clearFilters();
        if (onSearch) {
            onSearch('');
        }
    }

    return (
        <Container>
            <DatePicker className='input'
                defaultValue={moment(selectedKeys[0])}
                onChange={date => setSelectedKeys(date ? [date.toISOString()] : [])}
                format={moment.localeData().longDateFormat('L')} />
            <SmallPrimaryButton translation='global.button.search' icon='search' onClick={handleSearch} />
            <SmallButton translation='global.button.reset' onClick={handleReset} />
        </Container>
    );
};


const Container = styled.div`
    padding: 8px;

    > .input {
        width: 188px;
        margin-bottom: 8px;
        display: block;
    }
`;