import { GroupsResponse, onDeserializeGroupsResponse, onSerializeGroupsResponse } from '../models/groups-response';
import globalAxios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { mergeHeaders, AxiosHeaders } from '../utilities/merge-headers';

export class GroupsApi {
    private baseUrl: string;

    public constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    public async get(headers?: AxiosHeaders): Promise<AxiosResponse<GroupsResponse>> {
        const request = {
            url: this.baseUrl + '/groups',
            method: 'GET',
            responseType: 'json',
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<GroupsResponse>(request);
        
        if (response.status === 200) {
            response.data = onDeserializeGroupsResponse(response.data);
        }

        return response;
    }
}