/* eslint-disable @typescript-eslint/no-explicit-any */
import { GenericError } from '@/store/generic-error';
import moment from 'moment';
import { Status } from '@/store/status';
import { ListSortDirection } from '@/client/models';

export function initValue<P>(initialValue?: P): RetrievedValue<P> {
    return { status: Status.Init, on: moment(), value: initialValue };
}

export function loadingValue<P>(initialValue?: P, paging?: PagingInfo): RetrievedValue<P> {
    return { status: Status.Loading, on: moment(), value: initialValue, paging };
}

export function loadedValue<P>(value: P = undefined, paging?: PagingInfo): RetrievedValue<P> {
    return { status: Status.Loaded, on: moment(), value, paging };
}

export function initAction(): ActionResult {
    return { status: Status.Init, on: moment() };
}

export function loadingAction(): ActionResult {
    return { status: Status.Loading, on: moment() };
}

export function loadedAction(): ActionResult {
    return { status: Status.Loaded, on: moment() };
}

export function errorAction(error: GenericError<any>): ActionResult {
    return { status: Status.Error, on: moment(), error };
}

export function combine<P>(values: RetrievedValue<P>[], value: RetrievedValue<P>, keySelector: (value: P) => any): RetrievedValue<P>[] {
    if (value?.value == null) {
        return values;
    }
    
    const newKey = keySelector(value.value);

    const clonedValues = [...values];

    const existingIndex = clonedValues.findIndex(x => keySelector(x.value) === newKey);
    if (existingIndex !== -1) {
        clonedValues.splice(existingIndex, 1, value);
    } else {
        clonedValues.push(value);
    }

    return clonedValues;
}

export function errorValue<P>(error: GenericError<any>, value?: P, paging?: PagingInfo): RetrievedValue<P> {
    return { status: Status.Error, on: moment(), error, value, paging };
}

export interface ActionResult {
    on?: moment.Moment;
    status: Status;
    error?: GenericError<any>;
}

export interface PagingInfo {
    records?: number;
    totalRecords?: number;
    page?: number; 
    pageSize?: number; 
    sort?: { [key: string]: ListSortDirection };
}

export interface RetrievedValue<P> extends ActionResult {
    paging?: PagingInfo;
    value?: P;
}
