import { Group, TimeSlot, LocationUsagesResponse } from '@/client/models';
import { ApplicationSchedulePart, GroupComposition, ApplicationSchedulePeriod, ApplicationGroupPeriod } from '@/store/planboard/state';
import { createGroupPeriods, createPeriodContext, createGroupPeriodsForComposition } from '@/planboard/application-year-range';
import R from 'ramda';
import { VisibleGroup } from './visible-group';
import { combineUuids } from './uuid';
import moment from 'moment';

interface AgeRange {
    fromMonths: number;
    toMonths: number;
}

function countOverlap(categories: AgeRange[]): number {
    let count = 0;

    for (const target of categories) {
        for (const category of categories.filter(x => x !== target)) {
            if (category.fromMonths < target.toMonths && category.toMonths > target.fromMonths) {
                count++;
            }
        }
    }

    return count;
}

function createGapPeriod(part: ApplicationSchedulePart, from: moment.Moment, until: moment.Moment): ApplicationSchedulePeriod {
    const gapContext = createPeriodContext(part, from, until);
    return {
        start: gapContext.start,
        end: gapContext.end,
        groupPeriods: [],
    };
}

function createPeriods(schedulePart: ApplicationSchedulePart, groupPeriods: ApplicationGroupPeriod[]): ApplicationSchedulePeriod[] {
    const periods = new Array<ApplicationSchedulePeriod>();

    const periodsGrouping = R.groupBy<ApplicationGroupPeriod>(
        x => `${x.start.format('YYYY-MM-DD')}:${x.end.format('YYYY-MM-DD')}`,
        R.sortWith([R.ascend(x => x.start), R.ascend(x => x.end)], groupPeriods)
    );

    const periodKeys = Object.keys(periodsGrouping);

    let lastEndPeriod: moment.Moment;
    for (const periodKey of periodKeys) {
        const periodGroups = periodsGrouping[periodKey];
        const group = periodGroups[0];

        // We have a gap in periods
        if (lastEndPeriod != null && !group.start.isSameOrBefore(lastEndPeriod.clone().add(1, 'day'), 'day')) {
            periods.push(createGapPeriod(schedulePart, lastEndPeriod.clone().add(1, 'day'), group.start.subtract(1, 'day')));
        }

        lastEndPeriod = group.end;

        periods.push({
            start: group.start,
            end: group.end,
            groupPeriods: periodGroups,
        });
    }

    if (periods.length === 0) {
        periods.push(createGapPeriod(schedulePart, schedulePart.startsOn, schedulePart.endsOn));
    }

    return periods;
}

function createComposition(schedulePart: ApplicationSchedulePart, groups: VisibleGroup[], timeSlots: TimeSlot[], allUsages: LocationUsagesResponse[]): GroupComposition {
    const composition = {
        compositionId: combineUuids(groups.map(x => x.groupId)),
        groups: [...groups],
        periods: [],
        groupPeriods: [],
        schedulePart
    };
    
    composition.groupPeriods = createGroupPeriodsForComposition(timeSlots, composition, allUsages);
    composition.periods = createPeriods(composition.schedulePart, composition.groupPeriods);

    return composition;
}

function scoreComposition(schedulePart: ApplicationSchedulePart, composition: Group[]): number {
    let score = 100;

    const ageRanges = R.uniq(composition.map(x => ({ fromMonths: x.fromMonths, toMonths: x.toMonths }))).sort((a, b) => a.fromMonths - b.fromMonths);

    const overlaps = countOverlap(ageRanges);
    score -= overlaps * 25;

    let lastAgeRange = ageRanges[0];
    let lowestAge = 9999;
    let highestAge = -1;

    for (const current of ageRanges) {
        // Have an age gap
        const ageDiff = current.fromMonths - lastAgeRange.toMonths;
        if (ageDiff > 0) {
            return 0;
        }

        // Take in account the amount of months on the same group
        score += (current.toMonths - current.fromMonths) / 2;

        lowestAge = Math.min(lowestAge, current.fromMonths);
        highestAge = Math.max(highestAge, current.toMonths);

        lastAgeRange = current;
    }

    // Not from the start
    const ageDiffStart = lowestAge - schedulePart.startsOn.diff(schedulePart.child.birthDate, 'months');
    if (ageDiffStart > 0) {
        return 0;
    }

    // Not till the end
    const ageDiffEnd = schedulePart.endsOn.diff(schedulePart.child.birthDate, 'months') - highestAge;
    if (ageDiffEnd > 0) {
        return 0;
    }

    return score;
}

function generateCombinations(compositions: VisibleGroup[][]): VisibleGroup[][] {

    const combinations = new Array<VisibleGroup[]>();

    const length = compositions.length * compositions.length;

    // Start with 1 because with 0 it will generate an empty set
    for (let i = 1; i < length; i++) {

        const newSet = new Array<VisibleGroup>();

        for (let x = 1, y = 0; x < length; x = x << 1, y++) {
            if ((i & x) !== 0) {
                newSet.push(...compositions[y]);
            }
        }

        combinations.push(newSet);
    }

    return combinations;
}

export function createCompositions(locationId: string, schedulePart: ApplicationSchedulePart, groups: Group[], timeSlots: TimeSlot[], allUsages: LocationUsagesResponse[]): GroupComposition[] {

    const startAgeInMonths = schedulePart.startsOn.clone().diff(schedulePart.child.birthDate, 'month');
    const endAgeInMonths = schedulePart.endsOn.clone().diff(schedulePart.child.birthDate, 'month');

    const validGroups = R.sortWith(
        [R.ascend(x => x.fromMonths), R.ascend(x => x.toMonths)],
        groups.filter(x => x.locationId === locationId && x.fromMonths < endAgeInMonths && x.toMonths > startAgeInMonths)
    ).map<VisibleGroup>((x, i) => ({ ...x, visibleIndex: i + 1 }));
    
    const compositions = R.groupBy(x => `${x.fromMonths}-${x.toMonths}`, validGroups);
    const orderedCompositions = Object.keys(compositions).map(x => compositions[x]);

    const allPosibleCompositions = generateCombinations(orderedCompositions);

    const results = allPosibleCompositions.map(x => ({ composition: x, score: scoreComposition(schedulePart, x) }));

    const top3 = results.sort((a, b) => b.score - a.score).slice(0, 3).filter(x => x.score > 75);

    const resultingCompositions = top3.map(x => createComposition(schedulePart, x.composition, timeSlots, allUsages));
    if (resultingCompositions.every(x => x.groups.length !== validGroups.length)) {
        resultingCompositions.push(createComposition(schedulePart, validGroups, timeSlots, allUsages));
    }

    return resultingCompositions;
}

export function splitComposition(composition: GroupComposition, start: moment.Moment, end: moment.Moment, timeSlots: TimeSlot[], allUsages: LocationUsagesResponse[]): void {
    function recursive(composition: GroupComposition, start: moment.Moment, end: moment.Moment): void {
        for (const period of composition.groupPeriods) {
            if (period.start.isSameOrAfter(end, 'day') || period.end.isSameOrBefore(start, 'day')) {
                continue;
            }

            // Exact range already exist
            if (period.start.isSame(start, 'day') && period.end.isSame(end, 'day')) {
                return;
            }

            if (start.isSame(period.start, 'day') && end.isAfter(period.end, 'day')) {
                // Moved end date
                period.end = end.clone();
            } else if (end.isSame(period.end, 'day') && start.isBefore(period.start, 'day')) {
                // Moved start date
                period.start = start.clone();
            } else if (start.isSameOrBefore(period.start, 'day') && end.isAfter(period.start, 'day')) {
                // At start
                period.start = end.clone().add(1, 'day');
            } else if (start.isAfter(period.start, 'day') && end.isSame(period.end, 'day')) {
                // At end
                period.end = start.clone().subtract(1, 'day');
            } else if (start.isAfter(period.start, 'day') && end.isSameOrBefore(period.end, 'day')) {
                // In between
                recursive(composition, period.start, start.clone().subtract(1, 'day'));
                period.start = end.clone().add(1, 'day');
            }
        }

        composition.groupPeriods.push(...createGroupPeriods(timeSlots, composition, start, end, allUsages));
    }

    recursive(composition, start, end);

    composition.groupPeriods = R.sortWith([R.ascend(x => x.start), R.ascend(x => x.end)], composition.groupPeriods);
    composition.periods = createPeriods(composition.schedulePart, composition.groupPeriods);
}

export function mergeComposition(composition: GroupComposition, start: moment.Moment, end: moment.Moment, timeSlots: TimeSlot[], allUsages: LocationUsagesResponse[]): void {
    const containedPeriods = new Array<ApplicationGroupPeriod>();

    for (const period of composition.groupPeriods) {
        if (period.start.isSameOrAfter(end, 'day') || period.end.isSameOrBefore(start, 'day')) {
            continue;
        }

        if (period.start.isSameOrAfter(start, 'day') && period.end.isSameOrBefore(end, 'day')) {
            containedPeriods.push(period);
            continue;
        }

        // Exact range already exist
        if (period.start.isSame(start, 'day') && period.end.isSame(end, 'day')) {
            return;
        }

        if (start.isSame(period.start, 'day') && end.isAfter(period.end, 'day')) {
            // Moved end date
            period.end = end.clone();
        } else if (end.isSame(period.end, 'day') && start.isBefore(period.start, 'day')) {
            // Moved start date
            period.start = start.clone();
        } else if (start.isSameOrBefore(period.start, 'day') && end.isAfter(period.start, 'day')) {
            // At start
            period.start = end.clone().add(1, 'day');
        } else if (start.isAfter(period.start, 'day') && end.isSame(period.end, 'day')) {
            // At end
            period.end = start.clone().subtract(1, 'day');
        } else if (start.isAfter(period.start, 'day') && end.isSameOrBefore(period.end, 'day')) {
            // In between
            period.start = end.clone().add(1, 'day');
        }
    }

    for (const period of containedPeriods) {
        composition.groupPeriods.splice(composition.groupPeriods.indexOf(period), 1);
    }

    composition.groupPeriods.push(...createGroupPeriods(timeSlots, composition, start, end, allUsages));

    composition.groupPeriods = R.sortWith([R.ascend(x => x.start), R.ascend(x => x.end)], composition.groupPeriods);
    composition.periods = createPeriods(composition.schedulePart, composition.groupPeriods);
}