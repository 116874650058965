import React, { useContext } from 'react';
import styled from 'styled-components';

import { Translate } from 'react-localize-redux';

import { GridLayoutItem } from '@/components/grid-layout';
import { GroupComposition, ApplicationSchedulePeriod } from '@/store/planboard/state';
import { FaIcon } from '@/components/fa-icon';
import { faPen } from '@fortawesome/free-solid-svg-icons';

const CompositionContext = React.createContext<GroupComposition>(undefined);

export const useComposition = (): GroupComposition => {
    return useContext(CompositionContext);
};

interface Props {
    period: ApplicationSchedulePeriod;
    columnIndex: number;
}

export const CompositionPeriodHeader: React.FC<Props> = ({ period: { start, end }, columnIndex }) => {
    return (
        <GridLayoutItem key={`column-${start.format('YYYYMMDD')}-${end.format('YYYYMMDD')}`} column={columnIndex}>
            <YearHeader>
                <DateHeader>{start.format('l')}</DateHeader>
                &nbsp;<Translate id='global.generic.till' />&nbsp;
                <DateHeader>{end.format('l')}</DateHeader>
                <FaIcon className='edit-icon' icon={faPen} />
            </YearHeader>
        </GridLayoutItem>
    );
};

const DateHeader = styled.span`
    font-weight: bold;
    flex: 1;
`;

const YearHeader = styled.div`
    display: flex;
    justify-content: center;
    border: 1px solid lightgray;
    border-radius: 10px;
    text-align: center;

    .edit-icon {
        align-self: center;
        margin-right: 10px;
        opacity: 0.4;

        :hover {
            opacity: 1;
        }
    }
`;