import { ChildListItem, onDeserializeChildListItem, onSerializeChildListItem} from './child-list-item';
import { ParentListItem, onDeserializeParentListItem, onSerializeParentListItem} from './parent-list-item';

export interface FamilyModel {
    children: ChildListItem[];
    parents: ParentListItem[];
}

export function onDeserializeFamilyModel(data: FamilyModel): FamilyModel {
    if (data == null) {
        return data;
    }

    if (data.children != null) {
        for (let i = 0; i < data.children.length; i++) {
            if (data.children[i] != null) {
                data.children[i] = onDeserializeChildListItem(data.children[i]);
            }
        }
    }
    if (data.parents != null) {
        for (let i = 0; i < data.parents.length; i++) {
            if (data.parents[i] != null) {
                data.parents[i] = onDeserializeParentListItem(data.parents[i]);
            }
        }
    }

    return data;
}

export function onSerializeFamilyModel(data: FamilyModel): FamilyModel {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    if (data.children != null) {
        for (let i = 0; i < data.children.length; i++) {
            serialized.children[i] = onSerializeChildListItem(data.children[i]);
        }
    }      
    if (data.parents != null) {
        for (let i = 0; i < data.parents.length; i++) {
            serialized.parents[i] = onSerializeParentListItem(data.parents[i]);
        }
    }      

    return serialized;
}
