import React, { useContext } from 'react';
import styled from 'styled-components';

import { Translate } from 'react-localize-redux';

import { GroupCard } from './group-card';
import { GridLayout, GridLayoutItem } from '@/components/grid-layout';
import { GroupComposition } from '@/store/planboard/state';
import { CompositionPeriodHeader } from './composition-period-header';

const CompositionContext = React.createContext<GroupComposition>(undefined);

export const useComposition = (): GroupComposition => {
    return useContext(CompositionContext);
};

interface Props {
    composition: GroupComposition;
}

export function CompositionCard({ composition }: Props): React.ReactElement {
    const periods = composition.periods;

    const columns = [];
    for (const period of periods) {
        columns.push(<CompositionPeriodHeader key={periods.indexOf(period)} period={period} columnIndex={columns.length + 1} />);
    }

    const numberOfRows = Math.max(...periods.flatMap(x => x.groupPeriods.length));

    const groupCards = periods.map((x, xi) =>
        x.groupPeriods.map((y, yi) => (
            <GridLayoutItem key={`group-${xi}-${yi}`} column={xi + 1} row={(y.group?.visibleIndex ?? 1) + 1}>
                <GroupCard groupPeriod={y} />
            </GridLayoutItem>
        ))
    );

    return (
        <GridLayout columns={{ count: periods.length, size: 'max-content' }} rows={{ count: numberOfRows + 1, size: 'max-content' }} colgap='5px' rowgap='5px'>
            {columns}

            <CompositionContext.Provider value={composition}>
                {groupCards.length > 0 ?
                    groupCards :
                    <GridLayoutItem row={2} columnSpan={periods.length}>
                        <NoGroupsMessage>
                            <Translate id='planboard.messages.no-available-groups' />
                        </NoGroupsMessage>
                    </GridLayoutItem>
                }
            </CompositionContext.Provider>
        </GridLayout>
    );
}

const NoGroupsMessage = styled.p`
    text-align: center;
    color: lightgray;
    font-size: 2em;
`;