import {UserModel} from '@/client/models';
import {GenericError} from '@/store/generic-error';
import moment from 'moment';

export enum Status {
    Unauthenticated = 'Unauthenticated',
    Authenticating = 'Authenticating',
    Authenticated = 'Authenticated',
    AuthenticationFailed = 'AuthenticationFailed'
}

export interface State {
    status: Status;
    tenant: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error?: GenericError<any>;
    user?: UserModel;
    accessToken?: string;
    expires?: moment.Moment;
    refreshToken?: string;
    apiUrl: string;
    developMode: boolean;
    backendUrl?: string;
}
