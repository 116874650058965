import { RequestStatus, onDeserializeRequestStatus, onSerializeRequestStatus} from './request-status';
import { CareType } from './care-type';
import moment from 'moment';

export interface ContractsListItem {
    contractId: string;
    childId: string;
    number: string;
    start: moment.Moment;
    end: moment.Moment;
    status: RequestStatus;
    careType: CareType;
}

export function onDeserializeContractsListItem(data: ContractsListItem): ContractsListItem {
    if (data == null) {
        return data;
    }

    if (data.start != null) {
        data.start = moment(data.start);
    }
    if (data.end != null) {
        data.end = moment(data.end);
    }
    if (data.status != null) {
        data.status = onDeserializeRequestStatus(data.status);
    }

    return data;
}

export function onSerializeContractsListItem(data: ContractsListItem): ContractsListItem {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    serialized.start = data.start?.format('YYYY-MM-DD') as unknown as moment.Moment;
    serialized.end = data.end?.format('YYYY-MM-DD') as unknown as moment.Moment;
    serialized.status = onSerializeRequestStatus(data.status);

    return serialized;
}
