import moment from 'moment';

export interface CreateOrUpdateHolidayCommand {
    description?: string;
    start: moment.Moment;
    end: moment.Moment;
}

export function onDeserializeCreateOrUpdateHolidayCommand(data: CreateOrUpdateHolidayCommand): CreateOrUpdateHolidayCommand {
    if (data == null) {
        return data;
    }

    if (data.start != null) {
        data.start = moment(data.start);
    }
    if (data.end != null) {
        data.end = moment(data.end);
    }

    return data;
}

export function onSerializeCreateOrUpdateHolidayCommand(data: CreateOrUpdateHolidayCommand): CreateOrUpdateHolidayCommand {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    serialized.start = data.start?.format('YYYY-MM-DD') as unknown as moment.Moment;
    serialized.end = data.end?.format('YYYY-MM-DD') as unknown as moment.Moment;

    return serialized;
}
