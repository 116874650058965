import { ParentModel, onDeserializeParentModel, onSerializeParentModel } from '../models/parent-model';
import { DocumentsModel, onDeserializeDocumentsModel, onSerializeDocumentsModel } from '../models/documents-model';
import { FamilyModel, onDeserializeFamilyModel, onSerializeFamilyModel } from '../models/family-model';
import { TimelineModel, onDeserializeTimelineModel, onSerializeTimelineModel } from '../models/timeline-model';
import { TimelineMediaModel, onDeserializeTimelineMediaModel, onSerializeTimelineMediaModel } from '../models/timeline-media-model';
import moment from 'moment';
import qs from 'qs';
import globalAxios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { mergeHeaders, AxiosHeaders } from '../utilities/merge-headers';

export class ParentsApi {
    private baseUrl: string;

    public constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    public async getParentId(parentId: string, headers?: AxiosHeaders): Promise<AxiosResponse<ParentModel>> {
        const request = {
            url: this.baseUrl + '/parents/{parentId}'
                .replace(`{${'parentId'}}`, encodeURIComponent(parentId)),
            method: 'GET',
            responseType: 'json',
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<ParentModel>(request);
        
        if (response.status === 200) {
            response.data = onDeserializeParentModel(response.data);
        }

        return response;
    }

    public async getParentIdAnnualstatements(parentId: string, headers?: AxiosHeaders): Promise<AxiosResponse<DocumentsModel>> {
        const request = {
            url: this.baseUrl + '/parents/{parentId}/annualstatements'
                .replace(`{${'parentId'}}`, encodeURIComponent(parentId)),
            method: 'GET',
            responseType: 'json',
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<DocumentsModel>(request);
        
        if (response.status === 200) {
            response.data = onDeserializeDocumentsModel(response.data);
        }

        return response;
    }

    public async getParentIdFamily(parentId: string, headers?: AxiosHeaders): Promise<AxiosResponse<FamilyModel>> {
        const request = {
            url: this.baseUrl + '/parents/{parentId}/family'
                .replace(`{${'parentId'}}`, encodeURIComponent(parentId)),
            method: 'GET',
            responseType: 'json',
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<FamilyModel>(request);
        
        if (response.status === 200) {
            response.data = onDeserializeFamilyModel(response.data);
        }

        return response;
    }

    public async getParentIdInvoices(parentId: string, headers?: AxiosHeaders): Promise<AxiosResponse<DocumentsModel>> {
        const request = {
            url: this.baseUrl + '/parents/{parentId}/invoices'
                .replace(`{${'parentId'}}`, encodeURIComponent(parentId)),
            method: 'GET',
            responseType: 'json',
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<DocumentsModel>(request);
        
        if (response.status === 200) {
            response.data = onDeserializeDocumentsModel(response.data);
        }

        return response;
    }

    public async getParentIdTimeline(parentId: string, start: moment.Moment, end: moment.Moment, headers?: AxiosHeaders): Promise<AxiosResponse<TimelineModel>> {
        const request = {
            url: this.baseUrl + '/parents/{parentId}/timeline'
                .replace(`{${'parentId'}}`, encodeURIComponent(parentId)),
            method: 'GET',
            responseType: 'json',
            params: {
                start: start?.format('YYYY-MM-DD'),
                end: end?.format('YYYY-MM-DD'),
            },
            paramsSerializer: params => qs.stringify(params)
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<TimelineModel>(request);
        
        if (response.status === 200) {
            response.data = onDeserializeTimelineModel(response.data);
        }

        return response;
    }

    public async getParentIdTimelineMedia(parentId: string, start: moment.Moment, end: moment.Moment, headers?: AxiosHeaders): Promise<AxiosResponse<TimelineMediaModel>> {
        const request = {
            url: this.baseUrl + '/parents/{parentId}/timeline/media'
                .replace(`{${'parentId'}}`, encodeURIComponent(parentId)),
            method: 'GET',
            responseType: 'json',
            params: {
                start: start?.format('YYYY-MM-DD'),
                end: end?.format('YYYY-MM-DD'),
            },
            paramsSerializer: params => qs.stringify(params)
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<TimelineMediaModel>(request);
        
        if (response.status === 200) {
            response.data = onDeserializeTimelineMediaModel(response.data);
        }

        return response;
    }
}