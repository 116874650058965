import { PresenceItem, onDeserializePresenceItem, onSerializePresenceItem} from './presence-item';

export interface PresencesModel {
    presences: PresenceItem[];
}

export function onDeserializePresencesModel(data: PresencesModel): PresencesModel {
    if (data == null) {
        return data;
    }

    if (data.presences != null) {
        for (let i = 0; i < data.presences.length; i++) {
            if (data.presences[i] != null) {
                data.presences[i] = onDeserializePresenceItem(data.presences[i]);
            }
        }
    }

    return data;
}

export function onSerializePresencesModel(data: PresencesModel): PresencesModel {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    if (data.presences != null) {
        for (let i = 0; i < data.presences.length; i++) {
            serialized.presences[i] = onSerializePresenceItem(data.presences[i]);
        }
    }      

    return serialized;
}
