import { Name} from './name';
import { MediaReference} from './media-reference';
import { Gender } from './gender';
import moment from 'moment';

export interface ChildListItem {
    childId: string;
    isCurrentlySick: boolean;
    name: Name;
    birthDate?: moment.Moment;
    gender?: Gender;
    picture?: MediaReference;
    hasSSN: boolean;
}

export function onDeserializeChildListItem(data: ChildListItem): ChildListItem {
    if (data == null) {
        return data;
    }

    if (data.birthDate != null) {
        data.birthDate = moment(data.birthDate);
    }

    return data;
}

export function onSerializeChildListItem(data: ChildListItem): ChildListItem {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    serialized.birthDate = data.birthDate?.format('YYYY-MM-DD') as unknown as moment.Moment;

    return serialized;
}
