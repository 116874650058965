import { TenantModel } from '../models/tenant-model';
import { ConfigurationModel, onDeserializeConfigurationModel, onSerializeConfigurationModel } from '../models/configuration-model';
import qs from 'qs';
import globalAxios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { mergeHeaders, AxiosHeaders } from '../utilities/merge-headers';

export class TenantsApi {
    private baseUrl: string;

    public constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    public async getCode(code: string, headers?: AxiosHeaders): Promise<AxiosResponse<TenantModel>> {
        const request = {
            url: this.baseUrl + '/tenants/{code}'
                .replace(`{${'code'}}`, encodeURIComponent(code)),
            method: 'GET',
            responseType: 'json',
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<TenantModel>(request);
        

        return response;
    }

    public async getTenantCode(tenant: string, color?: string, headers?: AxiosHeaders): Promise<AxiosResponse<void>> {
        const request = {
            url: this.baseUrl + '/tenants/{tenant}/code'
                .replace(`{${'tenant'}}`, encodeURIComponent(tenant)),
            method: 'GET',
            params: {
                color: color,
            },
            paramsSerializer: params => qs.stringify(params)
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<void>(request);
        

        return response;
    }

    public async getTenantConfiguration(tenant: string, headers?: AxiosHeaders): Promise<AxiosResponse<ConfigurationModel>> {
        const request = {
            url: this.baseUrl + '/tenants/{tenant}/configuration'
                .replace(`{${'tenant'}}`, encodeURIComponent(tenant)),
            method: 'GET',
            responseType: 'json',
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<ConfigurationModel>(request);
        
        if (response.status === 200) {
            response.data = onDeserializeConfigurationModel(response.data);
        }

        return response;
    }

    public async getTenantLogo(tenant: string, headers?: AxiosHeaders): Promise<AxiosResponse<void>> {
        const request = {
            url: this.baseUrl + '/tenants/{tenant}/logo'
                .replace(`{${'tenant'}}`, encodeURIComponent(tenant)),
            method: 'GET',
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<void>(request);
        

        return response;
    }
}