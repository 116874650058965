import { GroupReference} from './group-reference';
import { TimeslotFrequency } from './timeslot-frequency';
import moment from 'moment';

export interface PlanningTimeslot {
    frequency?: TimeslotFrequency;
    extendedHours: boolean;
    timeslotId?: string;
    planningId?: string;
    name?: string;
    typeId?: string;
    start?: moment.Duration;
    end?: moment.Duration;
    group?: GroupReference;
    regularPlanningInHoliday?: boolean;
    creditHours?: string;
}

export function onDeserializePlanningTimeslot(data: PlanningTimeslot): PlanningTimeslot {
    if (data == null) {
        return data;
    }

    if (data.start != null) {
        data.start = moment.duration(data.start);
    }
    if (data.end != null) {
        data.end = moment.duration(data.end);
    }

    return data;
}

export function onSerializePlanningTimeslot(data: PlanningTimeslot): PlanningTimeslot {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };


    return serialized;
}
