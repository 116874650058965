
import {library} from '@fortawesome/fontawesome-svg-core';
import { faHome, faCalendarAlt, faHourglassHalf, faUserPlus, faLongArrowAltUp, faLongArrowAltDown, faSignOutAlt, faBatteryThreeQuarters, faTools } from '@fortawesome/free-solid-svg-icons';

///////////////////////////////////////////
/////   Imported Font Awesome icons   /////
///////////////////////////////////////////

export const initializeFontAwesome = (): void => {
    library.add(faHome);
    library.add(faCalendarAlt);
    library.add(faHourglassHalf);
    library.add(faUserPlus);
    library.add(faLongArrowAltUp);
    library.add(faLongArrowAltDown);
    library.add(faSignOutAlt);
    library.add(faBatteryThreeQuarters);
    library.add(faTools);
};