export function Action<T extends string, P = {}>(action: T, payload?: P): Action<T, P> {
    return { type: '', container: '', action, payload };
}

export interface Action<T extends string, P> {
    type: string;
    container: string;
    action: T;
    payload: P;
}

export function isActionFromContainer<TAction extends string, TPayload>(action: Action<TAction, TPayload>, container: { __className: string }): boolean {
    return action.container === container.__className;
}