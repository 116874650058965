import React, { useState, useContext } from 'react';
import { NamedLanguage as ReactNamedLanguage, LocalizeContext, LocalizeContextProps} from 'react-localize-redux';
import {renderToStaticMarkup} from 'react-dom/server';
import moment from 'moment';

import 'moment/locale/nl';
import 'moment/locale/en-gb';

import enGlobalTranslations from '@/translations/en.global.json';
import nlGlobalTranslations from '@/translations/nl.global.json';

import enPlanboardTranslations from '@/translations/en.planboard.json';
import nlPlanboardTranslations from '@/translations/nl.planboard.json';

import enToolsTranslations from '@/translations/en.tools.json';
import nlToolsTranslations from '@/translations/nl.tools.json';

import enAuthenticationTranslations from '@/translations/en.authentication.json';
import nlAuthenticationTranslations from '@/translations/nl.authentication.json';

import enDataGridTranslations from '@/translations/en.datagrid.json';
import nlDataGridTranslations from '@/translations/nl.datagrid.json';
import { ConfigProvider } from 'antd';

import enUS from 'antd/es/locale/en_US';
import nlNL from 'antd/es/locale/nl_NL';
import { Locale } from 'antd/lib/locale-provider';

type NamedLanguage = ReactNamedLanguage & { antLocale: Locale };

const DefaultLanguage = 'nl';

export const languages: NamedLanguage[] = [
    {
        name: 'Nederlands', 
        code: 'nl',
        antLocale: nlNL
    },
    {
        name: 'English', 
        code: 'en',
        antLocale: enUS
    }
];

export function useTranslate(): LocalizeContextProps {
    return useContext(LocalizeContext);
}

export function useActiveLanguage(): [NamedLanguage, (language: NamedLanguage) => void] {
    const languageContext = useTranslate();

    const code = languageContext.activeLanguage?.code ?? DefaultLanguage;
    const activeLanguage = languages.find(l => l.code === code);

    return [
        activeLanguage,
        (language) => {
            moment.locale(language.code);
            languageContext.setActiveLanguage(language.code);
        }
    ];
}

export function LanguageProvider(props: { children?: React.ReactNode }): React.ReactElement {
    const languageContext = useTranslate();
    const [activeLanguage, setActiveLanguage] = useActiveLanguage();

    useState(() => {
        languageContext.initialize({
            languages: languages,
            options: {renderToStaticMarkup}
        });
    
        languageContext.addTranslationForLanguage(enGlobalTranslations, 'en');
        languageContext.addTranslationForLanguage(nlGlobalTranslations, 'nl');

        languageContext.addTranslationForLanguage(enPlanboardTranslations, 'en');
        languageContext.addTranslationForLanguage(nlPlanboardTranslations, 'nl');

        languageContext.addTranslationForLanguage(enToolsTranslations, 'en');
        languageContext.addTranslationForLanguage(nlToolsTranslations, 'nl');
    
        languageContext.addTranslationForLanguage(enAuthenticationTranslations, 'en');
        languageContext.addTranslationForLanguage(nlAuthenticationTranslations, 'nl');
        
        languageContext.addTranslationForLanguage(enDataGridTranslations, 'en');
        languageContext.addTranslationForLanguage(nlDataGridTranslations, 'nl');

        setActiveLanguage(activeLanguage);
    });
  
    return (
        <ConfigProvider locale={activeLanguage.antLocale}>
            {props.children}
        </ConfigProvider>
    );
}