import { TimelineMediaItem, onDeserializeTimelineMediaItem, onSerializeTimelineMediaItem} from './timeline-media-item';
import moment from 'moment';

export interface TimelineMediaModel {
    start: moment.Moment;
    end: moment.Moment;
    albums: TimelineMediaItem[];
}

export function onDeserializeTimelineMediaModel(data: TimelineMediaModel): TimelineMediaModel {
    if (data == null) {
        return data;
    }

    if (data.start != null) {
        data.start = moment(data.start);
    }
    if (data.end != null) {
        data.end = moment(data.end);
    }
    if (data.albums != null) {
        for (let i = 0; i < data.albums.length; i++) {
            if (data.albums[i] != null) {
                data.albums[i] = onDeserializeTimelineMediaItem(data.albums[i]);
            }
        }
    }

    return data;
}

export function onSerializeTimelineMediaModel(data: TimelineMediaModel): TimelineMediaModel {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    serialized.start = data.start?.format('YYYY-MM-DD') as unknown as moment.Moment;
    serialized.end = data.end?.format('YYYY-MM-DD') as unknown as moment.Moment;
    if (data.albums != null) {
        for (let i = 0; i < data.albums.length; i++) {
            serialized.albums[i] = onSerializeTimelineMediaItem(data.albums[i]);
        }
    }      

    return serialized;
}
