import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DataGrid, DataGridColumn } from '@/data-grid';
import { SelectionDrawer } from '@/components/selection-drawer';
import { locationsSelector } from '@/store/general/selectors';
import moment from 'moment';
import { InputNumber } from 'antd';
import { Translate } from 'react-localize-redux';
import styled from 'styled-components';
import { GridSelectionMode } from '@/data-grid/grid-selection-mode';

interface DashboardSelectionProps { 
    visible: boolean;
    year: number;
    selected: string[] | string;
    selectionMode?: GridSelectionMode;
    availableLocations?: (year: number) => string[];    
    onSelect: (year: number, newSelection: string[]) => void; 
    onCancel: () => void;
}

export function DashboardSelection(props: DashboardSelectionProps): React.ReactElement {
    const { visible, availableLocations, onSelect, onCancel, year: selectedYear, selected, selectionMode } = props;
    const [ year, setYear ] = React.useState(selectedYear);

    const allLocations = useSelector(locationsSelector) || [];
    const available = availableLocations != null ? availableLocations(year) : allLocations.map(x => x.locationId);
    const locations = allLocations.filter(x => available.includes(x.locationId));
    const selectedLocations = locations.filter(x => selected.includes(x.locationId));

    const [ selection, setSelection ] = React.useState(selectedLocations);

    if (!selection.every(x => available.includes(x.locationId))) {
        setSelection(selectedLocations);
    }

    useEffect(() => {
        if (!visible) {
            setSelection(selectedLocations);
        }
    }, [visible]);
    
    if (selection.length === 0 && selected.length === 0 && locations.length > 0) {
        setSelection(locations);
    }

    return (
        <SelectionDrawer visible={visible} onCancel={onCancel} onSelect={() => onSelect(year, selection.map(x => x.locationId))} size={420} 
            selectDisabled={selection.length === 0}
            titleTranslation='planboard.filter'>
            <div>
                <Label><Translate id='global.generic.year' /></Label>
                <InputNumber min={moment().year() - 1} max={moment().year() + 1} defaultValue={year} onChange={setYear} />
            </div>

            <DataGrid keyPath='locationId' datasource={locations} selection={selection} onSelectionChanged={setSelection} selectionMode={selectionMode}>
                <DataGridColumn type='Text' translation='planboard.fields.location' path='name' />
            </DataGrid>
        </SelectionDrawer>
    );
}

const Label = styled.h3`
    display: inline-block;
    padding-right: 15px;
`;