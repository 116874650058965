import React, { useState } from 'react';
import styled from 'styled-components';
import { Translate } from 'react-localize-redux';
import { Button } from '@/components/button';
import { useLifeCycle } from '@/react/hooks';

export function NewVersion(): React.ReactElement {
    const [newServiceWorker, setNewServiceWorker] = useState(undefined as ServiceWorker);

    useLifeCycle(async () => {
        if (env.mode === 'development') {
            return;
        }
        
        const registration = await window.navigator.serviceWorker.register('/service-worker.js');

        setInterval(() => registration.update(), 60000);

        if (registration.waiting != null) {
            setNewServiceWorker(registration.waiting);
        }

        registration.addEventListener('updatefound', () => {
            const newWorker = registration.installing;

            newWorker.addEventListener('statechange', () => {
                if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
                    setNewServiceWorker(newWorker);
                }
            });
        });

        let refreshing;
        navigator.serviceWorker.addEventListener('controllerchange', function () {
            if (refreshing) {
                return;
            }

            window.location.reload();
            refreshing = true;
        });
    });

    if (newServiceWorker == null) {
        return <></>;
    }

    console.log('🚀 New version available 🚀');

    return (
        <Container>
            <Text><Translate id='global.version.new-version-available' /></Text>
            <RefreshButton translation='global.version.update-now' onClick={() => newServiceWorker.postMessage({ action: 'update' })} />
        </Container>
    );
}

const Container = styled.div`
    --width: 500px;

    position: absolute;
    display: grid;
    grid-template-columns: 200px;
    grid-template-areas: 
        'text'
        'button';
    align-content: center;
    justify-content: center;
    padding: 5px;

    width: var(--width);
    background-color: #f6ffed;
    border: 1px solid #b7eb8f;
    border-radius: 4px;
    left: calc(50% - var(--width) / 2);
    bottom: 25px;
`;

const Text = styled.p`
    grid-area: text;
    text-align: center;
    font-weight: bold;
`;

const RefreshButton = styled(Button)`
    grid-area: button;
`;