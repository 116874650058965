
import { Application } from '@/client/models';
import moment from 'moment';
import { ScoringOptions } from '@/store/planboard/state';

export interface ScoredApplication extends Application {
    score: number;
}

export function scaleValue(x: number, currentMin: number, currentMax: number, newMin: number, newMax: number): number {
    return (x - currentMin) * ((newMax - newMin) / (currentMax - currentMin)) + newMin;
}

function limitValue(min: number, max: number, value: number): number {
    return Math.max(min, Math.min(value, max));
}

export function calculateScore(applications: Application[], options: ScoringOptions): ScoredApplication[] {
    const result: ScoredApplication[] = applications.map(r => ({ ...r, score: 0 }));
    const limitToYear = (value: number): number => limitValue(0, 12, value);

    const now = moment();

    let maxValue = 0;
    let minValue = 9999;

    for (const application of result) {
        const createdDistance = now.diff(moment(application.requested.on), 'month');
        const startedDistance = moment(application.startsOn).diff(now, 'month');
        const hasSiblings = application.hasSibling;

        const createdScore = Math.pow(limitToYear(createdDistance), 2) * options.createdDistance; // 0 - 432
        const startedScore = Math.pow(limitToYear(12 - startedDistance), 2) * options.startingDistance; // 0 - 432
        const siblingScore = hasSiblings ? 144 * options.sibling : 0; // 0 - 432

        application.score = createdScore + startedScore + siblingScore;

        if (application.score > maxValue) {
            maxValue = application.score;
        }

        if (application.score < minValue) {
            minValue = application.score;
        }
    }

    for (const application of result) {
        application.score = scaleValue(application.score, minValue, maxValue, 0, 10);
    } 

    return result;
}