import React, { useMemo, useEffect } from 'react';
import { ApplicationSchedulePart, ApplicationPeriodGroupTime } from '@/store/planboard/state';
import { useApplicationPartProvider } from '@/planboard/planner-application-part-provider';
import { useTranslate } from '@/language-provider';
import { Form, FormRow } from '@/components/forms';
import { formatTime } from '@/momentjs/duration-helper';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { carePackagesSelector } from '@/store/general/selectors';
import { CarePackage } from '@/client/models';
import { Button } from '@/components/button';
import { PlanboardActions } from '@/store/planboard/actions';
import { offerContractStatusSelector, offerContractErrorSelector } from '@/store/planboard/selectors';
import { Status } from '@/store/status';
import { notification } from 'antd';

export const PlannerSummary: React.FC = () => {
    const provider = useApplicationPartProvider();
    const { translate } = useTranslate();
    const dispatch = useDispatch();
    const offerContractStatus = useSelector(offerContractStatusSelector);
    const offerContractError = useSelector(offerContractErrorSelector);

    useEffect(() => {
        if (offerContractStatus === Status.Error) {
            notification.error({
                message: translate('global.generic.unexpected-error-title'),
                description: offerContractError.message,
                placement: 'topRight',
                duration: 10
            });
        } else if (offerContractStatus === Status.Loaded) {
            notification.success({
                message: translate('planboard.messages.contract-offered'),
                placement: 'topRight',
                duration: 10
            });
        }
    }, [offerContractStatus, offerContractError]);

    return (
        <>
            {provider.scheduleParts.map(x => <SummaryPartItem key={x.schedulePartId} part={x} />)}
            <Button translation='planboard.offer'
                    size='large'
                    type='primary'
                    onClick={() => dispatch(PlanboardActions.offerContract(provider.details.applicationId))}
                    loading={offerContractStatus === Status.Loading}
                    disabled={offerContractStatus === Status.Loading} />
        </>
    );
};

const SummaryPartItem: React.FC<{ part: ApplicationSchedulePart }> = ({ part }) => {
    const provider = useApplicationPartProvider();
    const { translate } = useTranslate();

    const selectedTimes = provider.getSelectedTimes(part);

    return (
        <>
            <h1>{translate(`global.caretype.${part.careType}`)}</h1>
            {selectedTimes.map(x => <SummaryPartLine key={`${x.groupPeriod.start}-${x.groupPeriod.end}`} line={x} />)}
        </>
    );
};

type CarePackageLookup = { [key: string]: CarePackage };

const SummaryPartLine: React.FC<{ line: ApplicationPeriodGroupTime }> = ({ line }) => {
    const { translate } = useTranslate();
    const packages = useSelector(carePackagesSelector);

    const packagesLookup = useMemo(() => {
        if (packages != null) {
            return packages.reduce((acc, cur) => {
                acc[cur.packageId] = cur;
                return acc;
            }, {} as CarePackageLookup);
        }

        return {} as CarePackageLookup;
    }, [packages]);

    const period = line.groupPeriod;
    const till = translate('global.generic.till');

    return (
        <SummaryPartContainer>
            <Form className='part-form'>
                <FormRow label='planboard.group' value={period.group.name} />
                <FormRow label='planboard.day' value={line.line.day} translateValue={x => translate(`global.day-of-week.full.${x}`)} />
                <FormRow label='planboard.period' value={`${period.start.format('L')} ${till} ${period.end.format('L')}`} />
                <FormRow label='planboard.time' value={`${formatTime(line.line.start)} - ${formatTime(line.line.end)}`} />
                <FormRow label='planboard.package' value={packagesLookup[line.line.packageId]?.name} />
                <FormRow label='planboard.weeks' value={packagesLookup[line.line.packageId]?.numberOfWeeks} displayAsText />
            </Form>
        </SummaryPartContainer>
    );
};

const SummaryPartContainer = styled.div`    
    border: 1px solid lightgray;
    border-radius: 10px;

    margin-bottom: 10px;

    > .part-form {
        margin: 0px 0px;

        > div {
            padding: 0px 10px;
        }
    }    
`;