import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch, useStore } from 'react-redux';
import {
    reservedApplicationsRetrievedSelector,
    reservedApplicationsStatusSelector
} from '@/store/planboard/selectors';
import { locationsSelector } from '@/store/general/selectors';
import { PlanboardActions } from '@/store/planboard/actions';
import { Application } from '@/client/models';
import R from 'ramda';

import { DataGrid, ColumnPicker, DataGridColumn, Size, DataGridColumnOption, BooleanColors, createOptions, RemoteTableAdapter } from '@/data-grid';

import { ScreenContainer } from '@/screens/screen-container';
import { useLifeCycle } from '@/react/hooks';
import { Status } from '@/store/status';
import { useCache } from '@/caching/caching-provider';
import { ToolOutlined } from '@ant-design/icons';

const defaultColumns = ['applicationType', 'requested.on', 'careTypes', 'onHold', 'child.fullName', 'child.birthDate', 'fromDate', 'preferredLocationIds[0]'];

export function PlanboardReservedApplicationsScreen(): React.ReactElement {
    const dispatch = useDispatch();
    const store = useStore();
    const cache = useCache();
    const adapter = useMemo(() => new RemoteTableAdapter(store, reservedApplicationsRetrievedSelector, PlanboardActions.loadReservedApplications), []);

    useLifeCycle(() => cache.addCache(reservedApplicationsRetrievedSelector, PlanboardActions.loadReservedApplications()), () => cache.removeCache(reservedApplicationsRetrievedSelector));

    const requestsStatus = useSelector(reservedApplicationsStatusSelector);

    if (requestsStatus === Status.Init) {
        dispatch(PlanboardActions.loadReservedApplications({ pageSize: 10 }));
    }

    const retrieved = useSelector(reservedApplicationsRetrievedSelector);
    const applications = retrieved.value ?? [];
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [selection, setSelection] = useState(new Array<Application>());

    const locations = useSelector(locationsSelector) || [];

    const lookup = locations.reduce((acc, cur) => {
        acc[cur.locationId] = cur.name;
        return acc;
    }, {});
    const usedLocations = R.uniq(applications.reduce((acc, cur) => acc.concat(cur.preferredLocationIds), new Array<string>()).map(x => ({
        text: lookup[x],
        value: x
    })));
    const schoolOptions = createOptions(applications, 'school', 'schoolId', 'name');
    const ownerOptions = createOptions(applications, 'owner', 'userId', 'fullName');

    function offerContracts(selection: Application[]): void {
        for (const application of selection) {
            dispatch(PlanboardActions.offerContract(application.applicationId));
        }
    }

    return (
        <ScreenContainer showFullScreen={true} menuItems={[
            { icon: <ToolOutlined />, translation: 'planboard.offerContracts', onClick: () => offerContracts(selection), isEnabled: selection.length > 0 },
            { icon: <ToolOutlined />, translation: 'dataGrid.columnOptions', position: 'right', iconOnly: true, onClick: () => setDrawerVisible(true) },
        ]}>
            <DataGrid name='applications' keyPath='applicationId' adapter={adapter}
                onSelectionChanged={setSelection} selection={selection} onRowClicked={item => offerContracts([item])} useProvider
                defaultColumns={defaultColumns}>
                <ColumnPicker visible={drawerVisible} onClose={() => setDrawerVisible(false)} />

                <DataGridColumn type='Text' translation='planboard.fields.type' path='applicationType'
                    translateTemplate='planboard.{value}' size={Size.Small}
                    colors={{
                        new: 'green',
                        change: 'orange',
                        end: 'red'
                    }}>
                    <DataGridColumnOption translation='planboard.new' value='new' />
                    <DataGridColumnOption translation='planboard.change' value='change' />
                    <DataGridColumnOption translation='planboard.end' value='end' />
                </DataGridColumn>
                <DataGridColumn type='Date' translation='planboard.fields.requestedOn' path='requested.on'
                    defaultSortOrder='ascend' />
                <DataGridColumn type='Text' translation='planboard.fields.caretypes' path='careTypes'
                    translateTemplate='global.caretype.{value}' size={Size.Small}
                    colors={{
                        daycare: 'green',
                        betweenSchoolCare: 'orange',
                        outOfSchoolCare: 'red',
                        holidayCare: 'blue',
                        playschool: 'yellow'
                    }}>
                    <DataGridColumnOption translation='global.caretype.betweenSchoolCare' value='betweenSchoolCare' />
                    <DataGridColumnOption translation='global.caretype.daycare' value='daycare' />
                    <DataGridColumnOption translation='global.caretype.outOfSchoolCare' value='outOfSchoolCare' />
                    <DataGridColumnOption translation='global.caretype.holidayCare' value='holidayCare' />
                    <DataGridColumnOption translation='global.caretype.playschool' value='playschool' />
                </DataGridColumn>
                <DataGridColumn type='Bool' translation='planboard.fields.onHold' path='onHold' filter
                    colors={BooleanColors} size={Size.Small} />
                <DataGridColumn type='Text' translation='planboard.fields.name' path='child.fullName' size={Size.Small}
                    filter />
                <DataGridColumn type='Date' translation='planboard.fields.dateOfBirth' path='child.birthDate' />
                <DataGridColumn type='Bool' translation='planboard.fields.hasSibling' path='hasSibling' filter />
                <DataGridColumn type='Date' translation='planboard.fields.startsOn' path='fromDate' />
                <DataGridColumn type='Text' translation='planboard.fields.location' path='preferredLocationIds[0]'
                    size={Size.Medium} onValue={x => lookup[x]} dropdown={usedLocations} />
                <DataGridColumn type='Text' translation='planboard.fields.school' path='school.name'
                    dropdown={{ source: schoolOptions, allowNone: true }} />
                <DataGridColumn type='Text' translation='planboard.fields.owner' path='owner.fullName'
                    dropdown={{ source: ownerOptions, allowNone: true }} />
                <DataGridColumn type='Text' translation='planboard.fields.remarks' path='remarks' />
            </DataGrid>
        </ScreenContainer>
    );
}