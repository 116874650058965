/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Translate } from 'react-localize-redux';
import { ColumnProps } from 'antd/lib/table';
import { TextFilter } from './text-filter';
import { getProperty } from './table-helper';
import Highlighter from 'react-highlight-words';
import moment from 'moment';
import { DateFilter } from './date-filter';
import { SortOrder, ColumnFilterItem } from 'antd/lib/table/interface';

export interface Option {
    translation?: string;
    text?: string;
    value: string;
    isNone?: boolean;
}

export class ColumnDefinition<T> {

    public order: number;
    public visible: boolean;
    public colors?: { [key: string]: string };
    public defaultSortOrder?: SortOrder;

    public nativeColumn: ColumnProps<T>;

    private originalRender: (text: string, item: T, index: number) => React.ReactNode;
    public transformText?: (text: string) => string;

    public constructor(nativeColumn: ColumnProps<T>, currentIndex: number, transformText?: (text: string) => string) {
        this.nativeColumn = nativeColumn;
        this.order = currentIndex;
        this.visible = true;

        this.transformText = transformText != null ? transformText : text => text;
    }

    public width(value: string): ColumnDefinition<T> {
        this.nativeColumn.width = value;
        return this;
    }

    public filteredValues(value: any[]): ColumnDefinition<T> {
        this.nativeColumn.filteredValue = value;
        return this;
    }

    public sortOrder(order: SortOrder): ColumnDefinition<T> {
        this.nativeColumn.sortOrder = order;
        return this;
    }

    public textFilter(): ColumnDefinition<T> {

        const [searchText, setSearchText] = useState('' as React.Key);

        if (this.originalRender == null) {
            this.originalRender = this.nativeColumn.render;
        }

        // eslint-disable-next-line react/display-name
        this.nativeColumn.render = (text: string, item: T, index: number) => {
            return (<Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={this.originalRender(text, item, index)} />);
        };
        
        this.nativeColumn.filterDropdown = (props) => <TextFilter column={this.nativeColumn} onSearch={setSearchText} {...props} />;

        this.nativeColumn.onFilter = (searchValue: string, item: T) => {
            const propertyValue = getProperty(item, this.nativeColumn.dataIndex as string) as string;
            const displayValue = this.transformText(propertyValue);
            return displayValue != null && new RegExp(`${searchValue}`, 'i').test(displayValue);
        };

        return this;
    }

    public dateFilter(): ColumnDefinition<T> {        
        this.nativeColumn.filterDropdown = (props) => <DateFilter column={this.nativeColumn} {...props} />;

        this.nativeColumn.onFilter = (searchValue: string, item: T) => {
            const propertyValue = getProperty(item, this.nativeColumn.dataIndex as string) as moment.Moment;
            const searchMoment = moment(searchValue);

            return propertyValue.diff(searchMoment, 'days') === 0;
        };

        return this;
    }

    public optionsFilter(options: Option[], allowNone = false): ColumnDefinition<T> {

        this.nativeColumn.filters = options.map(option => ({
            text: this.getText(option),
            value: option.value
        }));

        if (allowNone) {
            this.nativeColumn.filters.push(this.noneOption());
        }

        this.nativeColumn.onFilter = (searchValue: string, item: T) => {
            const propertyValue = getProperty(item, this.nativeColumn.dataIndex as string);

            if (propertyValue == null) {
                if (allowNone || options.some(x => x.isNone === true)) {
                    return searchValue === 'none';
                } else {
                    return false;
                }
            }

            if (Array.isArray(propertyValue)) {
                return propertyValue.includes(searchValue);
            }

            return searchValue === propertyValue;
        };

        return this;
    }

    private getText(option: Option): string | React.ReactNode {
        if (option.text != null) {
            return option.text;
        } else {
            return <Translate id={option.translation} />;
        }
    }

    public booleanFilter(allowNone = false): ColumnDefinition<T> {

        this.nativeColumn.filters = [
            { text: <Translate id='dataGrid.true' />, value: 'true' },
            { text: <Translate id='dataGrid.false' />, value: 'false' },
        ];

        if (allowNone) {
            this.nativeColumn.filters.push(this.noneOption());
        }

        this.nativeColumn.onFilter = (searchValue: string, item: T) => {
            const propertyValue = getProperty(item, this.nativeColumn.dataIndex as string);

            if (propertyValue == null) {
                if (allowNone) {
                    return searchValue === 'none';
                } else {
                    return false;
                }
            }

            if (searchValue === 'true' && propertyValue === true) {
                return true;
            }

            if (searchValue === 'false' && propertyValue === false) {
                return true;
            }

            return false;
        };

        return this;
    }

    private noneOption(): ColumnFilterItem {
        return {
            text: <Translate id='dataGrid.none' />,
            value: 'none'
        };
    }

    public title(): string | React.ReactNode {
        return this.nativeColumn.title;
    }

    public dataIndex(): string {
        return this.nativeColumn.dataIndex as string;
    }
}
