/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import { SmallButton, SmallPrimaryButton } from '@/components/buttons';
import { ColumnProps } from 'antd/lib/table';
import { FilterDropdownProps } from 'antd/lib/table/interface';

export type HighlighterGeneratorCallback = (renderer: HighlighterGenerator) => void;
export type HighlighterGenerator = (text: string, record: any, index: number) => React.ReactNode;

export interface TextFilterProps extends FilterDropdownProps {
    onSearch: (key: React.Key) => void;
    column: ColumnProps<any>;
}

export function TextFilter({ setSelectedKeys, selectedKeys, confirm, clearFilters, onSearch, column }: TextFilterProps): React.ReactElement {
    if (selectedKeys == null || selectedKeys.length === 0) {
        selectedKeys = [''];
    }

    function handleSearch(): void {
        confirm();
        onSearch(selectedKeys[0]);
    }

    function handleReset(): void {
        clearFilters();
        onSearch('');
    }

    function setInputNode(searchInput: Input): void {
        column.onFilterDropdownVisibleChange = visible => {
            if (visible) {
                setTimeout(searchInput.select);
            }
        };
    }    

    return (
        <Container style={{ padding: 8 }}>
            <Input
                ref={setInputNode}
                value={selectedKeys[0]} id='input'
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={handleSearch}
            />
            <SmallPrimaryButton translation='global.button.search' icon="search" onClick={handleSearch} />
            <SmallButton translation='global.button.reset' onClick={handleReset} />
        </Container>
    );
}

const Container = styled.div`
    padding: 8px;

    > #input {
        width: 188px;
        margin-bottom: 8px;
        display: block;
    }
`;