import React from 'react';
import styled from 'styled-components';
import { Location } from '@/client/models';
import { Translate } from 'react-localize-redux';

interface LocationCardProps { 
    location: Location; 
    preferred?: number; 
    isSelected: boolean; 
    onClick(location: Location): void;
}

export function LocationCard({ location, isSelected, onClick, preferred }: LocationCardProps): React.ReactElement {
    return (
        <HoveredCard onClick={() => onClick(location)} selected={isSelected}>
            <CardTitle>{location.name}</CardTitle>
            {preferred && <p><Translate id='global.generic.preferred-location' />: {preferred}</p>}
        </HoveredCard>
    );
}

const CardTitle = styled.div`
    font-size: 1em;
    padding-bottom: 5px;
`;

const HoveredCard = styled.div<{ selected: boolean }>`
    display: inline-block;
    width: 100%;
    height: 50px;
    text-align: left;
    border: 1px rgba(0, 0, 0, 0.09) solid;
    margin-bottom: 5px;
    background-color: ${props => props.selected ? '#9999E5' : undefined};

	:hover {
		border-color: rgba(0, 0, 0, 0.09);
        box-shadow: 0 2px 8px rgba(0,0,0, 0.09);
        cursor: pointer;
	}
`;