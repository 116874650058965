import React, { useState } from 'react';
import { SummaryCard } from '../summary-card';
import { SummaryDetails } from '../summary';
import { ApplicationFilter } from '../application-filter';

interface Props {
    summary: SummaryDetails;
    onClick: (requesFiltert: ApplicationFilter) => void;
}

export function ScoreHighCard({ summary, onClick }: Props): React.ReactElement {
    const [isSelected, setSelected] = useState(false);

    return <SummaryCard key='score-high'
        translation='planboard.scoring.high'
        count={summary.scorings ? summary.scorings.high.length : 0}
        isSelected={isSelected}
        onClick={() => {
            setSelected(!isSelected);
            onClick({
                name: 'score-high',
                group: 'scoring',
                filter: applications => applications.filter(x => x.score > 6.66)
            });
        }} />;
}

export function ScoreMediumCard({ summary, onClick }: Props): React.ReactElement {
    const [isSelected, setSelected] = useState(false);

    return <SummaryCard key='score-medium'
        translation='planboard.scoring.medium'
        count={summary.scorings ? summary.scorings.medium.length : 0}
        isSelected={isSelected}
        onClick={() => {
            setSelected(!isSelected);
            onClick({
                name: 'score-medium',
                group: 'scoring',
                filter: applications => applications.filter(x => x.score > 3.33 && x.score <= 6.66)
            });
        }} />;
}

export function ScoreLowCard({ summary, onClick }: Props): React.ReactElement {
    const [isSelected, setSelected] = useState(false);

    return <SummaryCard key='score-low'
        translation='planboard.scoring.low'
        count={summary.scorings ? summary.scorings.low.length : 0}
        isSelected={isSelected}
        onClick={() => {
            setSelected(!isSelected);
            onClick({
                name: 'score-low',
                group: 'scoring',
                filter: applications => applications.filter(x => x.score <= 3.33)
            });
        }} />;
}