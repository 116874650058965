import React, { PropsWithChildren } from 'react';
import {ActionsUnion} from '@/store/types';
import { GridLayout } from './grid-layout';
import { AnyAction, Dispatch } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { Action } from '@/store/action';

export const GridLayoutContext = React.createContext<PersistanceProvider>(undefined);

export interface State {
    layouts: GridLayout[];
}

const layoutsSelector = createSelector((state: { grid: State }) => state.grid.layouts, x => x);

enum ActionTypes {
    STORE_LAYOUT = 'grid/store-layout',
    CLEAR_LAYOUT = 'grid/clear-layout'
}

const Actions = {
    storeGridLayout: (layout: GridLayout) => Action(ActionTypes.STORE_LAYOUT, { layout }),
    clearGridLayout: (name: string)       => Action(ActionTypes.CLEAR_LAYOUT, { name })
};

type GridLayoutActions =  ActionsUnion<typeof Actions>;

export const reducer = (state: State = { layouts: [] }, action: GridLayoutActions): State => {
    switch (action.action) {
        case ActionTypes.STORE_LAYOUT:
            return {
                ...state,
                layouts: [ ...state.layouts.filter(x => x.name !== action.payload.layout.name), action.payload.layout ]
            };
        case ActionTypes.CLEAR_LAYOUT:
            return {
                ...state,
                layouts: [ ...state.layouts.filter(x => x.name !== action.payload.name) ]
            };
        default:
            return state;
    }
};

class PersistanceProvider {

    private dispatch: Dispatch<AnyAction>;
    private layouts: GridLayout[];

    public constructor(dispatch: Dispatch<AnyAction>, layouts: GridLayout[]) {
        this.dispatch = dispatch;
        this.layouts = layouts;
    }
    
    public load(name: string): GridLayout {
        return this.layouts.find(x => x.name === name);
    }

    public store(layout: GridLayout): void {
        this.dispatch(Actions.storeGridLayout(layout));
    }

    public clear(name: string): void {
        this.dispatch(Actions.clearGridLayout(name));
    }
}

export function GridLayoutPersistanceProvider(props: PropsWithChildren<{}>): React.ReactElement {

    const dispatch = useDispatch();
    const layouts = useSelector(layoutsSelector);

    return (
        <GridLayoutContext.Provider value={new PersistanceProvider(dispatch, layouts)}>
            {props.children}
        </GridLayoutContext.Provider>
    );
}
