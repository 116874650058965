import {ApplicationState} from '@/store/application-state';
import { createSelector } from 'reselect';

export const authSelector = createSelector((state: ApplicationState) => state, x => x.authentication);
export const tenantSelector = createSelector(authSelector, x => x.tenant);
export const accessTokenSelector = createSelector(authSelector, x => x.accessToken);
export const refreshTokenSelector =  createSelector(authSelector, x => x.refreshToken);
export const authenticationStatusSelector = createSelector(authSelector, x => x.status);
export const userSelector = createSelector(authSelector, x => x.user);

export const developModeSelector = createSelector(authSelector, x => x.developMode);

export const apiUrlSelector = createSelector(authSelector, x => x.apiUrl);
export const backendUrSelector = createSelector(authSelector, x => x.backendUrl);
  