import { TimeSlotUsage, onDeserializeTimeSlotUsage, onSerializeTimeSlotUsage} from './time-slot-usage';

export interface GroupTimeSlotUsages {
    timeSlotId: string;
    usages: TimeSlotUsage[];
}

export function onDeserializeGroupTimeSlotUsages(data: GroupTimeSlotUsages): GroupTimeSlotUsages {
    if (data == null) {
        return data;
    }

    if (data.usages != null) {
        for (let i = 0; i < data.usages.length; i++) {
            if (data.usages[i] != null) {
                data.usages[i] = onDeserializeTimeSlotUsage(data.usages[i]);
            }
        }
    }

    return data;
}

export function onSerializeGroupTimeSlotUsages(data: GroupTimeSlotUsages): GroupTimeSlotUsages {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    if (data.usages != null) {
        for (let i = 0; i < data.usages.length; i++) {
            serialized.usages[i] = onSerializeTimeSlotUsage(data.usages[i]);
        }
    }      

    return serialized;
}
