import globalAxios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { mergeHeaders, AxiosHeaders } from '../utilities/merge-headers';

export class ContentApi {
    private baseUrl: string;

    public constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    public async getImagesImageId(imageId: string, headers?: AxiosHeaders): Promise<AxiosResponse<void>> {
        const request = {
            url: this.baseUrl + '/content/images/{imageId}'
                .replace(`{${'imageId'}}`, encodeURIComponent(imageId)),
            method: 'GET',
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<void>(request);
        

        return response;
    }

    public async getImagesImagePath(imagePath: string, headers?: AxiosHeaders): Promise<AxiosResponse<void>> {
        const request = {
            url: this.baseUrl + '/content/images/{imagePath}'
                .replace(`{${'imagePath'}}`, encodeURIComponent(imagePath)),
            method: 'GET',
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<void>(request);
        

        return response;
    }

    public async getMediaPath(path: string, headers?: AxiosHeaders): Promise<AxiosResponse<void>> {
        const request = {
            url: this.baseUrl + '/content/media/{path}'
                .replace(`{${'path'}}`, encodeURIComponent(path)),
            method: 'GET',
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<void>(request);
        

        return response;
    }

    public async getMediaenvironmentPath(environment: string, path: string, headers?: AxiosHeaders): Promise<AxiosResponse<void>> {
        const request = {
            url: this.baseUrl + '/content/media{environment}/{path}'
                .replace(`{${'environment'}}`, encodeURIComponent(environment))
                .replace(`{${'path'}}`, encodeURIComponent(path)),
            method: 'GET',
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<void>(request);
        

        return response;
    }
}