import moment from 'moment';

export interface TokenModel {
    accessToken: string;
    refreshToken: string;
    expires: moment.Moment;
}

export function onDeserializeTokenModel(data: TokenModel): TokenModel {
    if (data == null) {
        return data;
    }

    if (data.expires != null) {
        data.expires = moment(data.expires);
    }

    return data;
}

export function onSerializeTokenModel(data: TokenModel): TokenModel {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    serialized.expires = data.expires?.toISOString() as unknown as moment.Moment;

    return serialized;
}
