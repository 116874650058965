import React from 'react';
import { SelectionDrawer, PlacementType, DrawerSize } from '@/components/selection-drawer';
import { Tabs } from 'antd';
import { Translate } from 'react-localize-redux';
import { ApplicationDetails } from '@/client/models';
import { useTranslate } from '@/language-provider';
import { useSelector } from 'react-redux';
import { locationsSelector } from '@/store/general/selectors';
import { Form, FormRow, DateRow, StringRow, BoolRow } from '@/components/forms';

const { TabPane } = Tabs;

interface Props {
    visible: boolean;
    details: ApplicationDetails;
    onClose: () => void;
}

export const ApplicationDetailsDrawer: React.FC<Props> = props => {
    return (
        <SelectionDrawer
            isEditMode
            titleTranslation='global.generic.details'
            visible={props.visible}
            onCancel={props.onClose}
            onSelect={props.onClose}
            placement={PlacementType.right}
            size={DrawerSize.large}>

            <Tabs defaultActiveKey="details">
                <TabPane tab={<Translate id='global.generic.details' />} key="details">
                    <ApplicationDetailsPane details={props.details} />
                </TabPane>
                <TabPane tab={<Translate id='global.generic.parts' />} key="parts">
                </TabPane>
            </Tabs>
        </SelectionDrawer>
    );
};

function ApplicationDetailsPane({ details }: { details: ApplicationDetails }): React.ReactElement {
    const { translate } = useTranslate();

    const locations = useSelector(locationsSelector);
    const prefferedLocations = details.preferredLocationIds.map(x => locations.find(y => y.locationId === x)?.name).filter(x => x != null);

    return (
        <Form>
            <StringRow label='planboard.fields.name' value={details.child.fullName} />
            <DateRow label='planboard.fields.dateOfBirth' value={details.child.birthDate} />
            <DateRow label='planboard.fields.startsOn' value={details.startsOn} isEditable onChange={value => console.log(value.format('L'))} />
            <DateRow label='planboard.fields.endsOn' value={details.endsOn} isEditable onChange={value => console.log(value.format('L'))} />
            <FormRow label='planboard.fields.caretypes' value={details.careTypes} translateValue={x => translate(`global.caretype.${x}`)} />
            <BoolRow label='planboard.fields.hasSibling' value={details.hasSibling} translateValue={x => translate(`global.generic.${x ? 'yes' : 'no'}`)} />
            <BoolRow label='planboard.fields.onHold' value={details.onHold} translateValue={x => translate(`global.generic.${x ? 'yes' : 'no'}`)} />
            <FormRow label='planboard.fields.preferredLocations' value={prefferedLocations} />
            <StringRow label='planboard.fields.school' value={details.school?.name} />
            <StringRow label='planboard.fields.remarks' value={details.remarks} isDescription isEditable onChange={value => console.log(value)} />
            <StringRow label='planboard.fields.parentRemarks' value={details.parentRemarks} isDescription />
            <DateRow label='planboard.fields.requestedOn' value={details.requested.on} />
            <StringRow label='planboard.fields.owner' value={details.owner?.fullName} />
        </Form>
    );
}