import { Registration, onDeserializeRegistration, onSerializeRegistration} from './registration';

export interface ChildRegistrationsResponse {
    requested?: Registration[];
    approved?: Registration[];
    rejected?: Registration[];
    cancelled?: Registration[];
}

export function onDeserializeChildRegistrationsResponse(data: ChildRegistrationsResponse): ChildRegistrationsResponse {
    if (data == null) {
        return data;
    }

    if (data.requested != null) {
        for (let i = 0; i < data.requested.length; i++) {
            if (data.requested[i] != null) {
                data.requested[i] = onDeserializeRegistration(data.requested[i]);
            }
        }
    }
    if (data.approved != null) {
        for (let i = 0; i < data.approved.length; i++) {
            if (data.approved[i] != null) {
                data.approved[i] = onDeserializeRegistration(data.approved[i]);
            }
        }
    }
    if (data.rejected != null) {
        for (let i = 0; i < data.rejected.length; i++) {
            if (data.rejected[i] != null) {
                data.rejected[i] = onDeserializeRegistration(data.rejected[i]);
            }
        }
    }
    if (data.cancelled != null) {
        for (let i = 0; i < data.cancelled.length; i++) {
            if (data.cancelled[i] != null) {
                data.cancelled[i] = onDeserializeRegistration(data.cancelled[i]);
            }
        }
    }

    return data;
}

export function onSerializeChildRegistrationsResponse(data: ChildRegistrationsResponse): ChildRegistrationsResponse {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    if (data.requested != null) {
        for (let i = 0; i < data.requested.length; i++) {
            serialized.requested[i] = onSerializeRegistration(data.requested[i]);
        }
    }      
    if (data.approved != null) {
        for (let i = 0; i < data.approved.length; i++) {
            serialized.approved[i] = onSerializeRegistration(data.approved[i]);
        }
    }      
    if (data.rejected != null) {
        for (let i = 0; i < data.rejected.length; i++) {
            serialized.rejected[i] = onSerializeRegistration(data.rejected[i]);
        }
    }      
    if (data.cancelled != null) {
        for (let i = 0; i < data.cancelled.length; i++) {
            serialized.cancelled[i] = onSerializeRegistration(data.cancelled[i]);
        }
    }      

    return serialized;
}
