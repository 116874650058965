import { Option } from './column-definition';

/* eslint-disable @typescript-eslint/no-explicit-any */
function getPropertyValue(value: any, propertyName: string): any {

    const arrayRegex = /^([a-z]+)\[([0-9]+)\]$/i;
    
    const match = arrayRegex.exec(propertyName);
    if (match != null) {
        const fieldName = match[1];
        const arrayIndex = match[2];
        const array = value[fieldName];

        const arrayValue = array[arrayIndex];

        return arrayValue;
    }

    return value[propertyName];
}

export function getProperty<T>(value: T, propertyPath: string): any {
    const parts = propertyPath.split('.');

    let current = parts[0];
    let i = 0;
    
    while(i < parts.length) {
        value = getPropertyValue(value, current);
        if (value == null) {
            break;
        }
        
        current = parts[++i];
    }

    if(i === parts.length) {
        return value;
    }

    return null;
}

export function createOptions<T, TItemField extends keyof T, TInstanceField extends keyof T[TItemField]>(items: T[], itemField: TItemField, uniqueField: TInstanceField, displayField: TInstanceField): Option[] {

    const result = new Array<Option>();
    if (items == null) {
        return result;
    }

    for (const item of items) {
        if (item == null) {
            continue;
        }

        const instance = item[itemField];
        if (instance == null) {
            continue;
        }

        let found = false;
        const uniqueValue = instance[uniqueField].toString();
        
        for (const existing of result) {
            if (existing.value === uniqueValue) {
                found = true;
                break;
            }
        }

        if (!found) {
            result.push({
                text: (instance[displayField] ?? instance[uniqueField]).toString(),
                value: (instance[displayField] ?? instance[uniqueField]).toString()
            });
        }
    }

    return result;
}