import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectedApplicationsSelector } from '@/store/planboard/selectors';
import { locationsSelector } from '@/store/general/selectors';
import R from 'ramda';

import { DataGrid, ColumnPicker, DataGridColumn, Size, DataGridColumnOption, BooleanColors } from '@/data-grid';

import { ScreenContainer, MenuBackButton } from '@/screens/screen-container';
import { GridSelectionMode, Option } from '@/data-grid';
import { Routes, usePageNavigation } from '@/router/routes';
import { useLifeCycle } from '@/react/hooks';
import { useRouter } from '@/router/react-router-hooks';
import { ToolOutlined } from '@ant-design/icons';

const defaultColumns = ['applicationType', 'requested.on', 'careTypes', 'isWaitingList', 'child.fullName', 'child.birthDate', 'fromDate', 'preferredLocationIds[0]'];

export function PlanboardSelectedApplicationsScreen(): React.ReactElement {

    const { history } = useRouter();
    const { back, push } = usePageNavigation();
    const selectedApplications = useSelector(selectedApplicationsSelector);
    const [drawerVisible, setDrawerVisible] = useState(false);

    const locations = useSelector(locationsSelector) || [];

    useLifeCycle(() => {
        if (selectedApplications.length === 0) {
            back();
        }

        if (selectedApplications.length === 1) {
            if (history.action === 'POP') {
                back();
            } else {
                push(Routes.PlanboardPlanner, { applicationId: selectedApplications[0].applicationId });
            }
        }
    });

    const lookup = locations.reduce((acc, cur) => {
        acc[cur.locationId] = cur.name;
        return acc;
    }, {});
    const usedLocations = R.uniq(selectedApplications.reduce((acc, cur) => acc.concat(cur.preferredLocationIds), new Array<string>()).map(x => ({
        text: lookup[x],
        value: x
    })));
    const usedSchools = R.uniqBy(x => x.school.name, selectedApplications.filter(x => x.school != null)).map<Option>(x => ({
        text: x.school.name ?? x.school.schoolId,
        value: x.school.name ?? x.school.schoolId
    }));
    const usedOwners = R.uniqBy(x => x.owner.userId, selectedApplications.filter(x => x.owner != null)).map<Option>(x => ({
        text: x.owner.fullName ?? x.owner.userId,
        value: x.owner.fullName ?? x.owner.userId
    }));

    return (
        <ScreenContainer showFullScreen={true} menuItems={[
            MenuBackButton(),
            {
                icon: <ToolOutlined />,
                translation: 'dataGrid.columnOptions',
                position: 'right',
                iconOnly: true,
                onClick: () => setDrawerVisible(true)
            },
        ]}>
            <DataGrid
                name='selectedApplications'
                datasource={selectedApplications}
                keyPath='applicationId'
                selectionMode={GridSelectionMode.Single}
                onSelectionChanged={([first]) => push(Routes.PlanboardPlanner, { applicationId: first.applicationId })}
                useProvider
                defaultColumns={defaultColumns}>
                <ColumnPicker visible={drawerVisible} onClose={() => setDrawerVisible(false)} />

                <DataGridColumn type='Text' translation='planboard.fields.type' path='applicationType'
                    translateTemplate='planboard.{value}'
                    colors={{
                        new: 'green',
                        change: 'orange',
                        end: 'red'
                    }}>
                    <DataGridColumnOption translation='planboard.new' value='new' />
                    <DataGridColumnOption translation='planboard.change' value='change' />
                    <DataGridColumnOption translation='planboard.end' value='end' />
                </DataGridColumn>
                <DataGridColumn type='Date' translation='planboard.fields.requestedOn' path='requested.on'
                    defaultSortOrder='ascend' />
                <DataGridColumn type='Text' translation='planboard.fields.caretypes' path='careTypes'
                    translateTemplate='global.caretype.{value}'
                    colors={{
                        daycare: 'green',
                        betweenSchoolCare: 'orange',
                        outOfSchoolCare: 'red',
                        holidayCare: 'blue',
                        playschool: 'yellow'
                    }}>
                    <DataGridColumnOption translation='global.caretype.betweenSchoolCare' value='betweenSchoolCare' />
                    <DataGridColumnOption translation='global.caretype.daycare' value='daycare' />
                    <DataGridColumnOption translation='global.caretype.outOfSchoolCare' value='outOfSchoolCare' />
                    <DataGridColumnOption translation='global.caretype.holidayCare' value='holidayCare' />
                    <DataGridColumnOption translation='global.caretype.playschool' value='playschool' />
                </DataGridColumn>
                <DataGridColumn type='Bool' translation='planboard.fields.onHold' path='isWaitingList' filter
                    colors={BooleanColors} />
                <DataGridColumn type='Text' translation='planboard.fields.name' path='child.fullName' size={Size.Large}
                    filter />
                <DataGridColumn type='Date' translation='planboard.fields.dateOfBirth' path='child.birthDate' />
                <DataGridColumn type='Bool' translation='planboard.fields.hasSibling' path='hasSibling' filter />
                <DataGridColumn type='Date' translation='planboard.fields.startsOn' path='fromDate' />
                <DataGridColumn type='Text' translation='planboard.fields.location' path='preferredLocationIds[0]'
                    size={Size.Large} onValue={x => lookup[x]} dropdown={usedLocations} />
                <DataGridColumn type='Text' translation='planboard.fields.school' path='school.name'
                    dropdown={{ source: usedSchools, allowNone: true }} />
                <DataGridColumn type='Text' translation='planboard.fields.owner' path='owner.fullName'
                    dropdown={{ source: usedOwners, allowNone: true }} />
                <DataGridColumn type='Text' translation='planboard.fields.remarks' path='remarks' />
            </DataGrid>
        </ScreenContainer>
    );
}