import React, { } from 'react';
import { Menu, Breadcrumb, Dropdown, Avatar, Button } from 'antd';
import { useSelector } from 'react-redux';

import { Translate } from 'react-localize-redux';
import { breadcrumbsSelector } from '@/store/ui/selectors';
import { useActiveLanguage } from '@/language-provider';

import { languages } from '@/language-provider';

import { ProfileMenu } from '@/components/profile-menu';
import { FlagOutlined, UserOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const AppHeader: React.FC<{ hidden?: boolean }> = ({ hidden = false }) => {
    const breadcrumbs = useSelector(breadcrumbsSelector);
    const [activeLanguage, setActiveLanguage] = useActiveLanguage();

    return (
        <Header>
            <LogoContainer>
                <img src='/images/logo-kp.png' alt="brand" />
            </LogoContainer>
            <BreadcrumbContainer>
                {<Breadcrumb>
                    {breadcrumbs.map(breadcrumb => {
                        return (
                            <Breadcrumb.Item key={breadcrumb}>
                                <Translate id={`global.menu.${breadcrumb}`} />
                            </Breadcrumb.Item>
                        );
                    })}
                </Breadcrumb>}
            </BreadcrumbContainer>
            <ProfileContainer>
                <Dropdown className="language-picker" placement="bottomLeft" trigger={['click']} overlay={
                    <Menu>
                        {languages.map(x => <Menu.Item key={x.code} onClick={() => setActiveLanguage(x)}>{x.name}</Menu.Item>)}
                    </Menu>
                }>
                    <Button type="dashed" icon={<FlagOutlined />}>
                        {activeLanguage && activeLanguage.name}
                    </Button>
                </Dropdown>
                <Dropdown overlay={<ProfileMenu />} placement="bottomLeft" trigger={['click']}>
                    <Avatar icon={<UserOutlined />} size={45} />
                </Dropdown>
            </ProfileContainer>
        </Header>
    );
};

const Header = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: white;
`;

const LogoContainer = styled.div`
    width: 200px;
    padding: 20px;
    display: flex;
    float: left;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      width: -moz-stretch;
      width: -webkit-stretch;
      width: stretch;
    }
`;

const BreadcrumbContainer = styled.div`
    flex: 1;
`;

const ProfileContainer = styled.div`
    padding-right: 16px;

    .language-picker {
        margin-right: 20px;
    }
`;