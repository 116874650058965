import {GeneralActions} from '@/store/general/actions';
import {AuthenticationActions} from '@/store/authentication/actions';
import {AnyAction} from 'redux';
import {actionOf, GenericEpic, pipe} from '@/store/epic-helper';
import {Observable, pipe as rxjsPipe} from 'rxjs';
import {filter, flatMap, map} from 'rxjs/operators';
import {replace} from 'connected-react-router';
import {authenticationStatusSelector} from '@/store/authentication/selectors';
import {Status} from '@/store/authentication/state';

const loadLocationsEpic: GenericEpic = (action$, states$, {locationsApi}): Observable<AnyAction> => {
    return pipe(
        action$,
        actionOf(GeneralActions.loadLocations),
        () => locationsApi(states$.value).get(),
        response => GeneralActions.loadLocationsSuccess(response),
        (action, error) => GeneralActions.loadLocationsFailed({
            action: action,
            axios: error,
            message: 'Loading locations failed',
            description: 'A problem occurred while fetching locations'
        }));
};

const loadGroupsEpic: GenericEpic = (action$, states$, {groupsApi}): Observable<AnyAction> => {
    return pipe(
        action$,
        actionOf(GeneralActions.loadGroups),
        () => groupsApi(states$.value).get(),
        response => GeneralActions.loadGroupsSuccess(response),
        (action, error) => GeneralActions.loadGroupsFailed({
            action: action,
            axios: error,
            message: 'Loading groups failed',
            description: 'A problem occurred while fetching groups'
        }));
};

const loadCarePackagesEpic: GenericEpic = (action$, states$, {carePackagesApi}): Observable<AnyAction> => {
    return pipe(
        action$,
        actionOf(GeneralActions.loadCarePackages),
        () => carePackagesApi(states$.value).get(),
        response => GeneralActions.loadCarePackagesSuccess(response),
        (action, error) => GeneralActions.loadCarePackagesFailed({
            action: action,
            axios: error,
            message: 'Loading carepackages failed',
            description: 'A problem occurred while fetching carepackages'
        }));
};

const loadTimeSlotsEpic: GenericEpic = (action$, states$, {timeSlotsApi}): Observable<AnyAction> => {
    return pipe(
        action$,
        actionOf(GeneralActions.loadTimeSlots),
        () => timeSlotsApi(states$.value).get(),
        response => GeneralActions.loadTimeSlotsSuccess(response),
        (action, error) => GeneralActions.loadTimeSlotsFailed({
            action: action,
            axios: error,
            message: 'Loading timeslots failed',
            description: 'A problem occurred while fetching timeslots'
        }));
};

const signInEpic: GenericEpic = action$ =>
    pipe(action$,
        actionOf(AuthenticationActions.loginSuccess),
        flatMap(() => [GeneralActions.loadLocations(), GeneralActions.loadGroups(), GeneralActions.loadCarePackages(), GeneralActions.loadTimeSlots()])
    );

const applicationStartEpic: GenericEpic = (action$, states$) =>
    pipe(action$, 
        actionOf(GeneralActions.applicationStart),
        rxjsPipe(
            filter(() => authenticationStatusSelector(states$.value) === Status.Authenticated),
            flatMap(() => [GeneralActions.loadLocations(), GeneralActions.loadGroups(), GeneralActions.loadCarePackages(), GeneralActions.loadTimeSlots()])
        ));

const resetAppEpic: GenericEpic = action$ =>
    pipe(
        action$,
        actionOf(GeneralActions.resetApp),
        map(() => {
            return replace('/login');
        })
    );

export default [
    loadLocationsEpic,
    loadGroupsEpic,
    loadCarePackagesEpic,
    loadTimeSlotsEpic,
    signInEpic,
    resetAppEpic,
    applicationStartEpic
];