import { DocumentsListItem, onDeserializeDocumentsListItem, onSerializeDocumentsListItem} from './documents-list-item';

export interface DocumentsModel {
    documents?: DocumentsListItem[];
}

export function onDeserializeDocumentsModel(data: DocumentsModel): DocumentsModel {
    if (data == null) {
        return data;
    }

    if (data.documents != null) {
        for (let i = 0; i < data.documents.length; i++) {
            if (data.documents[i] != null) {
                data.documents[i] = onDeserializeDocumentsListItem(data.documents[i]);
            }
        }
    }

    return data;
}

export function onSerializeDocumentsModel(data: DocumentsModel): DocumentsModel {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    if (data.documents != null) {
        for (let i = 0; i < data.documents.length; i++) {
            serialized.documents[i] = onSerializeDocumentsListItem(data.documents[i]);
        }
    }      

    return serialized;
}
