import React from 'react';
import {authenticationStatusSelector} from '@/store/authentication/selectors';
import {useSelector} from 'react-redux';
import {Switch} from 'react-router';
import {Status} from '@/store/authentication/state';
import {RouteKind, Routes, PageRoute} from '@/router/routes';

export function AppRouter(): React.ReactElement {
    const status = useSelector(authenticationStatusSelector);

    let routes: PageRoute[];
    if (status === Status.Authenticated) {
        routes = Routes.All.filter(x => x.kind === RouteKind.authenticated);
    } else {
        routes = Routes.All.filter(x => x.kind === RouteKind.unauthenticated);
    }

    for (const route of routes) {
        console.log(`route: ${route.path}`);
    }

    return (
        <Switch>
            {routes.map(x => x.createElement())}
        </Switch>
    );
}