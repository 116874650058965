import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationDetails, ApplicationScheduleLine, Group, LocationUsagesResponse, TimeSlot } from '@/client/models';
import { Actions, PlanboardActions } from '@/store/planboard/actions';
import { ApplicationSchedulePart, ApplicationPeriodGroupTime, GroupComposition } from '@/store/planboard/state';
import { applicationDetailsRetrievedSelector, locationUsagesRetrievedSelector } from '@/store/planboard/selectors';
import { groupsRetrievedSelector, timeSlotsRetrievedSelector } from '@/store/general/selectors';
import { Status } from '@/store/status';
import { Progress } from 'antd';
import { useTranslate } from '@/language-provider';
import R from 'ramda';
import { GeneralActions } from '@/store/general/actions';
import { Error } from '@/app-error-boundry';
import styled from 'styled-components';
import { createCompositions, splitComposition, mergeComposition } from './group-compositions';
import { RetrievedValue } from '@/store/retrieved-value';
import { GenericError } from '@/store/generic-error';
import moment from 'moment';

export const PlannerApplicationPartProviderContext = React.createContext<ApplicationPartProvider>(undefined);

export type ActionCallback = () => void;

class SelectedItem {
    public schedulePartId: string;
    public compositionId: string;
    public groupLine: ApplicationPeriodGroupTime;

    public constructor(groupLine: ApplicationPeriodGroupTime) {
        this.schedulePartId = groupLine.groupPeriod.composition.schedulePart.schedulePartId;
        this.compositionId = groupLine.groupPeriod.composition.compositionId;
        this.groupLine = groupLine;
    }
}

export class ApplicationPartProvider {

    private dispatch: Dispatch<Actions>;
    public readonly details: ApplicationDetails;
    public readonly groups: Group[];
    public readonly timeSlots: TimeSlot[];
    public scheduleParts = new Array<ApplicationSchedulePart>();
    private selectedLocationId: string;
    private selectedSchedulePartId: string;
    public allUsages: LocationUsagesResponse[];

    private initialized = false;
    private readonly changeListeners = new Array<ActionCallback>();
    private readonly selection = new Array<SelectedItem>();


    public constructor(dispatch: Dispatch<Actions>, details: ApplicationDetails, groups: Group[], timeSlots: TimeSlot[]) {
        this.dispatch = dispatch;
        this.groups = groups;
        this.details = details;
        this.timeSlots = timeSlots;

        this.selectLocation(this.details.preferredLocationIds[0]);
    }

    public onChange(callback: ActionCallback): ActionCallback {
        this.changeListeners.push(callback);

        return () => this.changeListeners.splice(this.changeListeners.indexOf(callback), 1);
    }

    private unselectConflictingSelections(groupLine: ApplicationPeriodGroupTime): void {
        const { start: currentStart, end: currentEnd } = groupLine.groupPeriod;

        // This is the complete selection across all compositions
        const current = this.getSelection(groupLine.groupPeriod.composition.schedulePart);

        // Check if there is a overlap between the new selection and the previous, and deselect the others if they do
        for (const other of current.filter(x => x !== groupLine && x.line.timeslotId === groupLine.line.timeslotId)) {
            const { start: otherStart, end: otherEnd } = other.groupPeriod;

            if (currentStart.isSameOrBefore(otherEnd) && currentEnd.isSameOrAfter(otherStart)) {
                const index = R.findIndex(x => x.groupLine === other, this.selection);
                this.selection.splice(index, 1);
            }
        }
    }

    public toggle(groupLine: ApplicationPeriodGroupTime, fireChangedEvent = true): void {
        // Find out if it is a add or a remove
        const index = this.selection.findIndex(x => this.selectedItemEquals(x, groupLine));
        if (index >= 0) {
            this.unselect(groupLine, fireChangedEvent);
        } else {
            this.select(groupLine, fireChangedEvent);
        }
    }

    private selectedItemEquals(selectedItem: SelectedItem, groupLine: ApplicationPeriodGroupTime): boolean {
        if (selectedItem.compositionId !== groupLine.groupPeriod.composition.compositionId) {
            return false;
        }

        if (selectedItem.schedulePartId !== groupLine.groupPeriod.composition.schedulePart.schedulePartId) {
            return false;
        }

        if (selectedItem.groupLine.line.timeslotId !== groupLine.line.timeslotId) {
            return false;
        }

        if (selectedItem.groupLine.groupPeriod.group.groupId !== groupLine.groupPeriod.group.groupId) {
            return false;
        }

        if (!selectedItem.groupLine.groupPeriod.start.isSame(groupLine.groupPeriod.start, 'day')) {
            return false;
        }

        if (!selectedItem.groupLine.groupPeriod.end.isSame(groupLine.groupPeriod.end, 'day')) {
            return false;
        }

        return true;
    }

    public unselect(groupLine: ApplicationPeriodGroupTime, fireChangedEvent = true): void {
        const index = this.selection.findIndex(x => this.selectedItemEquals(x, groupLine));
        if (index >= 0) {
            this.selection.splice(index, 1);

            if (fireChangedEvent) {
                this.changed();
            }
        }
    }

    public select(groupLine: ApplicationPeriodGroupTime, fireChangedEvent = true): void {
        const index = this.selection.findIndex(x => this.selectedItemEquals(x, groupLine));
        if (index === -1) {
            this.unselectConflictingSelections(groupLine);
            this.selection.push(new SelectedItem(groupLine));

            if (fireChangedEvent) {
                this.changed();
            }
        }
    }

    public isSelected(groupLine: ApplicationPeriodGroupTime): boolean {

        const isSelected = this.getSelection(groupLine.groupPeriod.composition.schedulePart).some(x =>
            x.line.timeslotId === groupLine.line.timeslotId &&
            x.groupPeriod.group.groupId === groupLine.groupPeriod.group.groupId &&
            x.groupPeriod.start.isSame(groupLine.groupPeriod.start, 'day') &&
            x.groupPeriod.end.isSame(groupLine.groupPeriod.end, 'day')
        );

        return isSelected;
    }

    public compositionHasSelection(composition: GroupComposition): boolean {
        return this.selection.filter(x => x.compositionId === composition.compositionId).length > 0;
    }

    public isInitialized(): boolean {
        return this.initialized;
    }

    public getSelectedLocation(): string {
        return this.selectedLocationId;
    }

    public isCurrentPartCompleted(): boolean {
        return this.isPartCompleted(this.getCurrentPart());
    }

    public allPartsCompleted(): boolean {
        for (const part of this.scheduleParts) {
            if (!this.isPartCompleted(part)) {
                return false;
            }
        }

        return true;
    }

    public getSelectedTimes(part: ApplicationSchedulePart): ApplicationPeriodGroupTime[] {

        const selectedTimes = new Array<ApplicationPeriodGroupTime>();

        for (const locationId in part.compositions) {
            for (const composition of part.compositions[locationId]) {
                for (const period of composition.groupPeriods) {
                    for (const time of period.times) {
                        if (this.isSelected(time)) {
                            selectedTimes.push(time);
                        }
                    }
                }
            }
        }

        return selectedTimes;
    }

    public isPartCompleted(part: ApplicationSchedulePart): boolean {
        for (const line of part.added) {
            let currentDate = line.from;
            const until = line.until ?? part.endsOn;

            const filtered = this.getSelection(part)
                .filter(x => x.line.timeslotId === line.timeslotId)
                .sort((a, b) => a.groupPeriod.start.diff(b.groupPeriod.start));

            for (const selected of filtered) {
                if (selected.groupPeriod.start.clone().subtract(1, 'day').isAfter(currentDate)) {
                    return false;
                }

                currentDate = selected.groupPeriod.end;
            }

            if (currentDate.isBefore(until)) {
                return false;
            }
        }

        return true;
    }

    public selectSchedulePart(selectedSchedulePartId: string): void {
        this.selectedSchedulePartId = selectedSchedulePartId;
        this.changed();
    }

    public getCurrentPart(): ApplicationSchedulePart {
        return this.scheduleParts.find(x => x.schedulePartId === this.selectedSchedulePartId) ?? this.scheduleParts[0];
    }

    public getCurrentCompositions(): GroupComposition[] {
        return this.getCurrentPart().compositions[this.selectedLocationId];
    }

    private getSelection(part: ApplicationSchedulePart): ApplicationPeriodGroupTime[] {
        return this.selection
            .filter(x => x.schedulePartId === part.schedulePartId)
            .map(x => x.groupLine);
    }

    public selectLocation(locationId: string): void {
        if (locationId == null) {
            return;
        }

        this.selectedLocationId = locationId;
        this.dispatch(PlanboardActions.loadUsages(locationId, this.details.startsOn, this.details.endsOn));
    }

    public initialize(allUsages: LocationUsagesResponse[]): void {
        const schedulePartIds = R.uniq(R.map(x => x.schedulePartId, this.details.schedule.added));

        this.allUsages = allUsages;
        this.scheduleParts = new Array<ApplicationSchedulePart>();
        for (const schedulePartId of schedulePartIds) {
            const schedulePart = this.createSchedulePart(schedulePartId, this.details);
            this.scheduleParts.push(schedulePart);
        }

        if (this.scheduleParts.length > 0) {
            this.selectedSchedulePartId = this.scheduleParts[0].schedulePartId;
            this.initializeSelection(this.scheduleParts);
        }

        this.dispatch(PlanboardActions.initializeScheduleParts(this.scheduleParts));

        this.initialized = true;

        this.changed();
    }

    public splitComposition(composition: GroupComposition, start: moment.Moment, end: moment.Moment): void {
        splitComposition(composition, start, end, this.timeSlots, this.allUsages);

        this.changed();
    }

    public mergeComposition(composition: GroupComposition, start: moment.Moment, end: moment.Moment): void {
        mergeComposition(composition, start, end, this.timeSlots, this.allUsages);

        this.changed();
    }

    private initializeSelection(parts: ApplicationSchedulePart[]): void {
        this.selection.length = 0;

        for (const part of parts) {
            const partSelection = new Array<SelectedItem>();
            for (const locationId of Object.keys(part.compositions)) {
                for (const composition of part.compositions[locationId]) {
                    for (const line of part.added) {
                        if (line.compositionId !== composition.compositionId) {
                            continue;
                        }

                        for (const planning of composition.groupPeriods) {
                            if (planning.group.groupId !== line.groupId) {
                                continue;
                            }

                            for (const groupLine of planning.times) {
                                if (groupLine.line.timeslotId !== line.timeslotId) {
                                    continue;
                                }

                                const { from, until } = line;
                                const { start, end } = groupLine.groupPeriod;

                                partSelection.push(new SelectedItem({ ...groupLine, groupPeriod: { ...groupLine.groupPeriod, start: from, end: until } }));

                                if (!start.isSame(from) || !end.isSame(until)) {
                                    this.splitComposition(composition, line.from, line.until);
                                }
                            }
                        }
                    }
                }
            }


            this.selection.push(...partSelection);
        }
    }

    public saveCurrentPart(): void {
        this.savePart(this.getCurrentPart());
    }

    public savePart(part: ApplicationSchedulePart): void {
        if (!this.isPartCompleted(part)) {
            return;
        }

        const lines = new Array<ApplicationScheduleLine>();
        const selectedLines = this.selection.filter(x => x.schedulePartId === part.schedulePartId);

        for (const selectedLine of selectedLines) {
            lines.push({
                ...selectedLine.groupLine.line,
                groupId: selectedLine.groupLine.groupPeriod.group.groupId,
                from: selectedLine.groupLine.groupPeriod.start,
                until: selectedLine.groupLine.groupPeriod.end,
                compositionId: selectedLine.compositionId
            });
        }

        this.dispatch(PlanboardActions.saveSchedulePart(part, lines));
    }

    public changed(): void {
        for (const callback of this.changeListeners) {
            callback();
        }
    }

    private createSchedulePart(schedulePartId: string, details: ApplicationDetails): ApplicationSchedulePart {

        const { unchanged, added, removed } = this.filterLinesForSchedulePart(schedulePartId, details);

        const schedulePart = {
            applicationId: details.applicationId,
            schedulePartId: schedulePartId,
            startsOn: details.startsOn,
            endsOn: details.endsOn,
            child: details.child,
            careType: unchanged[0]?.careType ?? added[0]?.careType ?? removed[0]?.careType,
            unchanged: this.sortedScheduleLines(unchanged),
            added: this.sortedScheduleLines(added),
            removed: this.sortedScheduleLines(removed),
            compositions: {}
        } as ApplicationSchedulePart;

        schedulePart.compositions[this.selectedLocationId] = createCompositions(this.selectedLocationId, schedulePart, this.groups, this.timeSlots, this.allUsages);

        return schedulePart;
    }

    private sortedScheduleLines(lines: ApplicationScheduleLine[]): ApplicationScheduleLine[] {
        const combined = lines.map(x => ({ line: x, slot: this.timeSlots.find(y => y.timeSlotId === x.timeslotId) }));
        const sorted = R.sortBy(x => x.slot.sortOrder, combined);
        return sorted.map(x => x.line);
    }

    private filterLinesForSchedulePart(schedulePartId: string, details: ApplicationDetails): { unchanged: ApplicationScheduleLine[]; added: ApplicationScheduleLine[]; removed: ApplicationScheduleLine[] } {
        return {
            unchanged: details.schedule.unchanged.filter(x => x.schedulePartId === schedulePartId),
            added: details.schedule.added.filter(x => x.schedulePartId === schedulePartId),
            removed: details.schedule.removed.filter(x => x.schedulePartId === schedulePartId),
        };
    }
}

export function useApplicationPartProvider(): ApplicationPartProvider {
    return useContext(PlannerApplicationPartProviderContext);
}

export function usePartChanged(callback: ActionCallback): void {
    const provider = useContext(PlannerApplicationPartProviderContext);

    useEffect(() => {
        return provider.onChange(callback);
    }, []);
}

function aggregateUsages(allUsages: RetrievedValue<LocationUsagesResponse>[]): { status: Status; usages: LocationUsagesResponse[]; error: GenericError } {
    const usages = new Array<LocationUsagesResponse>();
    let status = Status.Loaded;
    let error = {} as GenericError;

    for (const usage of allUsages) {
        if (status !== Status.Error) {
            if (usage.status === Status.Error) {
                status = Status.Error;
                error = usage.error;
            } else if (usage.status === Status.Init) {
                status = Status.Init;
            } else if (usage.status === Status.Loading) {
                status = Status.Loading;
            }
        }

        usages.push(usage.value);
    }

    return {
        usages,
        status,
        error
    };
}

export const PlannerApplicationPartProvider: React.FC = (props) => {
    const dispatch = useDispatch();
    const { translate } = useTranslate();
    const { value: details, status: detailsStatus, error: detailsError } = useSelector(applicationDetailsRetrievedSelector);
    const { value: groups, status: groupsStatus, error: groupsError } = useSelector(groupsRetrievedSelector);
    const { value: timeSlots, status: timeSlotsStatus, error: timeSlotsError } = useSelector(timeSlotsRetrievedSelector);
    const [provider, setProvider] = useState<ApplicationPartProvider>(undefined);
    const [initialized, setInitialized] = useState(false);
    const allUsages = useSelector(locationUsagesRetrievedSelector);
    const aggregatedUsages = aggregateUsages(allUsages);

    const allStatuses = [groupsStatus, aggregatedUsages.status, timeSlotsStatus, detailsStatus];

    let status = allStatuses.every(x => x === Status.Loaded) ? Status.Loaded : Status.Loading;
    status = allStatuses.some(x => x === Status.Error) ? Status.Error : status;

    const isInitializing = status === Status.Loaded && initialized === false;
    const finishedStatuses = allStatuses.reduce((acc, cur) => acc + ((cur === Status.Loaded || cur === Status.Error) ? 1 : 0), 0);

    const percentage = (finishedStatuses + (status === Status.Loaded && !isInitializing ? 1 : 0)) / (allStatuses.length + 1) * 100;

    useEffect(() => {
        if (status === Status.Loaded && provider != null) {
            provider.initialize(aggregatedUsages.usages);
            setInitialized(true);
        }
    }, [provider, status, aggregatedUsages.usages]);

    useEffect(() => {
        if (detailsStatus === Status.Loaded && groupsStatus === Status.Loaded && timeSlotsStatus === Status.Loaded) {
            setProvider(new ApplicationPartProvider(dispatch, details, groups, timeSlots));
        } else if (groupsStatus === Status.Init) {
            dispatch(GeneralActions.loadGroups());
        } else if (timeSlotsStatus === Status.Init) {
            dispatch(GeneralActions.loadTimeSlots());
        }
    }, [groupsStatus, timeSlotsStatus, detailsStatus]);

    return (
        <PlannerApplicationPartProviderContext.Provider value={provider}>
            {status === Status.Loading && (
                <ProgressContainer>
                    <div id='progress'><Progress type="circle" percent={percentage} /></div>
                    <div id='text'>{translate('global.generic.loading')}</div>
                </ProgressContainer>
            )}
            {isInitializing && (
                <ProgressContainer>
                    <div id='progress'><Progress type="circle" percent={percentage} strokeColor='orange' /></div>
                    <div id='text'>{translate('global.generic.initializing')}</div>
                </ProgressContainer>
            )}
            {status === Status.Error && (
                <ProgressContainer>
                    <div id='progress'><Progress type="circle" percent={percentage} status='exception' /></div>
                    <div id='text'>
                        <Error error={{ message: aggregatedUsages.error?.message ?? groupsError?.message ?? timeSlotsError?.message ?? detailsError?.message }} />
                    </div>
                </ProgressContainer>
            )}

            {/* eslint-disable-next-line react/prop-types */}
            {initialized && props.children}
        </PlannerApplicationPartProviderContext.Provider>
    );
};

PlannerApplicationPartProvider.propTypes = {
    details: PropTypes.any
};

const ProgressContainer = styled.div`
    display: Grid;
    grid-template-rows: 1fr max-content max-content 1fr;
    row-gap: 15px;
    
    justify-content: center;
    height: 100%;
    
    #progress {
        grid-row: 2;
        text-align: center;
    }
    
    #text {
        grid-row: 3;
        text-align: center;
    }
`;