import { ContractModel, onDeserializeContractModel, onSerializeContractModel } from '../models/contract-model';
import globalAxios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { mergeHeaders, AxiosHeaders } from '../utilities/merge-headers';

export class ContractsApi {
    private baseUrl: string;

    public constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    public async getContractId(contractId: string, headers?: AxiosHeaders): Promise<AxiosResponse<ContractModel>> {
        const request = {
            url: this.baseUrl + '/contracts/{contractId}'
                .replace(`{${'contractId'}}`, encodeURIComponent(contractId)),
            method: 'GET',
            responseType: 'json',
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<ContractModel>(request);
        
        if (response.status === 200) {
            response.data = onDeserializeContractModel(response.data);
        }

        return response;
    }

    public async getContractIdpdf(contractId: string, headers?: AxiosHeaders): Promise<AxiosResponse<void>> {
        const request = {
            url: this.baseUrl + '/contracts/{contractId}.pdf'
                .replace(`{${'contractId'}}`, encodeURIComponent(contractId)),
            method: 'GET',
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<void>(request);
        

        return response;
    }
}