import { ApplicationScheduleLine, onDeserializeApplicationScheduleLine, onSerializeApplicationScheduleLine} from './application-schedule-line';

export interface ApplicationSchedulePart {
    schedulePartId: string;
    lines?: ApplicationScheduleLine[];
}

export function onDeserializeApplicationSchedulePart(data: ApplicationSchedulePart): ApplicationSchedulePart {
    if (data == null) {
        return data;
    }

    if (data.lines != null) {
        for (let i = 0; i < data.lines.length; i++) {
            if (data.lines[i] != null) {
                data.lines[i] = onDeserializeApplicationScheduleLine(data.lines[i]);
            }
        }
    }

    return data;
}

export function onSerializeApplicationSchedulePart(data: ApplicationSchedulePart): ApplicationSchedulePart {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    if (data.lines != null) {
        for (let i = 0; i < data.lines.length; i++) {
            serialized.lines[i] = onSerializeApplicationScheduleLine(data.lines[i]);
        }
    }      

    return serialized;
}
