import { CareTimeslot, onDeserializeCareTimeslot, onSerializeCareTimeslot} from './care-timeslot';
import moment from 'moment';

export interface CareTimeHistory {
    timeslots: CareTimeslot[];
    from: moment.Moment;
}

export function onDeserializeCareTimeHistory(data: CareTimeHistory): CareTimeHistory {
    if (data == null) {
        return data;
    }

    if (data.timeslots != null) {
        for (let i = 0; i < data.timeslots.length; i++) {
            if (data.timeslots[i] != null) {
                data.timeslots[i] = onDeserializeCareTimeslot(data.timeslots[i]);
            }
        }
    }
    if (data.from != null) {
        data.from = moment(data.from);
    }

    return data;
}

export function onSerializeCareTimeHistory(data: CareTimeHistory): CareTimeHistory {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    if (data.timeslots != null) {
        for (let i = 0; i < data.timeslots.length; i++) {
            serialized.timeslots[i] = onSerializeCareTimeslot(data.timeslots[i]);
        }
    }      
    serialized.from = data.from?.format('YYYY-MM-DD') as unknown as moment.Moment;

    return serialized;
}
