import React, { useEffect } from 'react';
import { Drawer } from 'antd';
import { SelectButton, SaveButton, CancelButton, ButtonBar, CloseButton } from '@/components/buttons';
import { ButtonProps } from '@/components/button';
import styled from 'styled-components';
import { Title } from './title';

export enum DrawerSize {
    small = 320,
    medium = 640,
    large = 720
}
export enum PlacementType {
    top = 'top',
    right = 'right',
    bottom = 'bottom',
    left = 'left',
}

interface SelectionDrawerProps {
    titleTranslation?: string;
    titleText?: string;
    placement?: keyof typeof PlacementType;
    size?: DrawerSize;
    visible: boolean;
    selectDisabled?: boolean;
    isEditMode?: boolean;
    onSelect?: () => void;
    onCancel?: () => void;
    hideDefaultButtons?: boolean;
    buttons?: React.ReactElement<ButtonProps>[];
}

const titleStyle: React.CSSProperties = {
    textAlign: 'left',
    paddingTop: '10px',
    paddingBottom: '10px',
};

export function SelectionDrawer(props: React.PropsWithChildren<SelectionDrawerProps>): React.ReactElement {

    const { isEditMode = false, placement = PlacementType.right, hideDefaultButtons = false} = props;

    let size = props.size;
    if (size == null) {
        size = DrawerSize.medium;
    }

    let borderSize = '0px';
    if (props.titleTranslation || props.titleText) {
        borderSize = '1px';
    }

    useEffect(() => {
        if (isEditMode && props.onSelect == null) {
            console.warn('The selectiongrid is used in an invalid mode, onSelect must be set in edit mode');
        }
    }, [isEditMode, props.onSelect]);

    return (
        <Drawer visible={props.visible} closable={false} onClose={props.onCancel} width={size} bodyStyle={{ paddingTop: '5px' }} placement={placement} >
            <Header size={borderSize}>
                {props.titleTranslation && <Title translation={props.titleTranslation} style={titleStyle} />}
                {props.titleText && <Title text={props.titleText} style={titleStyle} />}
            </Header>

            {props.children}

            <ButtonBar>
                {!hideDefaultButtons && props.onSelect && props.onCancel &&
                (
                    isEditMode ? 
                        <>
                            <SaveButton onClick={props.onSelect} disabled={props.selectDisabled} />
                            <CancelButton onClick={props.onCancel} />
                        </> : 
                        <>
                            <SelectButton onClick={props.onSelect} disabled={props.selectDisabled} />
                            <CloseButton onClick={props.onCancel} />
                        </>
                )}
                {props.buttons}
            </ButtonBar>
        </Drawer>
    );
}

const Header = styled.div<{ size: string }>`
    margin-left: -24px;
    margin-bottom: 11.8px;
    border-bottom: ${props => props.size} solid #e9e9e9;

    h4 {
        margin-left: 24px;
        opacity: 0.50;
    }
`;