export enum ValidationError {
    PlanningNotInvoicable = 'planningNotInvoicable',
    PackageCountWeeksUnavailable = 'packageCountWeeksUnavailable',
    MissingChild = 'missingChild',
    MissingPlanning = 'missingPlanning',
    MissingCareTime = 'missingCareTime',
    MissingClosedDay = 'missingClosedDay',
    MissingStudyDay = 'missingStudyDay',
    EndBeforeStart = 'endBeforeStart',
    MissingStartDate = 'missingStartDate',
    MissingSchool = 'missingSchool',
    MissingGroup = 'missingGroup',
    MissingFromPlanning = 'missingFromPlanning',
    MissingToPlanning = 'missingToPlanning',
    MissingFromTimeSlot = 'missingFromTimeSlot',
    MissingToTimeSlot = 'missingToTimeSlot',
    MissingTimeSlot = 'missingTimeSlot',
    MissingPackage = 'missingPackage',
    NoMorningTimeSlot = 'noMorningTimeSlot',
    NoAfternoonTimeSlot = 'noAfternoonTimeSlot',
    StudyDayBeforePlanning = 'studyDayBeforePlanning',
    StudyDayAfterPlanning = 'studyDayAfterPlanning',
    MissingStartTime = 'missingStartTime',
    MissingEndTime = 'missingEndTime',
    SameStartAndEndTime = 'sameStartAndEndTime',
    MissingEndDate = 'missingEndDate',
}