import {State} from './state';
import { initValue, loadingValue, loadedValue, errorValue } from '@/store/retrieved-value';
import { Actions, GeneralActions } from './actions';
import { isActionFromContainer } from '@/store/action';

const initialState: State = {
    applicationStarted: false,
    locations: initValue(),
    groups: initValue(),
    carePackages: initValue(),
    timeSlots: initValue(),
};

export const reducer = (state = initialState, action: Actions): State => {
    if (!isActionFromContainer(action, GeneralActions)) {
        return state;
    }

    switch (action.action) {
        
        case 'applicationStart': return { ...state, applicationStarted: true };

        case 'loadLocations':
            return { ...state, locations: loadingValue(state.locations.value) };
        case 'loadLocationsSuccess':
            return { ...state, locations: loadedValue(action.payload.locations) };
        case 'loadLocationsFailed':
            return { ...state, locations: errorValue(action.payload.error) };
            
        case 'loadGroups':
            return { ...state, groups: loadingValue(state.groups.value) };
        case 'loadGroupsSuccess':
            return { ...state, groups: loadedValue(action.payload.groups) };
        case 'loadGroupsFailed':
            return { ...state, groups: errorValue(action.payload.error) };

        case 'loadCarePackages':
            return { ...state, carePackages: loadingValue(state.carePackages.value) };
        case 'loadCarePackagesSuccess':
            return { ...state, carePackages: loadedValue(action.payload.packages) };
        case 'loadCarePackagesFailed':
            return { ...state, carePackages: errorValue(action.payload.error) };
                
        case 'loadTimeSlots':
            return { ...state, timeSlots: loadingValue(state.timeSlots.value) };
        case 'loadTimeSlotsSuccess':
            return { ...state, timeSlots: loadedValue(action.payload.timeSlots) };
        case 'loadTimeSlotsFailed':
            return { ...state, timeSlots: errorValue(action.payload.error) };
        default:
            return state;
    }
};