export enum PresenceType {
    NotExpected = 'notExpected',
    Planning = 'planning',
    Incidental = 'incidental',
    StudyDay = 'studyDay',
    SwapDay = 'swapDay',
    SwapDayTraded = 'swapDayTraded',
    BeforeContract = 'beforeContract',
    Absent = 'absent',
    Sick = 'sick',
}