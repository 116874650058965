export class NetworkUtils {
    public static getTenantFromUrl(url: string): string | null {
        const tenantRegex = /(?:(?:groep|backoffice|ouder|vervoer|office)-)?([a-z-0-9]+)\.kindplanner.nl/i;
        const match = tenantRegex.exec(url);
        if (match != null) {
            return match[1];
        }

        return null;
    }

    public static createBackOfficeApiUrl(tenant: string): string {
        return `https://backoffice-${tenant}.kindplanner.nl`;
    }
}
