import {History} from 'history';
import {persistReducer} from 'redux-persist';
import {persistConfig} from '@/store/persistance-config';
import {combineReducers, Reducer, AnyAction} from 'redux';
import {ApplicationState} from '@/store/application-state';
import {connectRouter} from 'connected-react-router';
import {reducer as authenticationReducer} from '@/store/authentication/reducer';
import {reducer as uiReducer} from '@/store/ui/reducer';
import {reducer as planboardReducer} from '@/store/planboard/reducer';
import {reducer as generalReducer} from '@/store/general/reducer';
import {reducer as gridLayoutReducer} from '@/data-grid';
import {GeneralActions} from '@/store/general/actions';

export const createRootReducer = (history: History): Reducer<ApplicationState> => {

    const appReducer: Reducer<ApplicationState> = combineReducers<ApplicationState>({
        router: connectRouter(history),        
        grid: gridLayoutReducer,
        authentication: authenticationReducer,
        ui: uiReducer,
        planboard: planboardReducer,
        general: generalReducer
    });

    const rootReducer = (state: ApplicationState, action: AnyAction): ApplicationState => {
        if (action.type === GeneralActions.resetApp().type) {
            state = undefined;
        }

        return appReducer(state, action);
    };

    return persistReducer(persistConfig, rootReducer);
};