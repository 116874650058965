import moment from 'moment';

export interface ChildDetailsReference {
    childId: string;
    firstName: string;
    fullName: string;
    birthDate: moment.Moment;
}

export function onDeserializeChildDetailsReference(data: ChildDetailsReference): ChildDetailsReference {
    if (data == null) {
        return data;
    }

    if (data.birthDate != null) {
        data.birthDate = moment(data.birthDate);
    }

    return data;
}

export function onSerializeChildDetailsReference(data: ChildDetailsReference): ChildDetailsReference {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    serialized.birthDate = data.birthDate?.format('YYYY-MM-DD') as unknown as moment.Moment;

    return serialized;
}
