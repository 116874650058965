/* eslint-disable @typescript-eslint/no-explicit-any */
import { createTransform, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import moment from 'moment';
import { ApplicationState } from './application-state';

export interface MomentJSTransform {
    needsChange(value: any): boolean;
    performChange(value: any): any;
}

export class MomentJSSerializeTransform implements MomentJSTransform {
    public needsChange(value: any): boolean {
        return moment.isMoment(value) || moment.isDuration(value);
    }

    public performChange(value: any): any {
        if (moment.isMoment(value)) {
            return {
                isMoment: true,
                value: value
            };
        } else if (moment.isDuration(value)) {
            return {
                isDuration: true,
                value: value
            };
        }
    }
}

export class MomentJSDeserializeTransform implements MomentJSTransform {
    public needsChange(value: any): boolean {
        return value.isMoment === true || value.isDuration === true;
    }

    public performChange(value: any): any {
        if (value.isMoment === true) {
            return moment(value.value);
        } else if (value.isDuration === true) {
            return moment.duration(value.value);
        }
    }
}

export function transformMomentJS<T>(object: T, transform: MomentJSTransform): { changed: boolean; value: T } {

    let hasChanges = false;

    for (const key of Object.keys(object)) {
        const value = object[key];
        if (value == null || typeof value !== 'object') {
            continue;
        }

        if (Array.isArray(value)) {
            let clonedArray = value;
            for (let i = 0; i < value.length; i++) {
                if (typeof value[i] !== 'object') {
                    break;
                }

                const arrayResult = transformMomentJS(value[i], transform);
                if (arrayResult.changed === true) {
                    if (hasChanges === false) {
                        object = { ...object };
                    }

                    if (clonedArray === value) {
                        clonedArray = [...value];
                    }

                    hasChanges = true;
                    clonedArray.splice(i, 1, arrayResult.value);
                }
            }

            object[key] = clonedArray;

            continue;
        }
        
        const result = transformMomentJS(value, transform);
        if (result.changed) {
            if (hasChanges === false) {
                object = { ...object };
            }

            hasChanges = true;
            object[key] = result.value;
            continue;
        }
        
        if (transform.needsChange(value) === false) {
            continue;
        }

        if (hasChanges === false) {
            object = { ...object };
        }

        hasChanges = true;
        
        object[key] = transform.performChange(value);
    }

    return { changed: hasChanges, value: object };
}

const momentjsTransform = createTransform(
    x => transformMomentJS(x, new MomentJSSerializeTransform()).value, 
    x => transformMomentJS(x, new MomentJSDeserializeTransform()).value
);

export const persistConfig: PersistConfig<ApplicationState> = {
    transforms: [momentjsTransform],
    key: 'root',
    storage: storage,
    whitelist: ['authentication']
};