import { Post, onDeserializePost, onSerializePost} from './post';

export interface PostsList {
    posts: Post[];
}

export function onDeserializePostsList(data: PostsList): PostsList {
    if (data == null) {
        return data;
    }

    if (data.posts != null) {
        for (let i = 0; i < data.posts.length; i++) {
            if (data.posts[i] != null) {
                data.posts[i] = onDeserializePost(data.posts[i]);
            }
        }
    }

    return data;
}

export function onSerializePostsList(data: PostsList): PostsList {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    if (data.posts != null) {
        for (let i = 0; i < data.posts.length; i++) {
            serialized.posts[i] = onSerializePost(data.posts[i]);
        }
    }      

    return serialized;
}
