import { CreatePostCommand, onDeserializeCreatePostCommand, onSerializeCreatePostCommand } from '../models/create-post-command';
import { PostDetails, onDeserializePostDetails, onSerializePostDetails } from '../models/post-details';
import { PostsList, onDeserializePostsList, onSerializePostsList } from '../models/posts-list';
import moment from 'moment';
import qs from 'qs';
import globalAxios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { mergeHeaders, AxiosHeaders } from '../utilities/merge-headers';

export class PostsApi {
    private baseUrl: string;

    public constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    public async post(data: CreatePostCommand, headers?: AxiosHeaders): Promise<AxiosResponse<void>> {
        const request = {
            url: this.baseUrl + '/posts',
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);

        request.data = onSerializeCreatePostCommand(data);

        const response = await globalAxios.request<void>(request);
        

        return response;
    }

    public async getPostId(postId: string, headers?: AxiosHeaders): Promise<AxiosResponse<PostDetails>> {
        const request = {
            url: this.baseUrl + '/posts/{postId}'
                .replace(`{${'postId'}}`, encodeURIComponent(postId)),
            method: 'GET',
            responseType: 'json',
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<PostDetails>(request);
        
        if (response.status === 200) {
            response.data = onDeserializePostDetails(response.data);
        }

        return response;
    }

    public async postPostIdComments(postId: string, data: CreatePostCommand, headers?: AxiosHeaders): Promise<AxiosResponse<void>> {
        const request = {
            url: this.baseUrl + '/posts/{postId}/comments'
                .replace(`{${'postId'}}`, encodeURIComponent(postId)),
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);

        request.data = onSerializeCreatePostCommand(data);

        const response = await globalAxios.request<void>(request);
        

        return response;
    }

    public async postPostIdCommentsCommentIdReplies(postId: string, commentId: string, data: CreatePostCommand, headers?: AxiosHeaders): Promise<AxiosResponse<void>> {
        const request = {
            url: this.baseUrl + '/posts/{postId}/comments/{commentId}/replies'
                .replace(`{${'postId'}}`, encodeURIComponent(postId))
                .replace(`{${'commentId'}}`, encodeURIComponent(commentId)),
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);

        request.data = onSerializeCreatePostCommand(data);

        const response = await globalAxios.request<void>(request);
        

        return response;
    }

    public async getLatest(since?: moment.Moment, childId?: string, pageSize?: number, headers?: AxiosHeaders): Promise<AxiosResponse<PostsList>> {
        const request = {
            url: this.baseUrl + '/posts/latest',
            method: 'GET',
            responseType: 'json',
            params: {
                since: since?.toISOString(),
                childId: childId,
                pageSize: pageSize,
            },
            paramsSerializer: params => qs.stringify(params)
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<PostsList>(request);
        
        if (response.status === 200) {
            response.data = onDeserializePostsList(response.data);
        }

        return response;
    }

    public async getUntilUntil(until: moment.Moment, pageSize?: number, childId?: string, headers?: AxiosHeaders): Promise<AxiosResponse<PostsList>> {
        const request = {
            url: this.baseUrl + '/posts/until/{until}'
                .replace(`{${'until'}}`, encodeURIComponent(until.toISOString())),
            method: 'GET',
            responseType: 'json',
            params: {
                pageSize: pageSize,
                childId: childId,
            },
            paramsSerializer: params => qs.stringify(params)
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<PostsList>(request);
        
        if (response.status === 200) {
            response.data = onDeserializePostsList(response.data);
        }

        return response;
    }
}