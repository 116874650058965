import React from 'react';

export interface ColumnPickerProps {
    visible: boolean;
    onClose(): void;
    onColumnsChanged?(columns: string[]): void;
}

export class ColumnPicker extends React.PureComponent<ColumnPickerProps> {
}
