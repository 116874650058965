import React from 'react';

export function NotFoundScreen(): React.ReactElement {
    return (
        <div className="loader">
            <div className="header">
                <div className="title">
                    <h1>Oops... Page not found</h1>
                </div>

                <div className="subtitle">
                    <h3>This page does not exist, or the content has been removed.</h3>
                </div>
            </div>
        </div>
    );
}