import { Status, State } from './state';
import { Actions, AuthenticationActions } from './actions';
import { isActionFromContainer } from '@/store/action';

const initialState: State = {
    status: Status.Unauthenticated,    
    tenant: 'ontwikkel',
    developMode: false,
    apiUrl: 'https://api-test.kindplanner.nl',
};

export const reducer = (state = initialState, action: Actions): State => {
    if (!isActionFromContainer(action, AuthenticationActions)) {
        return state;
    }
    
    switch (action.action) {
        case 'tenant': return { ...state, tenant: action.payload.tenant };
        case 'login':
            return { ...state, status: Status.Authenticating };
        case 'loginSuccess':
            return {
                ...state,
                status: Status.Authenticated,
                expires: action.payload.expires,
                accessToken: action.payload.accessToken,
                refreshToken: action.payload.refreshToken,
                error: undefined
            };
        case 'loginFailed':
            return { ...state, status: Status.AuthenticationFailed, error: action.payload.error };
        case 'retrieveAccount':
            return { ...state };
        case 'retrieveAccountSuccess':
            return { ...state, user: action.payload, error: undefined };
        case 'retrieveAccountFailed':
            return { ...state, status: Status.AuthenticationFailed, error: action.payload.error };
        case 'renewToken':
            return {
                ...state,
                expires: action.payload.expires,
                accessToken: action.payload.accessToken,
                refreshToken: action.payload.refreshToken
            };

        case 'developMode': return { ...state, developMode: true };

        case 'configureForCustom':
        case 'configureForDevelopment':
            return { ...state, apiUrl: action.payload.apiUrl, backendUrl: action.payload.backendUrl };
        case 'configureForTesting':
        case 'configureForProduction':
            return { ...state, apiUrl: action.payload.apiUrl };
        default:
            return state;
    }
};