import React from 'react';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { TimeSlotStatus } from './time-slot-status';

import { ApplicationPeriodGroupLineStatus } from '@/store/planboard/state';
import { Translate } from 'react-localize-redux';


export function ExtraEmployeeStatus({ status }: { status: ApplicationPeriodGroupLineStatus }): React.ReactElement {

    const active = status.extraEmployees.length > 0;

    return (
        <TimeSlotStatus icon={faUserPlus} slotIndex={3} active={active}>
            {active ? (
                <div>
                    <Translate id='planboard.messages.extra-employee-from' data={{ date: status.extraEmployees[0].reason.start.format('L') }} />
                </div>
            ) : undefined}
        </TimeSlotStatus>
    );
}
