import React, { useContext } from 'react';
import styled from 'styled-components';

import { GroupTimeSlot } from './group-time-slot';
import { GroupOverviewOptions } from './group-overview-options';
import { GroupSpecification } from './group-specification';
import { ApplicationGroupPeriod } from '@/store/planboard/state';
import { GroupAllTimeSlot } from './group-all-time-slot';

const GroupPeriodContext = React.createContext<ApplicationGroupPeriod>(undefined);

interface Props {
    groupPeriod: ApplicationGroupPeriod;
}

export const useGroupPeriod = (): ApplicationGroupPeriod => {
    return useContext(GroupPeriodContext);
};

export function GroupCard({ groupPeriod }: Props): React.ReactElement {

    const lines = groupPeriod.times.map((x, i) => (
        <GroupTimeSlot
            key={`card-${groupPeriod.start.year()}-${x.line.timeslotId}`}
            isLast={groupPeriod.times.length === i + 1}
            groupPeriodLine={x} />
    ));

    return (
        <GroupCardContainer>
            <GroupPeriodContext.Provider value={groupPeriod}>
                <Container>
                    <GroupAllTimeSlot />
                    {lines}
                </Container>
                <GroupCardInfoContainer>
                    <GroupSpecification group={groupPeriod.group} />
                </GroupCardInfoContainer>
                <Container>
                    <GroupOverviewOptions />
                </Container>
            </GroupPeriodContext.Provider>
        </GroupCardContainer>
    );
}

const GroupCardContainer = styled.div`
    display: flex;
    border: 1px solid lightgray;
    border-radius: 10px;
    
    width: 550px;
    min-height: 50px;
`;

const GroupCardInfoContainer = styled.div`
    flex: 1;
`;

const Container = styled.div`
    flex: 0;
`;