import { OrganizationModel} from './organization-model';
import { LanguageModel} from './language-model';
import { ConfigurationSettings} from './configuration-settings';
import { FeatureModel, onDeserializeFeatureModel, onSerializeFeatureModel} from './feature-model';

export interface ConfigurationModel {
    organization: OrganizationModel;
    languages: LanguageModel[];
    settings: ConfigurationSettings;
    features: FeatureModel[];
}

export function onDeserializeConfigurationModel(data: ConfigurationModel): ConfigurationModel {
    if (data == null) {
        return data;
    }

    if (data.features != null) {
        for (let i = 0; i < data.features.length; i++) {
            if (data.features[i] != null) {
                data.features[i] = onDeserializeFeatureModel(data.features[i]);
            }
        }
    }

    return data;
}

export function onSerializeConfigurationModel(data: ConfigurationModel): ConfigurationModel {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    if (data.features != null) {
        for (let i = 0; i < data.features.length; i++) {
            serialized.features[i] = onSerializeFeatureModel(data.features[i]);
        }
    }      

    return serialized;
}
