import React from 'react';
import styled from 'styled-components';
import { FormRowProps } from './form-row';


type FormProps = {
    children: React.ReactElement<FormRowProps>[];
    className?: string;
};

export const Form: React.FC<FormProps> = (props) => {
    const formRows = new Array<React.ReactElement>();
    React.Children.forEach(props.children as React.ReactElement, (row, index) => {
        formRows.push(React.cloneElement(row, { row: index + 1, key: `row-${index}` }));
    });

    return (
        <FormContainer className={props.className}>
            {formRows}
        </FormContainer>
    );
};

const FormContainer = styled.div`
    display: grid;
    grid-template-rows: max-content;
    grid-template-columns: max-content auto;

    > div {
        padding: 10px;
    }

    .value {
        color: rgba(0, 0, 0, 0.85);
    }
`;