import React from 'react';
import Terminal from 'react-console-emulator';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { terminalVisibleSelector } from '@/store/ui/selectors';
import { AuthenticationActions } from '@/store/authentication/actions';
import { tenantSelector, authenticationStatusSelector, developModeSelector, apiUrlSelector, backendUrSelector, userSelector } from '@/store/authentication/selectors';
import { UIActions } from '@/store/ui/actions';
import { exportPlanning } from '@/planboard/planning-helper';
import { groupsSelector, timeSlotsSelector, locationsSelector } from '@/store/general/selectors';
import { applicationDetailsSelector } from '@/store/planboard/selectors';

export function DeveloperConsole(): React.ReactElement {
    const dispatch = useDispatch();
    const store = useStore();
    
    const isTerminalVisible = useSelector(terminalVisibleSelector);

    if (!isTerminalVisible) {
        return <></>;
    }

    const commands = {
        config: {
            description: 'Configure for mode',
            usage: 'config (development | developmentWithLocal | testing | production | custom) <apiUrl?> <backendUrl?>',
            fn: function (mode: string, apiUrl?: string, backendUrl?: string) {
                if (mode == null) {
                    const developMode = developModeSelector(store.getState());
                    const apiUrl = apiUrlSelector(store.getState());
                    const backendUrl = backendUrSelector(store.getState());

                    if (developMode) {
                        return `development api=${apiUrl} backend=${backendUrl}`;
                    } else {
                        return `production api=${apiUrl}`;                        
                    }
                }

                switch (mode) {
                    case 'development': dispatch(AuthenticationActions.configureForDevelopment(false)); break;
                    case 'developmentWithLocal': dispatch(AuthenticationActions.configureForDevelopment(true)); break;
                    case 'testing': dispatch(AuthenticationActions.configureForTesting()); break;                 
                    case 'production': dispatch(AuthenticationActions.configureForProduction()); break;         
                    case 'custom': dispatch(AuthenticationActions.configureForCustom(apiUrl, backendUrl)); break;
                }
                
                return 'configured';
            }
        },
        tenant: {
            description: 'tenant',
            usage: 'tenant <tenant>',
            fn: function (tenant?: string) {
                if (tenant) {
                    dispatch(AuthenticationActions.tenant(tenant));
                }

                return `current: ${tenantSelector(store.getState())}`;
            }
        },
        login: {
            description: 'login',
            usage: 'login <username>(@<tenant>)? <password>',
            fn: function (username: string, password: string) {
                if (username != null && password != null) {
                    const atIndex = username.indexOf('@');
                    if (atIndex > -1) {
                        dispatch(AuthenticationActions.tenant(username.slice(atIndex + 1)));
                        username = username.slice(0, atIndex);
                    }
    
                    dispatch(AuthenticationActions.login(username, password));
                }

                const state = store.getState();
                const user = userSelector(state);
                const status = authenticationStatusSelector(state);

                if (status === 'Authenticated') {
                    return `User: ${user?.fullName} Status: ${status}`;
                } else {
                    return `Status: ${authenticationStatusSelector(store.getState())}`;
                }
            }
        },
        signout: {
            description: 'signout',
            usage: 'signout',
            fn: function () {
                dispatch(AuthenticationActions.signOut());
                dispatch(AuthenticationActions.developMode());
                dispatch(UIActions.toggleTerminal());
            }
        },
        version: {
            description: 'version',
            usage: 'version',
            fn: function () {
                return `${env.buildnumber}-${env.commit} (${env.mode})`;
            }
        },
        offline: {
            description: 'take offline',
            usage: 'offline',
            fn: () => {
                dispatch(UIActions.offline());
            }
        },
        online: {
            description: 'bring online',
            usage: 'online',
            fn: () => {
                dispatch(UIActions.online());
            }
        },
        dump: {
            description: 'dump the current planning',
            usage: 'dump planning',
            fn: function (type?: 'planning') {
                switch (type) {
                    case 'planning': return exportPlanning(applicationDetailsSelector(store.getState()), locationsSelector(store.getState()), groupsSelector(store.getState()), timeSlotsSelector(store.getState()));
                }
            }
        }
    };

    return (
        <Terminal
            commands={commands}
            dangerMode={true}
            autoFocus={true}
            welcomeMessage={'Welcome to the terminal!'}
            promptLabel={'$'} />
    );
}