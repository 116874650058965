import { ActionReference, onDeserializeActionReference, onSerializeActionReference} from './action-reference';
import { SenderType } from './sender-type';
import moment from 'moment';

export interface Post {
    postId: string;
    childId?: string;
    title: string;
    numberOfReplies: number;
    updated: ActionReference;
    regarding: string;
    created: ActionReference;
    isInternal: boolean;
    reminderDate?: moment.Moment;
    senderType?: SenderType;
}

export function onDeserializePost(data: Post): Post {
    if (data == null) {
        return data;
    }

    if (data.updated != null) {
        data.updated = onDeserializeActionReference(data.updated);
    }
    if (data.created != null) {
        data.created = onDeserializeActionReference(data.created);
    }
    if (data.reminderDate != null) {
        data.reminderDate = moment(data.reminderDate);
    }

    return data;
}

export function onSerializePost(data: Post): Post {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    serialized.updated = onSerializeActionReference(data.updated);
    serialized.created = onSerializeActionReference(data.created);
    serialized.reminderDate = data.reminderDate?.format('YYYY-MM-DD') as unknown as moment.Moment;

    return serialized;
}
