import { ApplicationSchedulePart, onDeserializeApplicationSchedulePart, onSerializeApplicationSchedulePart} from './application-schedule-part';

export interface UpdateApplicationCommand {
    applicationId: string;
    schedule?: ApplicationSchedulePart[];
}

export function onDeserializeUpdateApplicationCommand(data: UpdateApplicationCommand): UpdateApplicationCommand {
    if (data == null) {
        return data;
    }

    if (data.schedule != null) {
        for (let i = 0; i < data.schedule.length; i++) {
            if (data.schedule[i] != null) {
                data.schedule[i] = onDeserializeApplicationSchedulePart(data.schedule[i]);
            }
        }
    }

    return data;
}

export function onSerializeUpdateApplicationCommand(data: UpdateApplicationCommand): UpdateApplicationCommand {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    if (data.schedule != null) {
        for (let i = 0; i < data.schedule.length; i++) {
            serialized.schedule[i] = onSerializeApplicationSchedulePart(data.schedule[i]);
        }
    }      

    return serialized;
}
