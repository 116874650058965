import React from 'react';
import { ScoringOptions } from '@/store/planboard/state';
import { Translate, TranslateFunction } from 'react-localize-redux';
import { Typography, Slider, Row, Col } from 'antd';
import { SelectionDrawer, DrawerSize } from '@/components/selection-drawer';
import { useTranslate } from '@/language-provider';

interface ScoringOptionsDrawerProps { 
    visible: boolean; 
    options: ScoringOptions; 
    onSave: (options: ScoringOptions) => void; 
    onCancel: () => void;
}

interface OptionSliderProps {
    translationTag: string; 
    value: number; 
    onChange: (value: number) => void;
}

function formatter(value: number, translate: TranslateFunction): string {
    switch (value) {
        case 0: return translate('planboard.scoring.off') as string;
        case 1: return translate('planboard.scoring.low') as string;
        case 2: return translate('planboard.scoring.medium') as string;
        case 3: return translate('planboard.scoring.high') as string;
    }
}

function OptionSlider(props: OptionSliderProps): React.ReactElement {
    const { translate } = useTranslate();

    return (
        <>
            <Row>
                <Col sm={24}>
                    <Typography.Title level={4}><Translate id={props.translationTag} /></Typography.Title>
                </Col>                
            </Row>
            <Row>
                <Col sm={24}>
                    <Slider included={false} marks={{
                        3: formatter(3, translate), 
                        2: formatter(2, translate), 
                        1: formatter(1, translate),
                        0: formatter(0, translate),
                    }} min={0} max={3} defaultValue={2} value={props.value} 
                    tipFormatter={(value) => formatter(value, translate)} 
                    onChange={props.onChange} />
                </Col>                
            </Row>
        </>
    );
}

export function ScoringOptionsDrawer(props: ScoringOptionsDrawerProps): React.ReactElement {

    const [createdDistance, setCreatedDistance] = React.useState(props.options.createdDistance);
    const [startingDistance, setStartingDistance] = React.useState(props.options.startingDistance);
    const [sibling, setSibling] = React.useState(props.options.sibling);

    const newOptions = { createdDistance, startingDistance, sibling };

    return (
        <SelectionDrawer 
            isEditMode={true} 
            size={DrawerSize.small} 
            titleTranslation='planboard.priority'
            visible={props.visible} 
            onCancel={() => props.onCancel()} 
            onSelect={() => props.onSave(newOptions)}>
            <OptionSlider translationTag='planboard.scoring.created-distance' value={createdDistance} onChange={value => setCreatedDistance(value)} />
            <OptionSlider translationTag='planboard.scoring.start-distance' value={startingDistance} onChange={value => setStartingDistance(value)} />
            <OptionSlider translationTag='planboard.scoring.sibling' value={sibling} onChange={value => setSibling(value)} />
        </SelectionDrawer>
    );
}