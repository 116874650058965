
import React, { useState, useRef } from 'react';
import { Translate, TranslateFunction } from 'react-localize-redux';
import styled from 'styled-components';
import { Steps, notification } from 'antd';
import { useApplicationPartProvider, usePartChanged } from '@/planboard/planner-application-part-provider';
import { useTranslate } from '@/language-provider';

const { Step } = Steps;

function showCompleted(translate: TranslateFunction, partName: string): void {
    notification.success({
        message: translate('planboard.messages.completed-part-title', { partName }),
        description: translate('planboard.messages.completed-part-message', { partName }),
        placement: 'topRight',
        duration: 10
    });
}

function showIncompleet(translate: TranslateFunction, partName: string): void {
    notification.warning({
        message: translate('planboard.messages.incompleet-part-title', { partName }),
        description: translate('planboard.messages.incompleet-part-message', { partName }),
        placement: 'topRight',
        duration: 10
    });
}

type Props = {
    showSummary: () => void;
    showPart: (partIndex: number) => void;
};

export const PlannerStepper: React.FC<Props> = (props) => {
    const provider = useApplicationPartProvider();
    const { translate } = useTranslate();

    const [stepIndex, setStepIndex] = useState(0);
    const [allCompleted, setAllCompleted] = useState(provider.allPartsCompleted());
    const [isPartCompleted, setIsPartCompleted] = useState(provider.isCurrentPartCompleted());

    const isPartCompletedRef = useRef<boolean>();
    isPartCompletedRef.current = isPartCompleted;
    
    usePartChanged(() => {
        const currentPart = provider.getCurrentPart();
        const isCompleted = provider.isCurrentPartCompleted();
        setAllCompleted(provider.allPartsCompleted());

        if (isCompleted !== isPartCompletedRef.current) {
            setIsPartCompleted(isCompleted);

            const partName = translate(`global.caretype.${currentPart.careType}`).toString();

            if (isCompleted) {
                showCompleted(translate, partName);
            } else {
                showIncompleet(translate, partName);
            }
        }
    });
    
    const steps = [
        ...provider.scheduleParts.map((x, i) => (
            <Step key={x.careType}
                title={translate(`global.caretype.${x.careType}`)}
                status={i === stepIndex ? undefined : provider.isPartCompleted(x) ? 'finish' : 'wait'} />
        )),
        <Step key='overview' title={<Translate id='planboard.overview' />} disabled={!allCompleted} />
    ];

    function onSelectedStepChanged(selectedIndex: number): void {
        if (selectedIndex < provider.scheduleParts.length) {
            provider.saveCurrentPart();
            provider.selectSchedulePart(provider.scheduleParts[selectedIndex].schedulePartId);
            setIsPartCompleted(provider.isCurrentPartCompleted());

            props.showPart(selectedIndex);
        }

        setStepIndex(selectedIndex);

        switch(selectedIndex - provider.scheduleParts.length) {
            case 0: props.showSummary(); break;
        }
    }
    
    return (
        <StyleStepper current={stepIndex} size='small' onChange={onSelectedStepChanged}>
            {steps}
        </StyleStepper>
    );
};

const StyleStepper = styled(Steps)`
    margin: 20px 5px;
`;