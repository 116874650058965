import { PlanningWeek, onDeserializePlanningWeek, onSerializePlanningWeek} from './planning-week';

export interface MonthPlanningModel {
    weeks: PlanningWeek[];
    year: number;
    month: number;
    daysInWeek: number;
}

export function onDeserializeMonthPlanningModel(data: MonthPlanningModel): MonthPlanningModel {
    if (data == null) {
        return data;
    }

    if (data.weeks != null) {
        for (let i = 0; i < data.weeks.length; i++) {
            if (data.weeks[i] != null) {
                data.weeks[i] = onDeserializePlanningWeek(data.weeks[i]);
            }
        }
    }

    return data;
}

export function onSerializeMonthPlanningModel(data: MonthPlanningModel): MonthPlanningModel {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    if (data.weeks != null) {
        for (let i = 0; i < data.weeks.length; i++) {
            serialized.weeks[i] = onSerializePlanningWeek(data.weeks[i]);
        }
    }      

    return serialized;
}
