import { createStore, applyMiddleware } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createRootReducer } from '@/store/reducers';
import { persistStore } from 'redux-persist';
import { createEpicMiddleware } from 'redux-observable';
import { epics } from '@/store/epics';
import { createDependencies } from '@/store/dependencies';
import {authorizationInterceptor} from '@/networking/authorization-interceptor';
//import { composeWithDevTools } from 'remote-redux-devtools';

const epicMiddleware = createEpicMiddleware({
    dependencies: createDependencies()
});

const composeEnhancers = composeWithDevTools({ trace: true });
//const composeEnhancers = composeWithDevTools({ trace: true, port: 8000 });

// create the composing function for our middlewares
export const history = createBrowserHistory();

// configure middlewares
const middlewares = [
    epicMiddleware,
    routerMiddleware(history),
    authorizationInterceptor
];

// We'll create our store with the combined reducers/sagas, and the initial Redux state that
// we'll be passing from our entry point.
export const store = createStore(
    createRootReducer(history),
    undefined,
    composeEnhancers(
        applyMiddleware(...middlewares)
    )
);

export const persistor = persistStore(store);

// Don't forget to run the root saga, and return the store object.
epicMiddleware.run(epics);