/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { Application, ApplicationDetails, LocationUsagesResponse, ApplicationScheduleLine } from '@/client/models';
import { ApplicationSchedulePart, ScoringOptions } from './state';
import moment from 'moment';
import { GenericError } from '@/store/generic-error';
import { ActionsUnion } from '@/store/types';
import { action } from '@/store/decorators';
import { Action } from '@/store/action';
import { PagingInfo } from '@/store/retrieved-value';

export class PlanboardActions {
    public static __className = 'PlanboardActions';

    @action
    static initializeScheduleParts(scheduleParts: ApplicationSchedulePart[]) { return Action('initializeScheduleParts', { scheduleParts }); }

    @action
    static refreshOpenApplications() { return Action('refreshOpenApplications'); }
    @action
    static loadOpenApplications(paging?: PagingInfo) { return Action('loadOpenApplications', { paging }); }
    @action
    static loadOpenApplicationsSuccess(applications: Application[], paging: PagingInfo) { return Action('loadOpenApplicationsSuccess', { applications, paging }); }
    @action
    static loadOpenApplicationsFailed(error: GenericError<typeof PlanboardActions.loadOpenApplications>) { return Action('loadOpenApplicationsFailed', { error }); }
    
    @action
    static refreshReservedApplications() { return Action('refreshReservedApplications'); }
    @action
    static loadReservedApplications(paging?: PagingInfo) { return Action('loadReservedApplications', { paging }); }
    @action
    static loadReservedApplicationsSuccess(applications: Application[], paging: PagingInfo) { return Action('loadReservedApplicationsSuccess', { applications, paging }); }
    @action
    static loadReservedApplicationsFailed(error: GenericError<typeof PlanboardActions.loadReservedApplications>) { return Action('loadReservedApplicationsFailed', { error }); }

    @action
    static loadApplicationDetails(applicationId: string) { return Action('loadApplicationDetails', { applicationId }); }
    @action
    static loadApplicationDetailsSuccess(applicationDetails: ApplicationDetails) { return Action('loadApplicationDetailsSuccess', { applicationDetails }); }
    @action
    static loadApplicationDetailsFailed(error: GenericError<typeof PlanboardActions.loadApplicationDetails>) { return Action('loadApplicationDetailsFailed', { error }); }

    @action
    static storeScoringOptions(options: ScoringOptions) { return Action('storeScoringOptions', { options }); }

    @action
    static selectApplications(applications: Application[]) { return Action('selectApplications', { applications }); }

    @action
    static loadUsages(locationId: string, start: moment.Moment, end?: moment.Moment) { return Action('loadUsages', { locationId, start, end }); }
    @action
    static loadUsagesSuccess(response: LocationUsagesResponse) { return Action('loadUsagesSuccess', { response }); }
    @action
    static loadUsagesFailed(error: GenericError<typeof PlanboardActions.loadUsages>) { return Action('loadUsagesFailed', { error }); }

    @action
    static saveSchedulePart(schedulePart: ApplicationSchedulePart, lines: ApplicationScheduleLine[]) { return Action('saveSchedulePart', { schedulePart, lines }); }
    @action
    static saveSchedulePartSuccess() { return Action('saveSchedulePartSuccess'); }
    @action
    static saveSchedulePartFailed(error: GenericError<typeof PlanboardActions.saveSchedulePart>) { return Action('saveSchedulePartFailed', { error }); }
    
    @action
    static offerContract(applicationId: string) { return Action('offerContract', { applicationId }); }
    @action
    static offerContractSuccess() { return Action('offerContractSuccess'); }
    @action
    static offerContractFailed(error: GenericError<typeof PlanboardActions.offerContract>) { return Action('offerContractFailed', { error }); }
    
    @action
    static reserveApplication(applicationId: string) { return Action('reserveApplication', { applicationId }); }
    @action
    static reserveApplicationSuccess() { return Action('reserveApplicationSuccess'); }
    @action
    static reserveApplicationFailed(error: GenericError<typeof PlanboardActions.reserveApplication>) { return Action('reserveApplicationFailed', { error }); }
    
    @action
    static inProgressApplication(applicationId: string) { return Action('inProgressApplication', { applicationId }); }
    @action
    static inProgressApplicationSuccess() { return Action('inProgressApplicationSuccess'); }
    @action
    static inProgressApplicationFailed(error: GenericError<typeof PlanboardActions.inProgressApplication>) { return Action('inProgressApplicationFailed', { error }); }
}

export type Actions = ActionsUnion<typeof PlanboardActions>;