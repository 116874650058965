import React, { useEffect } from 'react';
import { DataGrid, DataGridColumn, DataGridColumnOption } from '@/data-grid';
import { SelectionDrawer, PlacementType, DrawerSize } from '@/components/selection-drawer';
import { Application } from '@/client/models';

interface ApplicationSelectionDrawerProps { 
    titleTranslation?: string;
    visible: boolean;
    selection: Application[];
    applications: Application[];
    placement?: keyof typeof PlacementType;
    onSelect: (selection: Application[]) => void; 
    onCancel: () => void;
}

export function ApplicationSelectionDrawer(props: ApplicationSelectionDrawerProps): React.ReactElement {

    const [ selection, setSelection ] = React.useState(props.selection);
    
    useEffect(() => {
        // Reset the state when hiding
        if (props.visible) {
            setSelection(props.selection);
        }
    }, [props.selection, props.visible]);

    return (
        <SelectionDrawer 
            titleTranslation={props.titleTranslation} 
            size={DrawerSize.large}
            visible={props.visible} 
            onCancel={props.onCancel} 
            onSelect={() => props.onSelect(selection)} 
            selectDisabled={selection.length === 0} 
            placement={props.placement}>
            <DataGrid keyPath='applicationId' datasource={props.applications} selection={selection} onSelectionChanged={setSelection}>
                <DataGridColumn type='Text' translation='planboard.fields.name' path='child.fullName' />
                <DataGridColumn type='Date' translation='planboard.fields.startsOn' path='startsOn' defaultSortOrder='ascend' />
                <DataGridColumn type='Date' translation='planboard.fields.requestedOn' path='requested.on' />
                <DataGridColumn type='Text' translation='planboard.fields.caretypes' path='careTypes' translateTemplate='global.caretype.{value}' 
                    colors={{
                        daycare: 'green',
                        betweenSchoolCare: 'orange',
                        outOfSchoolCare: 'red',
                        holidayCare: 'blue',
                        playschool: 'yellow'
                    }}>
                    <DataGridColumnOption translation='global.caretype.betweenSchoolCare' value='betweenSchoolCare' />
                    <DataGridColumnOption translation='global.caretype.daycare' value='daycare' />
                    <DataGridColumnOption translation='global.caretype.outOfSchoolCare' value='outOfSchoolCare' />
                    <DataGridColumnOption translation='global.caretype.holidayCare' value='holidayCare' />
                    <DataGridColumnOption translation='global.caretype.playschool' value='playschool' />
                </DataGridColumn>
                <DataGridColumn type='Bool' translation='planboard.fields.hasSibling' path='hasSibling' />
            </DataGrid>
        </SelectionDrawer>
    );
}