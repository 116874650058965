import React, { useState, PropsWithChildren } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MainMenu } from '@/components/main-menu';

import { isInFullScreenSelector, terminalVisibleSelector } from '@/store/ui/selectors';
import { authenticationStatusSelector, developModeSelector } from '@/store/authentication/selectors';
import { Status } from './store/authentication/state';

import { useCache } from './caching/caching-provider';
import { locationsRetrievedSelector, groupsRetrievedSelector, carePackagesRetrievedSelector, timeSlotsRetrievedSelector } from './store/general/selectors';
import { GeneralActions } from './store/general/actions';

import { initializeFontAwesome } from './font-awesome';
import { DeveloperConsole } from './components/developer-console';
import styled from 'styled-components';
import { NewVersion } from './components/new-version';
import moment from 'moment';
import { Offline } from './components/offline';
import { AppHeader } from './app-header';


export function AppComponent(props: PropsWithChildren<{}>): React.ReactElement {
    const cache = useCache();
    const dispatch = useDispatch();
    const status = useSelector(authenticationStatusSelector);
    const isInFullScreen = useSelector(isInFullScreenSelector) || status !== Status.Authenticated;
    const developMode = useSelector(developModeSelector);
    const isTerminalVisible = useSelector(terminalVisibleSelector);

    useState(() => {
        initializeFontAwesome();
        cache.addCache(locationsRetrievedSelector, GeneralActions.loadLocations(), moment.duration(1, 'hour'));
        cache.addCache(groupsRetrievedSelector, GeneralActions.loadGroups(), moment.duration(1, 'hour'));
        cache.addCache(carePackagesRetrievedSelector, GeneralActions.loadCarePackages(), moment.duration(1, 'hour'));
        cache.addCache(timeSlotsRetrievedSelector, GeneralActions.loadTimeSlots(), moment.duration(1, 'hour'));

        dispatch(GeneralActions.applicationStart());
    });

    return (
        <PageRoot>
            {developMode && isTerminalVisible &&
                <DeveloperConsoleContainer>
                    <DeveloperConsole />
                </DeveloperConsoleContainer>
            }

            <AppHeader hidden={isInFullScreen} />
            <PageBody>
                <PageMenu>
                    <MainMenu hidden={isInFullScreen} />
                </PageMenu>
                <PageContent>
                    {props.children}
                </PageContent>
            </PageBody>
            
            <NewVersion />
            <Offline />
        </PageRoot>
    );
}

const DeveloperConsoleContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 250px;
    z-index: 999;
`;

const PageRoot = styled.div`
    height: 100vh;
    background-color: ${props => props.theme.background};
`;

const PageBody = styled.div`
    display: flex;
`;

const PageContent = styled.div`
    flex: 1;
    height: 100%;
`;

const PageMenu = styled.div`
    flex: 0;
`;