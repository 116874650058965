import { GroupReference} from './group-reference';
import moment from 'moment';

export interface TimeslotRegistration {
    timeslotId?: string;
    planningId?: string;
    name?: string;
    typeId?: string;
    start?: moment.Duration;
    end?: moment.Duration;
    group?: GroupReference;
    regularPlanningInHoliday?: boolean;
    creditHours?: string;
}

export function onDeserializeTimeslotRegistration(data: TimeslotRegistration): TimeslotRegistration {
    if (data == null) {
        return data;
    }

    if (data.start != null) {
        data.start = moment.duration(data.start);
    }
    if (data.end != null) {
        data.end = moment.duration(data.end);
    }

    return data;
}

export function onSerializeTimeslotRegistration(data: TimeslotRegistration): TimeslotRegistration {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };


    return serialized;
}
