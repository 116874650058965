import React from 'react';
import ReactDOM from 'react-dom';
import {ConnectedRouter} from 'connected-react-router';
import {Provider} from 'react-redux';
import {history, store, persistor} from '@/store/configure-store';
import {PersistGate} from 'redux-persist/integration/react';

import {LocalizeProvider} from 'react-localize-redux';
import {LanguageProvider} from '@/language-provider';
import {GridLayoutPersistanceProvider} from '@/data-grid';
import {AppRouter} from '@/app-router';
import {AppComponent} from '@/app-component';
import {CachingProvider} from '@/caching/caching-provider';
import {ThemeProvider} from 'styled-components';
import {LightTheme} from '@/themes/default-theme';
import {InitializeSecretsListener, Secret} from '@/secrets-listener';
import {UIActions} from '@/store/ui/actions';
import {GeneralActions} from '@/store/general/actions';
import {EnvironmentMode} from '@/environment-mode';
import {NetworkUtils} from '@/networking/network-utils';
import {AuthenticationActions} from './store/authentication/actions';

console.log(`🚀 Version ${env.buildnumber} build from ${env.commit} and currently running in ${env.mode} mode`);

if (env.mode === EnvironmentMode.development) {
    store.dispatch(AuthenticationActions.developMode());
}

const tenant = NetworkUtils.getTenantFromUrl(window.location.hostname);
if (tenant != null) {
    store.dispatch(AuthenticationActions.tenant(tenant));
}

InitializeSecretsListener([
    new Secret('clear', () => {
        persistor.purge();
        store.dispatch(GeneralActions.resetApp());
        persistor.flush();
        persistor.persist();
    }),
    new Secret('`', () => {
        store.dispatch(UIActions.toggleTerminal());
    }),
    new Secret('develop', () => {
        store.dispatch(AuthenticationActions.developMode());
    })
]);

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <PersistGate loading={null} persistor={persistor}>
                <GridLayoutPersistanceProvider>
                    <LocalizeProvider>
                        <LanguageProvider>
                            <CachingProvider>
                                <ThemeProvider theme={LightTheme}>
                                    <AppComponent>
                                        <AppRouter/>
                                    </AppComponent>
                                </ThemeProvider>
                            </CachingProvider>
                        </LanguageProvider>
                    </LocalizeProvider>
                </GridLayoutPersistanceProvider>
            </PersistGate>
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
);