import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Popover } from 'antd';

interface Props {
    icon: IconDefinition;
    slotIndex: number;
    active: boolean;
}

export const TimeSlotStatus: React.FC<Props> = ({icon, slotIndex, children, active}) => {

    const styleName = active ? 'active' : 'inactive';

    return (
        <GroupCardTimeSlotStatusContainer style={{gridColumn: slotIndex}}>
            <Popover content={children} trigger="hover">
                <FontAwesomeIcon className={styleName} icon={icon} />
            </Popover>
        </GroupCardTimeSlotStatusContainer>
    );
};

const GroupCardTimeSlotStatusContainer = styled.span`
    display: flex;
    flex-direction: row;
    align-items: center;

    .active {
        color: red;
    }

    .inactive {
        color: lightgray;
        opacity: 0.3;
    }
`;