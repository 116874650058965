import { Validation} from './validation';
import { Corruption} from './corruption';
import { Skipped} from './skipped';
import { TimeLineItem, onDeserializeTimeLineItem, onSerializeTimeLineItem} from './time-line-item';

export interface TimeLineResponse {
    validations?: Validation[];
    corruptions?: Corruption[];
    skipped?: Skipped[];
    count: number;
    timeLine?: TimeLineItem[];
}

export function onDeserializeTimeLineResponse(data: TimeLineResponse): TimeLineResponse {
    if (data == null) {
        return data;
    }

    if (data.timeLine != null) {
        for (let i = 0; i < data.timeLine.length; i++) {
            if (data.timeLine[i] != null) {
                data.timeLine[i] = onDeserializeTimeLineItem(data.timeLine[i]);
            }
        }
    }

    return data;
}

export function onSerializeTimeLineResponse(data: TimeLineResponse): TimeLineResponse {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    if (data.timeLine != null) {
        for (let i = 0; i < data.timeLine.length; i++) {
            serialized.timeLine[i] = onSerializeTimeLineItem(data.timeLine[i]);
        }
    }      

    return serialized;
}
