import { PlanningBlock, onDeserializePlanningBlock, onSerializePlanningBlock} from './planning-block';
import { EmployeeModel, onDeserializeEmployeeModel, onSerializeEmployeeModel} from './employee-model';
import { PlanningDayType } from './planning-day-type';
import moment from 'moment';

export interface PlanningDay {
    date: moment.Moment;
    type: PlanningDayType;
    description?: string;
    blocks: PlanningBlock[];
    employees: EmployeeModel[];
}

export function onDeserializePlanningDay(data: PlanningDay): PlanningDay {
    if (data == null) {
        return data;
    }

    if (data.date != null) {
        data.date = moment(data.date);
    }
    if (data.blocks != null) {
        for (let i = 0; i < data.blocks.length; i++) {
            if (data.blocks[i] != null) {
                data.blocks[i] = onDeserializePlanningBlock(data.blocks[i]);
            }
        }
    }
    if (data.employees != null) {
        for (let i = 0; i < data.employees.length; i++) {
            if (data.employees[i] != null) {
                data.employees[i] = onDeserializeEmployeeModel(data.employees[i]);
            }
        }
    }

    return data;
}

export function onSerializePlanningDay(data: PlanningDay): PlanningDay {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    serialized.date = data.date?.format('YYYY-MM-DD') as unknown as moment.Moment;
    if (data.blocks != null) {
        for (let i = 0; i < data.blocks.length; i++) {
            serialized.blocks[i] = onSerializePlanningBlock(data.blocks[i]);
        }
    }      
    if (data.employees != null) {
        for (let i = 0; i < data.employees.length; i++) {
            serialized.employees[i] = onSerializeEmployeeModel(data.employees[i]);
        }
    }      

    return serialized;
}
