import { CareType } from './care-type';
import moment from 'moment';

export interface Group {
    locationId: string;
    fromMonths: number;
    toMonths: number;
    capacity: number;
    careType: CareType;
    start?: moment.Moment;
    end?: moment.Moment;
    groupId: string;
    name?: string;
}

export function onDeserializeGroup(data: Group): Group {
    if (data == null) {
        return data;
    }

    if (data.start != null) {
        data.start = moment(data.start);
    }
    if (data.end != null) {
        data.end = moment(data.end);
    }

    return data;
}

export function onSerializeGroup(data: Group): Group {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    serialized.start = data.start?.format('YYYY-MM-DD') as unknown as moment.Moment;
    serialized.end = data.end?.format('YYYY-MM-DD') as unknown as moment.Moment;

    return serialized;
}
