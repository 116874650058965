import { CareTimeDetails, onDeserializeCareTimeDetails, onSerializeCareTimeDetails} from './care-time-details';

export interface CareTimesResponse {
    careTimes?: CareTimeDetails[];
}

export function onDeserializeCareTimesResponse(data: CareTimesResponse): CareTimesResponse {
    if (data == null) {
        return data;
    }

    if (data.careTimes != null) {
        for (let i = 0; i < data.careTimes.length; i++) {
            if (data.careTimes[i] != null) {
                data.careTimes[i] = onDeserializeCareTimeDetails(data.careTimes[i]);
            }
        }
    }

    return data;
}

export function onSerializeCareTimesResponse(data: CareTimesResponse): CareTimesResponse {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    if (data.careTimes != null) {
        for (let i = 0; i < data.careTimes.length; i++) {
            serialized.careTimes[i] = onSerializeCareTimeDetails(data.careTimes[i]);
        }
    }      

    return serialized;
}
