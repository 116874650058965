import { RequestStatusType } from './request-status-type';
import moment from 'moment';

export interface RequestStatus {
    type: RequestStatusType;
    on: moment.Moment;
}

export function onDeserializeRequestStatus(data: RequestStatus): RequestStatus {
    if (data == null) {
        return data;
    }

    if (data.on != null) {
        data.on = moment(data.on);
    }

    return data;
}

export function onSerializeRequestStatus(data: RequestStatus): RequestStatus {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    serialized.on = data.on?.toISOString() as unknown as moment.Moment;

    return serialized;
}
