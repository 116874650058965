import { ActionReference, onDeserializeActionReference, onSerializeActionReference} from './action-reference';
import { ContractApproval} from './contract-approval';
import { ContractPlanning, onDeserializeContractPlanning, onSerializeContractPlanning} from './contract-planning';
import { Package, onDeserializePackage, onSerializePackage} from './package';
import { RequestStatus, onDeserializeRequestStatus, onSerializeRequestStatus} from './request-status';
import { CareType } from './care-type';
import moment from 'moment';

export interface ContractModel {
    created: ActionReference;
    earliestEnd?: moment.Moment;
    approval?: ContractApproval;
    plannings: ContractPlanning[];
    packages: Package[];
    downloadUrl: string;
    termsAndConditionsUrl: string;
    contractId: string;
    childId: string;
    number: string;
    start: moment.Moment;
    end: moment.Moment;
    status: RequestStatus;
    careType: CareType;
}

export function onDeserializeContractModel(data: ContractModel): ContractModel {
    if (data == null) {
        return data;
    }

    if (data.created != null) {
        data.created = onDeserializeActionReference(data.created);
    }
    if (data.earliestEnd != null) {
        data.earliestEnd = moment(data.earliestEnd);
    }
    if (data.plannings != null) {
        for (let i = 0; i < data.plannings.length; i++) {
            if (data.plannings[i] != null) {
                data.plannings[i] = onDeserializeContractPlanning(data.plannings[i]);
            }
        }
    }
    if (data.packages != null) {
        for (let i = 0; i < data.packages.length; i++) {
            if (data.packages[i] != null) {
                data.packages[i] = onDeserializePackage(data.packages[i]);
            }
        }
    }
    if (data.start != null) {
        data.start = moment(data.start);
    }
    if (data.end != null) {
        data.end = moment(data.end);
    }
    if (data.status != null) {
        data.status = onDeserializeRequestStatus(data.status);
    }

    return data;
}

export function onSerializeContractModel(data: ContractModel): ContractModel {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    serialized.created = onSerializeActionReference(data.created);
    serialized.earliestEnd = data.earliestEnd?.format('YYYY-MM-DD') as unknown as moment.Moment;
    if (data.plannings != null) {
        for (let i = 0; i < data.plannings.length; i++) {
            serialized.plannings[i] = onSerializeContractPlanning(data.plannings[i]);
        }
    }      
    if (data.packages != null) {
        for (let i = 0; i < data.packages.length; i++) {
            serialized.packages[i] = onSerializePackage(data.packages[i]);
        }
    }      
    serialized.start = data.start?.format('YYYY-MM-DD') as unknown as moment.Moment;
    serialized.end = data.end?.format('YYYY-MM-DD') as unknown as moment.Moment;
    serialized.status = onSerializeRequestStatus(data.status);

    return serialized;
}
