import moment from 'moment';

export interface CreatePostCommand {
    title: string;
    content: string;
    audienceIds: string[];
    childId?: string;
    reminderDate?: moment.Moment;
}

export function onDeserializeCreatePostCommand(data: CreatePostCommand): CreatePostCommand {
    if (data == null) {
        return data;
    }

    if (data.reminderDate != null) {
        data.reminderDate = moment(data.reminderDate);
    }

    return data;
}

export function onSerializeCreatePostCommand(data: CreatePostCommand): CreatePostCommand {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    serialized.reminderDate = data.reminderDate?.toISOString() as unknown as moment.Moment;

    return serialized;
}
