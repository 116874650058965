import React from 'react';
import { Translate } from 'react-localize-redux';
import { Typography } from 'antd';
import { TextAlignProperty } from 'csstype';

export interface TranslatedTitleProps {
    translation?: string;
    text?: string;
    level?: 1 | 2 | 3 | 4;
    style?: React.CSSProperties;
    align?: TextAlignProperty;
}

export function Title(props: TranslatedTitleProps): React.ReactElement {

    const { 
        align = 'left',
        level = 4,
        style = { textAlign: align, padding: '10px'} as React.CSSProperties 
    } = props;

    return (
        <Typography.Title level={level} style={style} >
            {props.translation && <Translate id={props.translation} />}
            {props.text}
        </Typography.Title>
    );
}