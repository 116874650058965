import { PostComment, onDeserializePostComment, onSerializePostComment} from './post-comment';
import { ActionReference, onDeserializeActionReference, onSerializeActionReference} from './action-reference';
import { SenderType } from './sender-type';
import moment from 'moment';

export interface PostDetails {
    content: string;
    comments: PostComment[];
    postId: string;
    childId?: string;
    title: string;
    numberOfReplies: number;
    updated: ActionReference;
    regarding: string;
    created: ActionReference;
    isInternal: boolean;
    reminderDate?: moment.Moment;
    senderType?: SenderType;
}

export function onDeserializePostDetails(data: PostDetails): PostDetails {
    if (data == null) {
        return data;
    }

    if (data.comments != null) {
        for (let i = 0; i < data.comments.length; i++) {
            if (data.comments[i] != null) {
                data.comments[i] = onDeserializePostComment(data.comments[i]);
            }
        }
    }
    if (data.updated != null) {
        data.updated = onDeserializeActionReference(data.updated);
    }
    if (data.created != null) {
        data.created = onDeserializeActionReference(data.created);
    }
    if (data.reminderDate != null) {
        data.reminderDate = moment(data.reminderDate);
    }

    return data;
}

export function onSerializePostDetails(data: PostDetails): PostDetails {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    if (data.comments != null) {
        for (let i = 0; i < data.comments.length; i++) {
            serialized.comments[i] = onSerializePostComment(data.comments[i]);
        }
    }      
    serialized.updated = onSerializeActionReference(data.updated);
    serialized.created = onSerializeActionReference(data.created);
    serialized.reminderDate = data.reminderDate?.format('YYYY-MM-DD') as unknown as moment.Moment;

    return serialized;
}
