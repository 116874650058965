import { ApplicationState } from '@/store/application-state';
import { createSelector } from 'reselect';

export const openApplicationsSelector = createSelector((state: ApplicationState) => state.planboard.openApplications, x => x?.value);
export const openApplicationsRetrievedSelector = createSelector((state: ApplicationState) => state.planboard.openApplications, x => x);
export const openApplicationsStatusSelector = createSelector((state: ApplicationState) => state.planboard.openApplications, x => x?.status);

export const reservedApplicationsSelector = createSelector((state: ApplicationState) => state.planboard.reservedApplications, x => x?.value);
export const reservedApplicationsRetrievedSelector = createSelector((state: ApplicationState) => state.planboard.reservedApplications, x => x);
export const reservedApplicationsStatusSelector = createSelector((state: ApplicationState) => state.planboard.reservedApplications, x => x?.status);

export const applicationDetailsSelector = createSelector((state: ApplicationState) => state.planboard.applicationDetails, x => x?.value);
export const applicationDetailsRetrievedSelector = createSelector((state: ApplicationState) => state.planboard.applicationDetails, x => x);
export const applicationDetailsStatusSelector = createSelector((state: ApplicationState) => state.planboard.applicationDetails, x => x?.status);

export const scoringOptionsSelector = createSelector((state: ApplicationState) => state.planboard.scoringOptions, x => x);

export const locationUsagesSelector = createSelector((state: ApplicationState) => state.planboard.locationUsages, x => x.map(y => y.value));
export const locationUsagesRetrievedSelector = createSelector((state: ApplicationState) => state.planboard.locationUsages, x => x);
export const locationUsagesStatusSelector = createSelector((state: ApplicationState) => state.planboard.locationUsages, x => x.map(y => y.status));

export const plannerStatusSelector = createSelector((state: ApplicationState) => state.planboard.plannerStatus, x => x?.status);
export const plannerErrorSelector = createSelector((state: ApplicationState) => state.planboard.plannerStatus, x => x?.error);

export const offerContractStatusSelector = createSelector((state: ApplicationState) => state.planboard.offerContract, x => x?.status);
export const offerContractErrorSelector = createSelector((state: ApplicationState) => state.planboard.offerContract, x => x?.error);

export const reserveApplicationStatusSelector = createSelector((state: ApplicationState) => state.planboard.reserveApplication, x => x?.status);
export const reserveApplicationErrorSelector = createSelector((state: ApplicationState) => state.planboard.reserveApplication, x => x?.error);

export const inProgressStatusSelector = createSelector((state: ApplicationState) => state.planboard.inProgressApplication, x => x?.status);
export const inProgressErrorSelector = createSelector((state: ApplicationState) => state.planboard.inProgressApplication, x => x?.error);

export const selectedApplicationsSelector = createSelector(
    (state: ApplicationState) => state.planboard.selectedApplications, openApplicationsSelector,
    (selection, applications) => (selection || []).map(x => applications.find(r => r.applicationId === x))
);
