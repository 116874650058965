import React from 'react';
import { SelectionDrawer, PlacementType, DrawerSize } from '@/components/selection-drawer';
import { Tabs } from 'antd';
import { Translate } from 'react-localize-redux';
import { LocationCard } from './location-card';
import { Location } from '@/client/models';

const { TabPane } = Tabs;


interface LocationSelectionDrawerProps<T extends Location> { 
    visible: boolean;
    selected: T;
    preferredLocations: T[];
    otherLocations: T[];
    onSelect: (selected: T) => void; 
    onCancel: () => void;
}

export function CurrentLocationSelectionDrawer<T extends Location>(props: LocationSelectionDrawerProps<T>): React.ReactElement {    
    return (
        <SelectionDrawer 
            titleTranslation='global.generic.locations'
            visible={props.visible} 
            onCancel={props.onCancel} 
            placement={PlacementType.right}
            size={DrawerSize.small}>

            <Tabs defaultActiveKey="preferred">
                <TabPane tab={<Translate id='global.generic.preferred' />} key="preferred">
                    {props.preferredLocations.map((x, i) => 
                        <LocationCard 
                            key={x.locationId} 
                            location={x} 
                            isSelected={props.selected && x.locationId === props.selected.locationId} 
                            onClick={props.onSelect} 
                            preferred={i + 1} />)}
                </TabPane>
                <TabPane tab={<Translate id='global.generic.other' />} key="other">
                    {props.otherLocations.map(x => 
                        <LocationCard 
                            key={x.locationId} 
                            location={x} 
                            isSelected={props.selected && x.locationId === props.selected.locationId} 
                            onClick={props.onSelect} />)}
                </TabPane>
            </Tabs>
        </SelectionDrawer>
    );
}