import { ContractsListItem, onDeserializeContractsListItem, onSerializeContractsListItem} from './contracts-list-item';

export interface ContractsListModel {
    contracts?: ContractsListItem[];
}

export function onDeserializeContractsListModel(data: ContractsListModel): ContractsListModel {
    if (data == null) {
        return data;
    }

    if (data.contracts != null) {
        for (let i = 0; i < data.contracts.length; i++) {
            if (data.contracts[i] != null) {
                data.contracts[i] = onDeserializeContractsListItem(data.contracts[i]);
            }
        }
    }

    return data;
}

export function onSerializeContractsListModel(data: ContractsListModel): ContractsListModel {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    if (data.contracts != null) {
        for (let i = 0; i < data.contracts.length; i++) {
            serialized.contracts[i] = onSerializeContractsListItem(data.contracts[i]);
        }
    }      

    return serialized;
}
