import {AuthenticationActions} from '@/store/authentication/actions';
import {map, mergeMap} from 'rxjs/operators';
import {AnyAction} from 'redux';
import {GenericEpic, actionOf, pipe} from '@/store/epic-helper';
import {Observable} from 'rxjs';
import {tenantSelector} from '@/store/authentication/selectors';
import {GeneralActions} from '@/store/general/actions';
import {UIActions} from '@/store/ui/actions';
import {Routes} from '@/router/routes';

const loginEpic: GenericEpic = (action$, states$, {authApi}): Observable<AnyAction> => 
    pipe(
        action$,
        actionOf(AuthenticationActions.login),
        ({payload: {userName, password}}) => authApi(states$.value).postTenantSignin(tenantSelector(states$.value), {
            userName,
            password
        }),
        response => AuthenticationActions.loginSuccess(response),
        (action, error) => AuthenticationActions.loginFailed({
            action,
            axios: error,
            message: 'Login failed',
            description: 'A problem occurred while trying to login'
        }));

const retrieveAccountEpic: GenericEpic = (action$, states$, {usersApi}): Observable<AnyAction> => {
    return pipe(
        action$,
        actionOf(AuthenticationActions.retrieveAccount),
        () => usersApi(states$.value).getMe(),
        response => AuthenticationActions.retrieveAccountSuccess(response),
        (action, error) => AuthenticationActions.retrieveAccountFailed({
            action,
            axios: error,
            message: 'Account failed',
            description: 'A problem occurred while fetching account information'
        }));
};

const loginSuccessEpic: GenericEpic = action$ =>
    pipe(
        action$,
        actionOf(AuthenticationActions.loginSuccess),
        mergeMap(() => {
            return [
                UIActions.replacePage(Routes.Home),
                AuthenticationActions.retrieveAccount()
            ];
        })
    );

const signOutEpic: GenericEpic = action$ =>
    pipe(
        action$,
        actionOf(AuthenticationActions.signOut),
        map(() => GeneralActions.resetApp())
    );

export default [
    loginEpic,
    retrieveAccountEpic,
    loginSuccessEpic,
    signOutEpic
];