import { GroupReference} from './group-reference';
import { PlanningBlockType } from './planning-block-type';
import { PlanningBlockStatus } from './planning-block-status';
import { PlanningBlockPresence } from './planning-block-presence';
import { CareType } from './care-type';
import { PackageType } from './package-type';
import moment from 'moment';

export interface PlanningBlock {
    description?: string;
    planningId?: string;
    type?: PlanningBlockType;
    status: PlanningBlockStatus;
    start?: moment.Duration;
    end?: moment.Duration;
    presence: PlanningBlockPresence;
    timeslotId?: string;
    canSetTimeInterval?: boolean;
    remarks?: string;
    group?: GroupReference;
    isPlannable?: boolean;
    deviatingGroup?: GroupReference;
    careType?: CareType;
    packageType?: PackageType;
    packageId?: string;
    companyId?: string;
}

export function onDeserializePlanningBlock(data: PlanningBlock): PlanningBlock {
    if (data == null) {
        return data;
    }

    if (data.start != null) {
        data.start = moment.duration(data.start);
    }
    if (data.end != null) {
        data.end = moment.duration(data.end);
    }

    return data;
}

export function onSerializePlanningBlock(data: PlanningBlock): PlanningBlock {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };


    return serialized;
}
