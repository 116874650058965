import { PlanningTimeslot, onDeserializePlanningTimeslot, onSerializePlanningTimeslot} from './planning-timeslot';
import { CareTypeReference} from './care-type-reference';
import { TimeslotFrequencyReference} from './timeslot-frequency-reference';
import { PackageTypeReference} from './package-type-reference';
import { PackageLabelReference} from './package-label-reference';

export interface Package {
    packageId: string;
    name: string;
    timeslots: PlanningTimeslot[];
    allTimeslots: boolean;
    careType: CareTypeReference;
    frequencies: TimeslotFrequencyReference[];
    packageType: PackageTypeReference;
    isSubsidyPlace: boolean;
    label?: PackageLabelReference;
}

export function onDeserializePackage(data: Package): Package {
    if (data == null) {
        return data;
    }

    if (data.timeslots != null) {
        for (let i = 0; i < data.timeslots.length; i++) {
            if (data.timeslots[i] != null) {
                data.timeslots[i] = onDeserializePlanningTimeslot(data.timeslots[i]);
            }
        }
    }

    return data;
}

export function onSerializePackage(data: Package): Package {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    if (data.timeslots != null) {
        for (let i = 0; i < data.timeslots.length; i++) {
            serialized.timeslots[i] = onSerializePlanningTimeslot(data.timeslots[i]);
        }
    }      

    return serialized;
}
