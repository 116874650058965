import React from 'react';
import { Group } from '@/client/models';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBatteryThreeQuarters, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Translate } from 'react-localize-redux';

export function GroupSpecification({ group }: { group: Group }): React.ReactElement {
    return (
        <GroupInfoContainer>
            <DesciptionContainer column={1}>
                <FontAwesomeIcon icon={faBatteryThreeQuarters} />
                {group == null ? <span></span> : 
                    <span>{group.capacity}</span>
                }
            </DesciptionContainer>
            <DesciptionContainer column={2}>
                <FontAwesomeIcon icon={faCalendarAlt} />
                {group == null ? <span></span> : 
                    <span>{Math.ceil(group.fromMonths / 12)} - {Math.ceil(group.toMonths / 12)}</span>
                }
            </DesciptionContainer>
            <NameContainer>{group == null ? <Translate id='planboard.noGroupAvailable' /> : group.name}</NameContainer>
        </GroupInfoContainer>
    );
}

const GroupInfoContainer = styled.div`
    grid-area: groupinfo;
    display: grid;

    grid-template-rows: 1fr max-content;
    grid-template-columns: 1fr 1fr;

    justify-items: center;
`;

const NameContainer = styled.div`
    grid-row: 1;
    grid-column-start: 1;
    grid-column-end: 3;
    text-align: center;
    align-self: start;
    color: ${props => props.theme.tertiary};
`;

const DesciptionContainer = styled.div<{ column: number }>`
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-column-gap: 5px;
    grid-row: 2;
    grid-column: ${props => props.column};

    align-items: center;
    justify-items: center;
    align-self: end;
`;