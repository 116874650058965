import { PlanningDay, onDeserializePlanningDay, onSerializePlanningDay} from './planning-day';

export interface PlanningWeek {
    weekNumber: number;
    days: PlanningDay[];
}

export function onDeserializePlanningWeek(data: PlanningWeek): PlanningWeek {
    if (data == null) {
        return data;
    }

    if (data.days != null) {
        for (let i = 0; i < data.days.length; i++) {
            if (data.days[i] != null) {
                data.days[i] = onDeserializePlanningDay(data.days[i]);
            }
        }
    }

    return data;
}

export function onSerializePlanningWeek(data: PlanningWeek): PlanningWeek {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    if (data.days != null) {
        for (let i = 0; i < data.days.length; i++) {
            serialized.days[i] = onSerializePlanningDay(data.days[i]);
        }
    }      

    return serialized;
}
