import { CareTimeHistory, onDeserializeCareTimeHistory, onSerializeCareTimeHistory} from './care-time-history';
import { CareType } from './care-type';
import moment from 'moment';

export interface CareTimeFullDetailsResponse {
    history: CareTimeHistory[];
    careTimeId: string;
    name?: string;
    careType: CareType;
    locationId?: string;
    schoolId?: string;
    expiresOn?: moment.Moment;
}

export function onDeserializeCareTimeFullDetailsResponse(data: CareTimeFullDetailsResponse): CareTimeFullDetailsResponse {
    if (data == null) {
        return data;
    }

    if (data.history != null) {
        for (let i = 0; i < data.history.length; i++) {
            if (data.history[i] != null) {
                data.history[i] = onDeserializeCareTimeHistory(data.history[i]);
            }
        }
    }
    if (data.expiresOn != null) {
        data.expiresOn = moment(data.expiresOn);
    }

    return data;
}

export function onSerializeCareTimeFullDetailsResponse(data: CareTimeFullDetailsResponse): CareTimeFullDetailsResponse {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    if (data.history != null) {
        for (let i = 0; i < data.history.length; i++) {
            serialized.history[i] = onSerializeCareTimeHistory(data.history[i]);
        }
    }      
    serialized.expiresOn = data.expiresOn?.format('YYYY-MM-DD') as unknown as moment.Moment;

    return serialized;
}
