import React from 'react';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslate } from '@/language-provider';

interface Props {
    icon: IconDefinition;
    tooltipTranslation?: string;
    className?: string;
}

export function FaIcon(props: Props): React.ReactElement {
    const { translate } = useTranslate();
    const { className = 'icon', icon, tooltipTranslation } = props;

    const title = tooltipTranslation != null ? translate(tooltipTranslation).toString() : undefined;

    return <FontAwesomeIcon className={className} icon={icon} title={title} />;
}