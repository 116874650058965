import { GroupUsages, onDeserializeGroupUsages, onSerializeGroupUsages} from './group-usages';

export interface LocationUsagesResponse {
    locationId: string;
    groups: GroupUsages[];
}

export function onDeserializeLocationUsagesResponse(data: LocationUsagesResponse): LocationUsagesResponse {
    if (data == null) {
        return data;
    }

    if (data.groups != null) {
        for (let i = 0; i < data.groups.length; i++) {
            if (data.groups[i] != null) {
                data.groups[i] = onDeserializeGroupUsages(data.groups[i]);
            }
        }
    }

    return data;
}

export function onSerializeLocationUsagesResponse(data: LocationUsagesResponse): LocationUsagesResponse {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    if (data.groups != null) {
        for (let i = 0; i < data.groups.length; i++) {
            serialized.groups[i] = onSerializeGroupUsages(data.groups[i]);
        }
    }      

    return serialized;
}
