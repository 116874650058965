import moment from 'moment';

export interface FeatureModel {
    code: string;
    name: string;
    since?: moment.Moment;
}

export function onDeserializeFeatureModel(data: FeatureModel): FeatureModel {
    if (data == null) {
        return data;
    }

    if (data.since != null) {
        data.since = moment(data.since);
    }

    return data;
}

export function onSerializeFeatureModel(data: FeatureModel): FeatureModel {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    serialized.since = data.since?.toISOString() as unknown as moment.Moment;

    return serialized;
}
