/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-ignore */

import { AxiosPromise, AxiosResponse, AxiosError } from 'axios';
import { map, mergeMap } from 'rxjs/operators';
import { Observable, from, OperatorFunction } from 'rxjs';
import { ActionsObservable, ofType, Epic } from 'redux-observable';
import { AnyAction } from 'redux';
import { Action } from './action';
import { ApplicationState } from './application-state';
import { Dependencies } from './dependencies';

export type GenericEpic = Epic<AnyAction, AnyAction, ApplicationState, Dependencies>;
export type ErrorHandler<A extends Action<any, any>> = (action: A, error: AxiosError) => AnyAction;

export type SuccessHandler<T, P> = (response: T, payload: P) => AnyAction;

export function isResponse<P>(arg: any): arg is AxiosResponse<P> {
    return arg.status !== undefined;
}

export function isAxiosError(arg: any): arg is AxiosError {
    return arg.code !== undefined || arg.response !== undefined || (arg.isAxiosError !== undefined && arg.isAxiosError);
}

export function handleResponse<P>(promise: AxiosPromise<P>): Observable<P | AxiosError> {
    return from(promise.catch(error => ({ isAxiosError: true, ...error }) as AxiosError))
        .pipe(
            map(response => {
                if (response && isResponse(response)) {
                    if (response.status >= 200 && response.status <= 299) {
                        return response.data;
                    }

                    return { response } as AxiosError;
                }

                return response as AxiosError;
            })
        );
}

export type NonUndefined<A> = A extends undefined ? never : A;
export type FunctionKeys<T extends object> = {
    [K in keyof T]-?: NonUndefined<T[K]> extends Function ? K : never
}[keyof T];

export function actionOf<T extends (...args: any) => any>(target: T): ReturnType<T> {
    // @ts-ignore
    return { type: target.__actionName } as ReturnType<T>;
}

type PromiseOperator<T, A> = (action: A) => AxiosPromise<T>;
type OperatorOrPromise<T, A> = OperatorFunction<A, AnyAction | AnyAction[]> | PromiseOperator<T, A>;

export function pipe<T, A extends Action<any, any>>(action$: ActionsObservable<AnyAction>, action: A, operator: (action: A) => AxiosPromise<T>, successHandler: SuccessHandler<T, A>, errorHandler: ErrorHandler<A>): Observable<AnyAction>;
export function pipe<T, A extends Action<any, any>>(action$: ActionsObservable<AnyAction>, action: A, operator: OperatorFunction<A, AnyAction | AnyAction[]>): Observable<AnyAction>;
export function pipe<T, A extends Action<any, any>>(action$: ActionsObservable<AnyAction>, action: A, operator: OperatorOrPromise<T, A>, successHandler?: SuccessHandler<T, A>, errorHandler?: ErrorHandler<A>): Observable<AnyAction> {
    if (successHandler != null && errorHandler != null) {
        return action$.pipe(
            ofType(action.type),
            mergeMap((action: A) => handleResponse((operator as PromiseOperator<T, A>)(action)), (action, response) => ({ action, response })),
            map(({ action, response }) => {
                if (isAxiosError(response)) {
                    return errorHandler(action, response);
                }
    
                return successHandler(response, action.payload);
            })
        );
    } else {
        return action$.pipe(
            ofType(action.type),
            operator as OperatorFunction<A, AnyAction>
        );
    }    
}