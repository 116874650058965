import React from 'react';
import styled from 'styled-components';
import { ActionBar, ActionBarItem, ActionBarItemProps, Position, IconSize } from '@/components/action-bar';
import { useSelector, useDispatch } from 'react-redux';
import { isInFullScreenSelector } from '@/store/ui/selectors';
import { UIActions } from '@/store/ui/actions';
import { AppErrorBoundary } from '@/app-error-boundry';
import { PageRoute } from '@/router/routes';
import { LeftOutlined, FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';

export type MenuItemProps = ActionBarItemProps;

interface ScreenContainerProps {
    children?: React.ReactNode;
    menuItems?: MenuItemProps[];
    showFullScreen?: boolean;
}

export function MenuBackButton(fallbackRoute?: PageRoute): MenuItemProps {
    return {
        translation: 'global.button.back', icon: <LeftOutlined />, route: fallbackRoute, isBack: true
    };
}

export function MenuButton(translation: string, icon: React.ReactElement, onclick: () => void, position: Position = Position.left): MenuItemProps {
    return {
        translation: translation, icon: icon, onClick: onclick, position
    };
}

export function ScreenContainer(props: ScreenContainerProps): React.ReactElement {

    const { children, menuItems = [], showFullScreen = false } = props;

    const dispatch = useDispatch();
    const isInFullScreen = useSelector(isInFullScreenSelector);

    const leftItems = menuItems.filter(x => x.position == null || x.position === Position.left);
    let rightItems = menuItems.filter(x => x.position === Position.right);

    function toggleFullscreen(): void {
        if (isInFullScreen) {
            dispatch(UIActions.leaveFullscreen());
        } else {
            dispatch(UIActions.enterFullscreen());
        }
    }

    if (showFullScreen) {
        rightItems = [{
            translation: isInFullScreen ? 'global.button.normalscreen' : 'global.button.fullscreen',
            icon: isInFullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />,
            iconSize: IconSize.large,
            onClick: toggleFullscreen,
            position: Position.right,
            iconOnly: true
        }, ...rightItems];
    }

    const allItems = [...leftItems, ...rightItems];

    return (
        <>
            {allItems.length > 0 &&
                <ActionBar>
                    {allItems.map((x, index) => <ActionBarItem key={`menu-${index}`} {...x} />)}
                </ActionBar>
            }

            <BodyContainer>
                <AppErrorBoundary>
                    {children}
                </AppErrorBoundary>
            </BodyContainer>
        </>
    );
}

const BodyContainer = styled.div`
    padding-left: 20px;
    padding-right: 20px;
`;