import { PaginationResponse} from './pagination-response';
import { Application, onDeserializeApplication, onSerializeApplication} from './application';

export interface ApplicationsResponse {
    pagination?: PaginationResponse;
    applications?: Application[];
}

export function onDeserializeApplicationsResponse(data: ApplicationsResponse): ApplicationsResponse {
    if (data == null) {
        return data;
    }

    if (data.applications != null) {
        for (let i = 0; i < data.applications.length; i++) {
            if (data.applications[i] != null) {
                data.applications[i] = onDeserializeApplication(data.applications[i]);
            }
        }
    }

    return data;
}

export function onSerializeApplicationsResponse(data: ApplicationsResponse): ApplicationsResponse {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    if (data.applications != null) {
        for (let i = 0; i < data.applications.length; i++) {
            serialized.applications[i] = onSerializeApplication(data.applications[i]);
        }
    }      

    return serialized;
}
