import { TimeslotRegistration, onDeserializeTimeslotRegistration, onSerializeTimeslotRegistration} from './timeslot-registration';
import moment from 'moment';

export interface TimesModel {
    date: moment.Moment;
    message?: string;
    timeslots?: TimeslotRegistration[];
}

export function onDeserializeTimesModel(data: TimesModel): TimesModel {
    if (data == null) {
        return data;
    }

    if (data.date != null) {
        data.date = moment(data.date);
    }
    if (data.timeslots != null) {
        for (let i = 0; i < data.timeslots.length; i++) {
            if (data.timeslots[i] != null) {
                data.timeslots[i] = onDeserializeTimeslotRegistration(data.timeslots[i]);
            }
        }
    }

    return data;
}

export function onSerializeTimesModel(data: TimesModel): TimesModel {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    serialized.date = data.date?.format('YYYY-MM-DD') as unknown as moment.Moment;
    if (data.timeslots != null) {
        for (let i = 0; i < data.timeslots.length; i++) {
            serialized.timeslots[i] = onSerializeTimeslotRegistration(data.timeslots[i]);
        }
    }      

    return serialized;
}
