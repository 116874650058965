import moment from 'moment';
import { ApplicationScheduleLine, Group, GroupUsages, LocationUsagesResponse, TimeSlot } from '@/client/models';
import { ApplicationGroupPeriod, ApplicationPeriodGroupTime, ApplicationSchedulePart, GroupComposition, EmptyStatus } from '@/store/planboard/state';
import { calculateStatus } from '@/planboard/part-status';
import { AssertionError } from 'assert';
import R from 'ramda';

export interface MergeAction {
    fromLines: ApplicationScheduleLine[];
    toLines: ApplicationScheduleLine[];
    groupIds: string[];
}

function tryMergeScheduleLines(timeSlots: TimeSlot[], lines: ApplicationScheduleLine[]): ApplicationScheduleLine[] {
    const mergedLines = [...lines];
    const lookup = timeSlots.reduce((acc, cur) => { 
        acc[cur.timeSlotId] = cur;
        return acc;
    }, { } as { [key: string]: TimeSlot });

    let hasChanges = false;
    do {
        hasChanges = false;

        for (const slotA of mergedLines) {
            if (slotA.until == null) {
                continue;
            }

            const nextStart = slotA.until.clone().add(1, 'day');

            for (const slotB of mergedLines) {
                // Itself or for different slot
                if (slotA === slotB || slotA.timeslotId !== slotB.timeslotId) {
                    continue;
                }

                // Slot a is not connected to slot b
                if (!slotB.from.isSame(nextStart, 'day')) {
                    continue;
                }

                // Times dot not match
                if (slotA.start.asMinutes() !== slotB.start.asMinutes() || slotA.end.asMinutes() !== slotB.end.asMinutes()) {
                    continue;
                }

                hasChanges = true;
                mergedLines.splice(mergedLines.indexOf(slotB), 1);
                mergedLines.splice(mergedLines.indexOf(slotA), 1);                
                mergedLines.push({ ...slotA, until: slotB.until, groupId: undefined, compositionId: undefined });

                break;
            }

            if (hasChanges) {
                break;
            }
        }

    } while(hasChanges);

    return R.sortBy(x => lookup[x.timeslotId].sortOrder, mergedLines);
}

function createPlanningLines(groupPeriod: ApplicationGroupPeriod, lines: ApplicationScheduleLine[], groupUsages: GroupUsages): ApplicationPeriodGroupTime[] {
    const result = new Array<ApplicationPeriodGroupTime>();

    const filteredLines = lines.filter(x => groupPeriod.end.isAfter(x.from) && (x.until == null || groupPeriod.start.isBefore(x.until)));

    for (const line of filteredLines) {

        let usages;
        if (groupUsages?.timeSlots != null) {
            const lineUsages = groupUsages.timeSlots.find(x => x.timeSlotId === line.timeslotId);
            if (lineUsages != null) {
                usages = lineUsages.usages;
            }
        }

        result.push({
            groupPeriod,
            line: line,
            status: calculateStatus(groupPeriod, usages)
        });
    }

    return result;
}

function createGroupPeriod(timeSlots: TimeSlot[], composition: GroupComposition, group: Group, periodContext: PeriodContext, locationUsages: LocationUsagesResponse[]): ApplicationGroupPeriod {

    const periodGroup = {
        group,
        start: periodContext.start,
        end: periodContext.end,
        ageInYear: periodContext.ageInYear,
        composition
    } as ApplicationGroupPeriod;

    const scheduleLines = tryMergeScheduleLines(timeSlots, composition.schedulePart.added);

    if (group == null) {
        periodGroup.times = scheduleLines.map<ApplicationPeriodGroupTime>(x => ({ line: x, groupPeriod: periodGroup, status: EmptyStatus }));        
    } else {
        const usages = locationUsages.find(x => x.locationId === group.locationId);
        periodGroup.times = createPlanningLines(periodGroup, scheduleLines, usages?.groups.find(x => x.groupId === group.groupId));
    }

    return periodGroup;
}

interface PeriodContext {
    start: moment.Moment;
    end: moment.Moment;
    fromMonths: number;
    toMonths: number;
    ageInYear: { [year: number]: number };
}

function determineStartAndEndDate(schedulePart: ApplicationSchedulePart, group: Group): { start: moment.Moment; end: moment.Moment } {
    const groupTransitionAfterBirthDate = schedulePart.added[0].groupTransitionAfterBirthDate;

    // Absolute anchor points in time
    const ageAtStart = schedulePart.startsOn.diff(schedulePart.child.birthDate, 'years');
    const birthDateInStartYear = schedulePart.child.birthDate.clone().add(ageAtStart, 'years');

    // Relative points in time    
    const groupStartAge = Math.floor(group.fromMonths / 12);
    const groupEndAge   = Math.floor(group.toMonths / 12);

    // Conversion from relative to absolute points in time
    const ageInGroupAtStart = groupStartAge - ageAtStart;
    const ageInGroupAtEnd   = groupEndAge   - ageAtStart;
    const birthDateAtStart = birthDateInStartYear.clone().add(ageInGroupAtStart, 'years');
    const birthDateAtEnd   = birthDateInStartYear.clone().add(ageInGroupAtEnd, 'years');
    let periodEnd: moment.Moment;
    let periodStart: moment.Moment;

    if (groupTransitionAfterBirthDate) {
        periodStart = ageAtStart === groupStartAge ? schedulePart.startsOn.clone() : birthDateAtStart.clone().add(1, 'day');
        periodEnd   = birthDateAtEnd.clone().add(1, 'day');
    } else {
        periodStart = ageAtStart === groupStartAge ? schedulePart.startsOn.clone() : birthDateAtStart.clone();
        periodEnd   = birthDateAtEnd.clone();
    }

    return {
        start: periodStart,
        end: periodEnd
    };
}

export function createPeriodContext(schedulePart: ApplicationSchedulePart, periodStart: moment.Moment, periodEnd: moment.Moment): PeriodContext {
    
    const startYear = periodStart.year();
    const endYear = periodEnd.year();

    if (periodStart.isAfter(periodEnd)) {
        throw new AssertionError({ message: 'Start of period cannot be after end of period' });
    }

    const periodContext: PeriodContext = {
        fromMonths: periodStart.diff(schedulePart.child.birthDate, 'months'),
        toMonths: periodEnd.diff(schedulePart.child.birthDate, 'months'),
        ageInYear: {},
        start: periodStart,
        end: periodEnd
    };

    const ageStartInMonths = periodStart.diff(schedulePart.child.birthDate, 'months');
    for (let year = startYear; year <= endYear; year++) {
        periodContext.ageInYear[year] = year - startYear + Math.floor(ageStartInMonths / 12);
    }

    return periodContext;
}

function addGroupPeriods(schedulePart: ApplicationSchedulePart, periods: moment.Moment[], groups: Group[]): void {    

    groups.reduce((acc, cur) => {
        const { start, end } = determineStartAndEndDate(schedulePart, cur);
        if (start.isBefore(schedulePart.startsOn)) {
            return acc;
        }

        if (acc.find(x => x.isSame(start, 'day')) == null) {
            acc.push(start);
        }

        if (acc.find(x => x.isSame(end, 'day')) == null) {
            acc.push(end);
        }

        return acc;
    }, periods);
}

function getPeriods(schedulePart: ApplicationSchedulePart, groups: Group[]): moment.Moment[] {    
    const periods = [schedulePart.startsOn, schedulePart.endsOn.clone().add(1, 'day')];

    addGroupPeriods(schedulePart, periods, groups);

    return periods.sort((a, b) => a.diff(b, 'days'));
}

export function createGroupPeriods(timeSlots: TimeSlot[], composition: GroupComposition, from: moment.Moment, until: moment.Moment, allUsages: LocationUsagesResponse[]): ApplicationGroupPeriod[] {
    const groupPeriods = new Array<ApplicationGroupPeriod>();
    
    const periodContext = createPeriodContext(composition.schedulePart, from, until);

    const groupsForPeriod = composition.groups.filter(x => x.fromMonths < periodContext.toMonths && x.toMonths >  periodContext.fromMonths);
    
    if (groupsForPeriod.length > 0) {
        for (const group of groupsForPeriod) {
            groupPeriods.push(createGroupPeriod(timeSlots, composition, group, periodContext, allUsages));
        }
    } else {        
        groupPeriods.push(createGroupPeriod(timeSlots, composition, null, periodContext, allUsages));
    }

    return groupPeriods;
}

export function createGroupPeriodsForComposition(timeSlots: TimeSlot[], composition: GroupComposition, allUsages: LocationUsagesResponse[]): ApplicationGroupPeriod[] {

    const groupPeriods = new Array<ApplicationGroupPeriod>();

    if (composition == null || composition.groups == null) {
        return groupPeriods;
    }

    const periods = getPeriods(composition.schedulePart, composition.groups);

    for (let i = 0; i < periods.length; i++) {
        if (periods[i + 1] == null) {
            continue;
        }

        const from  = periods[i];
        const until = periods[i + 1].clone().subtract(1, 'day'); // Convert next start date to end date of current

        groupPeriods.push(...createGroupPeriods(timeSlots, composition, from, until, allUsages));
    }

    return groupPeriods;
}