import { CareTimeslot, onDeserializeCareTimeslot, onSerializeCareTimeslot} from './care-timeslot';
import { CareType } from './care-type';
import moment from 'moment';

export interface CareTimeDetails {
    from: moment.Moment;
    careTimeslots?: CareTimeslot[];
    careTimeId: string;
    name?: string;
    careType: CareType;
    locationId?: string;
    schoolId?: string;
    expiresOn?: moment.Moment;
}

export function onDeserializeCareTimeDetails(data: CareTimeDetails): CareTimeDetails {
    if (data == null) {
        return data;
    }

    if (data.from != null) {
        data.from = moment(data.from);
    }
    if (data.careTimeslots != null) {
        for (let i = 0; i < data.careTimeslots.length; i++) {
            if (data.careTimeslots[i] != null) {
                data.careTimeslots[i] = onDeserializeCareTimeslot(data.careTimeslots[i]);
            }
        }
    }
    if (data.expiresOn != null) {
        data.expiresOn = moment(data.expiresOn);
    }

    return data;
}

export function onSerializeCareTimeDetails(data: CareTimeDetails): CareTimeDetails {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    serialized.from = data.from?.format('YYYY-MM-DD') as unknown as moment.Moment;
    if (data.careTimeslots != null) {
        for (let i = 0; i < data.careTimeslots.length; i++) {
            serialized.careTimeslots[i] = onSerializeCareTimeslot(data.careTimeslots[i]);
        }
    }      
    serialized.expiresOn = data.expiresOn?.format('YYYY-MM-DD') as unknown as moment.Moment;

    return serialized;
}
