import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { message } from 'antd';
import { useTranslate } from '@/language-provider';
import { useApplicationPartProvider, usePartChanged } from '@/planboard/planner-application-part-provider';
import { plannerStatusSelector } from '@/store/planboard/selectors';
import { Status } from '@/store/status';

export const PlannerSpinner: React.FC = () => {
    const provider = useApplicationPartProvider();

    const { translate } = useTranslate();
    const plannerStatus = useSelector(plannerStatusSelector);

    const [ previousPart, setPreviousPart ] = useState(translate(`global.caretype.${provider.getCurrentPart()?.careType}`).toString());
    const [ currentPart , setCurrentPart] = useState(translate(`global.caretype.${provider.getCurrentPart()?.careType}`).toString());
    
    const stateRef = useRef<{ status: Status; previousPart: string }>();
    stateRef.current = { status: plannerStatus, previousPart };
    
    const currentPartRef = useRef<string>();
    currentPartRef.current = currentPart;

    usePartChanged(() => {
        const currentPart = currentPartRef.current;

        const partName = translate(`global.caretype.${provider.getCurrentPart()?.careType}`).toString();

        setCurrentPart(partName);
        setPreviousPart(currentPart);
    });
    
    useEffect(() => {
        const { status, previousPart } = stateRef.current;

        if (status === Status.Loading) {
            message.loading(translate('planboard.messages.saving-part-message', { partName: previousPart }), 0);
        } else if (status === Status.Loaded) {
            message.destroy();
        }
    }, [plannerStatus]);

    return <></>;
};