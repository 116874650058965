import moment from 'moment';

export interface PresenceItem {
    state: string;
    date: moment.Moment;
    start: moment.Duration;
    end?: moment.Duration;
}

export function onDeserializePresenceItem(data: PresenceItem): PresenceItem {
    if (data == null) {
        return data;
    }

    if (data.date != null) {
        data.date = moment(data.date);
    }
    if (data.start != null) {
        data.start = moment.duration(data.start);
    }
    if (data.end != null) {
        data.end = moment.duration(data.end);
    }

    return data;
}

export function onSerializePresenceItem(data: PresenceItem): PresenceItem {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    serialized.date = data.date?.format('YYYY-MM-DD') as unknown as moment.Moment;

    return serialized;
}
