import React from 'react';
import styled from 'styled-components';
import { Translate } from 'react-localize-redux';
import { Col } from 'antd';

export interface SummaryCardProps { 
    key: string | number; 
    title?: string; 
    translation?: string; 
    count: number; 
    isSelected: boolean;
    onClick(): void;
}

export function SummaryCard({ title, translation, count, isSelected, onClick }: SummaryCardProps): React.ReactElement {

    const display = title != null ? (<>{title}</>) : (<Translate id={translation} />);

    return (
        <Col lg={3} offset={1}>
            <HoveredCard isSelected={isSelected} onClick={onClick}>
                <CardTitle>{display}</CardTitle>
                <CardNumber>{count}</CardNumber>
            </HoveredCard>
        </Col>
    );
}

const CardTitle = styled.div`
    font-size: 1em;
    padding-bottom: 5px;
`;

const CardNumber = styled.div`
    font-weight: bold;
`;

const HoveredCard = styled.div<{ isSelected: boolean }>`
    display: inline-block;
    width: 100%;
    height: 50px;
    text-align: center;
    border: 1px rgba(0, 0, 0, 0.09) solid;
    margin-bottom: 5px;
    background-color: ${props => props.isSelected ? props.theme.primary : props.theme.background};
    color: ${props => props.isSelected ? props.theme.background : props.theme.inverted};

	:hover {        
        color: ${props => props.isSelected ? props.theme.tertiary : props.theme.primary};
		border-color: rgba(0, 0, 0, 0.09);
        box-shadow: 0 2px 8px rgba(0,0,0, 0.09);
        cursor: pointer;
	}
`;