import { MediaReference} from './media-reference';
import { ActionDateReference, onDeserializeActionDateReference, onSerializeActionDateReference} from './action-date-reference';

export interface TimelineMediaItem {
    media: MediaReference[];
    childId?: string;
    albumId: string;
    title: string;
    description: string;
    published: ActionDateReference;
    mediaCount: number;
    poster: string;
}

export function onDeserializeTimelineMediaItem(data: TimelineMediaItem): TimelineMediaItem {
    if (data == null) {
        return data;
    }

    if (data.published != null) {
        data.published = onDeserializeActionDateReference(data.published);
    }

    return data;
}

export function onSerializeTimelineMediaItem(data: TimelineMediaItem): TimelineMediaItem {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    serialized.published = onSerializeActionDateReference(data.published);

    return serialized;
}
