import React, { useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import moment from 'moment';
import { DateRangeEditor } from '@/components/editors';

interface Props {
    visible: boolean;
    from: moment.Moment;
    till: moment.Moment;
    onOk: (from: moment.Moment, till: moment.Moment) => void;
    onClose: () => void;
}

export const GroupCardDatePicker: React.FC<Props> = ({ visible, from, till, onOk, onClose }) => {

    const [values, setValues] = useState<[moment.Moment, moment.Moment]>([from, till]);

    return (
        <Modal visible={visible} onOk={() => onOk(values[0], values[1])} onCancel={onClose}>
            <DateRangeEditor value={values} onChange={setValues} />
        </Modal>
    );
};