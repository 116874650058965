import {State} from '@/store/ui/state';
import {Actions, UIActions} from './actions';
import {isActionFromContainer} from '@/store/action';
import {applyParamsToRoute} from '@/router/routes';

const initialState: State = {
    breadcrumbs: [],
    isInFullScreen: false,
    terminalVisible: false,
    isOffline: false,
    pages: []
};

// Never put the class PageRoute in the state, use the spread operator to convert it to the IPageRoute shape instead of the class PageRoute.
// Redux does not allow classes to be put in the actions

export const reducer = (state: State = initialState, action: Actions): State => {
    if (!isActionFromContainer(action, UIActions)) {
        return state;
    }

    switch (action.action) {
        case 'pushPage':
            return {
                ...state,
                pages: [...state.pages, {
                    ...action.payload.pageRoute,
                    icon: undefined,
                    navigated: applyParamsToRoute(action.payload.pageRoute, action.payload.params)
                }]
            };
        case 'replacePage':
            return {
                ...state,
                pages: [{
                    ...action.payload.pageRoute,
                    icon: undefined,
                    navigated: applyParamsToRoute(action.payload.pageRoute, action.payload.params)
                }]
            };
        case 'popPage':
            return {...state, pages: state.pages.slice(0, -1)};
        case 'breadcrumbs':
            return {...state, breadcrumbs: [...action.payload.breadcrumbs]};
        case 'enterFullscreen':
            return {...state, isInFullScreen: true};
        case 'leaveFullscreen':
            return {...state, isInFullScreen: false};
        case 'toggleTerminal':
            return {...state, terminalVisible: !state.terminalVisible};
        case 'offline':
            return {...state, isOffline: true};
        case 'online':
            return {...state, isOffline: false};

        default:
            return state;
    }
};