import { Group, onDeserializeGroup, onSerializeGroup} from './group';

export interface GroupsResponse {
    groups?: Group[];
}

export function onDeserializeGroupsResponse(data: GroupsResponse): GroupsResponse {
    if (data == null) {
        return data;
    }

    if (data.groups != null) {
        for (let i = 0; i < data.groups.length; i++) {
            if (data.groups[i] != null) {
                data.groups[i] = onDeserializeGroup(data.groups[i]);
            }
        }
    }

    return data;
}

export function onSerializeGroupsResponse(data: GroupsResponse): GroupsResponse {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    if (data.groups != null) {
        for (let i = 0; i < data.groups.length; i++) {
            serialized.groups[i] = onSerializeGroup(data.groups[i]);
        }
    }      

    return serialized;
}
