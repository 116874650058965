/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { PlanboardActions } from './actions';
import { GenericEpic, actionOf, pipe } from '@/store/epic-helper';
import { map } from 'rxjs/operators';
import { ApplicationStatus } from '@/client/models';


const loadOpenApplications: GenericEpic = (action$, states$, { applicationsApi }) => {
    return pipe(action$,
        actionOf(PlanboardActions.loadOpenApplications),
        ({ payload : { paging }}) => applicationsApi(states$.value).get([ApplicationStatus.Received, ApplicationStatus.InProgress], paging?.page, paging?.pageSize, paging?.sort),
        response => PlanboardActions.loadOpenApplicationsSuccess(response.applications, response.pagination),
        (action, error) => PlanboardActions.loadOpenApplicationsFailed({
            action,
            axios: error,
            message: 'Failed to load applications',
            description: 'A problem occurred while trying to load applications'
        }));
};

const loadReservedApplications: GenericEpic = (action$, states$, { applicationsApi }) => pipe(action$,
        actionOf(PlanboardActions.loadReservedApplications),
        ({ payload : { paging }}) => applicationsApi(states$.value).get([ApplicationStatus.Reserved], paging?.page, paging?.pageSize, paging?.sort),
        response => PlanboardActions.loadReservedApplicationsSuccess(response.applications, response.pagination),
        (action, error) => PlanboardActions.loadReservedApplicationsFailed({
            action,
            axios: error,
            message: 'Failed to load applications',
            description: 'A problem occurred while trying to load applications'
        }));

const loadApplicationDetails: GenericEpic = (action$, states$, { applicationsApi }) => {
    return pipe(action$,
        actionOf(PlanboardActions.loadApplicationDetails),
        ({ payload: { applicationId } }) => applicationsApi(states$.value).getApplicationId(applicationId),
        applicationDetails => PlanboardActions.loadApplicationDetailsSuccess(applicationDetails),
        (action, error) => PlanboardActions.loadApplicationDetailsFailed({
            action,
            axios: error,
            message: 'Failed to load applications',
            description: 'A problem occurred while trying to load applications'
        }));
};

const loadUsages: GenericEpic = (action$, states$, { locationsApi }) => {
    return pipe(
        action$,
        actionOf(PlanboardActions.loadUsages),
        ({ payload: { locationId, start, end } }) => locationsApi(states$.value).getLocationIdUsages(locationId, start, end),
        response => PlanboardActions.loadUsagesSuccess(response),
        (action, error) => PlanboardActions.loadUsagesFailed({
            action,
            axios: error,
            message: 'Loading usages failed',
            description: 'A problem occurred while fetching usages'
        }));
};

const saveSchedulePart: GenericEpic = (action$, states$, { applicationsApi }) => {
    return pipe(
        action$,
        actionOf(PlanboardActions.saveSchedulePart),
        ({ payload: { schedulePart, lines } }) => applicationsApi(states$.value).putApplicationIdScheduleSchedulePartId(schedulePart.applicationId, schedulePart.schedulePartId, { schedulePartId: schedulePart.schedulePartId, lines }),
        () => PlanboardActions.saveSchedulePartSuccess(),
        (action, error) => PlanboardActions.saveSchedulePartFailed({
            action,
            axios: error,
            message: 'Loading usages failed',
            description: 'A problem occurred while fetching usages'
        }));
};

const offerContract: GenericEpic = (action$, states$, { applicationsApi }) => pipe(action$,
        actionOf(PlanboardActions.offerContract),
        ({ payload: { applicationId } }) => applicationsApi(states$.value).postApplicationIdOffer(applicationId),
        () => PlanboardActions.offerContractSuccess(),
        (action, error) => PlanboardActions.offerContractFailed({
            action,
            axios: error,
            message: 'Offer contract failed',
            description: 'A problem occurred while offering contract'
        }));

const reserveApplication: GenericEpic = (action$, states$, { applicationsApi }) => pipe(action$,
    actionOf(PlanboardActions.reserveApplication),
    ({ payload: { applicationId } }) => applicationsApi(states$.value).postApplicationIdReservation(applicationId),
    () => PlanboardActions.reserveApplicationSuccess(),
    (action, error) => PlanboardActions.reserveApplicationFailed({
        action,
        axios: error,
        message: 'Setting application on reserved failed',
        description: 'A problem occurred while setting application on reserved'
    }));

const inProgressApplication: GenericEpic = (action$, states$, { applicationsApi }) => pipe(action$,
    actionOf(PlanboardActions.inProgressApplication),
    ({ payload: { applicationId } }) => applicationsApi(states$.value).postApplicationIdInprogress(applicationId),
    () => PlanboardActions.inProgressApplicationSuccess(),
    (action, error) => PlanboardActions.inProgressApplicationFailed({
        action,
        axios: error,
        message: 'Setting application on in progress failed',
        description: 'A problem occurred while setting application on in progress'
    }));

const refreshOpenApplication: GenericEpic = (action$) => pipe(action$, actionOf(PlanboardActions.refreshOpenApplications), map(() => PlanboardActions.loadOpenApplications()));
const refreshReservedApplication: GenericEpic = (action$) => pipe(action$, actionOf(PlanboardActions.refreshReservedApplications), map(() => PlanboardActions.loadReservedApplications()));

export default [
    loadOpenApplications,
    loadReservedApplications,
    loadApplicationDetails,
    loadUsages,
    refreshOpenApplication,
    refreshReservedApplication,
    saveSchedulePart,
    offerContract,
    reserveApplication,
    inProgressApplication
];