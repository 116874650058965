import { ApplicationAnswer} from './application-answer';
import { RequestedApplicationSchedule, onDeserializeRequestedApplicationSchedule, onSerializeRequestedApplicationSchedule} from './requested-application-schedule';
import { ChildDetailsReference, onDeserializeChildDetailsReference, onSerializeChildDetailsReference} from './child-details-reference';
import { SchoolReference} from './school-reference';
import { ContractReference} from './contract-reference';
import { ActionReference, onDeserializeActionReference, onSerializeActionReference} from './action-reference';
import { RequestedDays} from './requested-days';
import { UserReference} from './user-reference';
import { ApplicationStatus } from './application-status';
import { ApplicationType } from './application-type';
import { CareType } from './care-type';
import moment from 'moment';

export interface ApplicationDetails {
    parentRemarks?: string;
    answers?: ApplicationAnswer[];
    schedule?: RequestedApplicationSchedule;
    applicationId: string;
    status: ApplicationStatus;
    applicationType: ApplicationType;
    hasSibling: boolean;
    onHold: boolean;
    isWaitinglistAvailable: boolean;
    child: ChildDetailsReference;
    school?: SchoolReference;
    originalContract?: ContractReference;
    requested: ActionReference;
    startsOn: moment.Moment;
    endsOn?: moment.Moment;
    careTypes: CareType[];
    days: RequestedDays;
    preferredLocationIds: string[];
    remarks?: string;
    owner?: UserReference;
    created: ActionReference;
    modifed: ActionReference;
}

export function onDeserializeApplicationDetails(data: ApplicationDetails): ApplicationDetails {
    if (data == null) {
        return data;
    }

    if (data.schedule != null) {
        data.schedule = onDeserializeRequestedApplicationSchedule(data.schedule);
    }
    if (data.child != null) {
        data.child = onDeserializeChildDetailsReference(data.child);
    }
    if (data.requested != null) {
        data.requested = onDeserializeActionReference(data.requested);
    }
    if (data.startsOn != null) {
        data.startsOn = moment(data.startsOn);
    }
    if (data.endsOn != null) {
        data.endsOn = moment(data.endsOn);
    }
    if (data.created != null) {
        data.created = onDeserializeActionReference(data.created);
    }
    if (data.modifed != null) {
        data.modifed = onDeserializeActionReference(data.modifed);
    }

    return data;
}

export function onSerializeApplicationDetails(data: ApplicationDetails): ApplicationDetails {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    serialized.schedule = onSerializeRequestedApplicationSchedule(data.schedule);
    serialized.child = onSerializeChildDetailsReference(data.child);
    serialized.requested = onSerializeActionReference(data.requested);
    serialized.startsOn = data.startsOn?.format('YYYY-MM-DD') as unknown as moment.Moment;
    serialized.endsOn = data.endsOn?.format('YYYY-MM-DD') as unknown as moment.Moment;
    serialized.created = onSerializeActionReference(data.created);
    serialized.modifed = onSerializeActionReference(data.modifed);

    return serialized;
}
