import React, { useState } from 'react';
import { SummaryCard } from '../summary-card';
import { SummaryDetails } from '../summary';
import { ApplicationFilter } from '../application-filter';
import { ApplicationType } from '@/client/models';

interface Props {
    summary: SummaryDetails;
    onClick: (requesFiltert: ApplicationFilter) => void;
}

interface InternalProps extends Props {
    translation: string; 
    applicationType: ApplicationType;
}

function ApplicationTypeCard({ summary, onClick, translation, applicationType }: InternalProps): React.ReactElement {
    const [isSelected, setSelected] = useState(false);

    return <SummaryCard key={translation}
        translation={translation}
        count={summary.applicationTypes[applicationType].length}
        isSelected={isSelected}
        onClick={() => {
            setSelected(!isSelected);
            onClick({
                name: translation,
                group: 'application-type',
                filter: applications => applications.filter(x => x.applicationType === applicationType)
            });
        }} />;
}

export function NewApplicationTypeCard({ summary, onClick }: Props): React.ReactElement {
    return <ApplicationTypeCard 
        summary={summary} 
        onClick={onClick} 
        translation='planboard.new' 
        applicationType={ApplicationType.New} />;
}

export function ChangeApplicationTypeCard({ summary, onClick }: Props): React.ReactElement {
    return <ApplicationTypeCard 
        summary={summary} 
        onClick={onClick} 
        translation='planboard.change' 
        applicationType={ApplicationType.Change} />;
}
