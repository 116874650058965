import uuidParse from 'uuid-parse';

export function combineUuids(uuids: string[]): string {
    const converted = uuids.map(x => uuidParse.parse(x));

    const newUuid = [];

    for (let i = 0; i < 16; i++) {
        let temp = 0;

        for (const uuid of converted) {
            temp = temp ^ uuid[i];
        }

        newUuid[i] = temp;
    }

    return uuidParse.unparse(newUuid);
}