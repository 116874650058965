import { PaytDebtInfo} from './payt-debt-info';
import { DocumentType } from './document-type';
import moment from 'moment';

export interface DocumentsListItem {
    childId?: string;
    date?: moment.Moment;
    url?: string;
    title?: string;
    description?: string;
    type?: DocumentType;
    paytDebtInfo?: PaytDebtInfo;
}

export function onDeserializeDocumentsListItem(data: DocumentsListItem): DocumentsListItem {
    if (data == null) {
        return data;
    }

    if (data.date != null) {
        data.date = moment(data.date);
    }

    return data;
}

export function onSerializeDocumentsListItem(data: DocumentsListItem): DocumentsListItem {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    serialized.date = data.date?.format('YYYY-MM-DD') as unknown as moment.Moment;

    return serialized;
}
