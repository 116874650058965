/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Translate } from 'react-localize-redux';
import { Table } from 'antd';
import moment, { duration } from 'moment';
import { ColumnProps } from 'antd/lib/table';
import { ColumnDefinition } from './column-definition';
import { getProperty } from './table-helper';
import { ColumnOptionsDrawer, ColumnOption } from './column-options-drawer';
import { useTranslate } from '@/language-provider';
import R from 'ramda';
import { GridSelectionMode } from './grid-selection-mode';
import { formatTime } from '@/momentjs/duration-helper';
import { PaginationConfig } from 'antd/lib/pagination';
import { SorterResult, TableCurrentDataSource, TableRowSelection } from 'antd/lib/table/interface';

type FieldSelector<T> = (item: T) => any;
export type OnTableChangedCallback<T> = (pagination: PaginationConfig, filters: Record<keyof T, string[]>, sorter: SorterResult<T>, extra: TableCurrentDataSource<T>) => void;

export interface DataTableAdapter {
    attach(table: TableDefinition<any>): void;
    render(): void;
    dataSource(): any[];
}

export class TableDefinition<T extends object> {

    public columns: ColumnDefinition<T>[];
    public paginationConfig: PaginationConfig;
    public loading?: boolean;

    private adapter: DataTableAdapter;

    private tableRowSelection: TableRowSelection<T>;

    private keySelector: (item: T) => string;

    private onSelectionChanged?: (items: T[]) => void;
    private onRowClicked?: (item: T) => void;

    public constructor(keySelector: (item: T) => string, selection?: T[], onSelectionChanged?: (items: T[]) => void, onRowClicked?: (item: T) => void, selectionMode = GridSelectionMode.Multiple) {

        this.keySelector = keySelector;
        this.onSelectionChanged = onSelectionChanged;
        this.onRowClicked = onRowClicked;
        this.rowClicked = this.rowClicked.bind(this);
        this.tableChanged = this.tableChanged.bind(this);
        this.onTableChanged = this.onTableChanged.bind(this);

        this.columns = [];

        if (this.onSelectionChanged) {
            this.tableRowSelection = {
                type: selectionMode === GridSelectionMode.Multiple ? 'checkbox' : 'radio',
                onChange: (_selectedRowKeys: string[] | number[], selectedRows: T[]) => this.onSelectionChanged(selectedRows),
                selectedRowKeys: selection?.map(x => this.keySelector(x))
            };
        }
    }

    public setAdapter(adapter: DataTableAdapter): void {
        this.adapter = adapter;
        this.adapter.attach(this);
    }

    private fieldSelectorToPath(fieldSelector: FieldSelector<T> | string): string {
        if (typeof fieldSelector ===  'string') {
            return fieldSelector;
        }

        const functionBody = fieldSelector.toString();
        const returnIndex = functionBody.indexOf('return') + 'return '.length;
        const semiColonIndex = functionBody.indexOf(';', returnIndex);

        const parts = functionBody.slice(returnIndex, semiColonIndex).split('.');
        const propertyPath = parts.slice(1).join('.');

        return propertyPath;
    }

    private addColumn(column: ColumnProps<T>, transformText?: (text: string) => string): ColumnDefinition<T> {

        let visible = true;
        let order = this.columns.length;

        const previous = this.columns.find(x => x.nativeColumn.dataIndex === column.dataIndex);
        if (previous != null) {
            visible = previous.visible;
            order = previous.order;
        } 

        const definition = new ColumnDefinition<T>(column, order, transformText);
        definition.visible = visible;

        this.columns.push(definition);

        return definition;
    }

    public addText(translation: string, fieldSelector: FieldSelector<T> | string, transformText?: (text: string) => string): ColumnDefinition<T> {
        const propertyPath = this.fieldSelectorToPath(fieldSelector);

        if (transformText == null) {
            transformText = (text) => text;
        }

        const column: ColumnProps<T> = {
            title: <Translate id={translation} />,
            dataIndex: propertyPath,
            key: propertyPath,
            render: (text: string, row: T) => {

                const value = getProperty(row, propertyPath);
                if (Array.isArray(value)) {
                    return value.map(transformText).join(', ');
                }

                return transformText(text);
            },
            sortDirections: ['descend', 'ascend'],
            sorter: (a: T, b: T) => this.compareField(a, b, (fieldA: string, fieldB: string) => {
                const valueA = transformText(fieldA);
                const valueB = transformText(fieldB);

                return valueA.localeCompare(valueB);
            }, propertyPath)
        };

        return this.addColumn(column, transformText);
    }

    public addDate(translation: string, fieldSelector: FieldSelector<T> | string): ColumnDefinition<T> {
        const propertyPath = this.fieldSelectorToPath(fieldSelector);

        const column: ColumnProps<T> = {
            title: <Translate id={translation} />,
            dataIndex: propertyPath,
            key: propertyPath,
            render: (text => moment(text).format('l')),
            align: 'right',
            sortDirections: ['descend', 'ascend'],
            width: '100px',
            sorter: (a: T, b: T) => this.compareField(a, b, (fieldA: string, fieldB: string) => moment(fieldA).diff(moment(fieldB)), propertyPath)
        };

        return this.addColumn(column);
    }

    public addTime(translation: string, fieldSelector: FieldSelector<T> | string): ColumnDefinition<T> {
        const propertyPath = this.fieldSelectorToPath(fieldSelector);

        const column: ColumnProps<T> = {
            title: <Translate id={translation} />,
            dataIndex: propertyPath,
            key: propertyPath,
            render: text => formatTime(duration(text)),
            align: 'right',
            sortDirections: ['descend', 'ascend'],
            width: '100px',
            sorter: (a: T, b: T) => this.compareField(a, b, (fieldA: string, fieldB: string) => duration(fieldA).asMilliseconds() - duration(fieldB).asMilliseconds(), propertyPath)
        };

        return this.addColumn(column);
    }

    public addBool(translation: string, fieldSelector: FieldSelector<T> | string): ColumnDefinition<T> {
        const { translate } = useTranslate();
        const propertyPath = this.fieldSelectorToPath(fieldSelector);
        const transformText = (value): string => {
            if (value === true) {
                return translate('dataGrid.true').toString();
            } else if (value === false) {
                return translate('dataGrid.false').toString();
            } else {
                return '';
            }
        };

        const column: ColumnProps<T> = {
            title: <Translate id={translation} />,
            dataIndex: propertyPath,
            key: propertyPath,
            render: (_text: string, row: T) => {
                const value = getProperty(row, propertyPath);
                return transformText(value);
            },
            sorter: (a: T, b: T) => this.compareField(a, b, (fieldA: boolean, fieldB: boolean) => {
                if (fieldA === true && fieldB === false) {
                    return 1;
                }

                if (fieldB === true) {
                    return -1;
                }

                return 0;
            }, propertyPath)
        };

        return this.addColumn(column, transformText);
    }

    private compareField(a: T, b: T, compare: (fieldA: any, fieldB: any) => number, propertyPath: string): number {
        let fieldA = getProperty(a, propertyPath);
        let fieldB = getProperty(b, propertyPath);

        if (fieldA != null && fieldB == null) {
            return 1;
        }

        if (fieldB != null && fieldA == null) {
            return -1;
        }

        if (fieldA == null && fieldB == null) {
            return 0;
        }

        if (Array.isArray(fieldA)) {
            fieldA = (fieldA as string[]).join('|');
            fieldB = (fieldB as string[]).join('|');
        }

        return compare(fieldA, fieldB);
    }

    private rowClicked(row: T): void {
        if (this.onRowClicked != null) {
            this.onRowClicked(row);
        }
        
        if (this.onSelectionChanged != null) {
            this.onSelectionChanged([row]);
        }
    }

    private onTableChangedHandlers = new Array<OnTableChangedCallback<T>>();

    public onTableChanged(onTableChange: OnTableChangedCallback<T>): void {
        this.onTableChangedHandlers.push(onTableChange);
    }

    private tableChanged(pagination: PaginationConfig, filters: Record<keyof T, string[]>, sorter: SorterResult<T>, extra: TableCurrentDataSource<T>): void {
        for (const handler of this.onTableChangedHandlers) {
            handler(pagination, filters, sorter, extra);
        }
    }

    public createTable(dataSource: T[]): React.ReactElement {
        this.adapter.render();
        const externalDataSource = this.adapter.dataSource();
        if (externalDataSource != null) {
            dataSource = externalDataSource;
        }

        const visibleColumns = this.columns.filter(x => x.visible);
        const orderedColumns = R.sortBy(x => x.order, visibleColumns);

        return <Table<T>
            rowSelection={this.tableRowSelection}
            pagination={this.paginationConfig}
            dataSource={dataSource}
            rowKey={this.keySelector}
            loading={this.loading}
            onRow={record => {
                return { 
                    onClick: () => this.rowClicked(record) 
                };
            }}
            size='middle'
            onChange={this.tableChanged}
            columns={orderedColumns.map(x => x.nativeColumn)} />;
    }

    private onColumnsChanged(options: ColumnOption[]): void {
        for (const option of options) {
            const column = this.columns.find(x => x.dataIndex() === option.key);
            column.order = option.order;
            column.visible = option.visible;
        }
    }

    public createColumnOptionsDrawer(visible: boolean, onSelect: (options: ColumnOption[]) => void, onCancel: () => void, onReset: () => void): React.ReactElement {
        return (
            <ColumnOptionsDrawer columns={this.columns} visible={visible}
                onSelect={options => {
                    this.onColumnsChanged(options);
                    onSelect(options);
                }}
                onCancel={onCancel}
                onReset={onReset} />
        );
    }
}