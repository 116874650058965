import moment from 'moment';

export interface TimeLineChild {
    childId: string;
    name?: string;
    dateOfBirth: moment.Moment;
}

export function onDeserializeTimeLineChild(data: TimeLineChild): TimeLineChild {
    if (data == null) {
        return data;
    }

    if (data.dateOfBirth != null) {
        data.dateOfBirth = moment(data.dateOfBirth);
    }

    return data;
}

export function onSerializeTimeLineChild(data: TimeLineChild): TimeLineChild {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    serialized.dateOfBirth = data.dateOfBirth?.toISOString() as unknown as moment.Moment;

    return serialized;
}
