import { UsersApi, AuthApi, ApplicationsApi, LocationsApi, GroupsApi, CarepackagesApi, TimeslotsApi } from '@/client/api';
import { apiUrlSelector } from './authentication/selectors';
import { ApplicationState } from './application-state';

export interface Dependencies {
    authApi(state: ApplicationState): AuthApi;
    usersApi(state: ApplicationState): UsersApi;
    applicationsApi(state: ApplicationState): ApplicationsApi;
    locationsApi(state: ApplicationState): LocationsApi;
    groupsApi(state: ApplicationState): GroupsApi;
    carePackagesApi(state: ApplicationState): CarepackagesApi;
    timeSlotsApi(state: ApplicationState): TimeslotsApi;
}

export const createDependencies = (): Dependencies => {
    return {
        authApi: state => new AuthApi(apiUrlSelector(state)),
        usersApi: state => new UsersApi(apiUrlSelector(state)),
        applicationsApi: state => new ApplicationsApi(apiUrlSelector(state)),
        locationsApi: state => new LocationsApi(apiUrlSelector(state)),
        groupsApi: state => new GroupsApi(apiUrlSelector(state)),
        carePackagesApi: state => new CarepackagesApi(apiUrlSelector(state)),
        timeSlotsApi: state => new TimeslotsApi(apiUrlSelector(state)),
    };
};