import { LocationsResponse } from '../models/locations-response';
import { GroupsResponse, onDeserializeGroupsResponse, onSerializeGroupsResponse } from '../models/groups-response';
import { LocationUsagesResponse, onDeserializeLocationUsagesResponse, onSerializeLocationUsagesResponse } from '../models/location-usages-response';
import moment from 'moment';
import qs from 'qs';
import globalAxios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { mergeHeaders, AxiosHeaders } from '../utilities/merge-headers';

export class LocationsApi {
    private baseUrl: string;

    public constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    public async get(headers?: AxiosHeaders): Promise<AxiosResponse<LocationsResponse>> {
        const request = {
            url: this.baseUrl + '/locations',
            method: 'GET',
            responseType: 'json',
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<LocationsResponse>(request);
        

        return response;
    }

    public async getLocationIdGroups(locationId: string, headers?: AxiosHeaders): Promise<AxiosResponse<GroupsResponse>> {
        const request = {
            url: this.baseUrl + '/locations/{locationId}/groups'
                .replace(`{${'locationId'}}`, encodeURIComponent(locationId)),
            method: 'GET',
            responseType: 'json',
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<GroupsResponse>(request);
        
        if (response.status === 200) {
            response.data = onDeserializeGroupsResponse(response.data);
        }

        return response;
    }

    public async getLocationIdUsages(locationId: string, start: moment.Moment, end: moment.Moment, headers?: AxiosHeaders): Promise<AxiosResponse<LocationUsagesResponse>> {
        const request = {
            url: this.baseUrl + '/locations/{locationId}/usages'
                .replace(`{${'locationId'}}`, encodeURIComponent(locationId)),
            method: 'GET',
            responseType: 'json',
            params: {
                start: start?.format('YYYY-MM-DD'),
                end: end?.format('YYYY-MM-DD'),
            },
            paramsSerializer: params => qs.stringify(params)
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<LocationUsagesResponse>(request);
        
        if (response.status === 200) {
            response.data = onDeserializeLocationUsagesResponse(response.data);
        }

        return response;
    }
}