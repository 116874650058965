import { RequestedTimeslot, onDeserializeRequestedTimeslot, onSerializeRequestedTimeslot} from './requested-timeslot';
import moment from 'moment';

export interface RequestSwapdayCommand {
    absentDate: moment.Moment;
    absentTimeslots: RequestedTimeslot[];
    presentDate: moment.Moment;
    presentTimeslots: RequestedTimeslot[];
    comments?: string;
}

export function onDeserializeRequestSwapdayCommand(data: RequestSwapdayCommand): RequestSwapdayCommand {
    if (data == null) {
        return data;
    }

    if (data.absentDate != null) {
        data.absentDate = moment(data.absentDate);
    }
    if (data.absentTimeslots != null) {
        for (let i = 0; i < data.absentTimeslots.length; i++) {
            if (data.absentTimeslots[i] != null) {
                data.absentTimeslots[i] = onDeserializeRequestedTimeslot(data.absentTimeslots[i]);
            }
        }
    }
    if (data.presentDate != null) {
        data.presentDate = moment(data.presentDate);
    }
    if (data.presentTimeslots != null) {
        for (let i = 0; i < data.presentTimeslots.length; i++) {
            if (data.presentTimeslots[i] != null) {
                data.presentTimeslots[i] = onDeserializeRequestedTimeslot(data.presentTimeslots[i]);
            }
        }
    }

    return data;
}

export function onSerializeRequestSwapdayCommand(data: RequestSwapdayCommand): RequestSwapdayCommand {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    serialized.absentDate = data.absentDate?.format('YYYY-MM-DD') as unknown as moment.Moment;
    if (data.absentTimeslots != null) {
        for (let i = 0; i < data.absentTimeslots.length; i++) {
            serialized.absentTimeslots[i] = onSerializeRequestedTimeslot(data.absentTimeslots[i]);
        }
    }      
    serialized.presentDate = data.presentDate?.format('YYYY-MM-DD') as unknown as moment.Moment;
    if (data.presentTimeslots != null) {
        for (let i = 0; i < data.presentTimeslots.length; i++) {
            serialized.presentTimeslots[i] = onSerializeRequestedTimeslot(data.presentTimeslots[i]);
        }
    }      

    return serialized;
}
