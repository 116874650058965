import React from 'react';
import { Button } from './button';
import { Button as AntButton } from 'antd';
import { ExtractReactProps } from '@/react/type-helpers';
import { ButtonProps } from './button';
import styled from 'styled-components';

type AntButtonProps = ExtractReactProps<AntButton>;

export function SaveButton(props: AntButtonProps): React.ReactElement {
    return <Button translation='global.button.save' type='primary' {...props} />;
}

export function CancelButton(props: AntButtonProps): React.ReactElement {
    return <Button translation='global.button.cancel' {...props} />;
}

export function SelectButton(props: AntButtonProps): React.ReactElement {
    return <Button translation='global.button.select' type='primary' {...props} />;
}

export function CloseButton(props: AntButtonProps): React.ReactElement {
    return <Button translation='global.button.close' {...props} />;
}

export function ResetButton(props: AntButtonProps): React.ReactElement {
    return <Button translation='global.button.reset' {...props} />;
}

export function SmallButton(props: ButtonProps): React.ReactElement {
    return <SmallButtonComponent size='small' htmlType='button' {...props}></SmallButtonComponent>;
}

export function SmallPrimaryButton(props: ButtonProps): React.ReactElement {
    return <SmallButtonComponent size='small' type='primary' htmlType='button' {...props}></SmallButtonComponent>;
}

export function ButtonBar(props: React.PropsWithChildren<{}>): React.ReactElement {
    return (
        <BottomButtonBar>
            {props.children}
        </BottomButtonBar>
    );
}

const BottomButtonBar = styled.div`
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e9e9e9;
    padding: 10px 16px;
    background: #fff;

    * {
        margin-right: 5px
    }
`;

const SmallButtonComponent = styled(Button)`
    width: 90px;
    margin-right: 8px;
`;