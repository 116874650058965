import { UserReference} from './user-reference';
import moment from 'moment';

export interface ActionReference {
    on: moment.Moment;
    by?: UserReference;
}

export function onDeserializeActionReference(data: ActionReference): ActionReference {
    if (data == null) {
        return data;
    }

    if (data.on != null) {
        data.on = moment(data.on);
    }

    return data;
}

export function onSerializeActionReference(data: ActionReference): ActionReference {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    serialized.on = data.on?.toISOString() as unknown as moment.Moment;

    return serialized;
}
