import { ChildModel, onDeserializeChildModel, onSerializeChildModel } from '../models/child-model';
import { CreateOrUpdateAbsenceCommand, onDeserializeCreateOrUpdateAbsenceCommand, onSerializeCreateOrUpdateAbsenceCommand } from '../models/create-or-update-absence-command';
import { OrganizationContactsResponse } from '../models/organization-contacts-response';
import { ContractsListModel, onDeserializeContractsListModel, onSerializeContractsListModel } from '../models/contracts-list-model';
import { CreateOrUpdateHolidayCommand, onDeserializeCreateOrUpdateHolidayCommand, onSerializeCreateOrUpdateHolidayCommand } from '../models/create-or-update-holiday-command';
import { MonthPlanningModel, onDeserializeMonthPlanningModel, onSerializeMonthPlanningModel } from '../models/month-planning-model';
import { PostsList, onDeserializePostsList, onSerializePostsList } from '../models/posts-list';
import { PresencesModel, onDeserializePresencesModel, onSerializePresencesModel } from '../models/presences-model';
import { DocumentsModel, onDeserializeDocumentsModel, onSerializeDocumentsModel } from '../models/documents-model';
import { ChildRegistrationsResponse, onDeserializeChildRegistrationsResponse, onSerializeChildRegistrationsResponse } from '../models/child-registrations-response';
import { KindPlannerRegistrationStatus } from '../models/kind-planner-registration-status';
import { RequestIncidentalCommand, onDeserializeRequestIncidentalCommand, onSerializeRequestIncidentalCommand } from '../models/request-incidental-command';
import { RequestSwapdayCommand, onDeserializeRequestSwapdayCommand, onSerializeRequestSwapdayCommand } from '../models/request-swapday-command';
import { TimelineModel, onDeserializeTimelineModel, onSerializeTimelineModel } from '../models/timeline-model';
import { TimelineMediaModel, onDeserializeTimelineMediaModel, onSerializeTimelineMediaModel } from '../models/timeline-media-model';
import { TimesModel, onDeserializeTimesModel, onSerializeTimesModel } from '../models/times-model';
import moment from 'moment';
import qs from 'qs';
import globalAxios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { mergeHeaders, AxiosHeaders } from '../utilities/merge-headers';

export class ChildrenApi {
    private baseUrl: string;

    public constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    public async getChildId(childId: string, headers?: AxiosHeaders): Promise<AxiosResponse<ChildModel>> {
        const request = {
            url: this.baseUrl + '/children/{childId}'
                .replace(`{${'childId'}}`, encodeURIComponent(childId)),
            method: 'GET',
            responseType: 'json',
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<ChildModel>(request);
        
        if (response.status === 200) {
            response.data = onDeserializeChildModel(response.data);
        }

        return response;
    }

    public async postChildIdAbsences(childId: string, data: CreateOrUpdateAbsenceCommand, headers?: AxiosHeaders): Promise<AxiosResponse<void>> {
        const request = {
            url: this.baseUrl + '/children/{childId}/absences'
                .replace(`{${'childId'}}`, encodeURIComponent(childId)),
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);

        request.data = onSerializeCreateOrUpdateAbsenceCommand(data);

        const response = await globalAxios.request<void>(request);
        

        return response;
    }

    public async getChildIdContactinfo(childId: string, headers?: AxiosHeaders): Promise<AxiosResponse<OrganizationContactsResponse>> {
        const request = {
            url: this.baseUrl + '/children/{childId}/contactinfo'
                .replace(`{${'childId'}}`, encodeURIComponent(childId)),
            method: 'GET',
            responseType: 'json',
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<OrganizationContactsResponse>(request);
        

        return response;
    }

    public async getChildIdContracts(childId: string, headers?: AxiosHeaders): Promise<AxiosResponse<ContractsListModel>> {
        const request = {
            url: this.baseUrl + '/children/{childId}/contracts'
                .replace(`{${'childId'}}`, encodeURIComponent(childId)),
            method: 'GET',
            responseType: 'json',
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<ContractsListModel>(request);
        
        if (response.status === 200) {
            response.data = onDeserializeContractsListModel(response.data);
        }

        return response;
    }

    public async postChildIdHolidays(childId: string, data: CreateOrUpdateHolidayCommand, headers?: AxiosHeaders): Promise<AxiosResponse<void>> {
        const request = {
            url: this.baseUrl + '/children/{childId}/holidays'
                .replace(`{${'childId'}}`, encodeURIComponent(childId)),
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);

        request.data = onSerializeCreateOrUpdateHolidayCommand(data);

        const response = await globalAxios.request<void>(request);
        

        return response;
    }

    public async getChildIdPlanningYearMonth(childId: string, year: number, month: number, headers?: AxiosHeaders): Promise<AxiosResponse<MonthPlanningModel>> {
        const request = {
            url: this.baseUrl + '/children/{childId}/planning/{year}/{month}'
                .replace(`{${'childId'}}`, encodeURIComponent(childId))
                .replace(`{${'year'}}`, encodeURIComponent(year))
                .replace(`{${'month'}}`, encodeURIComponent(month)),
            method: 'GET',
            responseType: 'json',
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<MonthPlanningModel>(request);
        
        if (response.status === 200) {
            response.data = onDeserializeMonthPlanningModel(response.data);
        }

        return response;
    }

    public async getChildIdPostsRemindersDate(childId: string, date: moment.Moment, headers?: AxiosHeaders): Promise<AxiosResponse<PostsList>> {
        const request = {
            url: this.baseUrl + '/children/{childId}/posts/reminders/{date}'
                .replace(`{${'childId'}}`, encodeURIComponent(childId))
                .replace(`{${'date'}}`, encodeURIComponent(date.format('YYYY-MM-DD'))),
            method: 'GET',
            responseType: 'json',
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<PostsList>(request);
        
        if (response.status === 200) {
            response.data = onDeserializePostsList(response.data);
        }

        return response;
    }

    public async getChildIdPresences(childId: string, from?: moment.Moment, to?: moment.Moment, headers?: AxiosHeaders): Promise<AxiosResponse<PresencesModel>> {
        const request = {
            url: this.baseUrl + '/children/{childId}/presences'
                .replace(`{${'childId'}}`, encodeURIComponent(childId)),
            method: 'GET',
            responseType: 'json',
            params: {
                from: from?.format('YYYY-MM-DD'),
                to: to?.format('YYYY-MM-DD'),
            },
            paramsSerializer: params => qs.stringify(params)
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<PresencesModel>(request);
        
        if (response.status === 200) {
            response.data = onDeserializePresencesModel(response.data);
        }

        return response;
    }

    public async getChildIdPublications(childId: string, headers?: AxiosHeaders): Promise<AxiosResponse<DocumentsModel>> {
        const request = {
            url: this.baseUrl + '/children/{childId}/publications'
                .replace(`{${'childId'}}`, encodeURIComponent(childId)),
            method: 'GET',
            responseType: 'json',
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<DocumentsModel>(request);
        
        if (response.status === 200) {
            response.data = onDeserializeDocumentsModel(response.data);
        }

        return response;
    }

    public async getChildIdRegistrations(childId: string, start: moment.Moment, end?: moment.Moment, status?: KindPlannerRegistrationStatus[], headers?: AxiosHeaders): Promise<AxiosResponse<ChildRegistrationsResponse>> {
        const request = {
            url: this.baseUrl + '/children/{childId}/registrations'
                .replace(`{${'childId'}}`, encodeURIComponent(childId)),
            method: 'GET',
            responseType: 'json',
            params: {
                start: start?.format('YYYY-MM-DD'),
                end: end?.format('YYYY-MM-DD'),
                status: status,
            },
            paramsSerializer: params => qs.stringify(params)
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<ChildRegistrationsResponse>(request);
        
        if (response.status === 200) {
            response.data = onDeserializeChildRegistrationsResponse(response.data);
        }

        return response;
    }

    public async postChildIdRegistrationsIncidentaldays(childId: string, data: RequestIncidentalCommand, headers?: AxiosHeaders): Promise<AxiosResponse<void>> {
        const request = {
            url: this.baseUrl + '/children/{childId}/registrations/incidentaldays'
                .replace(`{${'childId'}}`, encodeURIComponent(childId)),
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);

        request.data = onSerializeRequestIncidentalCommand(data);

        const response = await globalAxios.request<void>(request);
        

        return response;
    }

    public async postChildIdRegistrationsSwapdays(childId: string, data: RequestSwapdayCommand, headers?: AxiosHeaders): Promise<AxiosResponse<void>> {
        const request = {
            url: this.baseUrl + '/children/{childId}/registrations/swapdays'
                .replace(`{${'childId'}}`, encodeURIComponent(childId)),
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);

        request.data = onSerializeRequestSwapdayCommand(data);

        const response = await globalAxios.request<void>(request);
        

        return response;
    }

    public async postChildIdRequestsIncidentaldays(childId: string, data: RequestIncidentalCommand, headers?: AxiosHeaders): Promise<AxiosResponse<void>> {
        const request = {
            url: this.baseUrl + '/children/{childId}/requests/incidentaldays'
                .replace(`{${'childId'}}`, encodeURIComponent(childId)),
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);

        request.data = onSerializeRequestIncidentalCommand(data);

        const response = await globalAxios.request<void>(request);
        

        return response;
    }

    public async postChildIdRequestsSwapdays(childId: string, data: RequestSwapdayCommand, headers?: AxiosHeaders): Promise<AxiosResponse<void>> {
        const request = {
            url: this.baseUrl + '/children/{childId}/requests/swapdays'
                .replace(`{${'childId'}}`, encodeURIComponent(childId)),
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);

        request.data = onSerializeRequestSwapdayCommand(data);

        const response = await globalAxios.request<void>(request);
        

        return response;
    }

    public async getChildIdTimeline(childId: string, start: moment.Moment, end: moment.Moment, headers?: AxiosHeaders): Promise<AxiosResponse<TimelineModel>> {
        const request = {
            url: this.baseUrl + '/children/{childId}/timeline'
                .replace(`{${'childId'}}`, encodeURIComponent(childId)),
            method: 'GET',
            responseType: 'json',
            params: {
                start: start?.format('YYYY-MM-DD'),
                end: end?.format('YYYY-MM-DD'),
            },
            paramsSerializer: params => qs.stringify(params)
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<TimelineModel>(request);
        
        if (response.status === 200) {
            response.data = onDeserializeTimelineModel(response.data);
        }

        return response;
    }

    public async getChildIdTimelineMedia(childId: string, start: moment.Moment, end: moment.Moment, headers?: AxiosHeaders): Promise<AxiosResponse<TimelineMediaModel>> {
        const request = {
            url: this.baseUrl + '/children/{childId}/timeline/media'
                .replace(`{${'childId'}}`, encodeURIComponent(childId)),
            method: 'GET',
            responseType: 'json',
            params: {
                start: start?.format('YYYY-MM-DD'),
                end: end?.format('YYYY-MM-DD'),
            },
            paramsSerializer: params => qs.stringify(params)
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<TimelineMediaModel>(request);
        
        if (response.status === 200) {
            response.data = onDeserializeTimelineMediaModel(response.data);
        }

        return response;
    }

    public async getChildIdTimesDateAvailable(childId: string, date: moment.Moment, headers?: AxiosHeaders): Promise<AxiosResponse<TimesModel>> {
        const request = {
            url: this.baseUrl + '/children/{childId}/times/{date}/available'
                .replace(`{${'childId'}}`, encodeURIComponent(childId))
                .replace(`{${'date'}}`, encodeURIComponent(date.format('YYYY-MM-DD'))),
            method: 'GET',
            responseType: 'json',
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<TimesModel>(request);
        
        if (response.status === 200) {
            response.data = onDeserializeTimesModel(response.data);
        }

        return response;
    }

    public async getChildIdTimesDateTradable(childId: string, date: moment.Moment, headers?: AxiosHeaders): Promise<AxiosResponse<TimesModel>> {
        const request = {
            url: this.baseUrl + '/children/{childId}/times/{date}/tradable'
                .replace(`{${'childId'}}`, encodeURIComponent(childId))
                .replace(`{${'date'}}`, encodeURIComponent(date.format('YYYY-MM-DD'))),
            method: 'GET',
            responseType: 'json',
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<TimesModel>(request);
        
        if (response.status === 200) {
            response.data = onDeserializeTimesModel(response.data);
        }

        return response;
    }
}