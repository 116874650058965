import React, { useState } from 'react';

import { ScreenContainer } from '@/screens/screen-container';
import { useSelector } from 'react-redux';
import { apiUrlSelector, tenantSelector } from '@/store/authentication/selectors';
import { TimeLineApi } from '@/client/api';
import { TimeLineResponse, TimeLineItem, CorruptedRecord, ValidatedRecord } from '@/client/models';
import { DataGrid, DataGridColumn, DataGridColumnOption, ColumnPicker, Option } from '@/data-grid';
import { timeSlotsSelector } from '@/store/general/selectors';
import R from 'ramda';

import Search from 'antd/lib/input/Search';
import { Tabs, Row, Spin } from 'antd';
import { Title } from '@/components/title';
import { useParams } from '@/router/react-router-hooks';
import styled from 'styled-components';
import { ToolOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;

const defaultColumns = ['sourceType',
    'name',
    'startDate',
    'endDate',
    'tijdblokId',
    'times.from',
    'times.till',
    'group.name',
    'school.name',
    'expectedTimes.from',
    'expectedTimes.till'];

type InfoItem = { record?: ValidatedRecord | CorruptedRecord };

interface InfoPanelProps {
    title: string;
    descriptionField: string;
    source: InfoItem[];
}


const InfoPanel: React.FC<InfoPanelProps> = props => {
    const { title, descriptionField, source } = props;
    const tenant = useSelector(tenantSelector);

    function navigateToSource(item: InfoItem): void {
        window.open(`https://backoffice-${tenant}.kindplanner.nl/Forms/frm${item.record.sourceType}.aspx?id=${item.record.sourceId}`);
    }

    return (
        <React.Fragment>
            <Title text={title} />
            <DataGrid<InfoItem> datasource={source} keySelector={x => x.record.sourceId} onRowClicked={navigateToSource}>
                <DataGridColumn type='Text' translation='tools.fields.source-type' path='record.sourceType' translateTemplate='tools.source-type.{value}'>
                    <DataGridColumnOption translation='tools.source-type.chiRegistration' value='chiRegistration' />
                    <DataGridColumnOption translation='tools.source-type.chiPlanning' value='chiPlanning' />
                    <DataGridColumnOption translation='tools.source-type.chiLocatiesluitingsdag' value='chiLocatiesluitingsdag' />
                    <DataGridColumnOption translation='tools.source-type.chiRuildag' value='chiRuildag' />
                    <DataGridColumnOption translation='tools.source-type.chiAfwezig' value='chiAfwezig' />
                    <DataGridColumnOption translation='tools.source-type.chiStudiedag' value='chiStudiedag' />
                    <DataGridColumnOption translation='tools.source-type.chiStudiedagRegistratie' value='chiStudiedagRegistratie' />
                    <DataGridColumnOption translation='tools.source-type.chiWeekregistration' value='chiWeekregistration' />
                    <DataGridColumnOption translation='tools.source-type.chiIncidenteleOpvang' value='chiIncidenteleOpvang' />
                </DataGridColumn>
                <DataGridColumn type='Text' translation={`tools.fields.${descriptionField}`} path={descriptionField} />
                <DataGridColumn type='Text' translation='tools.fields.name' path='record.name' />
            </DataGrid>
        </React.Fragment>
    );
};

interface TimeLinePanelProps {
    closeDrawer: () => void;
    showDrawer: boolean;
    source: TimeLineItem[];
}

const TimeLinePanel: React.FC<TimeLinePanelProps> = props => {
    const { closeDrawer, showDrawer, source } = props;

    const timeSlots = useSelector(timeSlotsSelector);
    const tenant = useSelector(tenantSelector);

    function navigateToSource(item: TimeLineItem): void {
        window.open(`https://backoffice-${tenant}.kindplanner.nl/Forms/frm${item.sourceType}.aspx?id=${item.sourceId}`);
    }

    const usedSchools = R.uniqBy(x => x.school.id, source.filter(x => x.school != null)).map<Option>(x => ({
        text: x.school.name ?? x.school.id,
        value: x.school.name ?? x.school.id
    }));

    const usedGroups = R.uniqBy(x => x.group.id, source.filter(x => x.group != null)).map<Option>(x => ({
        text: x.group.name ?? x.group.id,
        value: x.group.name ?? x.group.id
    }));

    const usedTimeSlots = R.uniqBy(x => x.tijdblokId, source.filter(x => x.tijdblokId != null)).map<Option>(x => ({
        text: timeSlots.find(y => y.timeSlotId === x.tijdblokId).name,
        value: x.tijdblokId
    }));

    function generateKey(item: TimeLineItem): string {
        let baseKey = `${item.sourceId}-${item.sourceType}-${item.tijdblokId}-${item.startDate}-${item.endDate}-${item.presenceType}`;

        if (item.times != null) {
            baseKey += `-${item.times.from}-${item.times.till}`;
        }

        if (item.group != null) {
            baseKey += `-${item.group.id}`;
        }

        if (item.originalGroup != null) {
            baseKey += `-${item.originalGroup.id}`;
        }

        return baseKey;
    }

    return (
        <React.Fragment>
            <DataGrid name='timeline' datasource={source} keySelector={generateKey} defaultColumns={defaultColumns} onRowClicked={navigateToSource}>
                <ColumnPicker visible={showDrawer} onClose={closeDrawer} />

                <DataGridColumn type='Text' translation='tools.fields.source-type' path='sourceType' translateTemplate='tools.source-type.{value}'>
                    <DataGridColumnOption translation='tools.source-type.chiRegistration' value='chiRegistration' />
                    <DataGridColumnOption translation='tools.source-type.chiPlanning' value='chiPlanning' />
                    <DataGridColumnOption translation='tools.source-type.chiLocatiesluitingsdag' value='chiLocatiesluitingsdag' />
                    <DataGridColumnOption translation='tools.source-type.chiRuildag' value='chiRuildag' />
                    <DataGridColumnOption translation='tools.source-type.chiAfwezig' value='chiAfwezig' />
                    <DataGridColumnOption translation='tools.source-type.chiStudiedag' value='chiStudiedag' />
                    <DataGridColumnOption translation='tools.source-type.chiStudiedagRegistratie' value='chiStudiedagRegistratie' />
                    <DataGridColumnOption translation='tools.source-type.chiWeekregistration' value='chiWeekregistration' />
                    <DataGridColumnOption translation='tools.source-type.chiIncidenteleOpvang' value='chiIncidenteleOpvang' />
                </DataGridColumn>
                <DataGridColumn type='Text' translation='tools.fields.name' path='name' filter />
                <DataGridColumn type='Date' translation='tools.fields.start-date' path='startDate' filter />
                <DataGridColumn type='Date' translation='tools.fields.end-date' path='endDate' defaultSortOrder="descend" filter />
                <DataGridColumn type='Text' translation='tools.fields.day-of-week' path='tijdblokId'
                    onValue={x => x != null ? timeSlots.find(y => y.timeSlotId === x).name : null}
                    dropdown={{ source: usedTimeSlots, allowNone: true }} />
                <DataGridColumn type='Time' translation='tools.fields.time-from' path='times.from' />
                <DataGridColumn type='Time' translation='tools.fields.time-till' path='times.till' />
                <DataGridColumn type='Text' translation='tools.fields.group' path='group.name' dropdown={{ source: usedGroups, allowNone: true }} />
                <DataGridColumn type='Text' translation='tools.fields.school' path='school.name' dropdown={{ source: usedSchools, allowNone: true }} />                
                <DataGridColumn type='Time' translation='tools.fields.time-expected-from' path='expectedTimes.from' />
                <DataGridColumn type='Time' translation='tools.fields.time-expected-till' path='expectedTimes.till' />
            </DataGrid>
        </React.Fragment>
    );
};

export function ChildTimeLineScreen(): React.ReactElement {
    const apiUrl = useSelector(apiUrlSelector);
    const params = useParams();

    const [drawerVisible, setDrawerVisible] = useState(false);
    const [childId, setChildId] = useState(params['childId']);
    const [timeLineResponse, setTimeLineResponse] = useState({ timeLine: [] } as TimeLineResponse);
    const [isLoading, setIsLoading] = useState(false);

    function search(): void {
        setIsLoading(true);

        const client = new TimeLineApi(apiUrl);
        client.getChildId(childId).then(response => {
            if (response.status === 200) {
                setTimeLineResponse(response.data);
            }

            setIsLoading(false);
        });
    }

    return (
        <ScreenContainer menuItems={[
            {
                icon: <ToolOutlined />,
                translation: 'dataGrid.columnOptions',
                position: 'right',
                iconOnly: true,
                onClick: () => setDrawerVisible(true)
            },
        ]}>
            <MarginContainer>
                <Search placeholder="Kind ID" onChange={e => setChildId(e.target.value)} value={childId} onSearch={search} enterButton />
            </MarginContainer>
            <MarginContainer>
                <Spin spinning={isLoading}>
                    <TabContainer defaultActiveKey="1" hidden={childId == null}>
                        <TabPane tab="Info" key="1">
                            <Row>
                                <InfoPanel title="Skipped" descriptionField="reason" source={timeLineResponse.skipped} />
                            </Row>
                            <Row>
                                <InfoPanel title="Validations" descriptionField="error" source={timeLineResponse.validations} />
                            </Row>
                            <Row>
                                <InfoPanel title="Corruptions" descriptionField="error" source={timeLineResponse.corruptions} />
                            </Row>
                        </TabPane>
                        <TabPane tab="Tijdlijn" key="2">
                            <TimeLinePanel showDrawer={drawerVisible} closeDrawer={() => setDrawerVisible(false)} source={timeLineResponse.timeLine} />
                        </TabPane>
                    </TabContainer>
                </Spin>
            </MarginContainer>
        </ScreenContainer>
    );
}

const TabContainer = styled(Tabs) <{ hidden: boolean }>`
    display: ${props => props.hidden ? 'none' : 'block'}
`;

const MarginContainer = styled.div`
    margin-top: 25px;
`;