import { ActionReference, onDeserializeActionReference, onSerializeActionReference} from './action-reference';
import { SenderType } from './sender-type';

export interface TimelinePostItem {
    postId: string;
    title: string;
    childId?: string;
    numberOfReplies: number;
    regarding: string;
    created: ActionReference;
    senderType?: SenderType;
}

export function onDeserializeTimelinePostItem(data: TimelinePostItem): TimelinePostItem {
    if (data == null) {
        return data;
    }

    if (data.created != null) {
        data.created = onDeserializeActionReference(data.created);
    }

    return data;
}

export function onSerializeTimelinePostItem(data: TimelinePostItem): TimelinePostItem {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    serialized.created = onSerializeActionReference(data.created);

    return serialized;
}
