import React from 'react';
import styled from 'styled-components';
import { Menu } from 'antd';
import { useTranslate } from '@/language-provider';
import { PageNavigation, PageRoute, usePageNavigation } from '@/router/routes';

export enum Position {
    left = 'left',
    right = 'right'
}

export enum IconSize {
    small = 10,
    normal = 16,
    large = 20,
}

export interface ActionBarItemProps {
    position?: Position | keyof typeof Position;
    translation: string;
    icon?: React.ReactElement;
    iconSize?: number | keyof typeof IconSize;
    route?: PageRoute;
    onClick?: () => void;
    isEnabled?: boolean;
    iconOnly?: boolean;
    isBack?: boolean;
}

export class ActionBarItem extends React.PureComponent<ActionBarItemProps> {
}

export interface ActionBarProps {
    children?: React.ReactElement<ActionBarItemProps>[] | React.ReactElement<ActionBarItemProps>;
    menuItems?: ActionBarItemProps[];
}

function callThisBefore(call: () => void, before: () => void): () => void {
    if (call == null) {
        return before;
    }

    if (before == null) {
        return call;
    }

    return (): void => { call(); before(); };
}

function createClickAction(navigation: PageNavigation, props: ActionBarItemProps): () => void {
    const { push, back } = navigation;

    const clickAction = props.onClick;

    let navigateAction = undefined;
    if (props.isBack ?? false) {
        navigateAction = (): void => back();
        if (history.length <= 1 && props.route != null) {
            navigateAction = (): void => push(props.route);
        }
    } else if (props.route != null) {
        navigateAction = (): void => push(props.route);
    }

    if (navigateAction != null) {
        return callThisBefore(clickAction, navigateAction);
    }

    return clickAction;
}

export function ActionBar(props: ActionBarProps): React.ReactElement {

    const navigate = usePageNavigation();
    const { translate } = useTranslate();

    let { children } = props;

    if (!Array.isArray(children)) {
        children = [children];
    }

    return (
        <StyledMenu mode='horizontal' selectable={false}>
            {children.map(x => {

                const { position = Position.left, iconOnly = false, isEnabled = true, iconSize = IconSize.normal, translation } = x.props;

                let icon = undefined;
                if (x.props.icon != null) {
                    icon = React.cloneElement(x.props.icon, { ...x.props.icon.props, style: { fontSize: `${iconSize}px` } });
                }

                const positionClass = position === 'right' ? 'itemRight' : undefined;

                const clickAction = createClickAction(navigate, x.props);

                const translated = translate(translation).toString();

                return (
                    <Menu.Item key={translation} onClick={clickAction} className={positionClass} disabled={!isEnabled} title={translated}>
                        {icon}
                        {!iconOnly && <span>{translated}</span>}
                    </Menu.Item>
                );
            })}
        </StyledMenu>
    );
}

const StyledMenu = styled(Menu)`
    border-top: none;

    .itemRight {
        float: right;
    }
`;