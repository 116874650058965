import { UserModel } from '../models/user-model';
import { UserDeviceModel } from '../models/user-device-model';
import { SetLanguageCommand } from '../models/set-language-command';
import { UserNotificationsModel } from '../models/user-notifications-model';
import globalAxios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { mergeHeaders, AxiosHeaders } from '../utilities/merge-headers';

export class UsersApi {
    private baseUrl: string;

    public constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    public async getMe(headers?: AxiosHeaders): Promise<AxiosResponse<UserModel>> {
        const request = {
            url: this.baseUrl + '/users/me',
            method: 'GET',
            responseType: 'json',
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<UserModel>(request);
        

        return response;
    }

    public async postMeDevices(data: UserDeviceModel, headers?: AxiosHeaders): Promise<AxiosResponse<void>> {
        const request = {
            url: this.baseUrl + '/users/me/devices',
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);

        request.data = data;

        const response = await globalAxios.request<void>(request);
        

        return response;
    }

    public async deleteMeDevicesPushId(pushId: string, headers?: AxiosHeaders): Promise<AxiosResponse<void>> {
        const request = {
            url: this.baseUrl + '/users/me/devices/{pushId}'
                .replace(`{${'pushId'}}`, encodeURIComponent(pushId)),
            method: 'DELETE',
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<void>(request);
        

        return response;
    }

    public async postMeLanguage(data: SetLanguageCommand, headers?: AxiosHeaders): Promise<AxiosResponse<void>> {
        const request = {
            url: this.baseUrl + '/users/me/language',
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);

        request.data = data;

        const response = await globalAxios.request<void>(request);
        

        return response;
    }

    public async postMeNotifications(data: UserNotificationsModel, headers?: AxiosHeaders): Promise<AxiosResponse<void>> {
        const request = {
            url: this.baseUrl + '/users/me/notifications',
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);

        request.data = data;

        const response = await globalAxios.request<void>(request);
        

        return response;
    }
}