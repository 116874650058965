import { AbsenceType } from './absence-type';
import moment from 'moment';

export interface CreateOrUpdateAbsenceCommand {
    description?: string;
    type: AbsenceType;
    start?: moment.Moment;
    end?: moment.Moment;
}

export function onDeserializeCreateOrUpdateAbsenceCommand(data: CreateOrUpdateAbsenceCommand): CreateOrUpdateAbsenceCommand {
    if (data == null) {
        return data;
    }

    if (data.start != null) {
        data.start = moment(data.start);
    }
    if (data.end != null) {
        data.end = moment(data.end);
    }

    return data;
}

export function onSerializeCreateOrUpdateAbsenceCommand(data: CreateOrUpdateAbsenceCommand): CreateOrUpdateAbsenceCommand {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    serialized.start = data.start?.toISOString() as unknown as moment.Moment;
    serialized.end = data.end?.toISOString() as unknown as moment.Moment;

    return serialized;
}
