import { CareTimesResponse, onDeserializeCareTimesResponse, onSerializeCareTimesResponse } from '../models/care-times-response';
import { CareType } from '../models/care-type';
import { CareTimeFullDetailsResponse, onDeserializeCareTimeFullDetailsResponse, onSerializeCareTimeFullDetailsResponse } from '../models/care-time-full-details-response';
import qs from 'qs';
import globalAxios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { mergeHeaders, AxiosHeaders } from '../utilities/merge-headers';

export class CaretimesApi {
    private baseUrl: string;

    public constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    public async get(careType?: CareType, locationId?: string, schoolId?: string, packageId?: string, headers?: AxiosHeaders): Promise<AxiosResponse<CareTimesResponse>> {
        const request = {
            url: this.baseUrl + '/caretimes',
            method: 'GET',
            responseType: 'json',
            params: {
                careType: careType,
                locationId: locationId,
                schoolId: schoolId,
                packageId: packageId,
            },
            paramsSerializer: params => qs.stringify(params)
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<CareTimesResponse>(request);
        
        if (response.status === 200) {
            response.data = onDeserializeCareTimesResponse(response.data);
        }

        return response;
    }

    public async getCareTimeId(careTimeId: string, headers?: AxiosHeaders): Promise<AxiosResponse<CareTimeFullDetailsResponse>> {
        const request = {
            url: this.baseUrl + '/caretimes/{careTimeId}'
                .replace(`{${'careTimeId'}}`, encodeURIComponent(careTimeId)),
            method: 'GET',
            responseType: 'json',
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<CareTimeFullDetailsResponse>(request);
        
        if (response.status === 200) {
            response.data = onDeserializeCareTimeFullDetailsResponse(response.data);
        }

        return response;
    }
}