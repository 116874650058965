import { State } from './state';
import { loadingValue, loadedValue, errorValue, initValue, combine, loadedAction, loadingAction, errorAction } from '../retrieved-value';
import { Actions, PlanboardActions } from './actions';
import { isActionFromContainer } from '@/store/action';
import { LocationUsagesResponse } from '@/client/models';

const initialState: State = {
    scoringOptions: {
        createdDistance: 2,
        startingDistance: 2,
        sibling: 2
    },
    openApplications: initValue(),
    reservedApplications: initValue(),
    applicationDetails: initValue(),
    locationUsages: [],
    
    plannerStatus: loadedAction(),
    offerContract: loadedAction(),
    reserveApplication: loadedAction(),
    inProgressApplication: loadedAction()
};

export const reducer = (state: State = initialState, action: Actions): State => {
    if (!isActionFromContainer(action, PlanboardActions)) {
        return state;
    }

    switch (action.action) {

        case 'initializeScheduleParts':
            return { ...state, scheduleParts: action.payload.scheduleParts };

        case 'refreshOpenApplications':
            return { ...state, openApplications: loadingValue(state.openApplications.value, state.openApplications.paging) };
        case 'loadOpenApplications':
            return { ...state, openApplications: loadingValue(state.openApplications.value, action.payload.paging) };
        case 'loadOpenApplicationsSuccess':
            return { ...state, openApplications: loadedValue(action.payload.applications, action.payload.paging) };
        case 'loadOpenApplicationsFailed':
            return { ...state, openApplications: errorValue(action.payload.error, state.openApplications.value, state.openApplications.paging) };

        case 'refreshReservedApplications':
            return { ...state, reservedApplications: loadingValue(state.reservedApplications.value, state.reservedApplications.paging) };
        case 'loadReservedApplications':
            return { ...state, reservedApplications: loadingValue(state.reservedApplications.value, action.payload.paging) };
        case 'loadReservedApplicationsSuccess':
            return { ...state, reservedApplications: loadedValue(action.payload.applications, action.payload.paging) };
        case 'loadReservedApplicationsFailed':
            return { ...state, reservedApplications: errorValue(action.payload.error, state.reservedApplications.value, state.reservedApplications.paging) };
            
        case 'loadApplicationDetails':
            return { ...state, applicationDetails: loadingValue(state.applicationDetails.value) };
        case 'loadApplicationDetailsSuccess':
            return { ...state, applicationDetails: loadedValue(action.payload.applicationDetails) };
        case 'loadApplicationDetailsFailed':
            return { ...state, applicationDetails: errorValue(action.payload.error, state.applicationDetails.value) };

        case 'storeScoringOptions':
            return { ...state, scoringOptions: action.payload.options };

        case 'selectApplications':
            return { ...state, selectedApplications: action.payload.applications.map(x => x.applicationId) };

        case 'loadUsages':
            return { ...state, locationUsages: combine(state.locationUsages, loadingValue({ locationId: action.payload.locationId } as LocationUsagesResponse), x => x.locationId) };
        case 'loadUsagesSuccess':
            return { ...state, locationUsages: combine(state.locationUsages, loadedValue(action.payload.response), x => x.locationId) };
        case 'loadUsagesFailed':
            return { ...state, locationUsages: combine(state.locationUsages, errorValue(action.payload.error), x => x.locationId) };

        case 'saveSchedulePart':
            return { ...state, plannerStatus: loadingAction() };
        case 'saveSchedulePartSuccess':
            return { ...state, plannerStatus: loadedAction() };
        case 'saveSchedulePartFailed':
            return { ...state, plannerStatus: errorAction(action.payload.error) };
            
        case 'offerContract':
            return { ...state, offerContract: loadingAction() };
        case 'offerContractSuccess':
            return { ...state, offerContract: loadedAction() };
        case 'offerContractFailed':
            return { ...state, offerContract: errorAction(action.payload.error) };
            
        case 'reserveApplication':
            return { ...state, reserveApplication: loadingAction() };
        case 'reserveApplicationSuccess':
            return { ...state, reserveApplication: loadedAction() };
        case 'reserveApplicationFailed':
            return { ...state, reserveApplication: errorAction(action.payload.error) };
            
        case 'inProgressApplication':
            return { ...state, inProgressApplication: loadingAction() };
        case 'inProgressApplicationSuccess':
            return { ...state, inProgressApplication: loadedAction() };
        case 'inProgressApplicationFailed':
            return { ...state, inProgressApplication: errorAction(action.payload.error) };

        default:
            return state;
    }
};