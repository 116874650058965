import { ApplicationScheduleLine, onDeserializeApplicationScheduleLine, onSerializeApplicationScheduleLine} from './application-schedule-line';

export interface RequestedApplicationSchedule {
    unchanged?: ApplicationScheduleLine[];
    added?: ApplicationScheduleLine[];
    removed?: ApplicationScheduleLine[];
}

export function onDeserializeRequestedApplicationSchedule(data: RequestedApplicationSchedule): RequestedApplicationSchedule {
    if (data == null) {
        return data;
    }

    if (data.unchanged != null) {
        for (let i = 0; i < data.unchanged.length; i++) {
            if (data.unchanged[i] != null) {
                data.unchanged[i] = onDeserializeApplicationScheduleLine(data.unchanged[i]);
            }
        }
    }
    if (data.added != null) {
        for (let i = 0; i < data.added.length; i++) {
            if (data.added[i] != null) {
                data.added[i] = onDeserializeApplicationScheduleLine(data.added[i]);
            }
        }
    }
    if (data.removed != null) {
        for (let i = 0; i < data.removed.length; i++) {
            if (data.removed[i] != null) {
                data.removed[i] = onDeserializeApplicationScheduleLine(data.removed[i]);
            }
        }
    }

    return data;
}

export function onSerializeRequestedApplicationSchedule(data: RequestedApplicationSchedule): RequestedApplicationSchedule {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    if (data.unchanged != null) {
        for (let i = 0; i < data.unchanged.length; i++) {
            serialized.unchanged[i] = onSerializeApplicationScheduleLine(data.unchanged[i]);
        }
    }      
    if (data.added != null) {
        for (let i = 0; i < data.added.length; i++) {
            serialized.added[i] = onSerializeApplicationScheduleLine(data.added[i]);
        }
    }      
    if (data.removed != null) {
        for (let i = 0; i < data.removed.length; i++) {
            serialized.removed[i] = onSerializeApplicationScheduleLine(data.removed[i]);
        }
    }      

    return serialized;
}
