import { AgeClassifications, TimeSlotUsage } from '@/client/models';
import { AgeCount, DetermineEmployeesFor } from 'bkr.1ratio';
import R from 'ramda';
import { ApplicationGroupPeriod, ApplicationPeriodGroupLineStatus } from '@/store/planboard/state';

function count(ages: AgeClassifications): number {
    return R.sum([ages.age0, ages.age1, ages.age2, ages.age3, ages.age4To7, ages.age7ToBso]);
}

function toAgeCounts(ages: AgeClassifications): AgeCount[] {
    return [
        { Age: 0, Count: ages.age0 || 0 },
        { Age: 1, Count: ages.age1 || 0 },
        { Age: 2, Count: ages.age2 || 0 },
        { Age: 3, Count: ages.age3 || 0 },
        { Age: 4, Count: ages.age4To7 || 0 },
        { Age: 7, Count: ages.age7ToBso || 0 },
    ];
}

function calculateStatusForMoment(groupPeriod: ApplicationGroupPeriod, usage: TimeSlotUsage): Partial<ApplicationPeriodGroupLineStatus> {
    const status: Partial<ApplicationPeriodGroupLineStatus> = {
        overCapacity: [],
        extraEmployees: [],
        usages: [{ ...usage, moments: [] }]
    };

    const startYear = usage.start.year();
    const endYear = usage.end.year();

    for (const moment of usage.moments) {
        const children = count(moment.ages);

        const ages = toAgeCounts(moment.ages);

        for (let year = startYear; year < endYear; year++) {
            const age = groupPeriod.ageInYear[year];
            if (age == null) {
                continue;
            }

            const ageCategory = ages.find(x => x.Age === age);
            if (ageCategory != null) {
                ageCategory.Count++;
            } else {
                ages.push({ Age: age, Count: 1 });
            }

            const requiredEmployees = DetermineEmployeesFor(2019, ages);
            status.usages[0].moments.push({ ...moment, employees: requiredEmployees.IsSuccess ? requiredEmployees.Count : moment.employees });

            if (requiredEmployees.IsSuccess && requiredEmployees.Count > moment.employees) {
                status.extraEmployees.push({
                    reason: usage,
                    at: moment
                });
            }

            if (children + 1 > groupPeriod.group.capacity) {
                status.overCapacity.push({
                    reason: usage,
                    at: moment
                });
            }
        }
    }

    return status;
}

export function calculateStatus(groupPeriod: ApplicationGroupPeriod, usages: TimeSlotUsage[]): ApplicationPeriodGroupLineStatus {
    if (usages == null || groupPeriod.group == null) {
        return {
            usages: [],
            overCapacity: [],
            extraEmployees: []
        } as ApplicationPeriodGroupLineStatus;
    }

    const status: Partial<ApplicationPeriodGroupLineStatus> = {
        usages: usages.filter(s => groupPeriod.start.isBefore(s.end) && groupPeriod.end.isAfter(s.start)),
        overCapacity: [],
        extraEmployees: []
    };

    let startsFromTheBeginning = true;

    for (let i = 0; i < status.usages.length; i++) {
        const usage = status.usages[i];
        const { extraEmployees, overCapacity } = calculateStatusForMoment(groupPeriod, usage);

        if (i === 0 && (extraEmployees.length !== 0 || overCapacity.length !== 0)) {
            startsFromTheBeginning = false;
        } else if (i > 0 && !startsFromTheBeginning && status.canStartOn == null) {
            if (extraEmployees.length === 0 && overCapacity.length === 0 && usage.end.diff(usage.start, 'weeks') > 3) {
                status.canStartOn = usage.start;
            }
        }

        status.extraEmployees = R.uniq(R.concat(status.extraEmployees, extraEmployees));
        status.overCapacity = R.uniq(R.concat(status.overCapacity, overCapacity));
    }

    return status as ApplicationPeriodGroupLineStatus;
}