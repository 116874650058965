import React from 'react';
import styled from 'styled-components';
import { DatePicker, Input } from 'antd';
import moment from 'moment';
import { TextAreaProps } from 'antd/lib/input';
import { DatePickerProps, RangePickerProps } from 'antd/lib/date-picker';

export function DateEditor(props: DatePickerProps): React.ReactElement {
    return <EditorContainer><DatePicker {...props} format={moment.localeData().longDateFormat('L')} /></EditorContainer>;
}

export function DateRangeEditor(props: RangePickerProps): React.ReactElement {    
    return <EditorContainer><DatePicker.RangePicker {...props} format={moment.localeData().longDateFormat('L')} /></EditorContainer>;
}

export function TextAreaEditor(props: TextAreaProps): React.ReactElement {    
    return <EditorContainer><Input.TextArea {...props} ></Input.TextArea></EditorContainer>;
}

const EditorContainer = styled.div`
    .ant-input {
        border-color: ${props => props.theme.secondary};
        color: ${props => props.theme.dataFontColor}
    }
`;