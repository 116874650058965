import { ActionReference, onDeserializeActionReference, onSerializeActionReference} from './action-reference';

export interface TimelineDiaryEntry {
    diaryEntryId: string;
    childId: string;
    title?: string;
    created?: ActionReference;
    description?: string;
    colorCode?: string;
    iconUrl?: string;
}

export function onDeserializeTimelineDiaryEntry(data: TimelineDiaryEntry): TimelineDiaryEntry {
    if (data == null) {
        return data;
    }

    if (data.created != null) {
        data.created = onDeserializeActionReference(data.created);
    }

    return data;
}

export function onSerializeTimelineDiaryEntry(data: TimelineDiaryEntry): TimelineDiaryEntry {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    serialized.created = onSerializeActionReference(data.created);

    return serialized;
}
