import React from 'react';
import { TableDefinition } from './table-definition';
import { ColumnDefinition, Option } from './column-definition';
import { useTranslate } from '@/language-provider';
import { getProperty } from './table-helper';
import { Tag } from 'antd';
import { SortOrder } from 'antd/lib/table/interface';

export enum ColumnType {
    Text = 'Text',
    Date = 'Date',
    Bool = 'Bool',
    Time = 'Time'
}

export enum Size {
    ExtraSmall = 30,
    Small = 50,
    Medium = 175,
    Large = 250
}

export interface FilterOptions {
    allowNone?: boolean;
}

export interface DropdownOptions {
    allowNone?: boolean;
    source: Option[];
}

export interface DataGridColumnProps {
    type: ColumnType | keyof typeof ColumnType;
    translation: string;
    path: string;
    size?: Size | keyof typeof Size | number;
    onValue?(text: string): string;
    translateTemplate?: string;
    filter?: FilterOptions | boolean;
    dropdown?: DropdownOptions | Option[];
    children?: React.ReactElement<DataGridColumnOptionProps>[];
    defaultSortOrder?: SortOrder;
    colors?: { [key: string]: string };
}

export type DataGridColumnOptionProps = Option & { none?: boolean };

export class DataGridColumnOption extends React.PureComponent<DataGridColumnOptionProps> {
}

export const BooleanColors = {
    'true': 'green',
    'false': 'red'
};

export class DataGridColumn<T extends object = {}> extends React.PureComponent<DataGridColumnProps> {

    public apply(table: TableDefinition<T>): void {

        const { type, size, defaultSortOrder, colors } = this.props;

        let column: ColumnDefinition<T>;

        switch (type) {
            case ColumnType.Text:
                column = this.createText(table);
                break;

            case ColumnType.Date:
                column = this.createDate(table);
                break;

            case ColumnType.Time:
                column = this.createTime(table);
                break;

            case ColumnType.Bool:
                column = this.createBool(table);
                break;
        }

        column.defaultSortOrder = defaultSortOrder;
        this.setColorsOnColumn(column, colors);

        if (size != null) {
            column.width(size + 'px');
        }
    }

    private setColorsOnColumn(column: ColumnDefinition<unknown>, colors: { [key: string]: string }): void {
        if (colors == null) {
            return;
        }
        
        column.nativeColumn.render = (text: string, row: unknown) => {
            const value = getProperty(row, column.dataIndex());
            if (Array.isArray(value)) {
                return value.map(x => ({ propValue: x, text: column.transformText(x) })).map(x => {
                    const color = colors[x.propValue];            
                    if (color != null) {
                        return <Tag key={`${column.dataIndex()}-${color}`} color={color}>{x.text}</Tag>;
                    }

                    return x.text;
                });
            }
            
            if (colors[value] != null) {
                return <Tag color={colors[value]}>{column.transformText(value)}</Tag>;
            }
        };
    }

    private createText(table: TableDefinition<T>): ColumnDefinition<T> {

        const { translate } = useTranslate();

        const { translation, path, translateTemplate, filter, dropdown, children } = this.props;
        let onValue = this.props.onValue;

        if (onValue == null && translateTemplate != null) {                    
            onValue = x => translate(translateTemplate.replace('{value}', x)).toString();
        }

        const column = table.addText(translation, path, onValue);

        if (filter) {
            column.textFilter();
        } else if (dropdown) {
            if (Array.isArray(dropdown)) {
                column.optionsFilter(dropdown);
            } else {
                column.optionsFilter(dropdown.source, dropdown.allowNone);
            }
        } else if (children) {
            const options: Option[] = [];
            for (const child of children) {
                if (child.props.isNone) {
                    options.push({ text: translate('dataGrid.none').toString(), value: 'none' });
                } else {
                    options.push(child.props);
                }
            }

            column.optionsFilter(options);
        }

        return column;
    }

    private createDate(table: TableDefinition<T>): ColumnDefinition<T> {

        const { translation, path, filter } = this.props;

        const column = table.addDate(translation, path);

        if (filter) {
            column.dateFilter();
        }

        return column;
    }

    private createTime(table: TableDefinition<T>): ColumnDefinition<T> {

        const { translation, path } = this.props;

        const column = table.addTime(translation, path);

        return column;
    }

    private createBool(table: TableDefinition<T>): ColumnDefinition<T> {

        const { translation, path, filter } = this.props;

        const column = table.addBool(translation, path);

        if (filter) {
            if (typeof filter === 'boolean') {
                column.booleanFilter();
            } else {
                column.booleanFilter(filter.allowNone);
            }
        }

        return column;
    }
}
