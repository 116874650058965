/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import { ColumnDefinition } from './column-definition';
import { Select } from 'antd';
import { SelectionDrawer, DrawerSize } from '@/components/selection-drawer';
import { Button } from '@/components/button';
import styled from 'styled-components';
import R from 'ramda';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { MoreOutlined, CloseOutlined } from '@ant-design/icons';

const { Option } = Select;

export interface ColumnOption {
    key: string;
    visible: boolean;
    order: number;
    column: ColumnDefinition<any>;
    availableColumns: ColumnOption[];
}

interface ColumnOptionRowProps {
    option: ColumnOption;
    onChange: (previous: ColumnOption, next: ColumnOption) => void;
    onDelete: (option: ColumnOption) => void;
}

function ColumnOptionRow(props: ColumnOptionRowProps): React.ReactElement {

    const { option: option, option: { availableColumns: columns } } = props;

    return (
        <Container>
            <Draggable key={option.key} draggableId={option.key} index={option.order}>
                {(provided) => (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <PaddedIcon>
                            <MoreOutlined />
                        </PaddedIcon>

                        <FieldSelect defaultValue={props.option.key} showSearch
                            onChange={selectColumn => {
                                const columnOption = columns.find(x => x.key === selectColumn);
                                props.onChange(props.option, columnOption);
                            }}>
                            <Option key={option.key} value={option.key}>{option.column.title()}</Option>
                            {props.option.availableColumns.map(x => <Option key={x.key} value={x.key}>{x.column.title()}</Option>)}
                        </FieldSelect>

                        <PaddedIcon onClick={() => props.onDelete(props.option)} >
                            <CloseOutlined />
                        </PaddedIcon>
                    </div>
                )}
            </Draggable>
        </Container>
    );
}

function newColumnOption(column: ColumnDefinition<any>): ColumnOption {
    return {
        key: column.dataIndex(),
        column: column,
        visible: column.visible,
        order: column.order,
        availableColumns: []
    };
}

function reindexOptions(options: ColumnOption[]): void {
    let visibleIndex = 0;

    for (const option of R.sortBy(x => x.order, options)) {
        option.availableColumns = options.filter(x => !x.visible);
        option.order = option.visible ? visibleIndex++ : 99999;
    }
}

interface ColumnOptionsDrawerProps {
    visible: boolean;
    columns: ColumnDefinition<any>[];
    onSelect: (columnOptions: ColumnOption[]) => void;
    onCancel?: () => void;
    onReset?: () => void;
}

export function ColumnOptionsDrawer(props: ColumnOptionsDrawerProps): React.ReactElement {

    const columnOptions = props.columns.map(x => newColumnOption(x));

    const [options, setOptions] = useState(columnOptions);

    useEffect(() => {
        // Reset the state when hiding
        if (!props.visible) {
            setOptions(columnOptions);
        }
    }, [props.visible]);

    reindexOptions(options);

    function changeColumnOption(key: string, order: number, visible: boolean): void {
        const option = options.find(x => x.key === key);
        option.visible = visible;
        option.order = order;
    }

    function onColumnChanged(previous: ColumnOption, next: ColumnOption): void {
        changeColumnOption(previous.key, previous.order, false);
        changeColumnOption(next.key, next.order, true);

        setOptions([...options]);
    }

    function onColumnDeleted(option: ColumnOption): void {
        changeColumnOption(option.key, option.order, false);

        setOptions([...options]);
    }

    function onColumnAdded(): void {
        const option = options.find(x => x.visible === false);
        changeColumnOption(option.key, option.order, true);

        setOptions([...options]);
    }

    function onOrderChanged(result: DropResult): void {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const orderedOptions = R.sortBy(x => x.order, options).filter(x => x.key !== result.draggableId);

        const shouldIncrement = result.destination.index < result.source.index;
        const remaining = shouldIncrement ?
            orderedOptions.slice(result.destination.index, result.source.index) :
            orderedOptions.slice(result.source.index, result.destination.index);

        for (const option of remaining) {
            option.order = shouldIncrement ? option.order + 1 : option.order - 1;
        }

        changeColumnOption(result.draggableId, result.destination.index, true);

        setOptions([...options]);
    }

    return (
        <SelectionDrawer
            size={DrawerSize.small}
            titleTranslation='dataGrid.columnOptions'
            visible={props.visible}
            onCancel={props.onCancel}
            onSelect={() => props.onSelect(options)}
            buttons={[
                <Button key='reset-button' translation='global.button.reset' onClick={props.onReset} />
            ]}>
            <DragDropContext onDragEnd={onOrderChanged}>
                <Droppable droppableId="columns">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {
                                R.sortBy(x => x.order, options)
                                    .filter(x => x.visible)
                                    .map(x => <ColumnOptionRow key={x.key} option={x} onChange={onColumnChanged} onDelete={onColumnDeleted} />)
                            }
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            <div>
                <Button translation='dataGrid.addColumn' icon='plus'
                    onClick={onColumnAdded}
                    disabled={options.every(x => x.visible)} />
            </div>
        </SelectionDrawer>
    );
}

const Container = styled.div`
    margin-bottom: 10px;
`;

const FieldSelect = styled(Select)`
    width: 200px;
`;

const PaddedIcon = styled.span`
    padding-left: 5px;
    padding-right: 5px;
`;