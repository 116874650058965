import { SortOrder } from 'antd/lib/table/interface';
import { ColumnDefinition } from './column-definition';

export interface GridColumnLayout {
    name: string;
    filter?: unknown[];
    sort?: SortOrder | false;
}

export interface GridLayout {
    name: string;
    columns: GridColumnLayout[];
    pageSize?: number;
}

export function createColumnLayout(name: string, filter?: unknown[], sort?: SortOrder | false): GridColumnLayout {
    return {
        name,
        filter,
        sort,
    };
}

function isColumnLayout(columns: ColumnDefinition<unknown>[] | GridColumnLayout[]): columns is GridColumnLayout[] {
    return columns[0]['name'] !== undefined;
}

export function createGridLayout(name: string, columns?: ColumnDefinition<unknown>[] | GridColumnLayout[], pageSize?: number): GridLayout {
    return {
        name,
        columns: columns == null ? [] : 
            isColumnLayout(columns) ? columns : columns.map(x => createColumnLayout(x.dataIndex(), null, x.defaultSortOrder)),
        pageSize
    };
}

export function copyLayout(layout: GridLayout): GridLayout {
    return { ...layout, columns: [ ...layout.columns.map(x => ({ ...x })) ] };
}

export function findColumnLayout(layout: GridLayout, name: string): { index: number; column: GridColumnLayout } {
    const index = layout.columns.findIndex(x => x.name === name);
    const column = layout.columns[index];

    return { index, column };
}

export function findOrCreateColumnLayout(layout: GridLayout, name: string): GridColumnLayout {
    const existingColumn = layout.columns.find(x => x.name === name);
    if (existingColumn != null) {
        return existingColumn;
    }

    return createColumnLayout(name);
}