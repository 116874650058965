import { GroupReference} from './group-reference';
import { PlanningTimeslot, onDeserializePlanningTimeslot, onSerializePlanningTimeslot} from './planning-timeslot';
import { CareType } from './care-type';
import moment from 'moment';

export interface ContractPlanning {
    planningId: string;
    packageId: string;
    group?: GroupReference;
    careType: CareType;
    timeslots: PlanningTimeslot[];
    start?: moment.Moment;
    end?: moment.Moment;
}

export function onDeserializeContractPlanning(data: ContractPlanning): ContractPlanning {
    if (data == null) {
        return data;
    }

    if (data.timeslots != null) {
        for (let i = 0; i < data.timeslots.length; i++) {
            if (data.timeslots[i] != null) {
                data.timeslots[i] = onDeserializePlanningTimeslot(data.timeslots[i]);
            }
        }
    }
    if (data.start != null) {
        data.start = moment(data.start);
    }
    if (data.end != null) {
        data.end = moment(data.end);
    }

    return data;
}

export function onSerializeContractPlanning(data: ContractPlanning): ContractPlanning {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    if (data.timeslots != null) {
        for (let i = 0; i < data.timeslots.length; i++) {
            serialized.timeslots[i] = onSerializePlanningTimeslot(data.timeslots[i]);
        }
    }      
    serialized.start = data.start?.format('YYYY-MM-DD') as unknown as moment.Moment;
    serialized.end = data.end?.format('YYYY-MM-DD') as unknown as moment.Moment;

    return serialized;
}
