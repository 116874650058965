import { PresenceType } from './presence-type';
import { RegistrationStatus } from './registration-status';
import moment from 'moment';

export interface Registration {
    id: string;
    requestedOn: moment.Moment;
    start: moment.Moment;
    end: moment.Moment;
    previousStart?: moment.Moment;
    previousEnd?: moment.Moment;
    type: PresenceType;
    status: RegistrationStatus;
}

export function onDeserializeRegistration(data: Registration): Registration {
    if (data == null) {
        return data;
    }

    if (data.requestedOn != null) {
        data.requestedOn = moment(data.requestedOn);
    }
    if (data.start != null) {
        data.start = moment(data.start);
    }
    if (data.end != null) {
        data.end = moment(data.end);
    }
    if (data.previousStart != null) {
        data.previousStart = moment(data.previousStart);
    }
    if (data.previousEnd != null) {
        data.previousEnd = moment(data.previousEnd);
    }

    return data;
}

export function onSerializeRegistration(data: Registration): Registration {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    serialized.requestedOn = data.requestedOn?.toISOString() as unknown as moment.Moment;
    serialized.start = data.start?.toISOString() as unknown as moment.Moment;
    serialized.end = data.end?.toISOString() as unknown as moment.Moment;
    serialized.previousStart = data.previousStart?.toISOString() as unknown as moment.Moment;
    serialized.previousEnd = data.previousEnd?.toISOString() as unknown as moment.Moment;

    return serialized;
}
