import React from 'react';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { Translate } from 'react-localize-redux';
import { formatTime } from '@/momentjs/duration-helper';
import { TimeSlotStatus } from './time-slot-status';
import {ApplicationPeriodGroupLineStatus} from '@/store/planboard/state';


export function OverCapacityStatus({ status }: { status: ApplicationPeriodGroupLineStatus }): React.ReactElement {

    const active = status.overCapacity.length > 0;

    return (
        <TimeSlotStatus icon={faUsers} slotIndex={1} active={active}>
            {status.overCapacity.map(x => (
                <div key={x.reason.start.format('L') + x.at.moment.asSeconds()}>
                    {x.reason.start} <Translate id='global.generic.at' /> {formatTime(x.at.moment)}
                </div>
            ))}
        </TimeSlotStatus>
    );
}