import React from 'react';
import { faHourglassHalf } from '@fortawesome/free-solid-svg-icons';
import { Translate } from 'react-localize-redux';
import { TimeSlotStatus } from './time-slot-status';
import { ApplicationPeriodGroupLineStatus } from '@/store/planboard/state';


export function CanStartOnStatus({ status }: { status: ApplicationPeriodGroupLineStatus }): React.ReactElement {

    const active = status.canStartOn != null;

    return (
        <TimeSlotStatus icon={faHourglassHalf} slotIndex={2} active={active}>
            {active ? (
                <div>
                    <Translate id='planboard.messages.can-start-on' data={{ date: status.canStartOn.format('L') }} />
                </div>
            ) : undefined}
        </TimeSlotStatus>
    );
}