import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PlanboardActions } from '@/store/planboard/actions';
import { PlannerApplicationPartProvider } from '@/planboard/planner-application-part-provider';
import { useParams } from '@/router/react-router-hooks';
import { PlannerComponent } from './planner-component';

export const PlannerScreen: React.FC = () => {
    const { applicationId } = useParams();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(PlanboardActions.loadApplicationDetails(applicationId));
    }, []);

    return (
        <PlannerApplicationPartProvider>
            <PlannerComponent />
        </PlannerApplicationPartProvider>
    );
};