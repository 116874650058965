import React from 'react';
import { Line, ChartData } from 'react-chartjs-2';
import { useSelector, useDispatch } from 'react-redux';
import {
    openApplicationsSelector,
    scoringOptionsSelector,
    openApplicationsStatusSelector,
    openApplicationsRetrievedSelector
} from '@/store/planboard/selectors';
import moment from 'moment';
import { PlanboardActions } from '@/store/planboard/actions';
import * as chartjs from 'chart.js';
import { Row, Col } from 'antd';
import { useState } from 'react';
import { SummaryCard } from './summary-card';
import { MonthSummary, SummaryDetails, monthToArray, createSummary, combineSummaries } from './summary';
import { Title } from '@/components/title';
import { calculateScore, ScoredApplication } from './score-calculator';

import { ScoringOptionsDrawer } from './scoring-options-drawer';
import { ApplicationSelectionDrawer } from './application-selection';
import { DashboardSelection } from '../dashboard-selection';
import { ScreenContainer } from '@/screens/screen-container';
import { useTranslate } from '@/language-provider';
import { useCache } from '@/caching/caching-provider';
import { Status } from '@/store/status';

import { useLifeCycle } from '@/react/hooks';
import { ApplicationFilter, toggleApplicationFilter } from './application-filter';
import { ScoreHighCard, ScoreMediumCard, ScoreLowCard } from './cards/score-cards';
import {
    BetweenSchoolCareCard,
    DaycareCard,
    HolidayCareCard,
    OutOfSchoolCard,
    PlayschoolCard
} from './cards/care-type-cards';
import { NewApplicationTypeCard, ChangeApplicationTypeCard } from './cards/application-type-cards';

import R from 'ramda';
import { Routes, usePageNavigation } from '@/router/routes';
import { TranslateFunction } from 'react-localize-redux';
import { RightCircleOutlined, ToolOutlined, SyncOutlined, ControlOutlined, FilterOutlined } from '@ant-design/icons';


function createMonths(translate: TranslateFunction, summary: MonthSummary, selected: ApplicationFilter[], onclick: (requesFiltert: ApplicationFilter) => void): React.ReactElement[] {
    const months = [];

    for (const month of Object.keys(summary).map(x => Number.parseInt(x))) {
        months[month] = <SummaryCard
            key={month}
            title={translate(`global.months.${month + 1}`).toString()}
            count={summary[month].length}
            isSelected={selected.some(x => x.name === `month-${month}`)}
            onClick={() => onclick({
                name: `month-${month}`,
                group: 'month',
                filter: applications => applications.filter(x => x.startsOn.month() === month)
            })}
        />;
    }

    return months;
}

const chartColors = {
    red: (opacity: number) => `rgba(255, 99, 132, ${opacity})`,
    orange: (opacity: number) => `rgba(255, 159, 64, ${opacity})`,
    yellow: (opacity: number) => `rgba(255, 205, 86, ${opacity})`,
    green: (opacity: number) => `rgba(75, 192, 192, ${opacity})`,
    blue: (opacity: number) => `rgba(54, 162, 235, ${opacity})`,
    purple: (opacity: number) => `rgba(153, 102, 255, ${opacity})`,
    grey: (opacity: number) => `rgba(201, 203, 207, ${opacity})`
};

function createDataSets(translate: TranslateFunction, summary: SummaryDetails): ChartData<chartjs.ChartData>[] {

    return [
        {
            label: translate('planboard.applications-starting'),
            data: monthToArray(summary.starting),
            backgroundColor: chartColors.red(0.4),
            borderColor: chartColors.red(1)
        },
        {
            label: translate('planboard.new'),
            data: monthToArray(summary.newPerMonth),
            backgroundColor: chartColors.blue(0.4),
            borderColor: chartColors.blue(1)
        },
        {
            label: translate('planboard.change'),
            data: monthToArray(summary.changedPerMonth),
            backgroundColor: chartColors.orange(0.4),
            borderColor: chartColors.orange(1)
        }
    ];
}

function executeFilters(applicationFilters: ApplicationFilter[], applications: ScoredApplication[]): ScoredApplication[] {

    const grouping = R.groupBy(x => x.group, applicationFilters);

    let filteredApplications = applications;
    for (const applicationFilters of R.values(grouping)) {
        const subset = new Array<ScoredApplication>();

        for (const applicationFilter of applicationFilters) {
            for (const application of applicationFilter.filter(filteredApplications)) {
                subset.push(application);
            }
        }

        filteredApplications = subset;
    }

    return R.uniqBy(x => x.applicationId, filteredApplications);
}

export function PlanboardDashboardScreen(): React.ReactElement {
    const dispatch = useDispatch();
    const cache = useCache();
    const { push } = usePageNavigation();
    const { translate } = useTranslate();

    useLifeCycle(() => cache.addCache(openApplicationsRetrievedSelector, PlanboardActions.loadOpenApplications()), () => cache.removeCache(openApplicationsRetrievedSelector));

    const applications = useSelector(openApplicationsSelector) || [];
    const requestsStatus = useSelector(openApplicationsStatusSelector);
    const scoringOptions = useSelector(scoringOptionsSelector);

    const [selectedLocations, setSelectedLocations] = useState(new Array<string>());
    const [prioritiesVisible, setPrioritiesVisible] = useState(false);
    const [dashboardFilterVisible, setDashboardFilterVisible] = useState(false);
    const [requestSelectionVisible, setRequestSelectionVisible] = useState(false);
    const [year, setYear] = useState(moment().year());
    const [filters, setFilters] = useState(new Array<ApplicationFilter>());

    const handleCardClick = (requestFilter: ApplicationFilter): void => setFilters(toggleApplicationFilter(filters, requestFilter));

    if (requestsStatus === Status.Init) {
        dispatch(PlanboardActions.loadOpenApplications());
    }

    const screenFilteredApplications = applications.filter(r =>
        r.startsOn.year() === year &&
        (
            selectedLocations.length === 0 ||
            r.preferredLocationIds.some(x => selectedLocations.includes(x))
        )
    );
    const scoredApplication = calculateScore(screenFilteredApplications, scoringOptions);
    const filteredApplications = executeFilters(filters, scoredApplication);
    const summary = createSummary(filteredApplications, year);

    const summaryDetails = combineSummaries(R.values(summary).filter(x => x != null));

    const data = {
        labels: moment.months(),
        datasets: createDataSets(translate, summaryDetails)
    };

    return (
        <ScreenContainer showFullScreen={true} menuItems={[
            { icon: <RightCircleOutlined />, translation: 'planboard.viewAsApplications', route: Routes.PlanboardOpenApplications },
            {
                icon: <ToolOutlined />,
                translation: 'planboard.planApplications',
                onClick: () => setRequestSelectionVisible(true),
                isEnabled: filteredApplications.length > 0
            },
            {
                icon: <SyncOutlined />,
                iconOnly: true,
                translation: 'global.button.refresh',
                onClick: () => dispatch(PlanboardActions.refreshOpenApplications()),
                position: 'right'
            },
            {
                icon: <ControlOutlined />,
                translation: 'planboard.priority',
                onClick: () => setPrioritiesVisible(true),
                position: 'right'
            },
            {
                icon: <FilterOutlined />,
                translation: 'planboard.filter',
                onClick: () => setDashboardFilterVisible(true),
                position: 'right'
            }
        ]}>
            <ScoringOptionsDrawer visible={prioritiesVisible} options={scoringOptions}
                onSave={(options) => {
                    dispatch(PlanboardActions.storeScoringOptions(options));
                    setPrioritiesVisible(false);
                }}
                onCancel={() => setPrioritiesVisible(false)} />

            <DashboardSelection visible={dashboardFilterVisible} selected={selectedLocations} year={year}
                availableLocations={year => applications.filter(x => x.startsOn.year() === year).flatMap(x => x.preferredLocationIds || [])}
                onSelect={(year: number, newSelection: string[]) => {
                    setYear(year);
                    setSelectedLocations(newSelection);
                    setDashboardFilterVisible(false);
                }}
                onCancel={() => setDashboardFilterVisible(false)} />

            <ApplicationSelectionDrawer visible={requestSelectionVisible}
                titleTranslation='planboard.applications'
                selection={filteredApplications}
                applications={filteredApplications}
                onSelect={selected => {
                    setRequestSelectionVisible(false);
                    dispatch(PlanboardActions.selectApplications(selected));
                    push(Routes.PlanboardSelectedApplications);
                }}
                onCancel={() => setRequestSelectionVisible(false)} />

            <Row>
                <Col sm={24}>
                    <Line data={data} height={50} options={{ scales: { yAxes: [{ ticks: { stepSize: 1 } }] } }} />
                </Col>
            </Row>
            <Row><Col sm={24}><Title translation='planboard.priority' /></Col></Row>
            <Row>
                <ScoreHighCard summary={summaryDetails} onClick={handleCardClick} />
                <ScoreMediumCard summary={summaryDetails} onClick={handleCardClick} />
                <ScoreLowCard summary={summaryDetails} onClick={handleCardClick} />
            </Row>
            <Row><Col sm={24}><Title translation='planboard.starters' /></Col></Row>
            <Row>{createMonths(translate, summaryDetails.starting, filters, handleCardClick)}</Row>
            <Row><Col sm={24}><Title translation='planboard.application-type' /></Col></Row>
            <Row>
                <NewApplicationTypeCard summary={summaryDetails} onClick={handleCardClick} />
                <ChangeApplicationTypeCard summary={summaryDetails} onClick={handleCardClick} />
            </Row>
            <Row><Col sm={24}><Title translation='planboard.application-caretype' /></Col></Row>
            <Row>
                <BetweenSchoolCareCard summary={summaryDetails} onClick={handleCardClick} />
                <DaycareCard summary={summaryDetails} onClick={handleCardClick} />
                <HolidayCareCard summary={summaryDetails} onClick={handleCardClick} />
                <OutOfSchoolCard summary={summaryDetails} onClick={handleCardClick} />
                <PlayschoolCard summary={summaryDetails} onClick={handleCardClick} />
            </Row>
        </ScreenContainer>
    );
}