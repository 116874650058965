import {
    Application,
    ApplicationDetails,
    ApplicationScheduleLine,
    CareType, ChildDetailsReference, 
    LocationUsagesResponse,
    TimeSlotMoment,
    TimeSlotUsage
} from '@/client/models';
import {RetrievedValue, ActionResult} from '@/store/retrieved-value';
import moment from 'moment';
import { VisibleGroup } from '@/planboard/visible-group';

export interface ScoringOptions {
    createdDistance: number;
    startingDistance: number;
    sibling: number;
}

export interface TimeSlotChange {
    reason: TimeSlotUsage;
    at: TimeSlotMoment;
}

export interface ApplicationPeriodGroupLineStatus {
    extraEmployees: TimeSlotChange[];
    overCapacity: TimeSlotChange[];
    usages: TimeSlotUsage[];
    
    canStartOn: moment.Moment;
}

export const EmptyStatus: ApplicationPeriodGroupLineStatus = { extraEmployees: [], overCapacity: [], usages: [], canStartOn: null };

export interface ApplicationPeriodGroupTime {
    groupPeriod: ApplicationGroupPeriod;
    line: ApplicationScheduleLine;
    status: ApplicationPeriodGroupLineStatus;
}

export interface ApplicationGroupPeriod {
    composition: GroupComposition;
    group: VisibleGroup;
    times: ApplicationPeriodGroupTime[];
    start: moment.Moment;
    end: moment.Moment;
    ageInYear: { [year: number]: number };
}

export interface ApplicationSchedulePeriod {
    start: moment.Moment;
    end: moment.Moment;

    groupPeriods: ApplicationGroupPeriod[];
}

export interface GroupComposition {
    compositionId: string;
    groups: VisibleGroup[];
    groupPeriods: ApplicationGroupPeriod[];
    periods: ApplicationSchedulePeriod[];
    schedulePart: ApplicationSchedulePart;
}

export interface ApplicationSchedulePart {
    applicationId: string;
    schedulePartId: string;
    startsOn: moment.Moment;
    endsOn: moment.Moment;
    child: ChildDetailsReference;
    careType: CareType;
    
    unchanged: ApplicationScheduleLine[];
    added: ApplicationScheduleLine[];
    removed: ApplicationScheduleLine[];
    
    compositions: { [locationId: string]: GroupComposition[] };
}

export interface State {
    openApplications: RetrievedValue<Application[]>;
    reservedApplications: RetrievedValue<Application[]>;
    applicationDetails: RetrievedValue<ApplicationDetails>;
    locationUsages: RetrievedValue<LocationUsagesResponse>[];

    selectedApplications?: string[];
    scoringOptions: ScoringOptions;
    
    scheduleParts?: ApplicationSchedulePart[];
    plannerStatus: ActionResult;
    offerContract: ActionResult;

    reserveApplication: ActionResult;
    inProgressApplication: ActionResult;
}