import { TimelineMediaItem, onDeserializeTimelineMediaItem, onSerializeTimelineMediaItem} from './timeline-media-item';
import { TimelinePostItem, onDeserializeTimelinePostItem, onSerializeTimelinePostItem} from './timeline-post-item';
import { TimelineDiaryEntry, onDeserializeTimelineDiaryEntry, onSerializeTimelineDiaryEntry} from './timeline-diary-entry';
import moment from 'moment';

export interface TimelineModel {
    start: moment.Moment;
    end: moment.Moment;
    albums: TimelineMediaItem[];
    posts: TimelinePostItem[];
    diaryEntries: TimelineDiaryEntry[];
}

export function onDeserializeTimelineModel(data: TimelineModel): TimelineModel {
    if (data == null) {
        return data;
    }

    if (data.start != null) {
        data.start = moment(data.start);
    }
    if (data.end != null) {
        data.end = moment(data.end);
    }
    if (data.albums != null) {
        for (let i = 0; i < data.albums.length; i++) {
            if (data.albums[i] != null) {
                data.albums[i] = onDeserializeTimelineMediaItem(data.albums[i]);
            }
        }
    }
    if (data.posts != null) {
        for (let i = 0; i < data.posts.length; i++) {
            if (data.posts[i] != null) {
                data.posts[i] = onDeserializeTimelinePostItem(data.posts[i]);
            }
        }
    }
    if (data.diaryEntries != null) {
        for (let i = 0; i < data.diaryEntries.length; i++) {
            if (data.diaryEntries[i] != null) {
                data.diaryEntries[i] = onDeserializeTimelineDiaryEntry(data.diaryEntries[i]);
            }
        }
    }

    return data;
}

export function onSerializeTimelineModel(data: TimelineModel): TimelineModel {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    serialized.start = data.start?.format('YYYY-MM-DD') as unknown as moment.Moment;
    serialized.end = data.end?.format('YYYY-MM-DD') as unknown as moment.Moment;
    if (data.albums != null) {
        for (let i = 0; i < data.albums.length; i++) {
            serialized.albums[i] = onSerializeTimelineMediaItem(data.albums[i]);
        }
    }      
    if (data.posts != null) {
        for (let i = 0; i < data.posts.length; i++) {
            serialized.posts[i] = onSerializeTimelinePostItem(data.posts[i]);
        }
    }      
    if (data.diaryEntries != null) {
        for (let i = 0; i < data.diaryEntries.length; i++) {
            serialized.diaryEntries[i] = onSerializeTimelineDiaryEntry(data.diaryEntries[i]);
        }
    }      

    return serialized;
}
