export enum RequestStatusType {
    Offered = 'offered',
    Approved = 'approved',
    Active = 'active',
    Rejected = 'rejected',
    Cancelled = 'cancelled',
    Ended = 'ended',
    Reserved = 'reserved',
    Received = 'received',
    Pending = 'pending',
}