import { GroupReference} from './group-reference';
import { Name} from './name';
import { MediaReference} from './media-reference';
import moment from 'moment';

export interface EmployeeModel {
    employeeId: string;
    group?: GroupReference;
    name?: Name;
    checkedIn: boolean;
    checkedInTime?: moment.Duration;
    checkedOutTime?: moment.Duration;
    picture?: MediaReference;
}

export function onDeserializeEmployeeModel(data: EmployeeModel): EmployeeModel {
    if (data == null) {
        return data;
    }

    if (data.checkedInTime != null) {
        data.checkedInTime = moment.duration(data.checkedInTime);
    }
    if (data.checkedOutTime != null) {
        data.checkedOutTime = moment.duration(data.checkedOutTime);
    }

    return data;
}

export function onSerializeEmployeeModel(data: EmployeeModel): EmployeeModel {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };


    return serialized;
}
