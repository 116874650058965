import React from 'react';
import { CareType, Group } from '@/client/models';
import { Title } from '@/components/title';
import { Row, Col } from 'antd';
import { GroupCard } from './group-card';
import { DateRange } from './dashboard';

interface ExternalProps {
    groups: Group[];
    dateRange: DateRange;
}

interface InternalProps extends ExternalProps {
    translation: string;
    careType: CareType;
}

function CareTypeRow(props: InternalProps): React.ReactElement {
    return (
        <Row>
            <Col sm={24}>
                <Row>
                    <Title translation={props.translation} />
                </Row>
                <Row>
                    {props.groups.map(x => (
                        <Col key={x.groupId} lg={11} offset={1}>
                            <GroupCard group={x} dateRange={props.dateRange} />
                        </Col>
                    ))}
                </Row>
            </Col>
        </Row>
    );
}

export function BetweenSchoolCareRow(props: ExternalProps): React.ReactElement {
    return <CareTypeRow
        translation='global.caretype.betweenSchoolCare'
        careType={CareType.BetweenSchoolCare} 
        groups={props.groups}
        dateRange={props.dateRange} />;
}

export function DaycareRow(props: ExternalProps): React.ReactElement {
    return <CareTypeRow
        translation='global.caretype.daycare'
        careType={CareType.Daycare}
        groups={props.groups}
        dateRange={props.dateRange} />;
}

export function HolidayCareRow(props: ExternalProps): React.ReactElement {
    return <CareTypeRow
        translation='global.caretype.holidayCare'
        careType={CareType.HolidayCare}
        groups={props.groups}
        dateRange={props.dateRange} />;
}

export function OutOfSchoolCareRow(props: ExternalProps): React.ReactElement {
    return <CareTypeRow
        translation='global.caretype.outOfSchoolCare'
        careType={CareType.OutOfSchoolCare}
        groups={props.groups}
        dateRange={props.dateRange} />;
}

export function PlayschoolRow(props: ExternalProps): React.ReactElement {
    return <CareTypeRow
        translation='global.caretype.playschool'
        careType={CareType.Playschool}
        groups={props.groups}
        dateRange={props.dateRange} />;
}