import R from 'ramda';

export class Secret {
    constructor(public command: string, public action: () => void) {

    }
}

function GetPressedKey(e: KeyboardEvent): string {
    switch (e.code) {
        case 'Backquote':
            if (e.shiftKey) {
                return '~';
            } else {
                return '`';
        }
    }

    
    return e.key;
}

export function InitializeSecretsListener(secrets: Secret[]): void {
    const lookup = secrets.reduce((acc, cur) => {
        acc[cur.command] = cur; 
        return acc;
    }, {} as { [key: string]: Secret });

    let pressedKeys = [];
    const letters = R.uniq(R.flatten(R.map(x => x.command.split(''), secrets)));
    let timer = null;

    window.onkeyup = (e: KeyboardEvent) => {

        if (timer != null) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            pressedKeys = [];
        }, 3000);
        
        const pressedKey = GetPressedKey(e);
    
        if (!letters.includes(pressedKey) || pressedKey === 'Pause') {
            pressedKeys = [];
            return;
        }
    
        pressedKeys.push(pressedKey);
        const current = pressedKeys.join('');

        const command = lookup[current];
        if (command != null) {
            console.log(`Secret ${current} activated`);
            command.action();
            pressedKeys = [];
        }     
    };
}