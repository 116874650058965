import { TimeType } from './time-type';
import { DayOfWeek } from './day-of-week';
import moment from 'moment';

export interface CareTimeslot {
    timeslotId: string;
    timeslotName?: string;
    timeType: TimeType;
    day: DayOfWeek;
    start: moment.Duration;
    end: moment.Duration;
    extended?: boolean;
    from: moment.Moment;
}

export function onDeserializeCareTimeslot(data: CareTimeslot): CareTimeslot {
    if (data == null) {
        return data;
    }

    if (data.start != null) {
        data.start = moment.duration(data.start);
    }
    if (data.end != null) {
        data.end = moment.duration(data.end);
    }
    if (data.from != null) {
        data.from = moment(data.from);
    }

    return data;
}

export function onSerializeCareTimeslot(data: CareTimeslot): CareTimeslot {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    serialized.from = data.from?.format('YYYY-MM-DD') as unknown as moment.Moment;

    return serialized;
}
