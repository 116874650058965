import moment from 'moment';

export interface RequestedTimeslot {
    timeslotId?: string;
    planningId?: string;
    start?: moment.Duration;
    end?: moment.Duration;
    groupId?: string;
}

export function onDeserializeRequestedTimeslot(data: RequestedTimeslot): RequestedTimeslot {
    if (data == null) {
        return data;
    }

    if (data.start != null) {
        data.start = moment.duration(data.start);
    }
    if (data.end != null) {
        data.end = moment.duration(data.end);
    }

    return data;
}

export function onSerializeRequestedTimeslot(data: RequestedTimeslot): RequestedTimeslot {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };


    return serialized;
}
