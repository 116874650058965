import React from 'react';
import { Checkbox, InputNumber, Input } from 'antd';
import { useTranslate } from '@/language-provider';
import { isArray, isBoolean, isNumber } from 'util';
import moment, { isMoment } from 'moment';
import { DateEditor, TextAreaEditor } from '@/components/editors';


interface ControlOptions {
    value: unknown;
    translateValue: (value: unknown) => React.ReactNode;
    isEditable: boolean;
    onChange: (newValue: unknown) => void;
    isDescription: boolean;
    displayAsText: boolean;
}

function convertValueToControl(options: ControlOptions): React.ReactNode | React.ReactNode[] {
    const { value, isEditable, isDescription, displayAsText, onChange, translateValue } = options;

    if (value == null) {
        return null;
    }

    if (isEditable && isDescription) {
        return <TextAreaEditor autoSize value={value.toString()} disabled={!isEditable} onChange={event => onChange(event.target.value)} />;
    }

    if (isArray(value)) {
        return value.map((x, index) => {
            const subOptions = {...options, value: x };
            return (
                <React.Fragment key={index}>
                    {convertValueToControl(subOptions)}{index !== value.length ? <br /> : <></>}
                </React.Fragment>
            );
        });
    } else if (isEditable && isBoolean(value)) {
        return <Checkbox value={value} disabled={!isEditable} onChange={event => onChange(event.target.value)} />;
    } else if (isNumber(value) && !displayAsText) {
        return <InputNumber value={value} disabled={!isEditable} onChange={onChange} />;
    } else if (isMoment(value)) {
        if (isEditable) {
            return <DateEditor value={value} onChange={onChange} />;
        } else {
            return value.format('L');
        }
    } else {
        if (isEditable) {
            return <Input value={value.toString()} disabled={!isEditable} onChange={event => onChange(event.target.value)} />;
        } else {
            return translateValue(value).toString();
        }
    }
}

export interface FormRowProps<T = unknown> {
    label: string; 
    row?: number;
    value: T; 
    isDescription?: boolean;
    translateValue?: (value: T) => React.ReactNode;
    isEditable?: boolean;
    displayAsText?: boolean;
    onChange?: (newValue: T) => void;
}

export const FormRow: React.FC<FormRowProps> = (props) => {
    const { translate } = useTranslate();
    const { row, label, value, translateValue = x => x, isEditable = false, onChange, isDescription = false, displayAsText = false } = props;

    const options: ControlOptions = {
        value,
        translateValue,
        isEditable,
        isDescription,
        displayAsText,
        onChange
    };

    return (
        <React.Fragment key={`row-${row}`}>
            <div key={`row-${row}-label`} className='label' style={{ gridRow: row, gridColumn: 1 }}>{translate(label)}</div>
            <div key={`row-${row}-value`} className='value' style={{ gridRow: row, gridColumn: 2 }}>{convertValueToControl(options)}</div>
        </React.Fragment>
    );
};

export const DateRow: React.FC<FormRowProps<moment.Moment>> = props => <FormRow {...props} />;
export const StringRow: React.FC<FormRowProps<string>> = props => <FormRow {...props} />;
export const BoolRow: React.FC<FormRowProps<boolean>> = props => <FormRow {...props} />;