import { TimeRange} from './time-range';
import { TimeLineChild, onDeserializeTimeLineChild, onSerializeTimeLineChild} from './time-line-child';
import { Reference} from './reference';
import { DayOfWeek } from './day-of-week';
import { TimeLinePresenceType } from './time-line-presence-type';
import { CareFrequency } from './care-frequency';
import { Caretype } from './caretype';
import moment from 'moment';

export interface TimeLineItem {
    name?: string;
    startDate: moment.Moment;
    endDate: moment.Moment;
    dayOfWeek: DayOfWeek;
    times?: TimeRange;
    expectedTimes?: TimeRange;
    presenceType: TimeLinePresenceType;
    frequency: CareFrequency;
    inHoliday: boolean;
    child?: TimeLineChild;
    group?: Reference;
    originalGroup?: Reference;
    school?: Reference;
    planningId?: string;
    caretype: Caretype;
    state?: string;
    tijdblokId?: string;
    description?: string;
    pakketId?: string;
    isPlannable: boolean;
    pakketuseirregularinpresence: boolean;
    isInternal: boolean;
    packageSupportsIncidental: boolean;
    packageSupportsSwapday: boolean;
    sourceId: string;
    sourceType?: string;
}

export function onDeserializeTimeLineItem(data: TimeLineItem): TimeLineItem {
    if (data == null) {
        return data;
    }

    if (data.startDate != null) {
        data.startDate = moment(data.startDate);
    }
    if (data.endDate != null) {
        data.endDate = moment(data.endDate);
    }
    if (data.child != null) {
        data.child = onDeserializeTimeLineChild(data.child);
    }

    return data;
}

export function onSerializeTimeLineItem(data: TimeLineItem): TimeLineItem {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    serialized.startDate = data.startDate?.toISOString() as unknown as moment.Moment;
    serialized.endDate = data.endDate?.toISOString() as unknown as moment.Moment;
    serialized.child = onSerializeTimeLineChild(data.child);

    return serialized;
}
