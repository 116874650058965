import { ScoredApplication } from './score-calculator';

export interface ApplicationFilter {
    name: string;
    group: string;
    filter: (applications: ScoredApplication[]) => ScoredApplication[];
}

export function toggleApplicationFilter(applicationFilters: ApplicationFilter[], applicationFilter: ApplicationFilter): ApplicationFilter[] {
    if (applicationFilters.some(x => x.name === applicationFilter.name)) {
        return [...applicationFilters.filter(x => x.name !== applicationFilter.name)];
    }

    return [...applicationFilters, applicationFilter];
}