import { Address} from './address';
import { ContactInfo} from './contact-info';
import { Name} from './name';
import { MediaReference} from './media-reference';
import { Gender } from './gender';
import moment from 'moment';

export interface ParentModel {
    parentId: string;
    addresses: Address[];
    contactInformation: ContactInfo[];
    name: Name;
    birthDate?: moment.Moment;
    gender?: Gender;
    picture?: MediaReference;
    hasSSN: boolean;
}

export function onDeserializeParentModel(data: ParentModel): ParentModel {
    if (data == null) {
        return data;
    }

    if (data.birthDate != null) {
        data.birthDate = moment(data.birthDate);
    }

    return data;
}

export function onSerializeParentModel(data: ParentModel): ParentModel {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    serialized.birthDate = data.birthDate?.format('YYYY-MM-DD') as unknown as moment.Moment;

    return serialized;
}
