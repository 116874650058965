import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { DashboardSelection } from '../dashboard-selection';
import { useDispatch, useSelector } from 'react-redux';
import { locationUsagesSelector, locationUsagesStatusSelector } from '@/store/planboard/selectors';
import { PlanboardActions } from '@/store/planboard/actions';
import { GridSelectionMode } from '@/data-grid/grid-selection-mode';
import { locationsSelector, groupsSelector } from '@/store/general/selectors';
import { ScreenContainer } from '@/screens/screen-container';
import { Row, Col } from 'antd';
import { Title } from '@/components/title';
import { Status } from '@/store/status';
import { Location, LocationUsagesResponse, Group, CareType } from '@/client/models';
import { AnyAction } from 'redux';

import R, { Dictionary } from 'ramda';
import { BetweenSchoolCareRow, OutOfSchoolCareRow, PlayschoolRow, DaycareRow, HolidayCareRow } from './care-type-row';
import { SyncOutlined, FilterOutlined } from '@ant-design/icons';

export interface DateRange {
    start: moment.Moment;
    end: moment.Moment;
}

function createDateRange(year: number): DateRange {
    const now = moment.utc().year(year);
    const start = now.clone().startOf('month');
    const end = now.clone().add(1, 'year').endOf('month');

    return { start, end };
}

function loadUsages(selectedLocation: Location, range: DateRange): AnyAction {
    return PlanboardActions.loadUsages(selectedLocation.locationId, range.start, range.end);
}

function getUsedCareTypes(groups: Group[], usages: LocationUsagesResponse): Dictionary<Group[]> {
    const groupIds = R.uniqBy(x => x.groupId, usages.groups);
    const usedGroups = groupIds.map(x => groups.find(y => y.groupId === x.groupId));

    return R.groupBy(x => x.careType, usedGroups);
}

export function PlanboardCapacityDashboardScreen(): React.ReactElement {
    const dispatch = useDispatch();
    
    const usagesStatus = useSelector(locationUsagesStatusSelector);
    const usages = useSelector(locationUsagesSelector) || new Array<LocationUsagesResponse>();
    const locations = useSelector(locationsSelector) || [];
    const groups = useSelector(groupsSelector);
    const [selectedLocation, setSelectedLocation] = useState(locations[0]) ?? [{} as Location, undefined];
    const [dashboardSelectionVisible, setDashboardSelectionVisible] = useState(false);
    const [year, setYear] = useState(moment().year());
    const [range, setRange] = useState(createDateRange(year));
    const [usedCareTypes, setUsedCareTypes] = useState({} as Dictionary<Group[]>);

    useEffect(() => setRange(createDateRange(year)), [year]);

    useEffect(() => { dispatch(loadUsages(selectedLocation, range)); }, [selectedLocation, range]);
    useEffect(() => {
        if (usagesStatus.length > 0 && usagesStatus.every(x => x === Status.Loaded)) {
            setUsedCareTypes(getUsedCareTypes(groups, usages.find(x => x.locationId === selectedLocation.locationId)));
        }
    }, [groups, usages, usagesStatus]);

    if (usages.find(x => x.locationId === selectedLocation.locationId) == null) {
        dispatch(loadUsages(selectedLocation, range));
    }

    return (
        <ScreenContainer showFullScreen={true} menuItems={[
            { icon: <SyncOutlined />, iconOnly: true, translation: 'global.button.refresh', 
                onClick: () => dispatch(PlanboardActions.loadUsages(selectedLocation.locationId, range.start, range.end)), position: 'right' },
            { icon: <FilterOutlined />, translation: 'planboard.filter', onClick: () => setDashboardSelectionVisible(true), position: 'right' }
        ]}>
            <DashboardSelection visible={dashboardSelectionVisible} selected={selectedLocation.locationId} year={year} selectionMode={GridSelectionMode.Single}
                onSelect={(year, newSelection) => {
                    setYear(year);
                    setSelectedLocation(newSelection.length === 0 ? undefined : locations.find(x => x.locationId === newSelection[0]));
                    setDashboardSelectionVisible(false);
                }}
                onCancel={() => setDashboardSelectionVisible(false)} />

                
            <Row><Col sm={24}><Title text={selectedLocation.name} align='center' /></Col></Row>

            {usedCareTypes[CareType.OutOfSchoolCare] && <OutOfSchoolCareRow dateRange={range} groups={usedCareTypes[CareType.OutOfSchoolCare]} />}
            {usedCareTypes[CareType.BetweenSchoolCare] && <BetweenSchoolCareRow dateRange={range} groups={usedCareTypes[CareType.BetweenSchoolCare]}  />}
            {usedCareTypes[CareType.Playschool] && <PlayschoolRow dateRange={range} groups={usedCareTypes[CareType.Playschool]}  />}
            {usedCareTypes[CareType.Daycare] && <DaycareRow dateRange={range} groups={usedCareTypes[CareType.Daycare]}  />}
            {usedCareTypes[CareType.HolidayCare] && <HolidayCareRow dateRange={range} groups={usedCareTypes[CareType.HolidayCare]}  />}

        </ScreenContainer>
    );
}