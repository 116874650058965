import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Group, GroupTimeSlotUsages, AgeClassifications } from '@/client/models';
import { Title } from '@/components/title';
import { useSelector } from 'react-redux';
import { locationUsagesRetrievedSelector } from '@/store/planboard/selectors';
import moment from 'moment';
import { ChartData, Line } from 'react-chartjs-2';
import * as chartjs from 'chart.js';
import { DateRange } from './dashboard';
import { Status } from '@/store/status';
import { Row, Col } from 'antd';

interface Props {
    group: Group;
    dateRange: DateRange;
}

function forEachMonth<T>(dateRange: DateRange, selector: (i: number) => T): T[] {
    const start = dateRange.start.month();
    const end = dateRange.end.month() + 12;

    const result = new Array<T>();

    for (let i = start; i < end; i++) {
        result.push(selector(i % 12));
    }

    return result;
}

const chartColors = {
    red: (opacity = 1) => `rgba(255, 99, 132, ${opacity})`,
    orange: (opacity = 1) => `rgba(255, 159, 64, ${opacity})`,
    yellow: (opacity = 1) => `rgba(255, 205, 86, ${opacity})`,
    green: (opacity = 1) => `rgba(75, 192, 192, ${opacity})`,
    blue: (opacity = 1) => `rgba(54, 162, 235, ${opacity})`,
    purple: (opacity = 1) => `rgba(153, 102, 255, ${opacity})`,
    grey: (opacity = 1) => `rgba(201, 203, 207, ${opacity})`
};

function combineAgeClassifications(classifications1: AgeClassifications, classifications2: AgeClassifications): AgeClassifications {
    return {
        age0: classifications1.age0 || 0 + classifications2.age0 || 0,
        age1: classifications1.age1 || 0 + classifications2.age1 || 0,
        age2: classifications1.age2 || 0 + classifications2.age2 || 0,
        age3: classifications1.age3 || 0 + classifications2.age3 || 0,
        age4To7: classifications1.age4To7 || 0 + classifications2.age4To7 || 0,
        age7ToBso: classifications1.age7ToBso || 0 + classifications2.age7ToBso || 0,
    };
}

function getAgeCount(age: number, month: number, classifications: Array<AgeClassifications>): number {
    switch (age) {
        case 0: return classifications[month] == null ? 0 : classifications[month].age0 || 0;
        case 1: return classifications[month] == null ? 0 : classifications[month].age1 || 0;
        case 2: return classifications[month] == null ? 0 : classifications[month].age2 || 0;
        case 3: return classifications[month] == null ? 0 : classifications[month].age3 || 0;
    }

    if (age >= 4 && age < 7) {
        return classifications[month] == null ? 0 : classifications[month].age4To7 || 0;
    }

    return classifications[month] == null ? 0 : classifications[month].age7ToBso || 0;
}

function addToSet(datasets: unknown[], dataset: number[], label: string, colorSelector: (opacity: number) => string): void {
    if (dataset == null || dataset.length === 0) {
        return;
    }

    if (dataset.reduce((acc, cur) => acc + cur, 0) === 0) {
        return;
    }

    datasets.push({
        label: label,
        borderColor: colorSelector(0.4),
        backgroundColor: colorSelector(1),
        data: dataset,
        steppedLine: 'after'
    });
}

function splitToDataSets(dateRange: DateRange, classifications: Array<AgeClassifications>): unknown[] {

    const age0Set = forEachMonth(dateRange, x => getAgeCount(0, x, classifications));
    const age1Set = forEachMonth(dateRange, x => getAgeCount(1, x, classifications));
    const age2Set = forEachMonth(dateRange, x => getAgeCount(2, x, classifications));
    const age3Set = forEachMonth(dateRange, x => getAgeCount(3, x, classifications));
    const age4To7Set = forEachMonth(dateRange, x => getAgeCount(4, x, classifications));
    const age7ToBsoSet = forEachMonth(dateRange, x => getAgeCount(7, x, classifications));

    const datasets = new Array<unknown>();
    addToSet(datasets, age0Set, '0', chartColors.red);
    addToSet(datasets, age1Set, '1', chartColors.orange);
    addToSet(datasets, age2Set, '2', chartColors.yellow);
    addToSet(datasets, age3Set, '3', chartColors.green);
    addToSet(datasets, age4To7Set, '4 - 7', chartColors.blue);
    addToSet(datasets, age7ToBsoSet, '7+', chartColors.purple);

    return datasets;
}

function createChartData(dateRange: DateRange, usages: Array<GroupTimeSlotUsages>): ChartData<chartjs.ChartData> {

    const months = moment.months();
    const labels = forEachMonth(dateRange, x => months[x]);

    const classifications = new Array<AgeClassifications>(12);
    for (const usage of usages.flatMap(x => x.usages)) {
        const monthStart = usage.start.month();
        let monthEnd = usage.end.month();
        if (monthStart > monthEnd) {
            monthEnd += 12;
        }

        for (let month = monthStart; month <= monthEnd; month++) {
            classifications[month % 12] = usage.moments.reduce((acc, cur) => combineAgeClassifications(acc, cur.ages), {} as AgeClassifications);
        }
    }

    return {
        labels: labels,
        datasets: splitToDataSets(dateRange, classifications)
    };
}

export function GroupCard({ group, dateRange }: Props): React.ReactElement {

    const allUsages = useSelector(locationUsagesRetrievedSelector);
    const locationUsages = allUsages.find(x => x.value.locationId === group.locationId);
    const usagesStatus = locationUsages?.status ?? Status.Init;
    const usages = locationUsages?.value;

    const chartData: ChartData<chartjs.ChartData> = useMemo(() => {
        if (usages.groups == null) {
            return null;
        }

        const groupUsage = usages.groups.find(x => x.groupId === group.groupId);
        if (groupUsage != null) {
            const chartData = createChartData(dateRange, groupUsage.timeSlots);
            chartData.datasets.push({
                label: 'Capaciteit',
                yAxisID: 'capacity-axis',
                borderColor: chartColors.red(1),
                data: forEachMonth(dateRange, () => group.capacity)
            });

            return chartData;
        }

        return null;
    }, [usagesStatus]);

    return (
        <HoveredCard>
            <Row>
                <Col><Title text={group.name} /></Col>
            </Row>
            <Row>
                <Col>
                    {usagesStatus === Status.Loaded &&
                        <Line data={chartData} options={{
                            responsive: true,
                            scales: {
                                yAxes: [{
                                    stacked: true,
                                    ticks: {
                                        min: 0,
                                        suggestedMax: group.capacity + 1,
                                        stepSize: 1
                                    }
                                },
                                { 
                                    id: 'capacity-axis',
                                    display: false,
                                    ticks: {
                                        min: 0,
                                        suggestedMax: group.capacity + 1,
                                        stepSize: 1
                                    }
                                }]
                            }
                        }} />
                    }
                </Col>
            </Row>
        </HoveredCard>
    );
}

const HoveredCard = styled.div`
    display: inline-block;
    height: 100%;
    width: 100%;
    overflow: hidden;
    border: 1px rgba(0, 0, 0, 0.09) solid;
    margin-bottom: 5px;
    background-color: ${props => props.theme.background};
    color: ${props => props.theme.inverted};
`;