import { GroupTimeSlotUsages, onDeserializeGroupTimeSlotUsages, onSerializeGroupTimeSlotUsages} from './group-time-slot-usages';

export interface GroupUsages {
    groupId: string;
    timeSlots: GroupTimeSlotUsages[];
}

export function onDeserializeGroupUsages(data: GroupUsages): GroupUsages {
    if (data == null) {
        return data;
    }

    if (data.timeSlots != null) {
        for (let i = 0; i < data.timeSlots.length; i++) {
            if (data.timeSlots[i] != null) {
                data.timeSlots[i] = onDeserializeGroupTimeSlotUsages(data.timeSlots[i]);
            }
        }
    }

    return data;
}

export function onSerializeGroupUsages(data: GroupUsages): GroupUsages {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    if (data.timeSlots != null) {
        for (let i = 0; i < data.timeSlots.length; i++) {
            serialized.timeSlots[i] = onSerializeGroupTimeSlotUsages(data.timeSlots[i]);
        }
    }      

    return serialized;
}
