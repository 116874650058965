/* eslint-disable @typescript-eslint/explicit-function-return-type */

import axios, { AxiosRequestConfig, AxiosError as InternalAxiosError } from 'axios';
import { Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { ApplicationState } from '@/store/application-state';
import {accessTokenSelector, refreshTokenSelector, backendUrSelector} from '@/store/authentication/selectors';
import { createDependencies } from '@/store/dependencies';
import { AuthenticationActions } from '@/store/authentication/actions';
import { GeneralActions } from '@/store/general/actions';
import { AxiosHeaders } from '@/client/utilities/merge-headers';

type AxiosRequest = AxiosRequestConfig & { _requestId: number };
type AxiosError = Omit<InternalAxiosError, 'config'> & { config: AxiosRequest };

let createdInterceptors = false;

function isRetry(config: AxiosRequest): boolean {
    return config._requestId !== undefined;
}

let nextRequestId = 0;
function setRetryRequestId(config: AxiosRequest) {
    const adapter = config.adapter;
    const requestId = config._requestId || nextRequestId++;

    config.adapter = (config: AxiosRequest) => {
        config._requestId = requestId;
        return adapter(config);
    };
}

const setDefaultHeaders = (state: ApplicationState) => {    
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessTokenSelector(state)}`;

    const backendUrl = backendUrSelector(state);
    if (backendUrl != null) {
        axios.defaults.headers.common['remoteServer'] = backendUrl;
    }
};

export const authorizationInterceptor: Middleware = (api: MiddlewareAPI<Dispatch, ApplicationState>) => next => action => {
    // @ts-ignore
    if (action.type === AuthenticationActions.loginSuccess.__actionName) {
        const authSuccessAction = action as ReturnType<typeof AuthenticationActions.loginSuccess>;
        axios.defaults.headers.common['Authorization'] = `Bearer ${authSuccessAction.payload.accessToken}`;
    }

    // @ts-ignore
    if (action.type === GeneralActions.applicationStart.__actionName) {
        const accessToken = accessTokenSelector(api.getState());
        if (accessToken != null) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        }
    }

    // @ts-ignore
    if (action.type === AuthenticationActions.renewToken.__actionName) {
        const authSuccessAction = action as ReturnType<typeof AuthenticationActions.renewToken>;
        axios.defaults.headers.common['Authorization'] = `Bearer ${authSuccessAction.payload.accessToken}`;
    }

    // @ts-ignore
    if (action.type === AuthenticationActions.configureForDevelopment.__actionName) {
        const configureAction = action as ReturnType<typeof AuthenticationActions.configureForDevelopment>;
        if (configureAction.payload.backendUrl != null) {
            axios.defaults.headers.common['remoteServer'] = configureAction.payload.backendUrl;
        }
    }

    if (!createdInterceptors) {
        axios.interceptors.response.use(response => response, (error: AxiosError) => {
            if (error.response == null) {
                return Promise.reject(error);
            }

            if (isRetry(error.config) || error.code === 'ECONNABORTED' || error.response.status !== 401 || error.config.url.endsWith('auth/refresh')) {
                if (error.config.url.endsWith('auth/refresh')) {
                    api.dispatch(AuthenticationActions.signOut());
                }

                return Promise.reject(error);
            }

            const state = api.getState();
            const refreshToken = refreshTokenSelector(state);
            if (refreshToken == null) {
                return Promise.reject(error);
            }

            const dependencies = createDependencies();

            const headers = {
                'Authorization': `Bearer ${refreshToken}`
            } as AxiosHeaders;

            return dependencies.authApi(state).getRefresh(headers).then(response => {
                api.dispatch(AuthenticationActions.renewToken(response.data));
                error.config.headers['Authorization'] = `Bearer ${response.data.accessToken}`;

                setRetryRequestId(error.config);
                return axios(error.config);
            });
        });

        createdInterceptors = true;
    }

    const result = next(action);

    // @ts-ignore
    if (action.type === 'persist/REHYDRATE') {
        setDefaultHeaders(api.getState());
    }

    return result;
};