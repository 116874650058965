import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios, { AxiosError } from 'axios';
import { isOfflineSelector } from '../store/ui/selectors';
import { UIActions } from '../store/ui/actions';
import Modal from 'antd/lib/modal/Modal';
import { Translate } from 'react-localize-redux';
import { ApiOutlined } from '@ant-design/icons';

export const Offline: React.FC = () => {
    const dispatch = useDispatch();
    const offlineRef = useRef<boolean>();
    offlineRef.current = useSelector(isOfflineSelector);
    
    useEffect(() => {
        axios.interceptors.response.use(response => {

            if (offlineRef.current) {
                dispatch(UIActions.online());
            }

            return response;
        }, (error: AxiosError) => {
            if (error.response == null && !offlineRef.current) {
                dispatch(UIActions.offline());
            }

            return error;
        });
    }, []);

    return (
        <Modal visible={offlineRef.current} title='Offline' footer={null} closable={false}>
            <ApiOutlined />&nbsp;<Translate id='global.generic.offline' />
        </Modal>
    );
};