/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { TokenModel, UserModel } from '@/client/models';
import { GenericError } from '@/store/generic-error';
import { ActionsUnion } from '@/store/types';
import { action } from '@/store/decorators';
import { Action } from '@/store/action';

export class AuthenticationActions {
    public static __className = 'AuthenticationActions';

    @action
    static tenant(tenant: string) { return Action('tenant', { tenant }); }
    @action
    static signOut() { return Action('signOut'); }

    @action
    static login(userName: string, password: string) { return Action('login', { userName, password }); }
    @action
    static loginSuccess(response: TokenModel) { return Action('loginSuccess', { ...response }); }
    @action
    static loginFailed(error: GenericError<typeof AuthenticationActions.login>) { return Action('loginFailed', { error }); }

    @action
    static retrieveAccount() { return Action('retrieveAccount'); }
    @action
    static retrieveAccountSuccess(response: UserModel) { return Action('retrieveAccountSuccess', { ...response }); }
    @action
    static retrieveAccountFailed(error: GenericError<typeof AuthenticationActions.retrieveAccount>) { return Action('retrieveAccountFailed', { error }); }
    
    @action
    static renewToken(response: TokenModel) { return Action('renewToken', { ...response }); }
    
    @action
    static developMode() { return Action('developMode'); }

    @action
    static configureForCustom(apiUrl: string, backendUrl: string) { return Action('configureForCustom', { apiUrl, backendUrl }); }
    @action
    static configureForDevelopment(withLocal: boolean) { return Action('configureForDevelopment', { apiUrl: 'http://localhost:5000', backendUrl: withLocal ? 'http://backoffice.local/' : undefined }); }
    @action
    static configureForTesting() { return Action('configureForTesting', { apiUrl: 'https://api-test.kindplanner.nl' }); }
    @action
    static configureForProduction() { return Action('configureForProduction', { apiUrl: 'https://api.kindplanner.nl' }); }

}

export type Actions = ActionsUnion<typeof AuthenticationActions>;