import React, { useState } from 'react';
import styled from 'styled-components';
import { faCalendarAlt, faEllipsisV, faClock } from '@fortawesome/free-solid-svg-icons';

import { FaIcon } from '@/components/fa-icon';
import { ContextMenu } from '@/components/context-menu/context-menu';
import { ContextMenuItem } from '@/components/context-menu/context-menu-item';
import { Translate } from 'react-localize-redux';
import { useApplicationPartProvider, usePartChanged } from '@/planboard/planner-application-part-provider';
import { useGroupPeriod } from './group-card';

export const GroupAllTimeSlot: React.FC = () => {
    const provider = useApplicationPartProvider();
    const groupPeriod = useGroupPeriod();

    const [isSelected, setIsSelected] = useState(groupPeriod.times.every(x => provider.isSelected(x)));
    
    usePartChanged(() => {
        const allSelected = groupPeriod.times.every(x => provider.isSelected(x));
        setIsSelected(allSelected);
    });

    function selectAll(): void {
        for (const time of groupPeriod.times) {
            if (isSelected) {
                provider.unselect(time, false);
            } else {
                provider.select(time, false);
            }
        }

        provider.changed();
    }
    
    const ellipsisRef = React.createRef<HTMLElement>();

    return (
        <Container isSelected={isSelected}>
            <ContextMenu target={ellipsisRef}>
                <ContextMenuItem icon={faCalendarAlt} translation='planboard.adjust-date' onClick={() => { /* */}} />
                <ContextMenuItem icon={faClock} translation='planboard.adjust-time' onClick={() => { return false; }} />
            </ContextMenu>

            <TextContainer onClick={selectAll}>
                <Translate id='planboard.all' />
            </TextContainer>
            
            <EllipsisProperty ref={ellipsisRef}>
                <FaIcon icon={faEllipsisV} />
            </EllipsisProperty>

        </Container>
    );
};


const Container = styled.div<{ isSelected: boolean }>`
    display: flex;
    align-items: center;

    padding-left: 5px;
    border-bottom: 1px solid lightgray;
    border-right: 1px solid lightgray;
    border-top-left-radius: 10px;
    background-color: ${props => props.isSelected ? props.theme.tertiary : props.theme.background};
    color: ${props => props.isSelected ? props.theme.background : props.theme.inverted};
    min-height: 35px;

    :hover {
        border-color: rgba(0, 0, 0, 0.09);
        box-shadow: 0 2px 8px rgba(0,0,0, 0.09);
        cursor: pointer;
    }
`;

const TextContainer = styled.span`
    flex-grow: 1;
    text-align: center;
`;

const EllipsisProperty = styled.span`
    padding-right: 10px;
    opacity: 0.4;
    
    width: 25px;
    display: flex;
    justify-content: center;

    :hover {
        opacity: 1.0;
    }
`;