import React from 'react';
import {Avatar, List, Menu} from 'antd';
import {useSelector, useDispatch} from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { Translate } from 'react-localize-redux';
import { userSelector, authenticationStatusSelector/*, accessTokenSelector*/ } from '@/store/authentication/selectors';
import { AuthenticationActions } from '@/store/authentication/actions';
import { Status } from '@/store/authentication/state';

export function ProfileMenu(): React.ReactElement {
    const dispatch = useDispatch();

    const status = useSelector(authenticationStatusSelector);
    const user = useSelector(userSelector);

    if (status !== Status.Authenticated || user == null) {
        return <Menu></Menu>;
    }
    
    return (
        <Menu>
            <Menu.Item>
                <List.Item>
                    <List.Item.Meta avatar={<Avatar icon='user' size={45} />} title={<a href="">{user.fullName}</a>} />
                </List.Item>
            </Menu.Item>
            <Menu.Item onClick={() => dispatch(AuthenticationActions.signOut())}>
                <FontAwesomeIcon icon={faSignOutAlt} /> <Translate id='global.profileMenu.signOut'/>
            </Menu.Item>
        </Menu>
    );
}