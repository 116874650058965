import { TimeSlotMoment, onDeserializeTimeSlotMoment, onSerializeTimeSlotMoment} from './time-slot-moment';
import moment from 'moment';

export interface TimeSlotUsage {
    start: moment.Moment;
    end: moment.Moment;
    moments: TimeSlotMoment[];
}

export function onDeserializeTimeSlotUsage(data: TimeSlotUsage): TimeSlotUsage {
    if (data == null) {
        return data;
    }

    if (data.start != null) {
        data.start = moment(data.start);
    }
    if (data.end != null) {
        data.end = moment(data.end);
    }
    if (data.moments != null) {
        for (let i = 0; i < data.moments.length; i++) {
            if (data.moments[i] != null) {
                data.moments[i] = onDeserializeTimeSlotMoment(data.moments[i]);
            }
        }
    }

    return data;
}

export function onSerializeTimeSlotUsage(data: TimeSlotUsage): TimeSlotUsage {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    serialized.start = data.start?.format('YYYY-MM-DD') as unknown as moment.Moment;
    serialized.end = data.end?.format('YYYY-MM-DD') as unknown as moment.Moment;
    if (data.moments != null) {
        for (let i = 0; i < data.moments.length; i++) {
            serialized.moments[i] = onSerializeTimeSlotMoment(data.moments[i]);
        }
    }      

    return serialized;
}
