/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { LocationsResponse, GroupsResponse, TimeSlotsResponse, CarePackagesResponse } from '@/client/models';
import { GenericError } from '@/store/generic-error';
import { ActionsUnion } from '@/store/types';
import { action } from '@/store/decorators';
import { Action } from '@/store/action';

export class GeneralActions {
    public static __className = 'GeneralActions';

    @action
    static applicationStart() { return Action('applicationStart'); }
    
    @action
    static resetApp() { return Action('resetApp'); }
    
    @action
    static loadLocations() { return Action('loadLocations'); }
    @action
    static loadLocationsSuccess(response: LocationsResponse) { return Action('loadLocationsSuccess', { ...response }); }
    @action
    static loadLocationsFailed(error: GenericError<typeof GeneralActions.loadLocations>) { return Action('loadLocationsFailed', { error }); }

    @action
    static loadGroups() { return Action('loadGroups'); }
    @action
    static loadGroupsSuccess(response: GroupsResponse) { return Action('loadGroupsSuccess', { ...response }); }
    @action
    static loadGroupsFailed(error: GenericError<typeof GeneralActions.loadGroups>) { return Action('loadGroupsFailed', { error }); }
    
    @action
    static loadCarePackages() { return Action('loadCarePackages'); }
    @action
    static loadCarePackagesSuccess(response: CarePackagesResponse) { return Action('loadCarePackagesSuccess', { ...response }); }
    @action
    static loadCarePackagesFailed(error: GenericError<typeof GeneralActions.loadCarePackages>) { return Action('loadCarePackagesFailed', { error }); }

    @action
    static loadTimeSlots() { return Action('loadTimeSlots'); }
    @action
    static loadTimeSlotsSuccess(response: TimeSlotsResponse) { return Action('loadTimeSlotsSuccess', { ...response }); }
    @action
    static loadTimeSlotsFailed(error: GenericError<typeof GeneralActions.loadTimeSlots>) { return Action('loadTimeSlotsFailed', { error }); }
}

export type Actions = ActionsUnion<typeof GeneralActions>;