/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useState } from 'react';
import { TableDefinition, DataTableAdapter } from './table-definition';
import { ColumnOption } from './column-options-drawer';
import R from 'ramda';
import { DataGridColumn, DataGridColumnProps } from './data-grid-column';
import { ColumnPicker, ColumnPickerProps } from './column-picker';
import { GridLayout, createGridLayout, createColumnLayout, copyLayout, findOrCreateColumnLayout, findColumnLayout } from './grid-layout';
import { GridLayoutContext } from './grid-layout-persistance-provider';
import { GridSelectionMode } from './grid-selection-mode';
import { PaginationConfig } from 'antd/lib/pagination';
import { SorterResult } from 'antd/lib/table/interface';

type AllowedChildren = React.ReactElement<DataGridColumnProps> | React.ReactElement<ColumnPickerProps>;

class NullDataGridAdapter implements DataGridAdapter {
    dataSource(): any[] { return null; }
    beforeSavingLayout(): void { /* */ }
    applyLayout(): void { /* */ }
    attach(): void { /* */ }
    render(): void { /* */ }
}

export interface DataGridAdapter extends DataTableAdapter {
    beforeSavingLayout(newLayout: GridLayout, pagination: PaginationConfig, sorter: SorterResult<any>): void;
    applyLayout(newLayout: GridLayout): void;
}

export interface DataGridProps<T> {
    children: AllowedChildren[] | AllowedChildren;
    name?: string;
    datasource?: T[];
    keyPath?: keyof T;
    selection?: any[];
    layout?: GridLayout;
    useProvider?: boolean;
    defaultColumns?: string[];
    selectionMode?: GridSelectionMode;
    loading?: boolean;
    adapter?: DataGridAdapter;
    keySelector?: (item: T) => string;
    layoutChanged?(layout: GridLayout): void;
    onSelectionChanged?(items: T[]): void;
    onRowClicked?(item: T): void;
}

export function DataGrid<T = any>(props: DataGridProps<T>): React.SFCElement<DataGridProps<T>> {
    const { 
        name, datasource, keyPath, selection, onSelectionChanged, onRowClicked, children, defaultColumns, loading, adapter = new NullDataGridAdapter(),
        layoutChanged = () => { /* */ }, useProvider = false, selectionMode = GridSelectionMode.Multiple
    } = props;

    const persistance = useContext(GridLayoutContext);

    let keySelector = props.keySelector;
    if (keySelector == null) {
        keySelector = x => x[keyPath] as unknown as string;
    }

    const table = new TableDefinition<any>(keySelector, selection, onSelectionChanged, onRowClicked, selectionMode);
    table.loading = loading;
    table.onTableChanged(onTableChange);

    let columnPickerProps: ColumnPickerProps;

    if (Array.isArray(children)) {
        for (const child of children) {
            switch (child.type) {
                case DataGridColumn:
                    new DataGridColumn(child.props as DataGridColumnProps).apply(table);
                    break;
                case ColumnPicker:
                    columnPickerProps = child.props as ColumnPickerProps;
            }
        }
    } else {
        switch (children.type) {
            case DataGridColumn:
                new DataGridColumn(children.props as DataGridColumnProps).apply(table);
                break;
            case ColumnPicker:
                columnPickerProps = children.props as ColumnPickerProps;
        }
    }

    const currentLayout = (useProvider ? persistance.load(name) : props.layout) || createDefaultLayout(name, table, defaultColumns);

    const [layout, setInternalLayout] = useState(currentLayout);

    function onColumnOptionsChanged(options: ColumnOption[]): void {
        const selectedColumns = R.sortBy(x => x.order, options)
            .filter(x => x.visible)
            .map(x => ({ name: x.key, columLayout: findOrCreateColumnLayout(layout, x.key) }))
            .map(x => createColumnLayout(x.name, x.columLayout.filter, x.columLayout.sort));

        const newLayout = createGridLayout(props.name, selectedColumns, layout.pageSize);

        if (layoutChanged != null) {
            layoutChanged(newLayout);
        }

        if (useProvider) {
            persistance.store(newLayout);
        }
        
        setInternalLayout(newLayout);

        if (columnPickerProps != null && columnPickerProps.onClose != null) {
            columnPickerProps.onClose();
        }
    }

    function onLayoutReset(): void {

        if (useProvider) {
            persistance.clear(layout.name);
        } 
        
        setInternalLayout(createDefaultLayout(name, table, defaultColumns));

        if (columnPickerProps != null && columnPickerProps.onClose != null) {
            columnPickerProps.onClose();
        }
    }

    function onTableChange(pagination: PaginationConfig, filters: Record<keyof any, string[]>, sorter: SorterResult<any>): void {
        const newLayout = copyLayout(layout);

        for (const column of newLayout.columns) {
            column.filter = filters[column.name];
            if (column.name === sorter.columnKey) {
                column.sort = sorter.order;
            } else {
                column.sort = undefined;
            }
        }

        if (layoutChanged != null) {
            layoutChanged(newLayout);
        }

        if (useProvider) {
            adapter.beforeSavingLayout(newLayout, pagination, sorter);

            persistance.store(newLayout);
        }

        setInternalLayout(newLayout);
    }

    table.setAdapter(adapter);

    applyLayout(table, layout);

    adapter.applyLayout(layout);

    return (<>
        {columnPickerProps && table.createColumnOptionsDrawer(columnPickerProps.visible, onColumnOptionsChanged, columnPickerProps.onClose, onLayoutReset)}
        {table.createTable(datasource)}
    </>);
}

function applyLayout(table: TableDefinition<any>, layout: GridLayout): void {
    for (const column of table.columns) {
        const result = findColumnLayout(layout, column.dataIndex());
        if (result.index === -1) {
            column.visible = false;
            column.sortOrder(null);
            column.filteredValues(null);
        } else {
            column.visible = true;
            column.order = result.index;
            
            column.sortOrder(result.column.sort ? result.column.sort : null);
            column.filteredValues(result.column.filter ? result.column.filter : null);
        }
    }
}

function createDefaultLayout(name: string, table: TableDefinition<any>, defaultColumns?: string[]): GridLayout {

    if (defaultColumns != null) {
        return createGridLayout(name, defaultColumns.map(x => table.columns.find(c => c.dataIndex() === x)));
    }

    return createGridLayout(name, table.columns);
}