import { DayOfWeek } from './day-of-week';
import { TimeType } from './time-type';
import { CareType } from './care-type';
import { TimeslotFrequency } from './timeslot-frequency';
import moment from 'moment';

export interface ApplicationScheduleLine {
    schedulePartId: string;
    packageId: string;
    groupId?: string;
    timeslotId: string;
    from: moment.Moment;
    until?: moment.Moment;
    day: DayOfWeek;
    start: moment.Duration;
    end: moment.Duration;
    careTimeId: string;
    timeType: TimeType;
    careType: CareType;
    frequency: TimeslotFrequency;
    groupTransitionAfterBirthDate: boolean;
    compositionId?: string;
}

export function onDeserializeApplicationScheduleLine(data: ApplicationScheduleLine): ApplicationScheduleLine {
    if (data == null) {
        return data;
    }

    if (data.from != null) {
        data.from = moment(data.from);
    }
    if (data.until != null) {
        data.until = moment(data.until);
    }
    if (data.start != null) {
        data.start = moment.duration(data.start);
    }
    if (data.end != null) {
        data.end = moment.duration(data.end);
    }

    return data;
}

export function onSerializeApplicationScheduleLine(data: ApplicationScheduleLine): ApplicationScheduleLine {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    serialized.from = data.from?.format('YYYY-MM-DD') as unknown as moment.Moment;
    serialized.until = data.until?.format('YYYY-MM-DD') as unknown as moment.Moment;

    return serialized;
}
