import { useContext } from 'react';
import { __RouterContext as RouterContext, RouteComponentProps } from 'react-router';

export function useRouter(): RouteComponentProps {
    return useContext(RouterContext);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useParams(): any {
    const { match } = useRouter();
    return match.params;
}

type navigateFunc = (to: string, options: { replace: boolean }) => void;

export function useLocation(): { location: RouteComponentProps['location']; navigate: navigateFunc} {
    const { location, history } = useRouter();
    
    function navigate(to: string, { replace = false } = {}): void {
        if (replace) {
            history.replace(to);
        } else {
            history.push(to);
        }
    }
    
    return {
        location,
        navigate
    };
}