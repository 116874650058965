import React, { useState } from 'react';
import styled from 'styled-components';
import { OverCapacityStatus, CanStartOnStatus, ExtraEmployeeStatus } from './status';
import { faCalendarAlt, faCalendarPlus, faCalendarMinus, faCalendarDay, faEllipsisV, IconDefinition, faClock } from '@fortawesome/free-solid-svg-icons';

import { formatTime } from '@/momentjs/duration-helper';
import { ApplicationScheduleLine, TimeslotFrequency } from '@/client/models';
import { FaIcon } from '@/components/fa-icon';
import { ApplicationPeriodGroupTime } from '@/store/planboard/state';
import { Translate } from 'react-localize-redux';
import { useApplicationPartProvider, usePartChanged } from '@/planboard/planner-application-part-provider';
import { ContextMenu } from '@/components/context-menu/context-menu';
import { ContextMenuItem } from '@/components/context-menu/context-menu-item';
import { useComposition } from './composition-card';
import { GroupCardDatePicker } from './group-card-date-picker';
import moment from 'moment';

interface Props {
    isLast: boolean;
    groupPeriodLine: ApplicationPeriodGroupTime;
}

export function GroupTimeSlot({ isLast, groupPeriodLine }: Props): React.ReactElement {
    const provider = useApplicationPartProvider();
    const composition = useComposition();
    const [isSelected, setIsSelected] = useState(provider.isSelected(groupPeriodLine));
    const select = (): void => provider.toggle(groupPeriodLine);

    const [showDatePicker, setShowDatePicker] = useState(false);

    usePartChanged(() => {
        setIsSelected(provider.isSelected(groupPeriodLine));
    });

    function changePeriod(from: moment.Moment, till: moment.Moment): void {
        provider.splitComposition(composition, from, till);
        setShowDatePicker(false);
    }

    const ellipsisRef = React.createRef<HTMLElement>();

    const iconType = getIcon(groupPeriodLine.line);
    const status = groupPeriodLine.status;
    const hasStatus = (status.canStartOn != null || status.extraEmployees.length > 0 || status.overCapacity.length > 0) && !isSelected;

    return (
        <GroupCardTimeSlotContainer isBottom={isLast} isSelected={isSelected} hasStatus={hasStatus}>

            <GroupCardTimeSlotType onClick={select}><FaIcon {...iconType} /></GroupCardTimeSlotType>

            <GroupCardTimeSlotDay onClick={select}>
                <span><Translate id={`global.day-of-week.short.${groupPeriodLine.line.day}`} /></span>
                <span>{formatTime(groupPeriodLine.line.start)}</span>
                <span>-</span>
                <span>{formatTime(groupPeriodLine.line.end)}</span>
            </GroupCardTimeSlotDay>

            <GroupCardTimeSlotStatusContainer onClick={select}>
                <OverCapacityStatus status={groupPeriodLine.status} />
                <CanStartOnStatus status={groupPeriodLine.status} />
                <ExtraEmployeeStatus status={groupPeriodLine.status} />
            </GroupCardTimeSlotStatusContainer>

            <ContextMenu target={ellipsisRef}>
                <ContextMenuItem icon={faCalendarAlt} translation='planboard.adjust-date' onClick={() => { setShowDatePicker(true); }} />
                <ContextMenuItem icon={faClock} translation='planboard.adjust-time' onClick={() => { return false; }} />
            </ContextMenu>
            
            <EllipsisProperty ref={ellipsisRef}>
                <FaIcon icon={faEllipsisV} />
            </EllipsisProperty>

            <GroupCardDatePicker 
                visible={showDatePicker} 
                from={groupPeriodLine.groupPeriod.start} 
                till={groupPeriodLine.groupPeriod.end} 
                onOk={changePeriod} 
                onClose={() => setShowDatePicker(false)} />

        </GroupCardTimeSlotContainer>
    );
}

function getIcon(line: ApplicationScheduleLine): { icon: IconDefinition; tooltipTranslation: string } {
    switch (line.frequency) {
        case TimeslotFrequency.Always:
            return { icon: faCalendarAlt, tooltipTranslation: 'planboard.frequency.always' };
        case TimeslotFrequency.EvenWeeks:
            return { icon: faCalendarPlus, tooltipTranslation: 'planboard.frequency.even-weeks' };
        case TimeslotFrequency.OddWeeks:
            return { icon: faCalendarMinus, tooltipTranslation: 'planboard.frequency.odd-weeks' };
        case TimeslotFrequency.Irregular:
            return { icon: faCalendarDay, tooltipTranslation: 'planboard.frequency.irregular' };
    }
}


const GroupCardTimeSlotContainer = styled.div<{ isBottom: boolean; isSelected: boolean; hasStatus: boolean }>`
    display: flex;
    align-items: center;

    padding-left: 5px;
    border-bottom: ${props => props.isBottom ? 'none' : '1px solid lightgray'};
    border-right: 1px solid lightgray;
    border-bottom-left-radius: ${props => props.isBottom ? '10px' : undefined};
    background-color: ${props => props.isSelected ? props.theme.tertiary : props.theme.background};
    color: ${props => props.isSelected ? props.theme.background : props.theme.inverted};
    min-height: 35px;

    :hover {
        border-color: rgba(0, 0, 0, 0.09);
        box-shadow: 0 2px 8px rgba(0,0,0, 0.09);
        cursor: pointer;
    }
`;

const GroupCardTimeSlotType = styled.span`
    width: 25px;
    display: inline-block;
`;

const GroupCardTimeSlotDay = styled.span`
    width: 125px;
    display: inline-block;

    span {
        display: inline-block;
    }

    span:nth-of-type(1) {
        width: 30px;
    }

    span:nth-of-type(2) {
        width: 40px;
    }

    span:nth-of-type(3) {
        width: 10px;
    }

    span:nth-of-type(4) {
        width: 40px;
    }
`;

const GroupCardTimeSlotStatusContainer = styled.div`
    display: flex;
    width: 90px;

    > span {
        width: 30px;
    }
`;

const EllipsisProperty = styled.span`
    padding-right: 10px;
    opacity: 0.4;

    width: 25px;
    display: flex;
    justify-content: center;
    align-content: stretch;

    :hover {
        opacity: 1.0;
    }
`;