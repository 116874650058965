/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { ActionsUnion } from '@/store/types';
import { action } from '@/store/decorators';
import { Action } from '@/store/action';
import { PageRoute } from '@/router/routes';

export class UIActions {
    public static __className = 'UIActions';

    @action
    static pushPage(pageRoute: PageRoute, params?: { [key: string]: string }) { return Action('pushPage', { pageRoute: pageRoute.toPlainPageRouteObject(), params }); }

    @action
    static replacePage(pageRoute: PageRoute, params?: { [key: string]: string }) { return Action('replacePage', { pageRoute: pageRoute.toPlainPageRouteObject(), params }); }

    @action
    static popPage() { return Action('popPage'); }

    @action
    static breadcrumbs(breadcrumbs: string[]) { return Action('breadcrumbs', { breadcrumbs }); }

    @action
    static enterFullscreen() { return Action('enterFullscreen'); }

    @action
    static leaveFullscreen() { return Action('leaveFullscreen'); }

    @action
    static toggleTerminal() { return Action('toggleTerminal'); }

    @action
    static offline() { return Action('offline'); }
    @action
    static online() { return Action('online'); }
}

export type Actions = ActionsUnion<typeof UIActions>;