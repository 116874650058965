import React from 'react';
import styled from 'styled-components';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

import { FaIcon } from '@/components/fa-icon';
import { Translate } from 'react-localize-redux';
import { useContextMenu } from './context-menu';

interface Props {
    translation: string;
    icon: IconDefinition;
    onClick: () => boolean | void;
}

export const ContextMenuItem: React.FC<Props> = (props) => {
    const { close } = useContextMenu();

    return (
        <ContextMenuItemContainer onClick={() => {
            const result = props.onClick();
            if (result == null || result === true) {
                close();
            }
        }}>
            <FaIcon icon={props.icon} />
            <Translate id={props.translation} />
        </ContextMenuItemContainer>
    );
};

const ContextMenuItemContainer = styled.p`
    margin: 0px;
    padding: 10px;
    
    color: ${props => props.theme.inverted};

    >.icon {
        margin-right: 5px;
    }

    :hover {
        background-color: ${props =>  props.theme.tertiary};
        color: ${props => props.theme.background};
    }
`;