/* eslint-disable @typescript-eslint/no-explicit-any */
import { Action } from './action';

export function action(target: Object, methodName: string, propertyDesciptor: PropertyDescriptor): PropertyDescriptor {
    const className = target['__className'];
    
    if (className == null) {
        console.error('The \'public static __className = \'<className>\';\' Must be present in the class of method \'' + methodName + '\'');        
    } else {
        const actionName = `${target['__className']}:${methodName}`;
        
        const method = propertyDesciptor.value;

        propertyDesciptor.value = function (...args: any[]) {
            const result = method.apply(this, args) as Action<any, any>;

            // Always set the action to the calculated value
            result.type = actionName;
            result.container = className;
            result.action = methodName;

            return result;
        };

        propertyDesciptor.value.__actionName = actionName;
    }

    return propertyDesciptor;
}