import { AgeClassifications} from './age-classifications';
import moment from 'moment';

export interface TimeSlotMoment {
    moment: moment.Duration;
    employees: number;
    ages: AgeClassifications;
}

export function onDeserializeTimeSlotMoment(data: TimeSlotMoment): TimeSlotMoment {
    if (data == null) {
        return data;
    }

    if (data.moment != null) {
        data.moment = moment.duration(data.moment);
    }

    return data;
}

export function onSerializeTimeSlotMoment(data: TimeSlotMoment): TimeSlotMoment {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };


    return serialized;
}
