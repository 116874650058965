import React, { useState } from 'react';
import { ScreenContainer, MenuBackButton, MenuButton } from '../../screen-container';
import { useSelector, useDispatch } from 'react-redux';
import { Alert } from 'antd';
import { CompositionCard } from './composition/composition-card';
import { locationsSelector } from '@/store/general/selectors';
import { CurrentLocationSelectionDrawer } from './current-location-selection';
import styled from 'styled-components';
import { useTranslate } from '@/language-provider';
import { Position } from '@/components/action-bar';
import { ApplicationDetailsDrawer } from './application-details';
import { Routes } from '@/router/routes';
import { useApplicationPartProvider, usePartChanged } from '@/planboard/planner-application-part-provider';
import { GridLayoutItem } from '@/components/grid-layout';
import { Collapse } from 'antd';
import { ApplicationSchedulePeriod } from '@/store/planboard/state';
import R from 'ramda';
import { PlannerStepper } from './planner-stepper';
import { PlannerSpinner } from './planner-spinner';
import { PlannerSummary } from './planner-summary';
import { PlanboardActions } from '@/store/planboard/actions';
import { FilterOutlined, InfoCircleOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

const GroupsSection: React.FC = () => {
    const provider = useApplicationPartProvider();
    const { translate } = useTranslate();

    const [compositions, setCompositions] = useState(provider.getCurrentCompositions());

    const getCurrentCompositionSelection = (): number[] => R.uniq([0, ...compositions.map((x, i) => provider.compositionHasSelection(x) ? i : 0)]);
    const [openCompositions, setOpenCompositions] = useState(getCurrentCompositionSelection());

    usePartChanged(() => {
        const compositions = provider.getCurrentCompositions();
        setCompositions(compositions);
        setOpenCompositions(getCurrentCompositionSelection());
    });

    function createHeaderText(period: ApplicationSchedulePeriod): string {
        const start = period.start.format('YYYY');
        const end = period.end.format('YYYY');
        return `${start} ${translate('global.generic.till')} ${end}`;
    }

    return (
        <Collapse defaultActiveKey={openCompositions}>
            {compositions.map((x, i) => (
                <Panel key={i} header={x.periods.map((p, i) => <PeriodHeader key={i}>{createHeaderText(p)}</PeriodHeader>)}>
                    <GroupsGridContainer key={`groups-composition-${i}`} row={i + 1}>
                        <CompositionCard composition={x} />
                    </GroupsGridContainer>
                </Panel>
            ))}
        </Collapse>
    );
};

export const PlannerComponent: React.FC = () => {
    const provider = useApplicationPartProvider();
    const { translate } = useTranslate();
    const [isEmpty, setIsEmpty] = useState(provider.scheduleParts.length === 0);
    const dispatch = useDispatch();

    provider.onChange(() => {
        setIsEmpty(provider.scheduleParts.length === 0);

        if (provider.allPartsCompleted()) {
            dispatch(PlanboardActions.reserveApplication(provider.details.applicationId));
        } else {
            dispatch(PlanboardActions.inProgressApplication(provider.details.applicationId));
        }
    });

    const locations = useSelector(locationsSelector) ?? [];

    const [showLocations, setShowLocations] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [currentView, setCurrentView] = useState(<GroupsSection />);
    const [currentLocation, setCurrentLocation] = useState(locations.find(x => x.locationId === provider.getSelectedLocation()));

    if (isEmpty) {
        console.log(`Empty application (${provider.scheduleParts.length} parts): ${JSON.stringify(provider.details)}`);
    }

    return (
        <ScreenContainer showFullScreen={true} menuItems={[
            MenuBackButton(Routes.PlanboardSelectedApplications),
            MenuButton('global.generic.locations', <FilterOutlined />, () => setShowLocations(true), Position.right),
            MenuButton('global.generic.details', <InfoCircleOutlined />, () => setShowDetails(true), Position.right)
        ]}>
            <PlannerSpinner />
            <CurrentLocationSelectionDrawer visible={showLocations} selected={currentLocation}
                onCancel={() => setShowLocations(false)}
                otherLocations={locations.filter(x => !provider.details.preferredLocationIds.includes(x.locationId))}
                preferredLocations={
                    provider.details.preferredLocationIds
                        .filter(x => locations.some(l => l.locationId === x))
                        .map(x => locations.find(l => l.locationId === x))
                }
                onSelect={location => {
                    setShowLocations(false);
                    provider.selectLocation(location.locationId);
                    setCurrentLocation(location);
                }} />

            <ApplicationDetailsDrawer visible={showDetails} details={provider.details} onClose={() => setShowDetails(false)} />

            <Header>
                {provider.details?.child.fullName} - {provider.details?.careTypes.map(x => translate(`global.caretype.${x}`)).join(' & ')} {currentLocation && ` - ${currentLocation.name}`}
            </Header>
            {isEmpty ? (
                <Alert message={translate('global.generic.error')} description={`${translate('planboard.messages.empty-application')}`} type="error" showIcon />
            ) : (
                    <>
                        <PlannerStepper
                            showPart={() => setCurrentView(<GroupsSection />)}
                            showSummary={() => setCurrentView(<PlannerSummary />)} />
                        {currentView}
                    </>
                )}
        </ScreenContainer>
    );
};

const Header = styled.div`
    margin: 5px;
    font-size: 2em;
`;

const GroupsGridContainer = styled(GridLayoutItem)`
    overflow: auto;
`;

const PeriodHeader = styled.span`
    margin-right: 35px;
`;