import React, { useState } from 'react';
import { SummaryCard } from '../summary-card';
import { SummaryDetails } from '../summary';
import { ApplicationFilter } from '../application-filter';
import { CareType } from '@/client/models';

interface Props {
    summary: SummaryDetails;
    onClick: (requesFiltert: ApplicationFilter) => void;
}

interface InternalProps extends Props {
    translation: string; 
    careType: CareType;
}

function CareTypeCard({ summary, onClick, translation, careType }: InternalProps): React.ReactElement {
    const [isSelected, setSelected] = useState(false);

    return <SummaryCard key={translation}
        translation={translation}
        count={summary.careTypes[careType].length}
        isSelected={isSelected}
        onClick={() => {
            setSelected(!isSelected);
            onClick({
                name: translation,
                group: 'care-type',
                filter: applications => applications.filter(x => x.careTypes.includes(careType))
            });
        }} />;
}

export function BetweenSchoolCareCard({ summary, onClick }: Props): React.ReactElement {
    return <CareTypeCard 
        summary={summary} 
        onClick={onClick} 
        translation='global.caretype.betweenSchoolCare' 
        careType={CareType.BetweenSchoolCare} />;
}

export function DaycareCard({ summary, onClick }: Props): React.ReactElement {
    return <CareTypeCard 
        summary={summary} 
        onClick={onClick} 
        translation='global.caretype.daycare' 
        careType={CareType.Daycare} />;
}

export function HolidayCareCard({ summary, onClick }: Props): React.ReactElement {
    return <CareTypeCard 
        summary={summary} 
        onClick={onClick} 
        translation='global.caretype.holidayCare' 
        careType={CareType.HolidayCare} />;
}

export function OutOfSchoolCard({ summary, onClick }: Props): React.ReactElement {
    return <CareTypeCard 
        summary={summary} 
        onClick={onClick} 
        translation='global.caretype.outOfSchoolCare' 
        careType={CareType.OutOfSchoolCare} />;
}

export function PlayschoolCard({ summary, onClick }: Props): React.ReactElement {
    return <CareTypeCard 
        summary={summary} 
        onClick={onClick} 
        translation='global.caretype.playschool' 
        careType={CareType.Playschool} />;
}
