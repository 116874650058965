import { ApplicationState } from '@/store/application-state';
import { createSelector } from 'reselect';
import { Status } from '../status';
import { CareType, Location } from '@/client/models';

export const applicationStartedSelector = createSelector((state: ApplicationState) => state.general?.applicationStarted, x => x);

export const locationsRetrievedSelector = createSelector((state: ApplicationState) => state.general?.locations, x => x);
export const locationsStatusSelector = createSelector((state: ApplicationState) => state.general?.locations?.status, x => x);
export const locationsSelector = createSelector((state: ApplicationState) => state.general?.locations?.value, x => x);

export const groupsRetrievedSelector = createSelector((state: ApplicationState) => state.general?.groups, x => x);
export const groupsStatusSelector = createSelector((state: ApplicationState) => state.general?.groups?.status, x => x);
export const groupsSelector = createSelector((state: ApplicationState) => state.general?.groups?.value, x => x);

export const carePackagesRetrievedSelector = createSelector((state: ApplicationState) => state.general?.carePackages, x => x);
export const carePackagesStatusSelector = createSelector((state: ApplicationState) => state.general?.carePackages?.status, x => x);
export const carePackagesSelector = createSelector((state: ApplicationState) => state.general?.carePackages?.value, x => x);

export const timeSlotsRetrievedSelector = createSelector((state: ApplicationState) => state.general?.timeSlots, x => x);
export const timeSlotsStatusSelector = createSelector((state: ApplicationState) => state.general?.timeSlots?.status, x => x);
export const timeSlotsSelector = createSelector((state: ApplicationState) => state.general?.timeSlots?.value, x => x);


export interface LocationWithGroups extends Location {
    careTypes: CareType[];
}

export const locationsWithGroupsSelector = createSelector(
    [locationsRetrievedSelector, groupsRetrievedSelector],
    (locations, groups) => {
        if (locations.status !== Status.Loaded || groups.status !== Status.Loaded) {
            return [];
        }

        return groups.value.reduce((locationWithGroups, group) => {
            const found = locationWithGroups.find(x => x.locationId === group.locationId);

            if (found == null) {
                const location = locations.value.find(l => l.locationId === group.locationId);
                locationWithGroups.push({ 
                    ...location,
                    careTypes: [group.careType]
                });
            } else {
                if (!found.careTypes.includes(group.careType)) {
                    found.careTypes.push(group.careType);
                }
            }

            return locationWithGroups;
        }, [] as LocationWithGroups[]);
    }
);