import React, { useState, useEffect, RefObject, useRef, useContext } from 'react';
import styled from 'styled-components';

interface ContextMenuContext {
    close(): void;
}

const ContextMenuContext = React.createContext<ContextMenuContext>(undefined);

export const useContextMenu = (): ContextMenuContext => {
    const contextMenu = useContext(ContextMenuContext);
    return contextMenu;
};

interface Props {
    children: React.ReactNode[];
    target: RefObject<HTMLElement>;
}

export const ContextMenu: React.FC<Props> = (props) => {
    const [visible, setVisible] = useState(false);
    const [top, setTop] = useState(0);
    const [left, setLeft] = useState(0);

    const visibleRef = useRef<boolean>();
    visibleRef.current = visible;
    
    const containerRef = useRef<HTMLDivElement>();
    
    useEffect(() => {
        const { current: targetElement } = props.target;
        const { current: containerElement } = containerRef;

        const handleClick = (): void => setVisible(!visibleRef.current);
        const handleClickDocument = (event: MouseEvent): void => {
            if (!containerElement.contains(event.target as Node)) {
                setVisible(false);
            }
        };

        targetElement.addEventListener('click', handleClick);
        document.addEventListener('mousedown', handleClickDocument);
        
        setTop(targetElement.offsetTop - targetElement.scrollTop);
        setLeft(targetElement.offsetLeft + targetElement.offsetWidth + 10);

        return () => {
            targetElement.removeEventListener('click', handleClick);
            document.removeEventListener('mousedown', handleClickDocument);
        };
    }, [props.target, containerRef]);

    return (
        <ContextMenuContainer ref={containerRef} visible={visible} style={{ left, top }}>
            <ContextMenuContext.Provider value={{ close: () => setVisible(false) }}>
                {props.children}
            </ContextMenuContext.Provider>
            <ContextMenuArrow />
        </ContextMenuContainer>
    );
};


const ContextMenuContainer = styled.div<{ visible: boolean }>`
    display: ${({ visible }) => visible ? 'block' : 'none'};
    z-index: 1;
    position: absolute;
    background-color: ${({ theme }) => theme.background};
    box-shadow: 0 2px 8px rgba(0,0,0,0.15);
    border-radius: 10px;

    >p {
        border-bottom-width: 1px;
        border-bottom-color: lightgray;
        border-bottom-style: solid;

        :first-of-type {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        } 

        :last-of-type {
            border-bottom-style: none;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        } 
    }
`;

const ContextMenuArrow = styled.div`
    position: absolute;
    z-index: -1;
    left: -6px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    width: 10px;    
    height: 10px;
    background-color: ${({ theme }) => theme.background};
    box-shadow: -3px 3px 7px rgba(0,0,0,0.1);    
`;