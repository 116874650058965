import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { authenticationStatusSelector, developModeSelector, tenantSelector } from '@/store/authentication/selectors';
import { Form, Input, Select, Alert } from 'antd';
import { Status } from '@/store/authentication/state';
import { AuthenticationActions } from '@/store/authentication/actions';

import { Translate } from 'react-localize-redux';
import { Button } from '@/components/button';

import { EnvironmentMode } from '@/environment-mode';
import { HomeOutlined, UserOutlined, LockOutlined } from '@ant-design/icons';
import styled from 'styled-components';

interface FormData {
    tenant?: string;
    customTenant?: string;
    environment?: 'development' | 'developmentWithLocal' | 'testing' | 'production';
    userName: string;
    password: string;
}

export const LoginScreen: React.FC = () => {
    const [form] = Form.useForm();
    const { getFieldValue } = form;

    const dispatch = useDispatch();
    const authenticationStatus = useSelector(authenticationStatusSelector);
    const developMode = useSelector(developModeSelector) || env.mode === EnvironmentMode.development;
    const tenant = useSelector(tenantSelector);

    const [showCustomTenant, setShowCustomTenant] = useState(false);
    const [showTenant, setShowTenant] = useState(developMode);

    function onFinish(values: FormData): void {
        event.preventDefault();

        switch (values.environment) {
            case 'development':
                dispatch(AuthenticationActions.configureForDevelopment(false));
                break;
            case 'developmentWithLocal':
                dispatch(AuthenticationActions.configureForDevelopment(true));
                break;
            case 'testing':
                dispatch(AuthenticationActions.configureForTesting());
                break;
            default:
                dispatch(AuthenticationActions.configureForProduction());
                break;
        }

        if (developMode) {
            if (values.environment === 'developmentWithLocal') {
                dispatch(AuthenticationActions.tenant('ontwikkel')); // Required but unused when developing locally
            } else {

                let newTenant = tenant;
                if (values.tenant != null) {
                    if (values.tenant !== 'custom') {
                        newTenant = values.tenant;
                    } else if (values.customTenant != null) {
                        newTenant = values.customTenant;
                    }
                }

                dispatch(AuthenticationActions.tenant(newTenant));
            }
        }

        dispatch(AuthenticationActions.login(values.userName, values.password));
    }

    function onChange(): void {
        const selectedEnvironment = getFieldValue('environment');
        const selectedTenant = getFieldValue('tenant');

        setShowTenant(selectedEnvironment !== 'developmentWithLocal');
        setShowCustomTenant((selectedEnvironment === 'development' || selectedEnvironment === 'testing') && selectedTenant === 'custom');
    }

    return (
        <LoginPageContainer>
            <div>
                <img src='/images/logo-kp.png' />
                <CenteredContainer>
                    <LoginForm>
                        <Form form={form} onFinish={onFinish} initialValues={{ environment: 'testing', tenant: 'ontwikkel' }}>
                            {developMode &&
                                <>
                                    <Form.Item name='environment' rules={[{ required: true, message: 'Please choose environment' }]}>
                                        <Select defaultActiveFirstOption={true} onChange={onChange}>
                                            <Select.Option value='development'>Development</Select.Option>
                                            <Select.Option value='developmentWithLocal'>Local development</Select.Option>
                                            <Select.Option value='testing'>Testing</Select.Option>
                                            <Select.Option value='production'>Production</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    {showTenant &&
                                        <Form.Item name='tenant' >
                                            <Select defaultActiveFirstOption={true} onChange={onChange}>
                                                <Select.Option value='ontwikkel'>Ontwikkel</Select.Option>
                                                <Select.Option value='ontwikkel2'>Ontwikkel2</Select.Option>
                                                <Select.Option value='test1'>test1</Select.Option>
                                                <Select.Option value='test2'>test2</Select.Option>
                                                <Select.Option value='custom'>Custom</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    }
                                    {showCustomTenant &&
                                        <Form.Item name='customTenant'>
                                            <Input prefix={<HomeOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Tenant" />
                                        </Form.Item>
                                    }
                                </>
                            }
                            <Form.Item name='userName' rules={[{ required: true, message: 'Please input your username' }]}>
                                <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Username" />
                            </Form.Item>
                            <Form.Item name='password' rules={[{ required: true, message: 'Please input your Password' }]}>
                                <Input prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Password" type="password" />
                            </Form.Item>

                            {authenticationStatus === Status.AuthenticationFailed && 
                                <Alert message={<Translate id='authentication.invalid-credentials' />} type="error" />
                            }
                            
                            <Form.Item>
                                <Button
                                    translation='authentication.login'
                                    loading={authenticationStatus === Status.Authenticating}
                                    type="primary"
                                    htmlType="submit"
                                    style={{ width: 300 }} />
                            </Form.Item>
                        </Form>
                    </LoginForm>
                </CenteredContainer>
            </div>
        </LoginPageContainer>
    );
};

const LoginPageContainer = styled.div`
    background-image: url('/images/login-wallpaper.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100vw;

    display: flex;
    justify-content: center;
    align-items: center;
`;

const CenteredContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const LoginForm = styled.div`
    width: 300px;
`;