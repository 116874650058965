export type AxiosHeaders = { [key: string]: string };

export const mergeHeaders = (headers1: AxiosHeaders, headers2: AxiosHeaders): AxiosHeaders => {
    if (headers1 == null) {
        return headers2;
    }

    if (headers2 == null) {
        return headers1;
    }

    const copy = { ...headers1 };

    for (const key of Object.keys(headers2)) {
        copy[key] = headers2[key];
    }

    return copy;
};