import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBatteryThreeQuarters, faChartArea } from '@fortawesome/free-solid-svg-icons';

export function GroupOverviewOptions(): React.ReactElement {
    return (
        <OverviewContainer>
            <IconContainer><FontAwesomeIcon icon={faChartArea}/></IconContainer>
            <IconContainer><FontAwesomeIcon icon={faBatteryThreeQuarters}/></IconContainer>
        </OverviewContainer>
    );
}

const OverviewContainer = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-items: stretch;
    width: 40px;
    
    > :last-child {
        border-bottom: none;
    }
`;

const IconContainer = styled.div`
    border-left: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    flex: 1;

    display: flex;
    align-items: center;
    justify-content: center;

    :hover {
        border-color: rgba(0, 0, 0, 0.09);
        box-shadow: 0 2px 8px rgba(0,0,0, 0.09);
        cursor: pointer;
    }
`;