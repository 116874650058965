import React from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faBatteryThreeQuarters, faCalendarAlt, faHome, faTools } from '@fortawesome/free-solid-svg-icons';
import { PlanboardDashboardScreen } from '@/screens/planboard/dashboard/dashboard';
import { PlanboardOpenApplicationsScreen } from '@/screens/planboard/open-applications';
import { PlanboardReservedApplicationsScreen } from '@/screens/planboard/reserved-applications';
import { PlanboardCapacityDashboardScreen } from '@/screens/planboard/capacity-dashboard/dashboard';
import { PlanboardSelectedApplicationsScreen } from '@/screens/planboard/planner/selected-applications';
import { PlannerScreen } from '@/screens/planboard/planner/planner-screen';
import { useDispatch, useSelector } from 'react-redux';
import { UIActions } from '@/store/ui/actions';
import { NotFoundScreen } from '@/screens/notfound';
import { LoginScreen } from '@/screens/login';
import { AppErrorBoundary } from '@/app-error-boundry';
import { currentRouteSelector } from '@/store/ui/selectors';
import { ChildTimeLineScreen } from '@/screens/tools/child-timeline';

export enum Page {
    login = 'login',
    home = 'home',
    notFound = 'notFound',
    planboardDashboard = 'planboard.dashboard',
    planboardOpenApplications = 'planboard.open-applications',
    planboardReservedApplications = 'planboard.reserved-applications',
    planboardSelectedApplications = 'planboard.selected-applications',
    planboardPlanner = 'planboard.planner',
    planboardCapacity = 'planboard.capacity',
    tools = 'tools.tools',
    toolsChildTimeLine = 'tools.child-timeline'
}

export enum RouteKind {
    route = 'route',
    authenticated = 'authenticated',
    unauthenticated = 'unauthenticated'
}

type Component = React.ComponentClass<RouteComponentProps<unknown>> | React.FunctionComponent<RouteComponentProps<unknown>> | React.ComponentClass<unknown> | React.FunctionComponent<unknown>;

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
export interface IPageRoute {
    component: Component;
    icon?: IconDefinition;
    kind: RouteKind;
    page: Page;
    path?: string;
    parent?: IPageRoute;
    menuOptions?: MenuOptions;
}

interface MenuOptions {
    title: string;
    icon: IconDefinition;
}

export class PageRoute implements IPageRoute {
    public component: Component;
    public icon?: IconDefinition;
    public kind: RouteKind = RouteKind.route;
    public page: Page;
    public path?: string;
    public parent?: PageRoute;
    public menuOptions: MenuOptions;

    public constructor(page: Page, path: string | undefined, component: Component, icon?: IconDefinition) {
        this.page = page;
        this.path = path;
        this.component = component;
        this.icon = icon;
    }

    protected setKind(kind: RouteKind): PageRoute {
        this.kind = kind;
        return this;
    }

    public showInMenu(): boolean {
        return this.icon !== undefined;
    }

    public setParent(parent: PageRoute): PageRoute {
        this.parent = parent;
        return this;
    }

    public setMenuOptions(options: MenuOptions): PageRoute {
        this.menuOptions = options;
        return this;
    }

    public pathWithoutOptionalParams(): string {
        return stripRouteParams(this.path);
    }

    public createElement(): React.ReactElement {
        return <Route key={this.page} exact={true} path={this.path} component={() => <AppErrorBoundary>{React.createElement(this.component)}</AppErrorBoundary>} />;
    }

    public toPlainPageRouteObject(): IPageRoute {
        const pageRoute = {
            ...this,
            icon: undefined
        } as IPageRoute;

        if (this.menuOptions != null) {
            pageRoute.menuOptions = {
                ...this.menuOptions,
                icon: undefined
            };
        }

        pageRoute.parent = this.parent?.toPlainPageRouteObject();
        return pageRoute;
    }
}

export class UnauthenticatedRoute extends PageRoute {

    constructor(page: Page, path: string | undefined, component: Component, icon?: IconDefinition) {
        super(page, path, component, icon);

        this.setKind(RouteKind.unauthenticated);
    }
}

export class AuthenticatedRoute extends PageRoute {

    constructor(page: Page, path: string | undefined, component: Component, icon?: IconDefinition) {
        super(page, path, component, icon);

        this.setKind(RouteKind.authenticated);
    }
}

export class AuthenticatedRedirectRoute extends AuthenticatedRoute {
    constructor(page: Page, path: string, to: string, icon?: IconDefinition) {
        super(page, path, () => <Redirect to={to} />, icon);
    }
}

export class AuthenticatedCatchAllRoute extends AuthenticatedRoute {
    constructor(page: Page, component: Component) {
        super(page, undefined, component, undefined);
    }
}

export class UnauthenticatedCatchAllRoute extends UnauthenticatedRoute {
    constructor(page: Page, componentOrRoute: Component | string) {
        if (typeof componentOrRoute == 'string') {
            super(page, undefined, () => <Redirect key={page} to={componentOrRoute} />, undefined);
        } else {
            super(page, undefined, componentOrRoute, undefined);
        }
    }
}

export class Routes {
    public static NotFound = new AuthenticatedCatchAllRoute(Page.notFound, NotFoundScreen);
    public static AlwaysLogin = new UnauthenticatedCatchAllRoute(Page.login, '/login');
    public static Login = new UnauthenticatedRoute(Page.login, '/login', LoginScreen);

    public static Home = new AuthenticatedRedirectRoute(Page.home, '/', '/planboard/dashboard', faHome);

    public static PlanboardDashboard = new AuthenticatedRoute(Page.planboardDashboard, '/planboard/dashboard', PlanboardDashboardScreen, faCalendarAlt).setMenuOptions({ title: 'planboard.planboard', icon: faCalendarAlt });
    public static PlanboardOpenApplications = new AuthenticatedRoute(Page.planboardOpenApplications, '/planboard/open-applications', PlanboardOpenApplicationsScreen, faCalendarAlt).setParent(Routes.PlanboardDashboard);
    public static PlanboardReservedApplications = new AuthenticatedRoute(Page.planboardReservedApplications, '/planboard/reserved-applications', PlanboardReservedApplicationsScreen, faCalendarAlt).setParent(Routes.PlanboardDashboard);
    public static PlanboardCapacity = new AuthenticatedRoute(Page.planboardCapacity, '/planboard/capacity', PlanboardCapacityDashboardScreen, faBatteryThreeQuarters).setParent(Routes.PlanboardDashboard);
    public static PlanboardSelectedApplications = new AuthenticatedRoute(Page.planboardSelectedApplications, '/planboard/selectedApplications', PlanboardSelectedApplicationsScreen).setParent(Routes.PlanboardDashboard);
    public static PlanboardPlanner = new AuthenticatedRoute(Page.planboardPlanner, '/planboard/planner/:applicationId', PlannerScreen).setParent(Routes.PlanboardSelectedApplications);

    public static Tools = new AuthenticatedRedirectRoute(Page.tools, '/tools', '/tools/child-timeline', faTools).setMenuOptions({ title: 'tools.tools', icon: faTools });
    public static ToolsChildTimeLine = new AuthenticatedRoute(Page.toolsChildTimeLine, '/tools/child-timeline/:childId?', ChildTimeLineScreen, faTools).setParent(Routes.Tools);

    public static All =
        [
            Routes.Login,
            Routes.Home,
            Routes.PlanboardDashboard,
            Routes.PlanboardOpenApplications,
            Routes.PlanboardReservedApplications,
            Routes.PlanboardCapacity,
            Routes.PlanboardPlanner,
            Routes.PlanboardSelectedApplications,
            Routes.Tools,
            Routes.ToolsChildTimeLine,
            Routes.NotFound,
            Routes.AlwaysLogin
        ];
}

function getRouteParams(pageRoute: IPageRoute): string[] {
    const params = new Array<string>();

    const paramRegex = /:([a-zA-Z0-9]+)/g;

    let match = paramRegex.exec(pageRoute.path);
    while (match != null) {
        params.push(match[1]);
        match = paramRegex.exec(pageRoute.path);
    }

    return params;
}

export function stripRouteParams(path: string): string {
    const paramRegex = /:([a-zA-Z0-9]+)\??/g;

    let match = paramRegex.exec(path);
    while (match != null) {
        path = path.replace(match[0], '');
        match = paramRegex.exec(path);
    }

    return path;
}

export function applyParamsToRoute(pageRoute: IPageRoute, params?: { [key: string]: string }): string {
    if (params == null) {
        return stripRouteParams(pageRoute.path);
    }

    const paramKeys = Object.keys(params);
    if (paramKeys.length === 0) {
        return stripRouteParams(pageRoute.path);
    }

    const routeParams = getRouteParams(pageRoute);

    let path = pageRoute.path;
    for (const paramKey of paramKeys.filter(x => routeParams.includes(x))) {
        path = path.replace(`:${paramKey}`, encodeURIComponent(params[paramKey]));
    }

    return path;
}

export function findPageRouteStack(page: Page): PageRoute[] {
    const route = Routes.All.find(x => x.page === page);

    function createStack(pageRoute: PageRoute): PageRoute[] {
        if (pageRoute == null) {
            return [];
        }

        return [...createStack(pageRoute.parent), pageRoute];
    }

    return createStack(route);
}

export function matchRoute(urlRoute: string): PageRoute[] {
    const route = Routes.All.find(x => x.path === urlRoute || x.pathWithoutOptionalParams() === urlRoute);
    if (route == null) {
        return [];
    }

    return findPageRouteStack(route.page);
}

export interface PageNavigation {
    current: IPageRoute;
    back: () => void;
    push: (pageRoute: PageRoute, params?: { [key: string]: string }) => void;
    replace: (pageRoute: PageRoute, params?: { [key: string]: string }) => void;
}

export function usePageNavigation(): PageNavigation {
    const dispatch = useDispatch();
    const currentRoute = useSelector(currentRouteSelector);

    return {
        current: currentRoute,
        back: () => dispatch(UIActions.popPage()),
        push: (pageRoute: PageRoute, params?: { [key: string]: string }) => dispatch(UIActions.pushPage(pageRoute, params)),
        replace: (pageRoute: PageRoute, params?: { [key: string]: string }) => dispatch(UIActions.replacePage(pageRoute, params)),
    };
}