import { ActionReference, onDeserializeActionReference, onSerializeActionReference} from './action-reference';
import { SenderType } from './sender-type';

export interface PostComment {
    commentId: string;
    replyToCommentId?: string;
    content: string;
    created: ActionReference;
    senderType?: SenderType;
}

export function onDeserializePostComment(data: PostComment): PostComment {
    if (data == null) {
        return data;
    }

    if (data.created != null) {
        data.created = onDeserializeActionReference(data.created);
    }

    return data;
}

export function onSerializePostComment(data: PostComment): PostComment {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    serialized.created = onSerializeActionReference(data.created);

    return serialized;
}
