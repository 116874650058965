/* eslint-disable @typescript-eslint/no-explicit-any */
import {ListSortDirection} from '@/client/models';
import {Status} from '@/store/status';
import { OutputSelector } from 'reselect';
import { RetrievedValue, PagingInfo } from '@/store/retrieved-value';
import { Store } from 'redux';
import { TableDefinition } from '@/data-grid/table-definition';
import { DataGridAdapter } from './data-grid';
import { GridLayout } from './grid-layout';
import { PaginationConfig } from 'antd/lib/pagination';
import { SortOrder, SorterResult } from 'antd/lib/table/interface';

type PagedAction = { type: any; payload: { paging: PagingInfo } };
type PagedActionCreator = (paging: PagingInfo) => PagedAction;
type PagedSelector<TState, TSource> = OutputSelector<TState, RetrievedValue<TSource>, (res: RetrievedValue<TSource>) => RetrievedValue<TSource>>;

export class RemoteTableAdapter<TState, TSource> implements DataGridAdapter {
    private store: Store;
    private selector: PagedSelector<TState, TSource>;
    private actionCreator: PagedActionCreator;

    private table: TableDefinition<any>;
    
    private paginationConfig: PaginationConfig;
    private sortColumn?: React.Key;
    private sortOrder?: SortOrder;

    private readonly defaultPageSize = 10;

    constructor(store: Store, selector: PagedSelector<TState, TSource>, actionCreator: PagedActionCreator) {
        this.store = store;
        this.selector = selector;
        this.actionCreator = actionCreator;

        this.paginationConfig = {
            pageSize: this.defaultPageSize,
            pageSizeOptions: ['10', '20', '50'],
            showSizeChanger: true,
            showTotal: (total) => `${total} items`,
        };

        this.dataSource = this.dataSource.bind(this);
        this.beforeSavingLayout = this.beforeSavingLayout.bind(this);
        this.applyLayout = this.applyLayout.bind(this);
        this.attach = this.attach.bind(this);
        this.onPaginationChanged = this.onPaginationChanged.bind(this);
        this.render = this.render.bind(this);
    }

    public dataSource(): any[] {
        const retrieved = this.selector(this.store.getState());
        if (retrieved.status === Status.Loaded) {
            return retrieved.value as unknown as any[];
        }

        return null;
    }

    public beforeSavingLayout(newLayout: GridLayout, pagination: PaginationConfig): void {
        newLayout.pageSize = pagination.pageSize;
    }

    public applyLayout(newLayout: GridLayout): void {
        this.paginationConfig.pageSize = newLayout.pageSize ?? this.defaultPageSize;

        const sortedColumn = newLayout.columns.find(x => x.sort != null);
        if (sortedColumn != null) {
            this.sortColumn = sortedColumn.name;
            this.sortOrder = sortedColumn.sort === false ? undefined : sortedColumn.sort;
        }
    }

    public attach(table: TableDefinition<any>): void {
        this.table = table;
        this.table.onTableChanged(this.onPaginationChanged);
    }

    private onPaginationChanged(pagination: PaginationConfig, _filters: Record<keyof TSource, string[]>, sorter: SorterResult<TSource>): void {
        this.paginationConfig.current = pagination.current;
        this.paginationConfig.pageSize = pagination.pageSize ?? this.defaultPageSize;

        if (sorter?.columnKey != null) {
            this.sortColumn = sorter.columnKey;
            this.sortOrder = sorter.order;
        }

        this.executeAction();
    }
    
    public render(): void {

        const retrieved = this.selector(this.store.getState());
        
        this.table.paginationConfig = this.paginationConfig;
        this.table.loading = retrieved.status === Status.Loading;

        if (retrieved.status === Status.Init) {
            this.executeAction();
        } else {
            this.paginationConfig.total = retrieved.paging?.totalRecords;
        }
    }

    private executeAction(): void {
        const action = this.actionCreator({ 
            page: this.paginationConfig.current,
            pageSize: this.paginationConfig.pageSize ?? this.defaultPageSize
        });

        if (this.sortColumn != null) {
            action.payload.paging.sort = {
                [this.sortColumn]: this.sortOrder === 'ascend' ? ListSortDirection.Ascending : ListSortDirection.Descending
            };
        }

        this.table.loading = true;
        this.store.dispatch(action);
    }
}