import React, { useState } from 'react';
import { Menu, Layout } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Translate } from 'react-localize-redux';

import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { findPageRouteStack, Page, Routes, usePageNavigation } from '@/router/routes';
import R from 'ramda';
import { isMobileOnly } from 'react-device-detect';
import { useTranslate } from '@/language-provider';
import styled from 'styled-components';

const { Sider } = Layout;

const Item: React.FC<{ title: string; icon: IconDefinition }> = ({ title, icon }) => {
    const { translate } = useTranslate();

    return (
        <>
            <span className='anticon'><FontAwesomeIcon icon={icon} /></span>
            <span>{translate(`global.menu.${title}`)}</span>
        </>
    );
};

export const MainMenu: React.FC<{ hidden?: boolean }> = ({ hidden }) => {
    const { current, replace } = usePageNavigation();

    function navigateToPage(page: Page): void {
        const routes = findPageRouteStack(page);
        replace(routes[routes.length - 1]);
    }
    
    const [collapsed, setIsCollapsed] = useState(isMobileOnly);

    const menuItems = R.filter(x => x.showInMenu(), Routes.All);
    const groupedMenu = R.groupBy(x => x.parent?.page ?? x.page, menuItems);
    const allSubmenus = Object.keys(groupedMenu).filter(x => groupedMenu[x].length > 1).map(x => groupedMenu[x][0].menuOptions.title);

    return (
        <Sider collapsible collapsed={collapsed} onCollapse={setIsCollapsed} style={{ display: hidden ? 'none' : 'block' }} theme='light'>
            <Menu mode="inline" onSelect={x => navigateToPage(x.key as Page)} selectedKeys={[current?.page]} defaultOpenKeys={allSubmenus}>
                {Object.keys(groupedMenu).map(menuItem => {
                    const items = groupedMenu[menuItem];
                    if (items.length > 1) {
                        const options = items[0].menuOptions;
                        return (
                            <Menu.SubMenu key={options.title} title={<span><Item icon={options.icon} title={options.title} /></span>}>
                                {items.map(x => {
                                    return (
                                        <Menu.Item key={x.page}>
                                            <Item icon={x.icon} title={x.page} />
                                        </Menu.Item>
                                    );
                                })}
                            </Menu.SubMenu>
                        );
                    } else {
                        return (
                            <Menu.Item key={items[0].page}>
                                <Item icon={items[0].icon} title={items[0].page} />
                            </Menu.Item>
                        );
                    }
                })}
            </Menu>
        </Sider>
    );
};