import React, { useState, useContext } from 'react';
import { Selector } from 'reselect';
import { ApplicationState } from '@/store/application-state';
import { RetrievedValue } from '@/store/retrieved-value';
import moment from 'moment';
import { AnyAction } from 'redux';
import { useDispatch, useStore } from 'react-redux';
import { Status } from '@/store/status';

interface CacheContext {
    addCache: <T>(selector: Selector<ApplicationState, RetrievedValue<T>>, action: AnyAction, duration?: moment.Duration) => void;
    removeCache: <T>(selector: Selector<ApplicationState, RetrievedValue<T>>) => void;
}

interface Cache<T = unknown> {
    selector: Selector<ApplicationState, RetrievedValue<T>>;
    action: AnyAction;
    duration: moment.Duration;    
}

export const CachingContext = React.createContext<CacheContext>(null);

export function useCache(): CacheContext {
    return useContext(CachingContext);
}

function isStale(cache: Cache<unknown>, retrievedValue: RetrievedValue<unknown>): boolean {
    const now = moment();
    
    if (retrievedValue) {
        if (retrievedValue.status === Status.Loaded) {
            const timeBetween = moment.duration(now.diff(retrievedValue.on));
            return timeBetween > cache.duration;
        } else if (retrievedValue.status === Status.Loading || retrievedValue.status === Status.Error) {
            const timeBetween = moment.duration(now.diff(retrievedValue.on));
            return timeBetween > moment.duration(30, 'seconds');
        }
    }

    return false;
}

export function CachingProvider(props: React.PropsWithChildren<{}>): React.ReactElement {
    const dispatch = useDispatch();
    const store = useStore<ApplicationState>();

    const [caches, setCaches] = useState(new Array<Cache>());

    useState(() => {
        setInterval(() => {
            const state = store.getState();

            for (const cache of caches) {
                const retrievedValue = cache.selector(state);
                if (isStale(cache, retrievedValue)) {
                    dispatch(cache.action);
                }
            }
        }, 10000);
    });

    function addCache<T>(selector: Selector<ApplicationState, RetrievedValue<T>>, action: AnyAction, duration?: moment.Duration): void {
        if (duration == null) {
            duration = moment.duration(15, 'minutes');
        }

        caches.push({ selector, action, duration });
        setCaches(caches);
    }

    function removeCache<T>(selector: Selector<ApplicationState, RetrievedValue<T>>): void {
        setCaches(caches.filter(x => x.selector !== selector));
    }
    
    return (
        <CachingContext.Provider value={{ addCache, removeCache }}>
            {props.children}
        </CachingContext.Provider>
    );
}