import { TimeLineResponse, onDeserializeTimeLineResponse, onSerializeTimeLineResponse } from '../models/time-line-response';
import globalAxios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { mergeHeaders, AxiosHeaders } from '../utilities/merge-headers';

export class TimeLineApi {
    private baseUrl: string;

    public constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    public async getChildId(childId: string, headers?: AxiosHeaders): Promise<AxiosResponse<TimeLineResponse>> {
        const request = {
            url: this.baseUrl + '/TimeLine/{childId}'
                .replace(`{${'childId'}}`, encodeURIComponent(childId)),
            method: 'GET',
            responseType: 'json',
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<TimeLineResponse>(request);
        
        if (response.status === 200) {
            response.data = onDeserializeTimeLineResponse(response.data);
        }

        return response;
    }
}