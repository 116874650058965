import { ApplicationDetails, TimeSlot, Group, ApplicationScheduleLine, Location } from '@/client/models';
import moment from 'moment';

function properCase(value: string): string {
    return value[0].toUpperCase() + value.slice(1);
}

function sanitizeName(value: string): string {
    return value.replace(/[\s-]/g, '');
}

function toString(date: moment.Moment): string {
    return date.format('YYYY-MM-DD');
}

function serializePart(line: ApplicationScheduleLine, timeSlots: TimeSlot[], partMapping: { [partId: string]: string }): string {
    const timeSlot = timeSlots.find(x => x.timeSlotId === line.timeslotId);
    const timeSlotName = `TimeSlots.${properCase(timeSlot.name)}`;

    if (partMapping[line.schedulePartId] == null) {
        partMapping[line.schedulePartId] = `part${Object.keys(partMapping).length}`;
    }

    let base = `createPart('${partMapping[line.schedulePartId]}', ${timeSlotName}, CareType.${properCase(line.careType)}, '${toString(line.from)}'`;

    if (line.until != null) {
        base += `, '${line.until.format('YYYY-MM-DD')}'`;
    } else {
        base += ', null';
    }

    if (line.compositionId != null && line.groupId != null) {
        base += `, '${line.compositionId}', '${line.groupId}'`;
    }

    base += ')';
    
    return base;
}

function serializeGroup(group: Group, locations: Location[]): string {
    const location = locations.find(x => x.locationId === group.locationId);

    let base = `createGroup(Locations.${sanitizeName(location.name)}, '${group.name}', ${group.fromMonths}, ${group.toMonths}, CareType.${properCase(group.careType)}`;

    if (group.capacity != null) {
        base += `, ${group.capacity}`;
    } else {
        base += ', null';
    }

    if (group.groupId != null) {
        base += `, '${group.groupId}'`;
    } else {
        base += ', null';
    }

    base += ')';
    
    return base;
}

export function exportPlanning(details: ApplicationDetails, locations: Location[], groups: Group[], timeSlots: TimeSlot[]): string {

    const usedLocations = locations.filter(x => details.preferredLocationIds.includes(x.locationId));
    const usedGroups = groups.filter(x => usedLocations.some(y => y.locationId === x.locationId));
    const usedTimeSlots = timeSlots.filter(x => details.schedule.added.some(y => y.timeslotId === x.timeSlotId));
    const partMapping = {};

    return `
    import { createApplication, createGroup, createPart, createTimeSlot } from './test-case-helper';
    import { CareType, DayOfWeek } from '@/client/models';

    export const TimeSlots = {
        ${usedTimeSlots.map(x => `${properCase(x.name)}: createTimeSlot('${x.timeSlotId}', DayOfWeek.${properCase(x.dayOfWeek)})`).join(',')}
    };

    export const Locations = {
        ${usedLocations.map(x => `${sanitizeName(x.name)}: '${x.locationId}'`).join(',')}
    };
       
    const groups = [
        ${usedGroups.map(x => serializeGroup(x, usedLocations)).join(',')}
    ];

    const parts = [
        ${details.schedule.added.map(x => serializePart(x, timeSlots, partMapping)).join(',')}
    ];

    export default createApplication('${toString(details.startsOn)}', '${toString(details.endsOn)}', '${toString(details.child.birthDate)}', groups, parts, Object.keys(TimeSlots).map(x => TimeSlots[x]));
    `;
}