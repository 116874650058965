import { ApplicationsResponse, onDeserializeApplicationsResponse, onSerializeApplicationsResponse } from '../models/applications-response';
import { ApplicationStatus } from '../models/application-status';
import { ApplicationDetails, onDeserializeApplicationDetails, onSerializeApplicationDetails } from '../models/application-details';
import { UpdateApplicationCommand, onDeserializeUpdateApplicationCommand, onSerializeUpdateApplicationCommand } from '../models/update-application-command';
import { ApplicationSchedulePart, onDeserializeApplicationSchedulePart, onSerializeApplicationSchedulePart } from '../models/application-schedule-part';
import { ListSortDirection } from '../models/list-sort-direction';
import qs from 'qs';
import globalAxios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { mergeHeaders, AxiosHeaders } from '../utilities/merge-headers';

export class ApplicationsApi {
    private baseUrl: string;

    public constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    public async get(statuses?: ApplicationStatus[], page?: number, pageSize?: number, sort?: { [key: string]: ListSortDirection }, headers?: AxiosHeaders): Promise<AxiosResponse<ApplicationsResponse>> {
        const request = {
            url: this.baseUrl + '/Applications',
            method: 'GET',
            responseType: 'json',
            params: {
                statuses: statuses,
                page: page,
                pageSize: pageSize,
                sort: sort,
            },
            paramsSerializer: params => qs.stringify(params)
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<ApplicationsResponse>(request);
        
        if (response.status === 200) {
            response.data = onDeserializeApplicationsResponse(response.data);
        }

        return response;
    }

    public async getApplicationId(applicationId: string, headers?: AxiosHeaders): Promise<AxiosResponse<ApplicationDetails>> {
        const request = {
            url: this.baseUrl + '/Applications/{applicationId}'
                .replace(`{${'applicationId'}}`, encodeURIComponent(applicationId)),
            method: 'GET',
            responseType: 'json',
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<ApplicationDetails>(request);
        
        if (response.status === 200) {
            response.data = onDeserializeApplicationDetails(response.data);
        }

        return response;
    }

    public async putApplicationId(applicationId: string, data: UpdateApplicationCommand, headers?: AxiosHeaders): Promise<AxiosResponse<void>> {
        const request = {
            url: this.baseUrl + '/Applications/{applicationId}'
                .replace(`{${'applicationId'}}`, encodeURIComponent(applicationId)),
            method: 'PUT',
            headers: {
                'content-type': 'application/json',
            },
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);

        request.data = onSerializeUpdateApplicationCommand(data);

        const response = await globalAxios.request<void>(request);
        

        return response;
    }

    public async postApplicationIdInprogress(applicationId: string, headers?: AxiosHeaders): Promise<AxiosResponse<void>> {
        const request = {
            url: this.baseUrl + '/Applications/{applicationId}/inprogress'
                .replace(`{${'applicationId'}}`, encodeURIComponent(applicationId)),
            method: 'POST',
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<void>(request);
        

        return response;
    }

    public async postApplicationIdOffer(applicationId: string, headers?: AxiosHeaders): Promise<AxiosResponse<void>> {
        const request = {
            url: this.baseUrl + '/Applications/{applicationId}/offer'
                .replace(`{${'applicationId'}}`, encodeURIComponent(applicationId)),
            method: 'POST',
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<void>(request);
        

        return response;
    }

    public async postApplicationIdReservation(applicationId: string, headers?: AxiosHeaders): Promise<AxiosResponse<void>> {
        const request = {
            url: this.baseUrl + '/Applications/{applicationId}/reservation'
                .replace(`{${'applicationId'}}`, encodeURIComponent(applicationId)),
            method: 'POST',
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<void>(request);
        

        return response;
    }

    public async postApplicationIdSchedule(applicationId: string, data: ApplicationSchedulePart, headers?: AxiosHeaders): Promise<AxiosResponse<void>> {
        const request = {
            url: this.baseUrl + '/Applications/{applicationId}/schedule'
                .replace(`{${'applicationId'}}`, encodeURIComponent(applicationId)),
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);

        request.data = onSerializeApplicationSchedulePart(data);

        const response = await globalAxios.request<void>(request);
        

        return response;
    }

    public async putApplicationIdScheduleSchedulePartId(applicationId: string, schedulePartId: string, data: ApplicationSchedulePart, headers?: AxiosHeaders): Promise<AxiosResponse<void>> {
        const request = {
            url: this.baseUrl + '/Applications/{applicationId}/schedule/{schedulePartId}'
                .replace(`{${'applicationId'}}`, encodeURIComponent(applicationId))
                .replace(`{${'schedulePartId'}}`, encodeURIComponent(schedulePartId)),
            method: 'PUT',
            headers: {
                'content-type': 'application/json',
            },
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);

        request.data = onSerializeApplicationSchedulePart(data);

        const response = await globalAxios.request<void>(request);
        

        return response;
    }

    public async deleteApplicationIdScheduleSchedulePartId(applicationId: string, schedulePartId: string, headers?: AxiosHeaders): Promise<AxiosResponse<void>> {
        const request = {
            url: this.baseUrl + '/Applications/{applicationId}/schedule/{schedulePartId}'
                .replace(`{${'applicationId'}}`, encodeURIComponent(applicationId))
                .replace(`{${'schedulePartId'}}`, encodeURIComponent(schedulePartId)),
            method: 'DELETE',
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<void>(request);
        

        return response;
    }
}